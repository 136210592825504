import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import axiosConfig from "../../../../../utils/axiosConfig";
import { apiRoutes } from "utils/apiRoutes";

export const getCustomTags = createAsyncThunk(
  "customtags/getCustomtags",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.adminGetCustomtags}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetCustomtags}?data_limit=all&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const createCustomtag = createAsyncThunk(
  "customtags/createCustomtags",
  async (body, { dispatch }) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminCreateCustomTag, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const customTagdeleteApi = createAsyncThunk(
  "customtags/customTagdeleteApi",
  async (userIds, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminDeleteCustomtags, {
          ids: userIds?.body?.ids,
        }),
      );

      return dispatch(getCustomTags(userIds?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const customtagEditApi = createAsyncThunk(
  "customtags/customtagEditApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateCustomName}/${data?.body?.ids}`,
          data.body,
        ),
      );
      return dispatch(getCustomTags(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getCustomTagsByid = createAsyncThunk(
  "customtags/getCustomTagsByid",
  async (id) => {
    try {
      let apiUrl = `${apiRoutes.adminGetCustomtags}/${id}`;
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const customTagUpdateApi = createAsyncThunk(
  "customtags/customTagUpdateApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes?.adminCustomTagUpdate}/${data?.id}`,
          data?.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const customTagVisibilityCountApi = createAsyncThunk(
  "customtags/customTagVisibilityCountApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.adminCustomtagVisibilityCount),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const customTagRequiredCountApi = createAsyncThunk(
  "customtags/customTagRequiredCountApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.adminCustomtagRequiredCount),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getCustomTagDetail = createAsyncThunk(
  "customtags/getCustomtagsDeatil",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData?.id && (!paramData?.limit || !paramData?.page)) {
        apiUrl = `${apiRoutes.adminGetCustomTagDetail}/${paramData?.id}?limit=5000`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetCustomTagDetail}/${paramData?.id}?sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }

      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//user get all custom tag
export const getCustomTagDetailUser = createAsyncThunk(
  "customtags/getCustomTagDetailUser",
  async (paramData = null) => {
    try {
      const apiUrl = `${apiRoutes.adminGetCustomTagDetail}/${paramData?.id}?limit=99999`;
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllCustomTagDetail = createAsyncThunk(
  "customtags/getAllCustomTagDetail",
  async (paramData = null) => {
    try {
      let apiUrl;
      apiUrl = `${apiRoutes.adminGetCustomTagDetail}/${paramData?.[0]}?limit=9999999`;
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const addCustomTagData = createAsyncThunk(
  "customtags/addCustomTagData",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(`${apiRoutes.adminAddCustomTagData}`, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateCustomTagData = createAsyncThunk(
  "customtags/updateCustomTagData",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminEditCustomTagData}${body?.id}`,
          body?.body,
        ),
      );
      return dispatch(getCustomTagDetail(body?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteCustomTagData = createAsyncThunk(
  "customtags/deleteCustomTagData",
  async (collectionid, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminDeleteCustomTagData, collectionid.body),
      );
      return dispatch(getCustomTagDetail(collectionid?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const customTagCsvUpload = createAsyncThunk(
  "customtags/customTagCsvUpload",
  async (formData) => {
    try {
      const formDatanew = new FormData();
      formDatanew.append("csvFile", formData.file);
      return await performApiRequest(() =>
        axiosConfig.post(
          `${apiRoutes.adminCustomTagCsvUpload}${formData?.id}`,
          formDatanew,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//filter API's customtags
export const getCustomTagsFields = createAsyncThunk(
  "customtags/getCustomTagsFields",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetCustomTagsFileds}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetCustomTagsFileds}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getCustomTagByCollectionType = createAsyncThunk(
  "customtags/getCustomTagByCollectionType",
  async (data) => {
    try {
      const {
        collectionTypeId,
        page,
        limit,
        search,
        custom_tag_id,
        alfabetSearch,
      } = data;
      let apiUrl = `${apiRoutes.getCustomTagByCollectionType}/${collectionTypeId}`;
      const params = new URLSearchParams();

      if (page) params.append("page", parseInt(page));
      if (limit) params.append("limit", parseInt(limit));
      if (search) params.append("search", search);
      if (custom_tag_id) params.append("custom_tag_id", custom_tag_id);
      if (alfabetSearch) params.append("alfabetSearch", alfabetSearch);

      if (params.toString()) {
        apiUrl += `?${params.toString()}`;
      }

      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllCustomTagsDataKeywords = createAsyncThunk(
  "customtags/getAllCustomTagsDataKeywords",
  async (data) => {
    try {
      const { collectionTypeId, limit, custom_tag_id, search, alfabetSearch } =
        data;
      let apiUrl = `${apiRoutes.getCustomTagByCollectionType}/${collectionTypeId}`;
      const params = new URLSearchParams();

      if (custom_tag_id) params.append("custom_tag_id", custom_tag_id);
      if (limit) params.append("limit", parseInt(limit));
      if (search) params.append("search", search);
      if (alfabetSearch) params.append("alfabetSearch", alfabetSearch);

      if (params.toString()) {
        apiUrl += `?${params.toString()}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//filter API's customtags Data
export const getCustomTagsDataFields = createAsyncThunk(
  "customtags/getCustomTagsDataFields",
  async (data) => {
    try {
      const { column, filters, id } = data;
      let apiUrl = `${apiRoutes.adminGetCustomTagsDataFields}/${id}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetCustomTagsDataFields}/${id}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
