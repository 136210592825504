import { styled } from "@mui/system";

const KeyWordStyle = styled("div")(({ theme }) => ({
  "&.addOpacity": {
    opacity: "0.24",
    pointerEvents: "none",
  },
  ul: {
    padding: "0",
    margin: "0 0 16px 0",
    listStyle: "none",
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    ".noKeyWords ": {
      color: theme.palette.gray_400.main,
    },
    "span.readAllBtn": {
      cursor: "pointer",
      color: theme.palette.primaryColor.main,
      display: "flex",
      alignItems: "center",
    },
  },
  ".keyWordHeading": {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    margin: "32px 0 16px",
    button: {
      backgroundColor: theme.palette.primaryColor.main,
      color: theme.palette.whiteColor.main,
      svg: {
        path: {
          stroke: theme.palette.whiteColor.main,
        },
      },
    },
  },
  ".keyWordsAccording": {
    marginTop: "24px",
    ".MuiPaper-root": {
      border: "0",
      boxShadow: "unset",
      "&:not(last-child)": {
        borderBottom: `1px solid ${theme.palette.gray_250.main}`,
      },
      ".MuiCollapse-root": {
        ".MuiAccordionDetails-root": {
          padding: "0",
          ".noKeyWordFound": {
            color: theme.palette.gray_400.main,
          },
        },
      },
      ".MuiAccordionSummary-root": {
        padding: "0",
        ".MuiAccordionSummary-content": {
          margin: "24px 0 16px 0",
          justifyContent: "space-between",
          span: {
            color: theme.palette.blackColor.main,
            "&.keywordNumbers": {
              color: theme.palette.gray_300.main,
              marginLeft: "8px",
            },
          },
        },
      },
      "&:before": {
        display: "none",
      },
    },
  },
  ".showCaseDetailsFooterButtons": {
    position: "fixed",
    bottom: "0",
    width: "100%",
    left: "0",
    display: "flex",
    alignItems: "center",
    padding: "8px 64px",
    justifyContent: "end",
    gap: "8px",
    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.07)",
    backgroundColor: theme.palette.whiteColor.main,
    zIndex: "99",
    borderTop: `1px solid ${theme.palette.gray_250.main}`,
    button: {
      height: "32px",
      padding: "0 16px",
      "&.saveShowcaseDetails": {
        width: "100%",
        maxWidth: "200px",
        svg: {
          marginRight: "8px",
          path: {
            stroke: theme.palette.whiteColor.main,
          },
        },
      },
    },
  },
}));

export default KeyWordStyle;
