import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";
export const getContactUsTitleAction = createAsyncThunk(
  "contactUs/getContactUsTitleApi",
  async () => {
    try {
      return await performApiRequest(() =>axiosConfig.get(apiRoutes.getContactUsTitle));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateContactUsTitleAction= createAsyncThunk(
  "contactUs/updateContactUsTitleApi",
  async (body) => {
    try {
      return await performApiRequest(
        () => axiosConfig.put(apiRoutes.updateContactUsTitle, body)
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

