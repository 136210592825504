export function copyToClipboard(containerId) {
  const container = document.getElementById(containerId);

  if (!container) {
    console.error(`Element with ID '${containerId}' not found.`);
    return;
  }

  // Create a range and select the contents of the container
  const range = document.createRange();
  range.selectNodeContents(container);

  // Clear any current selections
  const selection = window.getSelection();
  selection.removeAllRanges();
  selection.addRange(range);

  // Try to copy the selection to the clipboard
  try {
    const successful = document.execCommand("copy");
    if (successful) {
      console.log("Text copied to clipboard successfully!");
    } else {
      console.error("Failed to copy text to clipboard.");
    }
  } catch (err) {
    console.error("Oops, unable to copy: ", err);
  }

  // Clear the selection (optional)
  selection.removeAllRanges();
}
