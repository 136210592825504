import React from "react";
import _ from "lodash";
import dayjs from "dayjs";
import { toCamelCase } from "components/ContributePopup/ConttributePopup";
import { fieldTypeMappedObject } from "pages/Admin-Pages/fields-management/Components/AddNewFieldPopup";
import DateComponent from "components/DateComponent/DateComponent";
import ContributePopTextfieldComp from "components/Textfield/ContributePopTextfieldComp";
import ContributePopTextAreaComp from "components/Textfield/ContributePopTextAreaComp";
import ContributePopSectionHeader from "components/ContributePopup/ContributePopupBody/components/ContributePopSectionHeader/ContributePopSectionHeader";
import ToggleSwitch from "components/Switch/ToggleSwitch";
import SearchSelectComp from "components/Select/SearchSelectComp";
import CheckboxWithLabel from "components/Checkbox/CheckboxWithLabel";
import { SaveMapLocation } from "components/MapLocationComp";
import TextNumberField from "components/Textfield/TextNumberField";

const DetailFormFields = ({ fieldData, formik }) => {
  const camelCaseName = toCamelCase(fieldData?._id);

  const onValueAddChange = (value) => {
    //add or replace the values
    // formik.setFieldValue(camelCaseName, value);
    formik.handleChange({
      target: {
        name: camelCaseName,
        value,
      },
    });
  };

  return (
    <div className="editFieldsData">
      <ContributePopSectionHeader
        sectionHeading={fieldData?.name}
        isRequired={fieldData?.required_enabled}
        tooltipHeading={fieldData?.name}
        tooltipContent={fieldData?.description}
        id={fieldData?.name}
        tooltipClass={"fieldEditTooltip"}
      />

      {fieldData?.field_type?.name === fieldTypeMappedObject?.address && (
        <SaveMapLocation
          getSelectedLocation={
            formik.values?.[camelCaseName] ? formik.values?.[camelCaseName] : {}
          }
          setSelectedLocation={() => {}}
          unMountActiveComponent={() => {}}
          showTextFieldOnly={true}
          onChange={(e) => {
            if (e && e.formatted_address) {
              onValueAddChange(JSON.stringify(e));
            } else {
              onValueAddChange(null);
            }
          }}
        />
      )}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.date && (
        <DateComponent
          date={dayjs(formik?.values?.[camelCaseName], "DD/MM/YYYY")}
          onDateChange={(e) => {
            const date = e?.$d ? dayjs(e?.$d).format("DD/MM/YYYY") : "";
            onValueAddChange(date);
          }}
        />
      )}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.textBox &&
        (fieldData?.field_default_value?.[0]?.limit ? (
          <ContributePopTextfieldComp
            maxLength={fieldData?.field_default_value?.[0]?.limit}
            value={formik?.values?.[camelCaseName]}
            name={camelCaseName}
            onChange={formik?.handleChange}
          />
        ) : (
          <ContributePopTextAreaComp
            value={formik?.values?.[camelCaseName]}
            name={camelCaseName}
            onChange={formik?.handleChange}
          />
        ))}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.toggle &&
        fieldData?.field_default_value?.[0]?.value?.length > 0 && (
          <ToggleSwitch
            name={camelCaseName}
            checked={formik.values?.[camelCaseName] === "active" ? true : false}
            onChange={(e) => {
              const value = e?.target?.checked ? "active" : "inactive";
              onValueAddChange(value);
            }}
          />
        )}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.dropDown &&
        fieldData?.field_default_value?.[0]?.value?.length > 0 && (
          <SearchSelectComp
            parentClass="editFieldsData"
            selectData={fieldData?.field_default_value?.[0]?.value}
            selectValue={
              fieldData?.field_default_value?.[0]?.value?.filter(
                (a) => a.value === formik?.values?.[camelCaseName],
              )?.[0]
            }
            selectName={"option"}
            title={fieldData?.name}
            onSelectChange={(i) => {
              const value = i?.value;
              onValueAddChange(value);
            }}
          />
        )}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.checkbox &&
        fieldData?.field_default_value?.[0]?.value?.length > 0 && (
          <div className="contributePopCheckboxes">
            {_.map(fieldData?.field_default_value?.[0]?.value, (r) => {
              return (
                <CheckboxWithLabel
                  key={r?.value}
                  labelName={r?.label}
                  name={camelCaseName}
                  value={r?.value}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                  checked={
                    formik?.values?.[camelCaseName]?.filter(
                      (c) => c === r?.value,
                    )?.[0]
                  }
                />
              );
            })}
          </div>
        )}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.customTag &&
        fieldData?.field_default_value?.[0]?.value && (
          <SearchSelectComp
            parentClass="editFieldsData"
            selectData={fieldData?.field_default_value?.[0]?.value}
            selectName={"option"}
            title={fieldData?.name}
            selectValue={
              fieldData?.field_default_value?.[0]?.value?.filter(
                (a) => a.custom_tag_data_id === formik?.values?.[camelCaseName],
              )?.[0]
            }
            onSelectChange={(i) => {
              const value = i?.custom_tag_data_id;
              onValueAddChange(value);
            }}
          />
        )}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.number && (
        <TextNumberField
          value={formik?.values?.[camelCaseName]}
          name={camelCaseName}
          onChange={formik?.handleChange}
        />
      )}
    </div>
  );
};

export default DetailFormFields;
