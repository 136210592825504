import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, TextField, Box } from "@mui/material";
import CustomButton from "components/Button/CustomButton";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import ToggleSwitch from "pages/Components/ToggleSwitch/ToggleSwitch";
import CustomComponentFilter from "pages/Components/CustomComponentfilter/CustomComponentFilter"; // getFieldsDataHelper, // ShowHeaderIcon,
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import CommonGrid from "components/Grid/CommonGrid";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import {
  createSearchApi,
  deleteSearchesApi,
  getAllSearchApi,
  // getSearchFieldsApi,
  updateSearchOrderApi,
  updateSearchStatusApi,
} from "./features/trendingSearchesAction";
import { allSearches, searchFieldData } from "./features/trendingSearchesSlice";
import CommonDeletePopup from "pages/Components/CommonDeletePopup/CommonDeletePopup";
import { FieldManagementStyle } from "pages/Admin-Pages/fields-management/fieldManagementStyle";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";

export default function TrendingSearches() {
  const dispatch = useDispatch();
  const fieldValueData = useSelector(searchFieldData);
  const searchData = useSelector(allSearches);
  const { t } = useTranslation();
  const inputRef = useRef(null);
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "rowDrag",
      headerName: "",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxDragColumn",
      maxWidth: "110",
      suppressNavigable: true,
      lockPosition: "left",
      foreverShow: true,
    },
    {
      field: "search",
      headerName: t("trendingSearches"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        // const filterClickProps = {
        //   dispatchGetName: "search",
        //   dispatchName: "search",
        //   applied_filters: params?.applied_filters_data?.applied_filters,
        // };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            // onClick={(e) => {
            //   commonFilterOnClick(e, filterClickProps);
            // }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            {/* <button>
              <ShowHeaderIcon
                columnName={"search"}
                sortBy={"search"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button> */}
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "number_of_search",
      headerName: t("count"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        // const filterClickProps = {
        //   dispatchGetName: "number_of_search",
        //   dispatchName: "number_of_search",
        //   applied_filters: params?.applied_filters_data?.applied_filters,
        // };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            // onClick={(e) => {
            //   commonFilterOnClick(e, filterClickProps);
            // }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            {/* <button>
              <ShowHeaderIcon
                columnName={"number_of_search"}
                sortBy={"number_of_search"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button> */}
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "enabled",
      headerName: t("status"),
      headerClass: "fieldCenter",
      headerComponent: (params) => {
        // const filterClickProps = {
        //   dispatchGetName: "enabled",
        //   dispatchName: "enabled",
        //   applied_filters: params?.applied_filters_data?.applied_filters,
        // };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            // onClick={(e) => {
            //   commonFilterOnClick(e, filterClickProps);
            // }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            {/* <button>
              <ShowHeaderIcon
                columnName={"enabled"}
                sortBy={"enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button> */}
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn",
      cellRenderer: (props) => {
        return (
          <Grid className="toggleBlockCo">
            <ToggleSwitch
              checked={props?.value}
              value={props?.value}
              defaultChecked={props?.value}
              onChange={(e) => {
                gridRef.current.api.deselectAll();
                handleSwitchChange(
                  props?.data?._id, //id
                  props?.value, //value
                  props,
                );
              }}
            />
          </Grid>
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <TableDeleteIcon
            onClick={() => {
              gridRef.current.api.deselectAll();
              setSelectedRows([]);
              setCurrentRowData(props?.data);
              setRowsToBeDeleted([props?.data?._id]);
              setOpenDelete(true);
            }}
          />
        </Grid>
      ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [settingType, setSettingType] = useState();
  const [currentRowData, setCurrentRowData] = useState();
  const [searchName, setTypeName] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState();
  const [alphaNumErrorAdd, setAlphaNumErrorAdd] = useState("");
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter

  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "asc", //probable values: asc, desc
    sortBy: "display_index", //column_name through which sort is applied like createdAt||
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    // setIsLoading(true);
    resetErrorStates();
    dispatch(getAllSearchApi(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  useEffect(() => {
    setPreviousColumnFilterData(searchData?.applied_filters);
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = searchData?.[commonKey]),
    );

    setRowData(
      searchData?.data?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        search: tableDataObj?.search,
        number_of_search: tableDataObj?.number_of_search,
        enabled: tableDataObj?.enabled,
        action: "",
        other_table_data: commonDataObj,
      })),
    );
  }, [searchData]);

  const handleSwitchChange = useCallback(
    async (entryId, switchValue, props) => {
      gridRef.current.api.forEachNode((rowNode) => {
        if (rowNode?.data?._id !== entryId) {
          return false;
        }
        setSelectedRows([]);
        resetErrorStates();

        rowNode.setDataValue("enabled", !switchValue);

        const body = {
          id: entryId,
          enabled: !switchValue,
        };

        let modifiedDataState = dataState;

        if (props?.data?.other_table_data) {
          const other_data = props?.data?.other_table_data;
          modifiedDataState = modifyDataState(other_data);
        }

        const databody = { body, dataState: modifiedDataState };

        dispatch(updateSearchStatusApi(databody)).then((res) => {
          if (res.error) {
            //reverting the change in case of api error
            dispatch(getAllSearchApi(modifiedDataState));
          }
        });
      });
    },
    //eslint-disable-next-line
    [],
  );

  const resetErrorStates = () => {
    setAlphaNumErrorAdd("");
  };

  const settingNewSearch = (e) => {
    const value = e.target.value;
    const alphaNumericRegex = validationRegex.alphanumeric;
    if (inputRef.current !== null) {
      inputRef.current.value = value;
      setTypeName(e.target.value);
    }
    if (value.length > 50) {
      setAlphaNumErrorAdd(validationMessages.tooLong);
    } else if (!alphaNumericRegex.test(value)) {
      setAlphaNumErrorAdd(validationMessages?.alphanumericOnly);
    } else if (!validationRegex?.checkForDoubleSpaces.test(value)) {
      setAlphaNumErrorAdd(validationMessages?.noConsecutiveDoubleSpaces);
    } else {
      resetErrorStates();
    }
  };

  function addNewSearch() {
    const trimmedSearch = !!searchName && searchName.trim();
    if (!!trimmedSearch && searchName.trim() !== "") {
      if (trimmedSearch.length > 50) {
        setAlphaNumErrorAdd(validationMessages.tooLong);
      } else if (!validationRegex.alphanumeric.test(trimmedSearch)) {
        setAlphaNumErrorAdd(validationMessages?.alphanumericOnly);
      } else if (!validationRegex?.checkForDoubleSpaces.test(trimmedSearch)) {
        setAlphaNumErrorAdd(validationMessages?.noConsecutiveDoubleSpaces);
      } else {
        const body = { search: trimmedSearch };
        dispatch(createSearchApi(body)).then((res) => {
          if (!res?.error) {
            setDataState({
              ...dataState,
              search: "",
              limit: 20,
              page: 1,
              column_name: "",
              column_values: "",
              filtersQueryString: [],
            });
            inputRef.current.value = "";
            setTypeName("");
            resetErrorStates();
          }
        });
      }
    } else {
      setAlphaNumErrorAdd(validationMessages.pleaseAddTrendingSearch);
    }
  }

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  function handleDelete() {
    const body = {
      ids:
        rowsToBeDeleted?.length > 0 ? rowsToBeDeleted : [currentRowData?._id],
    };

    const dataBody = { body, dataState };

    const shouldPageChange = deletePageChangeChecker(
      searchData?.currentPage,
      searchData?.totalDocuments,
      rowsToBeDeleted?.length,
      searchData?.limit,
    );

    dataBody.dataState = {
      ...dataState,
      page: shouldPageChange
        ? parseInt(dataState?.page) - 1
        : parseInt(dataState?.page),
    };

    dispatch(deleteSearchesApi(dataBody)).then(() => {
      gridRef.current.api.deselectAll();
      if (shouldPageChange) setDataState(dataBody.dataState);
    });

    setOpenDelete(false);
    setSelectedRows([]);
  }

  // function commonFilterOnClick(e, props) {
  //   const { dispatchGetName, dispatchName, applied_filters } = props;
  //   const ref = e.currentTarget;
  //   getFieldsDataHelper(
  //     applied_filters,
  //     dispatchGetName,
  //     dispatch,
  //     getSearchFieldsApi,
  //   );
  //   setOpenPopOver({
  //     ...openPopOver,
  //     open: ref,
  //     dispatchName,
  //     keys: "",
  //   });
  // }

  const onRowDragEnd = useCallback(
    (e) => {
      const dataBody = {
        body: {
          old_index:
            parseInt(e.node.id) +
            1 +
            (dataState.limit * dataState.page - dataState.limit),
          new_index:
            parseInt(e.node.rowIndex) +
            1 +
            (dataState.limit * dataState.page - dataState.limit),
        },
        dataState,
      };
      dispatch(updateSearchOrderApi(dataBody));
    },
    [dataState, dispatch],
  );

  const icons = useMemo(() => {
    return {
      rowDrag: `<img src ="./logos/menu.svg")} style="height: 46px; width: 26px;padding-right: 2px"/>`,
    };
  }, []);

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
    onRowDragEnd,
    rowDragManaged: true,
    icons,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("trendingSearches")} />
      <Box className="tabsLine" />
      <Box container spacing={4} className="userReq-table">
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType ">
                <Box className="leftSideInputs">
                  <Grid container spacing={4} className={`addNew`}>
                    <Box className={"addNewDiv"}>
                      <TextField
                        placeholder={t("enterTrendingSearch")}
                        size="small"
                        className={"addNewText"}
                        variant="outlined"
                        onChange={settingNewSearch}
                        onKeyDown={(e) => {
                          if (e.code === "Enter" || e.code === "NumpadEnter")
                            addNewSearch();
                        }}
                        inputRef={inputRef}
                      />
                    </Box>
                    <Box className={"addNewDiv1"}>
                      <CustomButton
                        onClick={() => addNewSearch()}
                        variant="outlined"
                      >
                        {t("add")}
                      </CustomButton>
                    </Box>
                    <span className="errorClass">{alphaNumErrorAdd}</span>
                  </Grid>
                  <PaginationLimitDropDown
                    dataState={dataState}
                    setDataState={setDataState}
                    setSelectedRows={setSelectedRows}
                  />
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={searchData?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
            />
          </Box>
        </Box>
      </Box>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("trendingMultipleDeleteConfirmationText")}
        singleDeleteText={t("trendingDeleteConfirmationText")}
      />
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
}
