import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { deepCopy } from "helper/deepCopyFunction";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import {
  allSortedMarketSegmentData,
  getQuickLinkFromSelector,
} from "./features/marketSegmentSlice";
import {
  getQuickLinkTitleAction,
  marketSegmentsColIndexSortedApi,
  updateMarketSegmentIndexApi,
  updateQuickLinkTitleAction,
} from "./features/marketSegmentsAction";
import SegmentColumn from "./SegmentColumn/SegmentColumn";
import FooterMenuStyles from "pages/Admin-Pages/pages-cms/footerMenu/footerMenuStyles";
import { Box } from "@mui/system";
import CustomButton from "components/Button/CustomButton";
import * as Yup from "yup";
import { DialogActions, DialogContent, TextField } from "@mui/material";
import { ErrorMessage, Form, Formik } from "formik";
import { t } from "i18next";
import { UserDetailDialogDesign } from "pages/Admin-Pages/user-administration/styles/roleStyles";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import DialogCommon from "components/DialogComponent/DialogCommon";
const MarketSegments = () => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const { data: orderedPages } = useSelector(allSortedMarketSegmentData);
  const { t } = useTranslation();
  const [popUpName, setPopUpName] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);
  const { data: dynamicQuickTitleData } = useSelector(getQuickLinkFromSelector);

  useEffect(() => {
    dispatch(marketSegmentsColIndexSortedApi());
    dispatch(getQuickLinkTitleAction());
  }, [dispatch]);

  useEffect(() => {
    if (orderedPages?.market_segment?.length > 0) {
      const pagesData = deepCopy(orderedPages?.market_segment);
      pagesData?.sort((prev, next) => prev.display_index - next.display_index);
      setColumns(pagesData);
    }
  }, [orderedPages]);

  async function handleRemoveChild(link, colId) {
    const copiedCols = deepCopy(columns);
    //getting the array of lists from where the element will be removed
    const listArray = copiedCols.filter(
      (colData) => colData?._id === colId,
    )?.[0]?.link_data;

    //also find the index from where the source list array is found
    const souceListArrayIndex = columns?.findIndex(
      (colData) => colData?._id === colId,
    );

    //finding the index of the item in the list
    const linkIndex = listArray?.findIndex((a) => a?.name === link?.name);

    listArray?.splice(linkIndex, 1);

    copiedCols[souceListArrayIndex].link_data = listArray;

    updateFooterDetails(copiedCols, false);

    return;
  }

  function updateFooterDetails(details, showToast = true) {
    setColumns(details);

    const finalDetails = details?.map((data, index) => {
      const obj = { ...data };
      obj.market_segment_id = data?._id;
      obj.display_index = index;
      obj.market_link_id = data?.link_data?.map((indiData) => {
        return {
          link: indiData?._id,
          target_blank: indiData?.target_blank,
          _id: indiData?.link_id,
        };
      });
      return obj;
    });

    const body = { detail: finalDetails };
    const data = { body, showToast };
    dispatch(updateMarketSegmentIndexApi(data)).then((res) => {
      if (res?.error) return;
      dispatch(marketSegmentsColIndexSortedApi());
    });
  }

  function onDragEnd(result) {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const source = result.source;
    const destination = result.destination;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      //copying the array
      const arr = [...columns];

      //finding the element we have to move
      const element = arr[source.index];

      //remove the element from its source position
      arr.splice(source.index, 1);

      //puting the element at its destination position
      arr.splice(destination.index, 0, element);

      //updating the result
      updateFooterDetails(arr);
      return;
    }

    //else reordering lists inside the column

    const clonedArray = deepCopy(columns); //clone the array
    const sourceColId = source?.droppableId; //column Id of source
    const sourceListIndex = source?.index; //list index of source
    const destinationColId = destination?.droppableId; //column Id of destination
    const destinationListIndex = destination?.index; //list index of destination

    //getting the array of lists from where the element will be removed
    const sourceListArray = columns.filter(
      (colData) => colData?._id === sourceColId,
    )?.[0]?.link_data;

    //also find the index from where the source list array is found
    const souceListArrayIndex = columns.findIndex(
      (colData) => colData?._id === sourceColId,
    );

    //getting the element that we have to move
    const elementToMove = sourceListArray?.[sourceListIndex];

    /*---removing the element from its source position---*/

    //copying the inner list array to make changes
    const dupSourceListArray = deepCopy(sourceListArray);

    //removing the element from innerlist
    dupSourceListArray?.splice(sourceListIndex, 1);

    //if change is in the same list do it here
    if (sourceColId === destinationColId) {
      dupSourceListArray?.splice(destinationListIndex, 0, elementToMove);
      clonedArray[souceListArrayIndex].link_data = dupSourceListArray;
      updateFooterDetails(clonedArray);
      return;
    }

    //putting the modified inner list inside the whole array
    clonedArray[souceListArrayIndex].link_data = dupSourceListArray;

    //--------------------------------------------------------

    const destinationListArray = columns.filter(
      (colData) => colData?._id === destinationColId,
    )?.[0]?.link_data;

    //finding the index where the destination list array is found
    const destinationListArrayIndex = columns.findIndex(
      (colData) => colData?._id === destinationColId,
    );

    /*---adding the element to its destination position---*/

    //copying the inner list array to make changes
    const dupDestinationListArray = deepCopy(destinationListArray);

    //adding the element to innerlist
    dupDestinationListArray?.splice(destinationListIndex, 0, elementToMove);

    // putting the modified inner list inside the whole array
    clonedArray[destinationListArrayIndex].link_data = dupDestinationListArray;

    updateFooterDetails(clonedArray);
  }

  return (
    <FooterMenuStyles className="marketSegmentMain">
      <Box className="titleButton">
        <PageTitle title={dynamicQuickTitleData?.title || t("quickLinks")} />
        <Box className={"addNewDiv1"}>
          <CustomButton
            onClick={() => {
              setPopUpName(t("changeSectionTitle"));
              setOpenPopUp(true);
            }}
            variant="outlined"
          >
            {t("changeSectionTitle")}
          </CustomButton>
        </Box>
      </Box>
    
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="board"
          type="COLUMN"
          direction="horizontal"
          ignoreContainerClipping={false}
          isCombineEnabled={false}
        >
          {(provided) => (
            <div
              className="footerMenuColumnOuter"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {columns.map((columnData, index) => {
                const listData = columnData?.link_data;
                return (
                  <SegmentColumn
                    key={columnData?._id}
                    index={index}
                    title={columnData?.name}
                    id={columnData?._id}
                    listData={listData}
                    isScrollable={true}
                    isCombineEnabled={false}
                    handleRemoveChild={handleRemoveChild}
                    columnData={columnData}
                  />
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <ChangeSelectionTitle
        popUpName={popUpName}
        closePopUp={() => setOpenPopUp(false)}
        open={openPopUp}
        data={dynamicQuickTitleData}
        dispatch={dispatch}
      />
    </FooterMenuStyles>
  );
};

export default MarketSegments;

const ChangeSelectionTitle = ({
  data,
  popUpName,
  closePopUp,
  open,
  dispatch,
}) => {
  const validationSchema = Yup.object({
    title: Yup.string()
      .trim().max(50, validationMessages.tooLong)
      .required(t("pleaseEnterPageTitle"))
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      ),
    description: Yup.string()
      .trim()
      .required(t("pleaseEnterDescription"))
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      ).max(400, validationMessages.tooLong),
  });
  const submitHandler = async (values) => {
    const body = {
      quickLinkId: data?._id,
      title: values?.title,
      description: values?.description,
    };
    dispatch(updateQuickLinkTitleAction(body)).then(() =>
      dispatch(getQuickLinkTitleAction()),
    );
    return closePopUp();
  };
  return (
    <DialogCommon
      title={popUpName}
      handleClose={closePopUp}
      open={open}
      extraPaperClasses={`singleFileInput`}
      maxWidth={"md"}
    >
      <UserDetailDialogDesign>
        <Box className="popup-main AddUserDetails addEditCollectionType">
          <DialogContent>
            <Box className="requesOuter">
              <Formik
                initialValues={{
                  title: data?.title,
                  description: data?.description,
                }}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
              >
                {({ values, handleBlur, handleChange }) => (
                  <Form>
                    <Box className="formSection">
                      <Box className="request-common">
                        <label htmlFor="title" className="label">
                          {t("enterName", { name: t("title") })}
                        </label>
                        <TextField
                          placeholder={t("enterName", { name: t("title") })}
                          size="small"
                          className={"addNewText"}
                          variant="outlined"
                          name={"title"}
                          id={"title"}
                          value={values?.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="title"
                          component={"span"}
                          className="errorClass"
                        />
                      </Box>
                      <Box className="request-common">
                        <label htmlFor={"description"} className="label">
                          {t("description")}
                        </label>
                        <TextField
                          placeholder={t("description")}
                          rows={3}
                          size="small"
                          variant="outlined"
                          type="text"
                          multiline={true}
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description ?? ""}
                        />
                        <ErrorMessage
                          name="description"
                          component={"span"}
                          className="errorClass"
                        />
                      </Box>
                      <DialogActions className="popup-button-outer">
                        <CustomButton variant={"contained"} type="submit">
                          {t("save")}
                        </CustomButton>
                        <CustomButton
                          variant={"contained"}
                          onClick={closePopUp}
                        >
                          {t("cancel")}
                        </CustomButton>
                      </DialogActions>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Box>
      </UserDetailDialogDesign>
    </DialogCommon>
  );
};
