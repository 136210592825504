import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";

const HomeSliderTopStyles = styled("div")(({ theme }) => ({
  ".featured_project_top": {
    display: "flex",
    alignItems: "center",
    margin: "0px 4rem 2.5rem 0",
    justifyContent: "space-between",
    ".featured_project_heading": {
      h2: {
        color: theme.palette.blackColor.main,
        margin: "0",
      },
    },
    ".featured_slider_buttons": {
      display: "flex",
      gap: "1rem",
      "svg.featured_icon": {
        cursor: "pointer",
        width: "1.5rem",
        height: "1.5rem",
        fill: theme.palette.blackColor.main,
        "&:is(.featured_svg_enabled)": {
          "&:hover": {
            fill: theme.palette.primaryColor.main,
          },
        },
        "&.featured_svg_disabled": { opacity: 0.4, cursor: "unset" },
      },
    },
  },
}));

const HomeSliderTop = (props) => {
  const { sectionHeading, swiperElRef, slidesPerView } = props;

  const [navigationButtonStatus, setNavigationButtonStatus] = useState({
    disable_prev_button: true,
    disable_next_button: false,
  });

  useEffect(() => {
    // listen for Swiper events using addEventListener
    swiperElRef.current.addEventListener("swiperprogress", (e) => {
      const [, progress] = e.detail;
      if (progress <= 0) {
        setNavigationButtonStatus({
          disable_prev_button: true,
          disable_next_button: false,
        });
      } else if (progress >= 1) {
        setNavigationButtonStatus({
          disable_prev_button: false,
          disable_next_button: true,
        });
      } else {
        setNavigationButtonStatus({
          disable_prev_button: false,
          disable_next_button: false,
        });
      }
    });
  }, [swiperElRef]);

  const slidesPerViewRounded = slidesPerView && Math.ceil(slidesPerView);

  return (
    <HomeSliderTopStyles>
      <div className="featured_project_top">
        <div className="featured_project_heading">
          <HeadingTag variant={headingVariants.h2}>{sectionHeading}</HeadingTag>
        </div>
        <div className="featured_slider_buttons">
          <ArrowBackIosRoundedIcon
            onClick={() => {
              if (
                swiperElRef?.current?.swiper?.slides?.length <
                slidesPerViewRounded
              ) {
                return;
              }
              swiperElRef.current.swiper.slidePrev();
            }}
            className={`featured_icon ${
              navigationButtonStatus?.disable_prev_button ||
              swiperElRef?.current?.swiper?.slides?.length <
                slidesPerViewRounded
                ? "featured_svg_disabled"
                : "featured_svg_enabled"
            }`}
          />
          <ArrowForwardIosRoundedIcon
            onClick={() => {
              if (
                swiperElRef?.current?.swiper?.slides?.length <
                slidesPerViewRounded
              ) {
                return;
              }
              swiperElRef.current.swiper.slideNext();
            }}
            className={`featured_icon ${
              navigationButtonStatus?.disable_next_button ||
              swiperElRef?.current?.swiper?.slides?.length <
                slidesPerViewRounded
                ? "featured_svg_disabled"
                : "featured_svg_enabled"
            }`}
          />
        </div>
      </div>
    </HomeSliderTopStyles>
  );
};

export default HomeSliderTop;
