import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import imageOne from "./Images/curatedGalleryImg1.jpg";
import imageTwo from "./Images/curatedGalleryImg2.jpg";
import imageThree from "./Images/curatedGalleryImg3.jpg";
import imageFour from "./Images/curatedGalleryImg4.jpg";
import imageFive from "./Images/curatedGalleryImg5.jpg";
import imageSix from "./Images/curatedGalleryImg6.jpg";
import imageSeven from "./Images/curatedGalleryImg7.jpg";
import imageEight from "./Images/curatedGalleryImg8.jpg";
import imageNine from "./Images/curatedGalleryImg9.jpg";
import imageTen from "./Images/curatedGalleryImg10.jpg";
import imageEleven from "./Images/curatedGalleryImg11.jpg";

import MainButton, { buttonVariants } from "components/Button/MainButton";
import { CuratedGalleryStyle } from "./curatedGalleryStyles";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";

gsap.registerPlugin(ScrollTrigger);

const HorizontalScroll = ({ galleryImages, isReversed = false }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    const container = containerRef.current;
    const images = container.querySelectorAll(".image");

    gsap.fromTo(
      images,
      // Set starting position based on isReversed
      isReversed ? { xPercent: 0 } : { xPercent: -20 * (images.length + 1) },
      {
        xPercent: isReversed ? -20 * (images.length + 1) : 0,
        ease: "none",
        scrollTrigger: {
          start: "top bottom",
          trigger: container,
          invalidateOnRefresh: true,
          pin: false,
          scrub: 1,
          end: "220% top",
        },
      },
    );

    // Cleanup function
    return () => gsap.killTweensOf(images);
  }, [galleryImages, isReversed]);

  return (
    <div
      ref={containerRef}
      id="horizontal-scroll"
      className="horizontal-scroll"
    >
      {galleryImages.map((image, index) => (
        <div key={index} className="image">
          <img src={image} alt={image} />
        </div>
      ))}
    </div>
  );
};

const CuratedGallery = () => {
  const { t } = useTranslation();

  const galleryImages = [
    imageOne,
    imageTwo,
    imageThree,
    imageFour,
    imageFive,
    imageSix,
  ];
  const galleryImagesBottom = [
    imageSeven,
    imageEight,
    imageNine,
    imageTen,
    imageEleven,
    imageOne,
  ];
  const reverseGalleryImages = [...galleryImages];
  reverseGalleryImages.reverse();

  return (
    <CuratedGalleryStyle>
      <div className="galleryBlock">
        <div className="curated_gallery_upper">
          <HeadingTag variant={headingVariants.h2}>
            {t("curatedGallery")}
          </HeadingTag>
          <TextTag variant={textVariants._16px.regular}>
            {t("curatedParaText")}
          </TextTag>
        </div>
        <div className="animation_section">
          <HorizontalScroll galleryImages={galleryImages} isReversed={true} />
          <HorizontalScroll galleryImages={galleryImagesBottom} />
        </div>
        <div className="curated_gallery_lower">
          <MainButton
            variant={buttonVariants.grey}
            title={"View Photo Gallery"}
          />
        </div>
      </div>
    </CuratedGalleryStyle>
  );
};

export default CuratedGallery;
