import { styled } from "@mui/system";

const HeroTrendingSearchesStyle = styled("div")(({ theme }) => ({
  ".heroTrendingSearchMain": {
    marginTop: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "0.5rem",

  },
}));

export default HeroTrendingSearchesStyle;
