import React from 'react'

const LinkIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M12 5.33203C13.1046 5.33203 14 4.4366 14 3.33203C14 2.22746 13.1046 1.33203 12 1.33203C10.8954 1.33203 10 2.22746 10 3.33203C10 4.4366 10.8954 5.33203 12 5.33203Z" stroke="#212A36" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4 10C5.10457 10 6 9.10457 6 8C6 6.89543 5.10457 6 4 6C2.89543 6 2 6.89543 2 8C2 9.10457 2.89543 10 4 10Z" stroke="#212A36" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12 14.668C13.1046 14.668 14 13.7725 14 12.668C14 11.5634 13.1046 10.668 12 10.668C10.8954 10.668 10 11.5634 10 12.668C10 13.7725 10.8954 14.668 12 14.668Z" stroke="#212A36" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.72656 9.00781L10.2799 11.6611" stroke="#212A36" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.2732 4.33984L5.72656 6.99318" stroke="#212A36" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    )
}

export default LinkIcon