import React from "react";

const CalendarIcon = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1 5.66667H13M3.66667 1V2.33333M10.3333 1V2.33333M3.13333 13H10.8667C11.6134 13 11.9868 13 12.272 12.8547C12.5229 12.7269 12.7269 12.5229 12.8547 12.272C13 11.9868 13 11.6134 13 10.8667V4.46667C13 3.71993 13 3.34656 12.8547 3.06135C12.7269 2.81046 12.5229 2.60649 12.272 2.47866C11.9868 2.33333 11.6134 2.33333 10.8667 2.33333H3.13333C2.3866 2.33333 2.01323 2.33333 1.72801 2.47866C1.47713 2.60649 1.27315 2.81046 1.14533 3.06135C1 3.34656 1 3.71993 1 4.46667V10.8667C1 11.6134 1 11.9868 1.14533 12.272C1.27315 12.5229 1.47713 12.7269 1.72801 12.8547C2.01323 13 2.38659 13 3.13333 13Z"
        stroke="#676D75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default CalendarIcon;
