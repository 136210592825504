import { styled } from "@mui/system";
import React, { useState } from "react";
import { BiPencil } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import { t } from "i18next";
import TextTag, { textVariants } from "components/Typography/TextTag";
import ButtonWithFrontIcon from "components/Button/ButtonWithfrontIcon";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";

const DetailFormLeftHeaderStyle = styled("div")(({ theme }) => ({
  display: "flex",
  position: "relative",
  gap: "16px",
  marginBottom: "16px",
  alignItems: "center",
  span: {
    color: theme.palette.blackColor.main,
  },
  button: {
    // "&.editButton": {
    //   opacity: "0",
    //   visibility: "hidden",
    // },
    "&.closeButton ": {
      backgroundColor: theme.palette.primaryColor.main,
      color: theme.palette.whiteColor.main,
    },
  },
  "&:hover": {
    button: {
      opacity: "1",
      visibility: "visible",
    },
  },
}));

const handleScrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth", // for smooth scrolling
  });
};

const DetailFormLeftHeader = ({
  title,
  onEditClick,
  activeComponent,
  onCloseClick,
  doNotHover,
  mainTitle,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  return (
    <DetailFormLeftHeaderStyle
      onMouseEnter={() => {
        setShowEdit(true);
      }}
      onMouseLeave={() => {
        setShowEdit(false);
      }}
      className={`${showEdit ? "onHover" : ""}`}
    >
      {mainTitle ? (
        <HeadingTag variant={headingVariants.h2}>{title}</HeadingTag>
      ) : (
        <TextTag variant={textVariants._14px.medium}>{title}</TextTag>
      )}
      {!doNotHover &&
        onEditClick &&
        (activeComponent ? (
          <ButtonWithFrontIcon
            icon={RxCross2}
            className="backButtonClass closeButton"
            variant="contained"
            onClick={onCloseClick}
          >
            {t("close")}
          </ButtonWithFrontIcon>
        ) : (
          <ButtonWithFrontIcon
            icon={BiPencil}
            className={`backButtonClass editButton`}
            variant="contained"
            onClick={() => {
              onEditClick();
              handleScrollToTop();
            }}
          >
            {t("edit")}
          </ButtonWithFrontIcon>
        ))}
    </DetailFormLeftHeaderStyle>
  );
};

export default DetailFormLeftHeader;
