import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContributePopSectionHeader from "../ContributePopSectionHeader/ContributePopSectionHeader";
import SelectComp from "components/Select/SelectComp";
import { getCollectionUser } from "pages/Admin-Pages/collections/features/collectionAction";
import { allCollectionsUser } from "pages/Admin-Pages/collections/features/collectionSlice";
import { StyledContributePopupBodySection } from "../contributePopupBodyCompStyles";
import { t } from "i18next";

const WhatTypeOfCollection = ({
  setCollectionType,
  setShowCollectionFields,
}) => {
  const dispatch = useDispatch();
  const allCollectionTypes = useSelector(allCollectionsUser)?.collections;

  useEffect(() => {
    dispatch(getCollectionUser());
    setShowCollectionFields(false);
    return () => {
      setShowCollectionFields(false);
    };
    //eslint-disable-next-line
  }, [dispatch]);

  return (
    <StyledContributePopupBodySection>
      <ContributePopSectionHeader
        sectionHeading={t("whatTypeOfCollection")}
        isRequired={true}
        tooltipHeading={t("whatTypeOfCollection")}
        tooltipContent={t("whatCollectionTypeTooltip")}
      />
      <SelectComp
        selectData={allCollectionTypes}
        selectName={"name"}
        selectValue={"_id"}
        onSelectChange={(i) => {
          setCollectionType(i);
          setShowCollectionFields(true);
        }}
      />
    </StyledContributePopupBodySection>
  );
};

export default WhatTypeOfCollection;
