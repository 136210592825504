import React, { useRef, useState } from "react";
import HeaderHoverMenuStyles from "./HeaderHoverMenuStyles";
import TextTag, { textVariants } from "components/Typography/TextTag";
import ArrowUpIcon from "components/Icons/ArrowIcons/ArrowUpIcon";
import ArrowDownIcon from "components/Icons/ArrowIcons/ArrowDownIcon";
import { headerMenuObj } from "../../HeaderNew";
import HeaderTypeMenu from "./HeaderTypeMenu/HeaderTypeMenu";
import HeaderCollectionMenu from "./HeaderCollectionMenu/HeaderCollectionMenu";

const HeaderHoverMenu = (props) => {
  const {
    itemName,
    headerTypeData,
    menuType,
    allTypes,
    activeTypeIndex,
    setActiveTypeIndex,
    index,
  } = props;
  const [isActive, setIsActive] = useState(false);
  const timeoutRef = useRef(null);

  const handleMouseEnter = () => {
    timeoutRef.current = setTimeout(() => {
      setIsActive(true);
      if (setActiveTypeIndex && index) {
        setActiveTypeIndex(index);
      }
    }, 200);
  };

  const handleMouseLeave = () => {
    setIsActive(false);
    clearTimeout(timeoutRef.current);
  };

  return (
    <HeaderHoverMenuStyles
      className={`headerItmeAndMenu ${isActive ? "active" : "inactive"}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      // onClick={() => {
      //   setIsActive(true);
      //   setActiveTypeIndex && index && setActiveTypeIndex(index);
      // }}
      {...props}
    >
      <div className="headerTextItem">
        <TextTag variant={textVariants._14px.medium}>{itemName}</TextTag>
        {headerTypeData?.noDropdown ? (
          ""
        ) : isActive ? (
          <ArrowUpIcon />
        ) : (
          <ArrowDownIcon />
        )}
      </div>
      {isActive && (
        <div className="headerMenu">
          {(menuType === headerMenuObj?.type ||
            menuType === headerMenuObj?.more) && (
            <HeaderTypeMenu
              menuType={menuType}
              headerTypeData={headerTypeData}
              allTypes={allTypes}
              activeTypeIndex={activeTypeIndex}
              setActiveTypeIndex={setActiveTypeIndex}
              handleMouseLeave={handleMouseLeave}
            />
          )}
          {menuType === headerMenuObj?.collection && (
            <HeaderCollectionMenu handleMouseLeave={handleMouseLeave} />
          )}
        </div>
      )}
    </HeaderHoverMenuStyles>
  );
};

export default HeaderHoverMenu;
