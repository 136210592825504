import { styled } from "@mui/system";

const ProfileSettingPagesStyles = styled("div")(({ theme }) => ({
  marginTop: "0",
  paddingTop: "50px",
  ".container": {
    width: "100%",
    maxWidth: "1440px",
    margin: "auto",
    padding: "0 15px",
    "h1.typo": {
      fontWeight: "400",
    },
    ".profileSetting": {
      display: "flex",
      gap: "83px",
      alignItems: "flex-start",
      fontFamily: "Cera Regular",
      ".sideTabs ": {
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        flex: "0 0 27%",
        borderRadius: "8px",
        backgroundColor: theme.palette.white.main,
        overflow: "hidden",

        ul: {
          padding: "0",
          listStyle: "none",
          margin: "0",
          li: {
            lineHeight: "74px",
            padding: "0 24px 0 33px",
            cursor: "pointer",
            position: "relative",
            a: {
              fontSize: "24px",
              color: theme.palette.primary.main,
              textDecoration: "none",
              width: "100%",
              display: "block",
              height: "100%",
              svg: {
                width: "18px",
                height: "18px",
                marginRight: "13px",
              },
            },
            ".rightArrowIcon": {
              position: "absolute",
              top: "20px",
              right: "15px",
              width: "35px",
              height: "35px",
              display: "none",
            },
            "&.activeTab": {
              backgroundColor: "rgb(112 112 112 / 12%)",
              ".rightArrowIcon": {
                display: "block",
              },
              a: {
                fontWeight: "600",
              },
            },
            "&:hover": {
              backgroundColor: "rgb(112 112 112 / 12%)",
            },
          },
        },
      },
      ".tabPages": {
        width: "100%",
        "h1.typo": {
          margin: "0 0 47px",
          fontSize: "26px",
          color: theme.palette.primary.main,
        },
      },
      ":is(.fr, .it, .de, .es)": {
        " .sideTabs": {
          flex: "0 0 30%",
          ul: {
            li: {
              a: {
                fontSize: "20px",
              },
            },
          },
        },
        ".updateButton": {
          button: {
            paddingRight: "20px",
            maxWidth: "max-content",
            svg: {
              transform: "translateX(10px)",
            },
          },
        },

        ".changePassWordPage": {
          ".loginMain": {
            ".updateButton": {
              button: { maxWidth: "max-content", width: "100%" },
            },
          },
        },
      },
    },
    ".updateButton": {
      float: "left",
      width: "100%",
      textAlign: "left",
      padding: "0 13px",
      height: "60px",
      marginTop: "25px",
      button: {
        height: "40px",
        width: "100%",
        maxWidth: "160px",
        span: {
          flex: "1",
          fontSize: "18px",
        },
        svg: {
          width: "30px",
          rect: {
            fill: theme.palette.white.main,
          },
        },
        "&:hover": {
          svg: {
            rect: {
              fill: theme.palette.primary.main,
            },
            path: {
              stroke: theme.palette.white.main,
            },
          },
        },
      },
    },
  },
}));

export default ProfileSettingPagesStyles;
