import React from "react";
import { styled } from "@mui/system";
import SingleCheckbox from "./SingleCheckbox";

const MultiCheckboxesStyles = styled("label")(({ theme }) => ({
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: "400",
  color: theme.palette.gray_300.main,
  display: "flex",
  gap: "6px",
  input: {
    position: 'relative',
    top: '3px',
  },
  "&:has(input[type='checkbox'])": {
    color: theme.palette.blackColor.main,
    alignItem: "center",
  },
}));

const CheckboxWithLabel = ({ labelName, ...props }) => {
  return (
    <MultiCheckboxesStyles>
      <SingleCheckbox {...props} />
      {labelName}
    </MultiCheckboxesStyles>
  );
};

export default CheckboxWithLabel;
