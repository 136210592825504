/**
 * This function will scroll the to the element Id
 * offset is offset from the top
 */

export const scrollToElementId = (elementId, offset = 100) => {
  const element = document?.getElementById(elementId);
  if (!element) {
    return;
  }

  const elementPosition = element.getBoundingClientRect().top + window.scrollY;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });
};
