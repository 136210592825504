import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import TextTag, { textVariants } from "components/Typography/TextTag";
import TooltipWrapper from "components/TooltipComponent/TooltipWrapper";
import FePaginationComp from "components/Pagination/FePaginationComp";
import { getCuratedGalleryLinks } from "pages/Admin-Pages/cms-changes/CategoryQuickLinks/features/categoryQuickLinkAction";
import { curatedGalleryLinksData } from "pages/Admin-Pages/cms-changes/CategoryQuickLinks/features/categoryQuickLinkSlice";

const chunkArray = (array, size) => {
  const result = [];
  for (let i = 0; i < array?.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

const initialCuratedGalleryState = {
  page: 1,
  limit: 31,
};

const HeaderCuratedGallery = ({
  curatedData,
  oneTypeShowing,
  setOneTypeShowing,
}) => {
  const dispatch = useDispatch();
  const curatedGalleryLinks = useSelector(curatedGalleryLinksData);

  const [showAll, setShowAll] = useState(false);
  const [curatedGalleryState, setCuratedGalleryState] = useState(
    initialCuratedGalleryState,
  );

  useEffect(() => {
    setCuratedGalleryState(initialCuratedGalleryState);
  }, [showAll]);

  useEffect(() => {
    const data = {
      categoryId: curatedData?.category_id,
      columnId: curatedData?._id,
      limit: curatedGalleryState?.limit,
      page: curatedGalleryState?.page,
    };

    if (showAll) dispatch(getCuratedGalleryLinks(data));
  }, [showAll, dispatch, curatedData, curatedGalleryState]);

  const arr = showAll
    ? curatedGalleryLinks?.data?.[0]?.linkData
    : curatedData?.linkData;
  const chunkedCuratedArray = chunkArray(arr, 8);

  return (
    <div
      className={`curatedSections ${
        showAll ? "showAll" : oneTypeShowing ? "hideAll" : ""
      }`}
    >
      <div className="curatedSectionHeading">
        <label>
          <TooltipWrapper tooltipText={curatedData?.name}>
            <div className="collectionCustomTag">
              <TextTag
                variant={textVariants._14px.semiBold}
                className={"customTagName"}
              >
                {curatedData?.name}
              </TextTag>
            </div>
          </TooltipWrapper>
        </label>
      </div>
      {chunkedCuratedArray?.map((cData, index) => {
        return (
          (showAll || index < 1) && (
            <div className="curatedSectionDatas" key={index}>
              {cData.map((linkd, linkIndex) => (
                <div key={linkIndex} className="curatedHeadLink">
                  <label>
                    <TooltipWrapper tooltipText={linkd?.name}>
                      <div className="collectionCustomTag">
                        <a
                          href={linkd?.url}
                          target={linkd?.target_blank ? "_blank" : "_self"}
                          rel="noreferrer"
                        >
                          <TextTag
                            className={"customTagName"}
                            variant={textVariants._14px.medium}
                          >
                            {linkd?.name}
                          </TextTag>
                        </a>
                      </div>
                    </TooltipWrapper>
                  </label>
                </div>
              ))}
              {chunkedCuratedArray?.length - 1 === index && showAll && (
                <button
                  onClick={() => {
                    setShowAll(false);
                    setOneTypeShowing(false);
                  }}
                  className="seeAllButton"
                >
                  {t("back")}
                </button>
              )}
            </div>
          )
        );
      })}
      {chunkedCuratedArray?.length > 1 && !showAll && (
        <button
          onClick={() => {
            setShowAll(true);
            setOneTypeShowing(true);
          }}
          className="seeAllButton"
        >
          {t("seeAll")}
        </button>
      )}
      {showAll && curatedGalleryLinks?.totalPages > 1 && (
        <FePaginationComp
          count={curatedGalleryLinks?.totalPages}
          onChange={(e, value) => {
            setCuratedGalleryState((prev) => ({ ...prev, page: value }));
          }}
          page={curatedGalleryLinks?.currentPage}
        />
      )}
    </div>
  );
};

export default HeaderCuratedGallery;
