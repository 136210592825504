import { createSlice } from "@reduxjs/toolkit";
import { createContributeCollectionApi } from "./contributePopupAction";

const initialState = {
  allSortedMarketData: [],
  contributePopupLoading: false,
};

const contributePopupSlice = createSlice({
  name: "contributePopupSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //createContributeCollectionApi
    builder
      .addCase(createContributeCollectionApi.pending, (state) => {
        state.contributePopupLoading = true;
      })
      .addCase(createContributeCollectionApi.fulfilled, (state) => {
        state.contributePopupLoading = false;
      })
      .addCase(createContributeCollectionApi.rejected, (state) => {
        state.contributePopupLoading = false;
      });
  },
});

export const contributePopupLoading = (state) =>
  state.contributePopupSlice.contributePopupLoading;

export default contributePopupSlice.reducer;
