import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  useMapsLibrary,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { MapHandler } from ".";
import CrossIcon from "components/Icons/CrossIcon/CrossIcon";
import ShowcaseCommonFooter from "pages/User-Pages/ContributePage/Components/DetailForm/components/ShowcaseCommonFooter";
import { updateContributeDetailsApi } from "pages/User-Pages/ContributePage/features/contributionAction";
import MapLocationStyle from "./MapLocation.style";
import TextfieldWithIcon from "components/Textfield/TextfieldwithIcon";

const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY;

const PlaceAutocomplete = ({ onPlaceSelect, defaultPlace }) => {
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null);
  const inputRef = useRef(null);
  const places = useMapsLibrary("places");
  const [textFieldValue, setTextFieldValue] = useState("");
  useEffect(() => {
    if (!places || !inputRef.current) return;
    const options = {
      fields: ["geometry", "name", "formatted_address"],
    };

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options));
  }, [places, textFieldValue]);

  useEffect(() => {
    if (!placeAutocomplete) return;

    placeAutocomplete.addListener("place_changed", () => {
      onPlaceSelect(placeAutocomplete.getPlace());
    });
  }, [onPlaceSelect, placeAutocomplete]);

  return (
    <div className="mapTextField">
      <TextfieldWithIcon
        placeholder={"Enter Location"}
        compRef={inputRef}
        defaultValue={defaultPlace?.formatted_address}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
        // readOnly={defaultPlace && defaultPlace?.formatted_address}
        onChange={(e) => {
          if (!e.target.value) {
            onPlaceSelect(null);
          }
          setTextFieldValue(e.target.value);
        }}
      />
      {((defaultPlace && !_.isEmpty(defaultPlace)) || textFieldValue) && (
        <CrossIcon
          className={"crossIcon"}
          onClick={(e) => {
            e.preventDefault();
            onPlaceSelect(null);
            inputRef.current.value = "";
          }}
        />
      )}
    </div>
  );
};

const SaveMapLocation = ({
  getSelectedLocation,
  showTextFieldOnly,
  unMountActiveComponent,
  addressMapFieldId,
  id,
  onChange,
  contributerData,
}) => {
  const dispatch = useDispatch();
  const [selectedPlace, setSelectedPlace] = useState(getSelectedLocation);
  const [markerRef, marker] = useAdvancedMarkerRef();

  useEffect(() => {
    onChange && onChange(selectedPlace);
    //eslint-disable-next-line
  }, [selectedPlace]);

  const onSaveClick = (e) => {
    if (!(getSelectedLocation && getSelectedLocation.formatted_address)) {
      return;
    }

    const ogField = contributerData?.[0]?.fieldsData;

    const fields = ogField.map((data) => {
      return {
        field_id: data?._id,
        value:
          data?._id === addressMapFieldId
            ? JSON.stringify(getSelectedLocation)
            : data?.field_value?.value,
      };
    });

    if (fields.findIndex((x) => x.field_id === addressMapFieldId) === -1) {
      fields.push({
        field_id: addressMapFieldId,
        value: JSON.stringify(getSelectedLocation),
      });
    }

    const body = { fields, id };

    dispatch(updateContributeDetailsApi(body)).then((res) => {
      if (res?.error) {
        return;
      }
      unMountActiveComponent();
    });
  };

  return (
    <APIProvider solutionChannel="GMP_map" apiKey={MAP_API_KEY}>
      <MapLocationStyle>
        <div className="saveLocation-head">
          <PlaceAutocomplete
            onPlaceSelect={setSelectedPlace}
            defaultPlace={selectedPlace}
            setSelectedPlace={setSelectedPlace}
          />
        </div>
        {!showTextFieldOnly && (
          <>
            <div className="saveLocation-body">
              <Map
                mapId={"bf51a910020fa25a"}
                defaultZoom={3}
                defaultCenter={{ lat: 22.54992, lng: 0 }}
                gestureHandling={"greedy"}
                disableDefaultUI={true}
                className="hideTags saveLocation-map"
              >
                <AdvancedMarker ref={markerRef} position={null} />
              </Map>
              <MapHandler place={selectedPlace} marker={marker} />
            </div>
            <ShowcaseCommonFooter
              onCancelClick={unMountActiveComponent}
              onSaveClick={onSaveClick}
              disableSave={
                !(getSelectedLocation && getSelectedLocation.formatted_address)
              }
            />
          </>
        )}
      </MapLocationStyle>
    </APIProvider>
  );
};
export default SaveMapLocation;
