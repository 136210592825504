/**
 * A test page dedicated to test and experiment
 */

import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchSelectComp from "components/Select/SearchSelectComp";
import withAuth from "../../../../Auth/withAuth";
import { routeConfigs } from "../../../../utils/routeConfig";
import { getBusiness } from "pages/Admin-Pages/sub-groups/features/subGroupAction";
import { selectAllBusiness } from "pages/Admin-Pages/sub-groups/features/subGroupSlice";
import DateComponent from "components/DateComponent/DateComponent";

function HomeDuplicateNew() {
  const dispatch = useDispatch();
  const businessData = useSelector(selectAllBusiness);

  useEffect(() => {
    dispatch(getBusiness());
  }, [dispatch]);

  return (
    <div>
      {businessData && (
        <SearchSelectComp
          selectData={businessData}
          selectName={"name"}
          onSelectChange={(valueObj) => {}}
        />
      )}
      <DateComponent />
    </div>
  );
}
export default withAuth(HomeDuplicateNew, routeConfigs.userLogin);
