import React from "react";
import { styled } from "@mui/system";
import { t } from "i18next";
import SearchIcon from "pages/User-Pages/UserPages/home/HomeComponents/HeroSection/Components/Icons/SearchIcon";
import ContributePopTextfieldComp from "./ContributePopTextfieldComp";

export const StyledTextfieldWithIcon = styled("div")(({ theme }) => ({
  position: "relative",

  svg: {
    position: 'absolute',
    top: '12px',
    left: '16px',
    path: {
      stroke: theme.palette.gray_400.main,
    },
  },
  input: {
    paddingLeft: "40px",
    borderColor: theme.palette.gray_300.main,
  },
}));

const TextfieldWithIcon = ({ compRef, type, text, ...props }) => {
  return (
    <StyledTextfieldWithIcon>
      <SearchIcon />
      <ContributePopTextfieldComp
        type="text"
        placeholder={t("search")}
        compRef={compRef}
        {...props}
      />
    </StyledTextfieldWithIcon>
  );
};

export default TextfieldWithIcon;
