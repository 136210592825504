import React, { Fragment, useEffect, useState } from "react";
import { t } from "i18next";
import { styled } from "@mui/system";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import InputBase from "@mui/material/InputBase";
import { Popover } from "@mui/material";
import ArrowUpIcon from "components/Icons/ArrowIcons/ArrowUpIcon";
import ArrowDownIcon from "components/Icons/ArrowIcons/ArrowDownIcon";
import SearchIcon from "components/Icons/SearchIcon/SearchIcon";
import PlusIcon from "components/Icons/PlusIcon/PlusIcon";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import CrossIcon from "components/Icons/CrossIcon/CrossIcon";

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  background: "#000",
  width: "100%",
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
    borderRadius: "0",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: "8px 10px",
      borderBottom: `1px solid  ${
        theme.palette.mode === "light" ? " #eaecef" : "#30363d"
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;

  return <StyledAutocompletePopper {...other} />;
}

const StyledPopover = styled(Popover)(({ theme }) => ({
  width: "100%",
  maxHeight: "323px",
  ".searchSelectPopover": {
    // minWidth: "368px",
    overflow: "hidden",
    ul: {
      marginRight: "8px",
      maxHeight: "263px",
      "&::-webkit-scrollbar": {
        width: "4px",
      },
      "&::-webkit-scrollbar-thumb": {
        background: theme.palette.gray_250.main,
      },
      ".MuiAutocomplete-option": {
        fontFamily: "poppins",
        fontWeight: "500",
        color: theme.palette.blackColor.main,
        fontSize: "14px",
        border: "0",
        backgroundColor: theme.palette.whiteColor.main,
        "&:hover": {
          backgroundColor: theme.palette.whiteColor.main,
          color: theme.palette.primaryColor.main,
        },
      },
    },
  },
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  "& input": {
    borderRadius: 8,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",
    padding: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `1px solid ${
      theme.palette.mode === "light" ? "#eaecef" : "#30363d"
    }`,
    paddingLeft: "40px",
    fontSize: 14,
    "&:focus": {
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.mode === "light"
          ? "rgba(3, 102, 214, 0.3)"
          : "rgb(12, 45, 107)"
      }`,
      borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
    },
  },
}));

const SearchSelectOuter = styled("div")(({ theme }) => ({
  "&.selectDefaultValue ": {
    button: {
      borderColor: theme.palette.gray_400.main,
      span: {
        color: theme.palette.gray_400.main,
      },
    },
  },
  ".pillsText": {
    display: "flex",
    gap: "8px",
    overflow: "hidden",
    flexWrap: "nowrap",
    alignItems: "center",
    ".crossIconPill": {
      borderRadius: "16px",
      background: "#F5F5F6",
      textWrap: "nowrap",
      padding: "2px 16px",
      display: "flex",
      alignItems: "center",
      gap: "4px",
    },
  },

  button: {
    width: "100%",
    backgroundColor: theme.palette.whiteColor.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.gray_500.main}`,
    borderRadius: "8px",
    cursor: "pointer",
    fontFamily: "poppins",
    fontSize: "12px!important",
    color: "#212A36",
    fontWeight: "500!important",
    height: "40px",
    padding: "0px 16px",
    svg: {
      width: "16px",
      height: "16px",
      path: {
        stroke: theme.palette.blackColor.main,
      },
    },
  },
}));

const StyledInputSearchSelect = styled("div")(({ theme }) => ({
  ".placeholderSearchIcon": {
    position: "absolute",
    top: "23px",
    zIndex: "1",
    left: "25px",
    width: "auto",
    path: {
      stroke: theme.palette.gray_400.main,
    },
  },
  input: {
    paddingLeft: "40px",
  },
  ".addNewValSelect": {
    position: "absolute",
    right: "20px",
    top: "22px",
    cursor: "pointer",
  },
  ".errorClass": {
    paddingLeft: "8px",
  },
}));

export default function SearchSelectComp(props) {
  const {
    selectData,
    selectName,
    onSelectChange,
    title,
    selectValue,
    parentClass,
    multiple,
    handleAddNewValue, //only if we want to add new value
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(selectValue);
  const [pendingValue, setPendingValue] = useState(selectValue);

  const handleClick = (event) => {
    event.preventDefault();
    setPendingValue(value);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    // If pendingValue is undefined or null, fall back to the initial value (default empty array)
    const safePendingValue = pendingValue || [];

    setValue(safePendingValue); // Set value to the safePendingValue
    if (anchorEl) {
      anchorEl.focus(); // Ensure focus is returned if anchorEl exists
    }
    setPendingValue([]); // Reset pendingValue to empty array to avoid undefined state
    setAnchorEl(null); // Close the dropdown
  };

  const open = Boolean(anchorEl);
  const id = open ? "selectComponent" : undefined;
  const [parentWidth, setParentWidth] = useState("100%");

  useEffect(() => {
    if (parentClass) {
      const width = document.querySelector(`.${parentClass}`)?.clientWidth;
      setParentWidth(`${width}px`);
    }
  }, [parentClass]);

  return (
    <Fragment>
      <SearchSelectOuter
        className={
          multiple && value?.length === 0
            ? "selectTitle selectDefaultValue"
            : value
            ? "selectTitle"
            : "selectTitle selectDefaultValue"
        }
      >
        <button onClick={handleClick}>
          <span className="pillsText">
            {multiple
              ? value?.length > 0
                ? value?.map((x) => (
                    <div className="crossIconPill">
                      {x?.[selectName]}
                      <CrossIcon
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          const index = value.findIndex(
                            (v) => v?.[selectName] === x?.[selectName],
                          );
                          if (index === -1) {
                            return;
                          }
                          const prevArr = [...value];
                          prevArr.splice(index, 1);
                          setValue(prevArr);
                          onSelectChange(prevArr);
                        }}
                      />
                    </div>
                  ))
                : t("select")
              : value
              ? value?.[selectName]
              : t("select")}
          </span>
          {anchorEl ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </button>
      </SearchSelectOuter>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{ transform: "translate(0px, 8px)" }}
        classes={{ paper: "searchSelectPopover" }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <div className="autocompleteMain" style={{ width: parentWidth }}>
            <Autocomplete
              open
              multiple={multiple}
              onClose={(event, reason) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              value={pendingValue}
              onChange={(event, newValue, reason) => {
                if (
                  (event.type === "keydown" &&
                    (event.key === "Backspace" || event.key === "Delete") &&
                    reason === "removeOption") ||
                  reason === "clear"
                ) {
                  return;
                }
                onSelectChange(newValue);
                setPendingValue(newValue);
                setValue(newValue);
                setAnchorEl(null);
              }}
              PopperComponent={PopperComponent} //for design of popup
              renderTags={() => null}
              noOptionsText={`No ${title}`}
              options={[...selectData]}
              getOptionLabel={(option) => {
                return option?.[selectName];
              }}
              renderOption={(option) => {
                return (
                  <div
                    className="selectRenderOption"
                    {...option}
                    onClick={(e) => {
                      option.onClick(e);
                      setAnchorEl(null);
                    }}
                  >
                    {option?.key}
                  </div>
                );
              }}
              renderInput={(params) => {
                const value = params?.inputProps?.ref?.current?.value?.trim();
                const doubleSpaceError =
                  !validationRegex.checkForDoubleSpaces.test(value);

                return (
                  <StyledInputSearchSelect>
                    <SearchIcon className={"placeholderSearchIcon"} />
                    <StyledInput
                      ref={params.InputProps.ref}
                      inputProps={params.inputProps}
                      autoFocus
                      placeholder={`${t("enter")} ${title}`}
                    />
                    <span className="errorClass">
                      {doubleSpaceError
                        ? validationMessages.noConsecutiveDoubleSpaces
                        : ""}
                    </span>
                    {handleAddNewValue && value?.length > 0 && (
                      <PlusIcon
                        className={"addNewValSelect"}
                        onClick={() => {
                          if (!doubleSpaceError)
                            handleAddNewValue(
                              params?.inputProps?.ref?.current?.value,
                            );
                        }}
                      />
                    )}
                  </StyledInputSearchSelect>
                );
              }}
            />
          </div>
        </ClickAwayListener>
      </StyledPopover>
    </Fragment>
  );
}
