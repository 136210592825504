import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const createCuratedGalleryApi = createAsyncThunk(
  "categoryQuickLinks/createCuratedGalleryApi",
  async (formData) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminCreateCuratedGallery, formData),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminGetCuratedGalleries
export const getCuratedGalleries = createAsyncThunk(
  "categoryQuickLinks/getCuratedGalleries",
  async (id) => {
    try {
      return await performApiRequest(
        () => axiosConfig.get(`${apiRoutes.adminGetCuratedGalleries}/${id}`),
        null,
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//getCuratedGallery
export const getCuratedGallery = createAsyncThunk(
  "categoryQuickLinks/getCuratedGallery",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getCuratedGallery}/${id}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateGalleryName
export const updateGalleryNameApi = createAsyncThunk(
  "categoryQuickLinks/updateGalleryNameApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateGalleryName}/${data?.id}`,
          data?.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminAddCuratedGalleryLink
export const addCuratedGalleryLinkApi = createAsyncThunk(
  "categoryQuickLinks/addCuratedGalleryLinkApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminAddCuratedGalleryLink}/${data?.id}`,
          data?.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminCuratedGalleryUpdateIndexes
export const curatedGalleryUpdateIndexesApi = createAsyncThunk(
  "categoryQuickLinks/curatedGalleryUpdateIndexesApi",
  async (data) => {
    try {
      return await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.adminCuratedGalleryUpdateIndexes}`,
            data?.body,
          ),
        null,
        data?.showToast,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateCuratedLinkApi = createAsyncThunk(
  "categoryQuickLinks/updateCuratedLinkApi",
  async (body) => {
    try {
      return await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.adminCuratedGalleryUpdateLink}/${body.id}`,
            body,
          ),
        null,
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminCuratedGalleryUpdateLinkTarget

export const curatedGalleryUpdateLinkTargetApi = createAsyncThunk(
  "categoryQuickLinks/curatedGalleryUpdateLinkTargetApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminCuratedGalleryUpdateLinkTarget}/${body.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteCuratedGalleryApi = createAsyncThunk(
  "categoryQuickLinks/deleteCuratedGalleryApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminCuratedLinkDelete}/${data?.colId}`,
          data?.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const removeCuratedGalleryApi = createAsyncThunk(
  "categoryQuickLinks/removeCuratedGalleryApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.delete(`${apiRoutes.removeCuratedGallery}/${data}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getCuratedGalleryLinks = createAsyncThunk(
  "categoryQuickLinks/getCuratedGalleryLinks",
  async (data) => {
    try {
      const { categoryId, columnId, page, limit } = data;
      let apiUrl = `${apiRoutes.getCuratedGalleryLinks}/${categoryId}`;
      const params = new URLSearchParams();

      if (page) params.append("page", parseInt(page));
      if (limit) params.append("limit", parseInt(limit));
      if (columnId) params.append("columnId", columnId);

      if (params.toString()) {
        apiUrl += `?${params.toString()}`;
      }

      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
