import React from "react";

const FullScreenIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M2.66797 6.0013V3.73464C2.66797 3.36127 2.66797 3.17458 2.74063 3.03198C2.80455 2.90654 2.90654 2.80455 3.03198 2.74064C3.17458 2.66797 3.36127 2.66797 3.73464 2.66797H6.0013M2.66797 10.0013V12.268C2.66797 12.6414 2.66797 12.828 2.74063 12.9706C2.80455 13.0961 2.90654 13.198 3.03198 13.262C3.17458 13.3346 3.36127 13.3346 3.73464 13.3346H6.0013M10.0013 2.66797H12.268C12.6414 2.66797 12.828 2.66797 12.9706 2.74063C13.0961 2.80455 13.198 2.90654 13.262 3.03198C13.3346 3.17458 13.3346 3.36127 13.3346 3.73464V6.0013M13.3346 10.0013V12.268C13.3346 12.6414 13.3346 12.828 13.262 12.9706C13.198 13.0961 13.0961 13.198 12.9706 13.262C12.828 13.3346 12.6414 13.3346 12.268 13.3346H10.0013"
        stroke="#212A36"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FullScreenIcon;
