import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import _ from "lodash";
import ContributePopSectionHeader from "../ContributePopSectionHeader/ContributePopSectionHeader";
import { getActiveAssetCategoryByType } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategoryAction";
import { allActiveAssetCategoryByType } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategorySlice";
import SelectComp from "components/Select/SelectComp";
import { categoriesBySubCategories } from "pages/Admin-Pages/asset-administration/AssetSubCategory/features/assetSubCategorySlice";
import { getsubCategoryBasedOnCategory } from "pages/Admin-Pages/asset-administration/AssetSubCategory/features/assetSubCategoryAction";
import { StyledContributePopupBodySection } from "../contributePopupBodyCompStyles";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { getActiveAssetType } from "pages/Admin-Pages/asset-administration/AssetType/features/typeAction";
import { allActiveAssets } from "pages/Admin-Pages/asset-administration/AssetType/features/typeSlice";

const SelectCategorySubCategory = ({
  selectedCategory,
  setSelectedCategory,
}) => {
  const dispatch = useDispatch();

  const allTypes = useSelector(allActiveAssets)?.data;
  const allCategoryByType = useSelector(allActiveAssetCategoryByType).data;
  const subCategoriesData = useSelector(
    categoriesBySubCategories,
  )?.sub_category_data;

  const [selectedType, setSelectedType] = useState("");

  useEffect(() => {
    dispatch(getActiveAssetType());
  }, [dispatch]);

  const handleTypeChange = (e) => {
    setSelectedCategory("");
    if (e) {
      setSelectedType(e?._id);
      dispatch(getActiveAssetCategoryByType(e?._id));
    } else {
      setSelectedType("");
    }
  };

  const handleCategoryChange = (e) => {
    if (e) {
      setSelectedCategory(e?._id);
      dispatch(getsubCategoryBasedOnCategory(e?._id));
    } else {
      setSelectedCategory("");
    }
  };

  const handleSubCategoryChange = (e) => {
    // console.log(e);
  };

  return (
    <>
      <StyledContributePopupBodySection>
        <ContributePopSectionHeader
          sectionHeading={t("fileType")}
          isRequired={true}
          tooltipHeading={t("fileType")}
          tooltipContent={t("fileType")}
        />
        <SelectComp
          selectData={allTypes}
          selectName={"name"}
          selectValue={"_id"}
          onSelectChange={handleTypeChange}
        />
      </StyledContributePopupBodySection>
      {selectedType &&
        (_.isEmpty(allCategoryByType) ? (
          <TextTag
            className={"popupError"}
            variant={textVariants._14px.regular}
          >
            {t("noAssociatedCategoryFound")}
          </TextTag>
        ) : (
          <StyledContributePopupBodySection>
            <ContributePopSectionHeader
              sectionHeading={t("category")}
              isRequired={true}
              tooltipHeading={t("category")}
              tooltipContent={t("category")}
            />
            <SelectComp
              selectData={allCategoryByType}
              selectName={"name"}
              selectValue={"_id"}
              onSelectChange={handleCategoryChange}
              defaultValue={selectedCategory}
            />
          </StyledContributePopupBodySection>
        ))}
      {selectedType &&
        selectedCategory &&
        (_.isEmpty(subCategoriesData) ? (
          <TextTag
            className={"popupError"}
            variant={textVariants._14px.regular}
          >
            {t("noAssociatedSubCategoryFound")}
          </TextTag>
        ) : (
          <StyledContributePopupBodySection className="paddingAbove">
            <ContributePopSectionHeader
              sectionHeading={`${t("subCategory")} (${t("optional")})`}
              isRequired={false}
              tooltipHeading={t("subCategory")}
              tooltipContent={t("subCategory")}
            />
            <SelectComp
              selectData={subCategoriesData}
              selectName={"name"}
              selectValue={"_id"}
              onSelectChange={handleSubCategoryChange}
            />
          </StyledContributePopupBodySection>
        ))}
    </>
  );
};

export default SelectCategorySubCategory;
