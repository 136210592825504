import React from "react";

const UploadImageSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
    >
      <path
        d="M70 50V54C70 59.6007 70 62.4007 68.91 64.54C67.9513 66.4217 66.4217 67.9513 64.54 68.91C62.4007 70 59.6007 70 54 70H26C20.3995 70 17.5992 70 15.4601 68.91C13.5785 67.9513 12.0487 66.4217 11.0899 64.54C10 62.4007 10 59.6007 10 54V50M56.6667 26.6667L40 10M40 10L23.3333 26.6667M40 10V50"
        stroke="#676D75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default UploadImageSvg;
