import React from "react";
import { FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Box, DialogActions, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { videoModuleSectiontitleSchema } from "utils/validation/validation-schemas/videoModuleSectiontitleSchema";
import {
  getVideoContentApi,
  updateVideoContentApi,
} from "../../features/videoModuleAction";
import { getVideoModule } from "../../features/videoModuleSlice";
import { CsvPopupStyle } from "pages/Admin-Pages/user-administration/Components/CsvPopup/CsvPopupStyle";

const ChangeTitlePopUp = (props) => {
  const dispatch = useDispatch();
  const { updateLoading } = useSelector(getVideoModule);
  const { openChangeTitle, setOpenChangeTitle, videoContent } = props;
  const { t } = useTranslation();

  const initialValues = {
    name: videoContent?.name ? videoContent?.name : "",
    description: videoContent?.description ? videoContent?.description : "",
    button_name: videoContent?.button_name ? videoContent?.button_name : "",
  };

  const formik = useFormik({
    validationSchema: videoModuleSectiontitleSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  async function handleSubmit(values) {
    dispatch(
      updateVideoContentApi({ id: videoContent?._id, body: values }),
    ).then((res) => {
      if (res?.error) {
        return;
      }
      setOpenChangeTitle(false);
      dispatch(getVideoContentApi());
    });
  }

  return (
    <DialogCommon
      fullwidth={true}
      open={openChangeTitle}
      handleClose={() => setOpenChangeTitle(false)}
      title={t("changeSectionDetails")}
      maxWidth={"md"}
    >
      <CsvPopupStyle>
        <Box className="Csv_main addEditCategory">
          <Box className="upload_Csv commanCsvBlock">
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Box className="request-common">
                  <Box className="categoryImageName">
                    <p>{t("sectionTitle")}</p>
                    <TextField
                      placeholder={t("enterSectionTitle")}
                      size="small"
                      variant="outlined"
                      type="text"
                      name="name"
                      onChange={(e) => {
                        if (e.target.value?.length <= 50) {
                          formik.setFieldValue("name", e.target.value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    <span className="errorClass">
                      {formik.errors.name &&
                        formik.touched.name &&
                        formik.errors.name}
                    </span>
                  </Box>
                </Box>
                <Box className="request-common">
                  <Box className="categoryImageName catDescription">
                    <p className="label">{t("description")}</p>
                    <TextField
                      placeholder={t("description")}
                      rows={3}
                      size="small"
                      variant="outlined"
                      type="text"
                      multiline={true}
                      name="description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.description}
                    />
                    <span className="errorClass">
                      {formik.errors.description &&
                        formik.touched.description &&
                        formik.errors.description}
                    </span>
                  </Box>
                </Box>
                <Box className="request-common">
                  <Box className="categoryImageName ">
                    <p>{t("buttonName")}</p>
                    <TextField
                      placeholder={t("enterButtonName")}
                      size="small"
                      variant="outlined"
                      type="text"
                      name="button_name"
                      onChange={(e) => {
                        if (e.target.value?.length <= 50) {
                          formik.setFieldValue("button_name", e.target.value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.button_name}
                    />
                    <span className="errorClass">
                      {formik.errors.button_name &&
                        formik.touched.button_name &&
                        formik.errors.button_name}
                    </span>
                  </Box>
                </Box>
                <DialogActions className="popup-button-outer">
                  <StyledButton
                    variant={"contained"}
                    type="submit"
                    disabled={updateLoading}
                  >
                    {t("save")}
                  </StyledButton>
                  <CustomButton
                    variant={"contained"}
                    onClick={() => setOpenChangeTitle(false)}
                    disabled={updateLoading}
                  >
                    {t("cancel")}
                  </CustomButton>
                </DialogActions>
              </form>
            </FormikProvider>
          </Box>
        </Box>
      </CsvPopupStyle>
    </DialogCommon>
  );
};

export default ChangeTitlePopUp;
