import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import DialogCommon from "components/DialogComponent/DialogCommon";
import CustomButton from "components/Button/CustomButton";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import { assetsCategorydeleteApi } from "../../features/assetCategoryAction";

const ReassignSubCategory = (props) => {
  const {
    open,
    showReassign,
    setShowReassign,
    allMainTableData,
    selectValue,
    setSelectValue,
    currentRowData,
    dataState,
    // resetErrorStates,
    setOpen,
    rowsToBeDeleted,
    regionData1,
    setDataState,
    openedFor,
    setOpenDelete,
    openDelete,
    setSelectedRows,
    handleSwitchChange,
    gridRef,
  } = props;

  function handleReassign() {
    const body = {
      RegionId: currentRowData?._id,
      enabled: currentRowData?.enabled,
      newRegionsId: selectValue,
    };
    const dataBody = { body, dataState };
    dataBody.showReassignToast = openedFor === "DELETE" ? false : true;
    if (selectValue === "-1") {
      //   ToastHelper("error", validationMessages.pleaseSelectRegion);
    } else {
      handleSwitchChange(
        currentRowData?._id, //id
        currentRowData?.enabled,
        currentRowData?.business_count, //count
        selectValue, //new entry for reassign
      ).then((res) => {
        if (res !== "error" && openedFor === "DELETE") handleDelete();
      });
      handleClose();
    }
  }

  function handleNotReassign() {
    handleSwitchChange(
      currentRowData?._id, //id
      currentRowData?.enabled,
      0, // count
    );
    handleClose();
  }

  function handleDelete(roleId = true) {
    const body = {
      ids: rowsToBeDeleted,
    };
    if (selectValue && selectValue !== -1 && selectValue !== "-1") {
      body.new_category = selectValue;
    }
    const dataBody = { body, dataState };

    const shouldPageChange = deletePageChangeChecker(
      allMainTableData?.currentPage,
      allMainTableData?.totalDocuments,
      rowsToBeDeleted?.length,
      allMainTableData?.limit,
    );

    dataBody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(assetsCategorydeleteApi(dataBody)).then(() => {
      gridRef?.current.api.deselectAll();
      if (shouldPageChange) setDataState(dataBody.dataState);
    });

    handleClose();
  }

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const handleClose = () => {
    setShowReassign(false);
    setSelectedRows();
    setOpen(false);
    setSelectValue("-1");
    // resetErrorStates();
    setOpenDelete(false);
  };

  const optionsData =
    regionData1 &&
    rowsToBeDeleted &&
    regionData1?.asset_type?.filter(
      (data, i) => !rowsToBeDeleted?.includes(data._id),
    );

  return (
    <>
      <DialogCommon
        open={openDelete}
        handleClose={handleClose}
        contentText={
          rowsToBeDeleted?.length > 1
            ? t("categoryMultipleDeleteConfirmationText")
            : t("categoryDeleteConfirmationText")
        }
        title={" "}
        fullwidth={true}
      >
        <DialogActions className={"popup-button-outer"}>
          <CustomButton variant="contained" onClick={() => handleDelete(false)}>
            {t("yes")}
          </CustomButton>
          <CustomButton variant="contained" onClick={handleClose}>
            {t("no")}
          </CustomButton>
        </DialogActions>
      </DialogCommon>
      <DialogCommon
        open={open}
        handleClose={handleClose}
        title={t("reassignSubcategory")}
        contentText={t("reassignSubCategoryQue")}
        fullWidth={true}
      >
        <DialogActions className={"popup-button-outer"}>
          <CustomButton
            variant="contained"
            className={
              showReassign ? "btn-design-theme yes-button" : "btn-design-theme"
            }
            onClick={() => setShowReassign(true)}
          >
            {t("yes")}
          </CustomButton>
          <CustomButton
            variant="contained"
            className="btn-design-theme cancel-button"
            onClick={() => {
              if (openedFor === "REASSIGN") {
                handleNotReassign();
              } else {
                handleDelete(false);
              }
            }}
          >
            {t("no")}
          </CustomButton>
        </DialogActions>
        {showReassign && (
          <div className={"reassignDiv addNewDivSelect"}>
            <DialogContent className="userRoleText">
              <DialogContentText
                id="alert-dialog-description"
                className="popup-text"
              >
                {t(`selectNewSubCategory`)}
              </DialogContentText>
              <div className={"flexCenter "}>
                <span> {t("category")}</span>
                <FormControl
                  variant="outlined"
                  className={"modalSelect"}
                  size="small"
                >
                  <Select
                    className="permission-role-select"
                    value={selectValue}
                    onChange={(i) => {
                      setSelectValue(i?.target.value);
                    }}
                  >
                    <MenuItem value={"-1"} className="select-none">
                      {t("select")}
                    </MenuItem>
                    {optionsData &&
                      optionsData?.map((data, i) => {
                        if (
                          data?.enabled &&
                          data?._id !== currentRowData?._id
                        ) {
                          return (
                            <MenuItem key={i} value={data?._id}>
                              {data?.name}
                            </MenuItem>
                          );
                        }
                        return null;
                      })}
                  </Select>
                </FormControl>
              </div>
            </DialogContent>
            <DialogActions className={"popup-button-outer"}>
              <CustomButton
                variant="contained"
                className={"btn-design-theme"}
                onClick={() =>
                  openedFor === "DELETE"
                    ? handleDelete(true)
                    : handleReassign(currentRowData)
                }
              >
                {openedFor === "DELETE" ? t("delete") : t("save")}
              </CustomButton>
              <CustomButton
                variant="contained"
                className={"btn-design-theme cancel-button"}
                onClick={handleClose}
              >
                {t("cancel")}
              </CustomButton>
            </DialogActions>
          </div>
        )}
      </DialogCommon>
    </>
  );
};

export default ReassignSubCategory;
