import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import TextTag, { textVariants } from "components/Typography/TextTag";

const ArrowLinkCompStyles = styled(Link)(({ theme }) => ({
  color: theme.palette.blackColor.main,
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  gap: "6px",
  svg: {
    width: "14px",
    height: "14px",
    fill: theme.palette.primaryColor.main,
  },
  "&:hover": {
    color: theme.palette.primaryColor.main,
  },
  "&.disabled": {
    opacity: "20%",
    cursor: "auto",
    pointerEvents: "none",
  },
}));

export const ArrowLinkComp = (props) => {
  const { link, title, disabled, targetBlank } = props;
  return (
    <ArrowLinkCompStyles
      to={link}
      className={`${disabled ? "disabled" : ""}`}
      disabled={disabled}
      target={targetBlank ? "_blank" : "_self"}
    >
      <ArrowForwardIosRoundedIcon />
      <TextTag variant={textVariants._14px.medium}>{title}</TextTag>
    </ArrowLinkCompStyles>
  );
};
