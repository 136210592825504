import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

//adminGetFieldsDataByCollectionId
export const getFieldsDataByCollectionId = createAsyncThunk(
  "fieldsManagement/getFieldsDataByCollectionId",
  async (paramData) => {
    try {
      let apiUrl;
      if (paramData?.collectionId && (!paramData?.limit || !paramData?.page)) {
        apiUrl = `${apiRoutes.adminGetFieldsDataByCollectionId}/${paramData?.collectionId}?sortOrder=asc&sortBy=order_index&limit=999999`;
      } else {
        const {
          sortBy,
          sortOrder,
          limit,
          page,
          // filtersQueryString,
          collectionId,
        } = paramData;
        // const encodedQueryString = encodeURIComponent(
        //   JSON.stringify(filtersQueryString),
        // );
        apiUrl = `${apiRoutes.adminGetFieldsDataByCollectionId}/${collectionId}?sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//contribute popoup get fields
export const getContributeRequiredFieldsApi = createAsyncThunk(
  "fieldsManagement/getContributeRequiredFieldsApi",
  async (paramData) => {
    try {
      const apiUrl = `${apiRoutes.getContributeRequiredFields}/${paramData?.collectionId}`;
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getFieldTypeApi = createAsyncThunk(
  "fieldsManagement/getFieldTypeApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.adminGetFieldType),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateFieldPermission = createAsyncThunk(
  "fieldsManagement/updateFieldPermission",
  async (bodyNew) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateFieldPermission}/${bodyNew?.body.id}`,
          bodyNew.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateFieldSettings
export const updateFieldSettingsApi = createAsyncThunk(
  "fieldsManagement/updateFieldSettingsApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminUpdateFieldSettings, data?.body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateField
export const updateFieldApi = createAsyncThunk(
  "fieldsManagement/updateFieldApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateField}/${data?.fieldId}`,
          data?.body,
        ),
      );
      return dispatch(
        getFieldsDataByCollectionId({
          collectionId: data?.currentCollectionId,
          ...data?.dataState,
        }),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const adminUpdateFieldRowsIndexApi = createAsyncThunk(
  "fieldsManagement/adminUpdateFieldRowsIndexApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminUpdateFieldRowsIndex, data?.body),
      );
      return dispatch(
        getFieldsDataByCollectionId({
          collectionId: data?.body?.collection_id,
          ...data?.dataState,
        }),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const adminCreateFieldApi = createAsyncThunk(
  "fieldsManagement/adminCreateFieldApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminCreateField, data.body),
      );
      return dispatch(
        getFieldsDataByCollectionId({
          collectionId: data.collectionId,
        }),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminDeleteFields
export const adminDeleteFieldsApi = createAsyncThunk(
  "fieldsManagement/adminDeleteFieldsApi",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminDeleteFields, data.body),
      );
      return dispatch(
        getFieldsDataByCollectionId({
          collectionId: data?.collectionId,
          ...data?.dataState,
        }),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
