import { styled } from "@mui/system";

export const CommonGridStyle = styled("div")(({ theme }) => ({
  ".customfieldGrid": {
    ".ag-input-field-input.ag-checkbox-input": {
      cursor: "pointer",
    },
    ".ag-header": {
      height: "33px !important",
      minHeight: "33px !important",
      background: "#eaedf4",
      ".ag-header-cell": {
        height: "33px !important",
        borderRight: "1px solid #dddddd",
        fontSize: ["16px", "16px"],
        color: "#707070",
        fontWeight: "400 !important",
        padding: "0px 23px",

        ".withIcon": {
          fontSize: "16px",
          color: "#707070",
        },
        ".ag-header-cell-comp-wrapper": {
          justifyContent: "center",
        },
        "&:last-child": {
          ".ag-header-cell-label": { justifyContent: "center" },
        },
        "&.leftAlign": {
          th: {
            textAlign: "left",
          },
        },
        "&.checkboxColumn": {
          justifyContent: "center",
        },
        "&.checkboxDragColumn": {
          paddingLeft: "73px",
        },
      },
      "&.ag-pivot-off": { background: "#eaedf4" },
    },

    ".fieldCenter": {
      ".ag-header-cell-comp-wrapper": {
        justifyContent: "center",
        th: {
          width: "100%",
        },
      },
    },
    ".ag-body": {
      ".fieldFirstLetterCapital": { textTransform: "capitalize" },
      ".ag-row": {
        ".ag-cell": {
          border: "0",
          fontSize: "16px",
          color: "black",
          borderRight: "1px solid #dddddd",
          height: "100%",
          fontWeight: "400 !important",
          overflow: "unset",
          "&.ag-cell-focus": {
            // stopping the blue border to come when click on a cell
            border: "0px solid transparent !important",
            background: "transparent",
            borderRight: "1px solid #ddd !important",
            boxShadow: "unset",
            padding: "0 25px",

            ".gridTooltip": {},
          },
          "&:not(:first-of-type)": {
            ".ag-drag-handle": { display: "none" },
            ".ag-selection-checkbox": {
              width: "0",
              minWidth: "0",
              maxWidth: "1px",
              margin: "0",
            },
          },
          "&:not(:last-child)": {
            borderRight: "1px solid #dddddd",
            padding: "0 23px",
          },
          ".ag-cell-wrapper": {
            height: "100%",
          },
          ".ag-cell-wrapper span": {
            border: "0px",
            fontSize: "18px",
            color: theme.palette.black.main,
            alignItems: "center",
            display: "flex",
          },

          "&.actionColumn": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ".actionIcons": {
              justifyContent: "center",
            },
          },
          "&.statusColumn": {
            span: {
              display: "block",
              minHeight: "46px",
              " .button": {
                margin: "0 auto!important",
                top: "7px",
              },
            },
          },
          "&.category_status .button": {
            transform: "translate(-50%,-50%)",
            top: "45px !important",
            left: "50%",
            margin: "0 !important",
          },
          "&.mainDescription ": {
            color: theme.palette.primaryColor.main,
          },
          "&.clientId ": {
            color: theme.palette.errorColor.main,
          },
          "&.mainTitle ": {
            color: theme.palette.info.main,
          },
          "td.userRole.leftAlign": {
            display: "block",
            textWrap: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          },
        },
        ".category_center .ag-cell-wrapper span.ag-cell-value": {
          justifyContent: "center",
          alignItems: "center",
        },
        // "&:last-child": {
        //   borderBottom: "0!important",
        // },
        "&:is(.ag-row-dragging)": {
          background: "#f7f7f7",
          opacity: "unset!important",
        },
        "&:first-child": {
          ".ag-cell": {
            ".tooltipPopup": {
              bottom: "22px",
              left: "54px",
            },
          },
        },
      },
      " .ag-center-cols-viewport": {
        minHeight: "unset",
      },
      ".ag-center-cols-container": {
        display: "block",
        // width: "100% !important",
        //it was causing the border in bottom to not show in case of table scroll in all users
      },
    },
    ".ag-header-cell-comp-wrapper": {
      fontSize: "16px",
      color: "#707070",
      fontWeight: 700,
      th: {
        fontWeight: 700,
        width: "100%",
      },
      span: {
        fontWeight: 700,
        ".tooltip_main_outer": {
          svg: {
            top: "1px",
            position: "relative",
          },
        },
      },
      button: {
        background: "transparent",
        border: "0",
        height: "unset",
        padding: "0",
        position: "absolute",
        right: "10px",
        top: "0",
        minHeight: "33px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        svg: {
          width: "17px",
          fill: "#4A4A4A",
        },
      },
    },
    ".ag-selection-checkbox.ag-invisible": {
      display: "none",
      width: "0",
      padding: "0",
      margin: "0",
    },

    //for custom tag
    " .customTagCellMain.d-flex": {
      width: "100%",
    },

    "&.noRowsGrid": {
      " .ag-center-cols-viewport": {
        minHeight: "150px",
      },
    },

    ".chip": {
      background: "#cdcdcd",
      borderRadius: "30px",
      padding: "5px 15px",
      maxWidth: "max-content",
      margin: "auto",
      color: "#000",
      height: "25px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));
