import React from "react";

const HexTick = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <path
        d="M5.33398 8.72227L6.97498 10.5001L10.6673 6.50007M1.33398 8.50007L4.66732 2.72656H11.334L14.6673 8.50007L11.334 14.2736H4.66732L1.33398 8.50007Z"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HexTick;
