import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Box } from "@mui/material";
import {
  deleteUserRequest,
  getUserRequests,
  getUserRequestsFields,
} from "pages/Admin-Pages/user-administration/features/userAction";
import {
  selectAllUserRequest,
  userRequestsFieldValueArray,
} from "pages/Admin-Pages/user-administration/features/userSlice";
import { getRegions } from "pages/Admin-Pages/groups/features/groupAction";
import { getDepartments } from "pages/Admin-Pages/tertiaryGroup/features/departmentAction";
import { getUserRoles } from "pages/Admin-Pages/user-administration/features/userRolePermissionAction";
import { selectAllRegion } from "pages/Admin-Pages/groups/features/groupSlice";
import { selectAllUserRole } from "pages/Admin-Pages/user-administration/features/userRolePermissionSlice";
import UserRequestDetails from "pages/Admin-Pages/user-administration/Components/UserRequestDetailsPopup/userRequestDetails";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Components/CustomComponentfilter/CustomComponentFilter";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import { usersPageTabs } from "pages/Components/Tabs/TabsConstant";
import TabsComponent from "pages/Components/Tabs/Tabs";
import {
  AgViewColumn,
  hiddenAgGridColumn,
} from "utils/datatable/agTableOptions";
import CommonGrid from "components/Grid/CommonGrid";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import TableViewIcon from "components/Grid/TableComponents/TableIcons/TableViewIcon";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import CommonDeletePopup from "pages/Components/CommonDeletePopup/CommonDeletePopup";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";

export default function UsersRequests() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const userRequestData1 = useSelector(selectAllUserRequest);
  const regionData = useSelector(selectAllRegion);
  const userRoleData = useSelector(selectAllUserRole);
  const fieldValueData = useSelector(userRequestsFieldValueArray); //filter
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "first_name",
      headerName: t("firstName"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "first_name",
          dispatchName: "first_name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"first_name"}
                sortBy={"first_name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "last_name",
      headerName: t("lastName"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "last_name",
          dispatchName: "last_name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"last_name"}
                sortBy={"last_name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "email",
      headerName: t("email"),
      headerClass: "leftAlign",
      flex: 2,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "email",
          dispatchName: "email",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"email"}
                sortBy={"email"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid container className={"actionIcons"}>
          <TableViewIcon
            onClick={() => {
              setOpen(true);
              setCurrentRowData(props?.data);
              gridRef.current.api.deselectAll();
            }}
          />
          <TableDeleteIcon
            onClick={() => {
              setOpenDelete(true);
              setRowsToBeDeleted([props?.data?._id]);
            }}
          />
        </Grid>
      ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [rowData, setRowData] = useState();
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [settingType, setSettingType] = useState();
  const [openDelete, setOpenDelete] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState("");
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter

  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    dispatch(getRegions());
    dispatch(getDepartments());
    dispatch(getUserRoles());
  }, [dispatch]);

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getUserRequests(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  useEffect(() => {
    setPreviousColumnFilterData(userRequestData1?.applied_filters);
    setRowData(
      userRequestData1?.userDataArray?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        first_name: tableDataObj?.first_name,
        last_name: tableDataObj?.last_name,
        email: tableDataObj?.email,
        enabled: tableDataObj?.enabled,
      })),
    );
  }, [userRequestData1]);

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleDelete() {
    const body = {
      userIds: rowsToBeDeleted,
    };
    const dataBody = { body, dataState };
    const shouldPageChange = deletePageChangeChecker(
      userRequestData1?.currentPage,
      userRequestData1?.totalDocuments,
      rowsToBeDeleted?.length,
      userRequestData1?.limit,
    );
    dataBody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };
    dispatch(deleteUserRequest(dataBody)).then(() => {
      gridRef.current.api.deselectAll();
      if (shouldPageChange) setDataState(dataBody.dataState);
    });

    setSelectedRows([]);
    setOpenDelete(false);
  }

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getUserRequestsFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("userRequests")} />
      <TabsComponent tabsDataArray={usersPageTabs} />
      <Box container spacing={4} className={"userReq-table"}>
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType">
                <div className="search_table" />
                <PaginationLimitDropDown
                  dataState={dataState}
                  setDataState={setDataState}
                  setSelectedRows={setSelectedRows}
                />
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={userRequestData1?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
            />
          </Box>
        </Box>
      </Box>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        setRowsToBeDeleted={setRowsToBeDeleted}
        handleDelete={handleDelete}
        singleDeleteText={t("pendingDeleteSingleText")}
        multiDeleteText={t("pendingDeleteMutipleText")}
      />
      <UserRequestDetails
        open={open}
        regionData={regionData}
        userRoleData={userRoleData}
        dataState={dataState}
        setDataState={setDataState}
        handleClose={handleClose}
        currentRowData={currentRowData}
      />
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
}
