import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Grid, Box } from "@mui/material";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import {
  deleteContributeCollection,
  getAllContributionbyCollectionId,
  Getcollection,
  getContributionbyCollectionId,
  getUploadedCollectionFilterFields,
  updateContributeCollection,
} from "../../collections/features/collectionAction";
import { routeConfigs } from "utils/routeConfig";
import TabsComponent from "pages/Components/Tabs/Tabs";
import { useQuery } from "utils/queryString";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import CommonGrid from "components/Grid/CommonGrid";
import TableEditIcon from "components/Grid/TableComponents/TableIcons/TableEditIcon";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import { FieldManagementStyle } from "pages/Admin-Pages/fields-management/fieldManagementStyle";
import {
  allUploadedCollectionsData,
  uploadedCollectionFields,
  uploadedCollectionsData,
} from "../features/collectionSlice";
import CustomComponentFilter, {
  getFieldsDataHelper,
  ShowHeaderIcon,
} from "pages/Components/CustomComponentfilter/CustomComponentFilter";
import ToggleSwitch from "pages/Components/ToggleSwitch/ToggleSwitch";
import ReassignUploadedCollection from "../components/ReassignUploadedCollection/ReassignUploadedCollection";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import TableViewIcon from "components/Grid/TableComponents/TableIcons/TableViewIcon";
import { Link } from "react-router-dom";

const UploadedCollections = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef();
  const [searchParams] = useSearchParams();
  const currentCollectionId1 = useQuery().get("id");

  const fieldValueData = useSelector(uploadedCollectionFields); //filter
  const allUploadedColData = useSelector(allUploadedCollectionsData);
  const fieldsAllDataByCollectionId = useSelector(uploadedCollectionsData);

  const tableDataArray = fieldsAllDataByCollectionId?.data;

  const initialDataState = {
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "asc", //probable values: asc, desc
    sortBy: "order_index", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  };

  const [dataState, setDataState] = useState(initialDataState); //filter changes

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "unique_id",
      headerName: t("systemId"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const id = gridRef?.current?.props?.rowData?.[0]?.currentCollectionId;
        const filterClickProps = {
          dispatchGetName: "unique_id",
          dispatchName: "unique_id",
          applied_filters: params?.applied_filters_data?.applied_filters,
          id,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              id && commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"unique_id"}
                sortBy={"unique_id"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "name",
      headerName: t("title"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const id = gridRef?.current?.props?.rowData?.[0]?.currentCollectionId;
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
          id,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              id && commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "client_id",
      headerName: t("clientId"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const id = gridRef?.current?.props?.rowData?.[0]?.currentCollectionId;
        const filterClickProps = {
          dispatchGetName: "client_id",
          dispatchName: "client_id",
          applied_filters: params?.applied_filters_data?.applied_filters,
          id,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              id && commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"client_id"}
                sortBy={"client_id"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "createdAt",
      headerName: t("uploadDate"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const id = gridRef?.current?.props?.rowData?.[0]?.currentCollectionId;
        const filterClickProps = {
          dispatchGetName: "createdAt",
          dispatchName: "createdAt",
          applied_filters: params?.applied_filters_data?.applied_filters,
          id,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              id && commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"createdAt"}
                sortBy={"createdAt"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ data }) =>
        ToolTipWrapperComponent(dayjs(data?.createdAt).format("DD-MM-YYYY")),
    },
    {
      field: "enabled",
      headerName: t("status"),
      headerClass: "fieldCenter",
      headerComponent: (params) => {
        const id = gridRef?.current?.props?.rowData?.[0]?.currentCollectionId;
        const filterClickProps = {
          dispatchGetName: "enabled",
          dispatchName: "enabled",
          applied_filters: params?.applied_filters_data?.applied_filters,
          id,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              id && commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"enabled"}
                sortBy={"enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn",
      cellRenderer: (props) => {
        return (
          <Grid className="toggleBlockCo">
            <ToggleSwitch
              checked={props?.value}
              value={props?.value}
              defaultChecked={props?.value}
              onChange={(e) => {
                gridRef.current.api.deselectAll();
                handleSwitchChange(
                  props?.data?._id, //id
                  props?.value, //value
                  props,
                );
              }}
            />
          </Grid>
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <span>{props?.displayName}</span>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <Link
            to={`${routeConfigs.contributePage}?id=${props?.data?._id}&type=view`}
            target="_blank"
          >
            <TableViewIcon />
          </Link>
          <TableEditIcon
            onClick={() => {
              navigate(`${routeConfigs.contributePage}?id=${props.data?._id}`);
            }}
          />
          <TableDeleteIcon
            onClick={() => {
              gridRef.current.api.deselectAll();
              setCurrentRowData(props.data);
              setRowsToBeDeleted([props?.data?._id]);
              setOpen(true);
            }}
          />
        </Grid>
      ),
    },
  ];

  const [tabsDataArray, setTabsDataArray] = useState();
  const [currentRowData, setCurrentRowData] = useState();
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [rowData, setRowData] = useState(tableDataArray);
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [currentCollectionId, setCurrentCollectionId] = useState();
  const [isloading, setIsLoading] = useState(false);
  const [settingType, setSettingType] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [open, setOpen] = useState(false);
  const [showReassign, setShowReassign] = useState(false);
  const [selectValue, setSelectValue] = useState(-1);

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    if (open) {
      dispatch(
        getAllContributionbyCollectionId({
          collectionId: currentCollectionId,
        }),
      );
    }
  }, [currentCollectionId, dispatch, open]);

  useEffect(() => {
    try {
      setIsLoading(true);
      setCurrentCollectionId(atob(currentCollectionId1));
      setDataState(initialDataState);
    } catch (e) {}
    //eslint-disable-next-line
  }, [currentCollectionId1]);

  useEffect(() => {
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) =>
        (commonDataObj[commonKey] =
          fieldsAllDataByCollectionId?.fieldsByCollection?.[commonKey]),
    );

    setRowData(
      tableDataArray?.map((fieldData) => ({
        _id: fieldData?._id,
        unique_id: fieldData?.unique_id,
        name: fieldData?.name,
        createdAt: fieldData?.createdAt,
        enabled: fieldData?.enabled,
        client_id: fieldData?.client_id,
        action: "",
        other_table_data: commonDataObj,
        currentCollectionId: currentCollectionId1,
      })),
    );
    setIsLoading(false);
  }, [
    fieldsAllDataByCollectionId?.fieldsByCollection,
    tableDataArray,
    currentCollectionId1,
  ]);

  useEffect(() => {
    dispatch(Getcollection()).then((resp) => {
      if (resp?.error) {
        return;
      }
      const dataArray = [];
      resp?.payload?.collections?.map((dataObj) => {
        if (dataObj?.enabled) {
          dataArray.push({
            _id: dataObj?._id,
            value: routeConfigs.adminAssetsUploadedCollections,
            label: dataObj?.name,
          });
        }
        return false;
      });
      if (!window.location.search) {
        //set first tab when page opens for the first time
        navigate({
          pathname: window.location.pathname,
          search: `?id=${btoa(dataArray?.[0]?._id)}`,
          replace: true,
        });
      }
      setTabsDataArray(dataArray);
    });
    //eslint-disable-next-line
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (currentCollectionId?.length > 20) {
      //to verify if collection id is actually an id and not any other thing
      dispatch(
        getContributionbyCollectionId({
          collectionId: currentCollectionId,
          ...dataState,
        }),
      ).then((res) => {
        setRowsToBeDeleted(false);
        setSelectedRows(false);
        const paramsObj = {
          tableDataResponse: res, //response form get table data api
          setSettingType, //state to set setting type which will be requied in set column settings api
          colDefs, //state which gives current column defination
          setColDefs, //state to set column definations
          dataState,
        };
        agSetColumnIndexes(paramsObj);
      });
    }
    //eslint-disable-next-line
  }, [currentCollectionId, dispatch, dataState]);

  const handleSwitchChange = useCallback(
    async (entryId, switchValue, props) => {
      gridRef.current.api.forEachNode((rowNode) => {
        if (rowNode?.data?._id !== entryId) {
          return false;
        }
        setSelectedRows([]);

        rowNode.setDataValue("enabled", !switchValue);

        const body = {
          id: entryId,
          enabled: !switchValue,
        };

        let modifiedDataState = dataState;

        if (props?.data?.other_table_data) {
          const other_data = props?.data?.other_table_data;
          modifiedDataState = modifyDataState(other_data);
        }

        const databody = { body, dataState: modifiedDataState };

        dispatch(updateContributeCollection(databody)).then((res) => {
          if (res.error) {
            //reverting the change in case of api error
            // dispatch(getAssetType(modifiedDataState));
            dispatch(
              getContributionbyCollectionId({
                collectionId: currentCollectionId,
                ...modifiedDataState,
              }),
            );
          }
        });
      });
    },
    //eslint-disable-next-line
    [],
  );

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters, id } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getUploadedCollectionFilterFields,
      id ? atob(id) : "",
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const handleDeleteAndReassign = (deleteIdArray, newCollectionId) => {
    const data = {
      dataState,
      collectionId: currentCollectionId,
      body: {
        ids: deleteIdArray?.[0] ? deleteIdArray : rowsToBeDeleted,
        new_contribute: newCollectionId ? newCollectionId : null,
      },
    };

    const shouldPageChange = deletePageChangeChecker(
      fieldsAllDataByCollectionId?.fieldsByCollection?.currentPage,
      fieldsAllDataByCollectionId?.fieldsByCollection?.totalDocuments,
      rowsToBeDeleted?.length,
      fieldsAllDataByCollectionId?.fieldsByCollection?.limit,
    );

    data.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(deleteContributeCollection(data)).then((resp) => {
      if (resp?.error) {
        return;
      }
      setOpen(false);
      setRowsToBeDeleted([]);
      setSelectedRows([]);
      setDataState(data.dataState);
      //further code to run after delete
    });
  };

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("viewCollections")} />
      {tabsDataArray?.length > 0 ? (
        window.location.search && (
          <TabsComponent
            tabsDataArray={tabsDataArray}
            setDataState={setDataState}
            initialDataState={initialDataState}
            className="subTabs"
          />
        )
      ) : (
        <Box style={{ height: "120px" }} />
      )}
      <Box container spacing={4} className={"userReq-table  withSubTab"}>
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType collectionAssets paddingZero">
                <Box className="leftSideInputs">
                  <Grid container spacing={4} className={"addNew"} />
                  <PaginationLimitDropDown
                    dataState={dataState}
                    setDataState={setDataState}
                    setSelectedRows={null} //no rows to select
                  />
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpen}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={
                fieldsAllDataByCollectionId?.state === "loading" || isloading
              }
              totalPages={fieldsAllDataByCollectionId?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
              minNumberOfColumnsForAutosize={8}
            />
          </Box>
        </Box>
      </Box>
      <ReassignUploadedCollection
        open={open}
        setOpen={setOpen}
        showReassign={showReassign}
        setShowReassign={setShowReassign}
        allMainTableData={
          allUploadedColData?.data ? allUploadedColData.data : []
        }
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        currentRowData={currentRowData}
        dataState={dataState}
        setDataState={setDataState}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDeleteAndReassign={handleDeleteAndReassign}
        setSelectedRows={setSelectedRows}
        gridRef={gridRef}
        selectedRows={selectedRows}
        setRowsToBeDeleted={setRowsToBeDeleted}
      />

      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
};

export default UploadedCollections;
