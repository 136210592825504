import React from 'react'

const CartIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M4.19985 3.33333H14L12.6667 8H4.91781M13.3333 10.6667H5.33333L4 2H2M6 13.3333C6 13.7015 5.70152 14 5.33333 14C4.96515 14 4.66667 13.7015 4.66667 13.3333C4.66667 12.9651 4.96515 12.6667 5.33333 12.6667C5.70152 12.6667 6 12.9651 6 13.3333ZM13.3333 13.3333C13.3333 13.7015 13.0349 14 12.6667 14C12.2985 14 12 13.7015 12 13.3333C12 12.9651 12.2985 12.6667 12.6667 12.6667C13.0349 12.6667 13.3333 12.9651 13.3333 13.3333Z" stroke="#212A36" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default CartIcon;