import { styled } from "@mui/system";

export const FieldManagementStyle = styled("div")(({ theme }) => ({
  "&.extraSpaceAbove .fieldTableHeader": {
    // for assets collections page
    marginTop: "60px",
  },

  ".requestLink": {
    margin: "0 0 0 auto",
    width: "100%",
    maxWidth: "max-content",
    fontSize: "18px",
    textDecoration: "underline",
    textAlign: "right",
    cursor: "pointer",
    paddingRight: "45px",
    position: "relative",
    color: "#0052b4",
    float: "right",
    ".arrowIcon": {
      position: "absolute",
      right: "9px",
      top: "0",
      background: theme.palette.primary.main,
      width: "30px",
      height: "30px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      transition: "all 0.2s linear",
      svg: {
        fill: "#fff",
      },
    },
    "&:hover": {
      color: theme.palette.primary.main,
      position: "relative",
      ".arrowIcon": {
        right: "0",
      },
    },
  },

  ".ag-theme-material": {
    margin: "6px 0 20px",
    position: "relative",
    "--ag-checkbox-checked-color": theme.palette.dimGray.main,
    "--ag-checkbox-unchecked-color": theme.palette.dimGray.main,
    "--ag-checkbox-indeterminate-color": theme.palette.dimGray.main,
    ".ag-root-wrapper": { borderRadius: "0", border: "1px solid #dddddd" },
  },

  ".fieldTableFooter": { margin: "0 auto", maxWidth: "max-content" },
  ".fieldMultiSelectHeader": {
    background: "transparent",
    color: theme.palette.dimGray.main,
    borderRadius: "4px",
    margin: "8px 0",
    padding: "8px 14px",
    display: "flex",
    alignItems: "center",
    position: "absolute",
    top: "-77px",
    height: "64px",
    gap: "16px",
  },
  ".fieldMultiSelectHeader svg": {
    color: theme.palette.dimGray.main,
    transform: "scale(1.1)",
  },
  ".fieldMultiSelectHeader span": {
    margin: "0",
    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: 1.75,
    letterSpacing: "0.00938em",
  },
  ".fieldMultiSelectHeader svg.MuiSvgIcon-root": { margin: "0 0 0 auto" },
  ".hideFieldMultiDelete": { display: "none" },
  ".cellEditMain": {
    position: "relative ",
    height: "100%",
    display: "flex",
    alignItems: "center",

    ".errorClass": {
      color: "red",
      position: "absolute",
      bottom: "-18px",
      left: "0",
      fontSize: "11px",
    },
  },
  ".category_edit span.errorClass": {
    bottom: "0",
  },

  //banner upper section start
  ".checkBoxesBlockArea": {
    display: "flex",
    float: "left",
    width: "100%",
    ".bannerCheckBox": {
      display: "flex",
      WebkitBoxAlign: "center",
      alignItems: "center",
      gap: "11px",
      ".tooltipWrapper": {
        position: "relative",
        top: "3px",
      },
      ".checkboxWrapper": {
        marginInlineStart: "0",
      },
    },
  },
  ".bannerTable .fieldTableMain": {
    margin: "65px 0",
  },

  ".userReq-table": {
    float: "left",
    width: "100%",
    ".table-upper": {
      marginBottom: "0",
      padding: "12px 0",
    },
    ".newTableBox": {
      marginTop: "0",
    },
    ".bannerTable": {
      paddingTop: "18px",
    },
    " &.withSubTab ": {
      ".fieldTableMain": {
        ".fieldTableHeader": {
          marginBottom: "16px",
        },
      },
    },
  },

  ".fieldTableMain": {
    ".fieldTableHeader": {
      display: "flex;",
      alignItems: "center;",
      justifyContent: "flex-end;",
      padding: "0",
      width: "100%",
      ".paddingZero": {
        padding: "0",
      },
      ".addNew": {
        gap: "0!important",
        marginRight: "0!important",
      },
      ".leftSideInputs": {
        gap: "15px !important",
        ".addNew": {
          gap: "15px !important",
        },
      },
    },
  },
  ".tabsLine": {
    minHeight: "47px",
    borderBottom: "1px solid #dadada",
  },
  //banner upper section start end

  ".breadcrumbs ": {
    float: "left",
    width: "100%",
    maxWidth: "max-content",
    marginBottom: "8px",
  },

  ".table-upper": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ".leftSideInputs": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      justifyContent: "flex-end",
      " .addNewDivSelect": {
        marginLeft: "0",
        ".MuiFormControl-root": {
          width: "100%",
        },
        "span.errorClass": {
          position: "absolute",
          width: "100%",
          left: "0",
          bottom: "-17px",
        },
      },
      ".addNew": {
        marginRight: "15px",
        maxWidth: "max-content",
        position: "relative",
        gap: "15px",
        margin: "0",
        justifyContent: "end",
        "span.errorClass": {
          flex: "0 0 100%",
          position: "absolute",
          bottom: "-17px",
          left: "0",
        },
        ".add_button": {
          button: {
            marginRight: "0",
          },
        },
        ".addNewDiv": {
          input: {
            height: "40px",
            padding: "0 15px",
            fontSize: "16px",
            fontWeight: "400",
            "&::placeholder": {
              textTransform: "capitalize",
            },
          },
          fieldset: {
            borderColor: "rgba(0, 0, 0, 0.23)",
            borderWidth: "1px",
            top: "-4px",
            height: "44px",
          },
        },
        ".addNewDiv1": {
          button: {
            borderBottomRightRadius: "6px",
            borderTopRightRadius: "6px",
            width: "100%",
            maxWidth: "max-content",
            textTransform: "capitalize",
            marginRight: "0",
            height: " 40px",
            backgroundColor: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            color: theme.palette.white.main,
            ":hover": {
              color: theme.palette.white.main,
            },
          },
        },
        "+ .MuiGrid-root.MuiGrid-container": {
          marginTop: "0",
        },
      },
    },
    ".search_table": {
      position: "relative",
      input: {
        height: "40px",
        minWidth: "235px",
        width: "100%",
        border: "1px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "5px",
        background: "transparent",
        paddingLeft: "10px",
        fontSize: "16px",
        fontWeight: "400",
        paddingRight: "40px",
        outline: "unset",
        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
        "&::placeholder": {
          fontSize: "16px",
          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
          color: theme.palette.black.main,
          fontWeight: "400",
        },
      },
      svg: {
        position: "absolute",
        top: "50%",
        transform: "translatey(-50%)",
        height: "22px",
        width: "22px",
        right: "15px",
      },
    },
    ".addNewDivSelect": {
      "&.selectDrop": {
        width: "100%",
        position: "relative",
        marginRight: ["15px", "0"],
        marginLeft: "15px",
        minWidth: "84px",
      },
    },
    "+.MuiGrid-root": {
      marginTop: "0",
      ".MuiGrid-root.MuiGrid-item": {
        paddingTop: "0px",
      },
    },
    "&.withOutViewIcon": {
      adding: "12px 0",
    },
    "&.singleButtnBar": {
      marginBottom: "15px",
      paddingTop: "55px",
    },
  },
  ".userReq-table.footerGeneralPages .fieldTableHeader": { height: "64px" },
  ".titleButton": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
}));
