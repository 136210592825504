import React from "react";

const NoImageSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M29.3346 20.0013L25.5098 16.1765C24.9818 15.6485 24.7177 15.3844 24.4133 15.2856C24.1456 15.1985 23.857 15.1985 23.5893 15.2856C23.2849 15.3844 23.0208 15.6485 22.4928 16.1765L19.5545 19.1148M29.3346 20.0013V21.6013C29.3346 23.8416 29.3346 24.9616 28.8986 25.8173C28.6158 26.3724 28.2088 26.8509 27.7127 27.2176M29.3346 20.0013V10.4013C29.3346 8.16109 29.3346 7.04098 28.8986 6.18534C28.5152 5.43269 27.9033 4.82077 27.1506 4.43728C26.2949 4.0013 25.1749 4.0013 22.9346 4.0013H9.06797C6.82776 4.0013 5.70765 4.0013 4.85201 4.43728M2.66797 10.668V21.6013C2.66797 23.8416 2.66797 24.9616 3.10394 25.8173C3.48744 26.57 4.09936 27.1818 4.85201 27.5653C5.70765 28.0013 6.82776 28.0013 9.06797 28.0013H20.0013M5.69742 27.6385L11.3346 22.0013M29.3346 29.3346L2.66797 2.66797M10.668 17.3346C9.19521 17.3346 8.0013 16.1407 8.0013 14.668"
        stroke="#BDC0C4"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default NoImageSvg;
