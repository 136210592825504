import { styled } from "@mui/system";

export const ContributePopupStyles = styled("div")(({ theme }) => ({
  position: "fixed",
  zIndex: "99",
  background: "rgba(33, 42, 54, 0.72)",
  width: "100%",
  height: "100%",
  top: "0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  ".contributePopupMain": {
    width: "100%",
    // minWidth: "416px",
    maxWidth: "416px",
    background: theme.palette.whiteColor.main,
    padding: "1.5rem 0",
    borderRadius: "0.5rem",
    position: "relative",
    svg: {
      cursor: "pointer",
    },
    "button.createAlbum": {
      svg: {
        path: {
          stroke: theme.palette.whiteColor.main,
        },
      },
    },
  },
}));
