import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Menu, MenuItem } from "@mui/material";
import CustomButton from "components/Button/CustomButton";
import Notification from "Layout/AdminLayout/Header/components/Notification/Notification";
import NotificationStyle from "Layout/AdminLayout/Header/components/Notification/styles";

const HeaderNotificationMenu = (props) => {
  const {
    notificationsMenu,
    setNotificationsMenu,
    notificationsDataArray,
    deleteNotification,
    filteredUnreadNotications,
    readAllNotifications,
  } = props;

  const { t } = useTranslation();

  return (
    <Menu
      id="notifications-menu"
      open={Boolean(notificationsMenu)}
      anchorEl={notificationsMenu}
      onClose={() => setNotificationsMenu(null)}
      className="headerMenu"
      disableAutoFocusItem
      classes={{ list: "notificationMenuList" }}
    >
      <NotificationStyle>
        {notificationsDataArray?.length > 0 ? (
          <Box className="notificationBox">
            <Box className="readButtons">
              <CustomButton onClick={() => deleteNotification(false)}>
                {t("deleteAllNotification")}
              </CustomButton>
              {filteredUnreadNotications?.length > 0 && (
                <CustomButton onClick={() => readAllNotifications()}>
                  {t("markAllasRead")}
                </CustomButton>
              )}
            </Box>
            {notificationsDataArray?.map((notification, index, arr) => (
              <MenuItem key={index} className="headerMenuItem">
                <Notification
                  {...notification}
                  typographyVariant="inherit"
                  deleteNotification={deleteNotification}
                  setNotificationsMenu={setNotificationsMenu}
                />
              </MenuItem>
            ))}
          </Box>
        ) : (
          <div className="notificationBox empty">
            <span>{t("noNotifications")}</span>
          </div>
        )}
      </NotificationStyle>
    </Menu>
  );
};

export default HeaderNotificationMenu;
