import { createSlice } from "@reduxjs/toolkit";
import {
  getVideoModuleData,
  deleteVideoApi,
  updateVideoStatusApi,
  getVideoContentApi,
  updateVideoContentApi,
  getPublicAssetApi,
  getStockVideoApi,
  createVideoApi,
  getVideoColumnField,
} from "./videoModuleAction";

const initialState = {
  loading: false,
  videoModuleData: [],
  dataLoading: false,
  stockVideoLoading: false,
  deleteLoading: false,
  updateLoading: false,
  contentLoading: false,
  error: null,
  videoContent: null,
  publishAssets: [],
  stockVideos: [],
  videoModuleFieldValues: [], //filterfields
};

const videoModuleSlice = createSlice({
  name: "videoModule",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    resetVideoModuleData: (state) => {
      state.videoModuleData = [];
    },
    resetVideoContent: (state) => {
      state.videoContent = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetching video module data
      .addCase(getVideoModuleData.pending, (state) => {
        state.dataLoading = true;
      })
      .addCase(getVideoModuleData.fulfilled, (state, action) => {
        state.dataLoading = false;
        state.videoModuleData = action.payload;
      })
      .addCase(getVideoModuleData.rejected, (state, action) => {
        state.dataLoading = false;
      });

    // Handle deleting a video
    builder
      .addCase(deleteVideoApi.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteVideoApi.fulfilled, (state, action) => {
        state.deleteLoading = false;
      })
      .addCase(deleteVideoApi.rejected, (state, action) => {
        state.deleteLoading = false;
      });

    //get Stock video

    builder
      .addCase(getStockVideoApi.pending, (state) => {
        state.stockVideoLoading = true;
      })
      .addCase(getStockVideoApi.fulfilled, (state, action) => {
        state.stockVideoLoading = false;
        state.stockVideos = action.payload;
      })
      .addCase(getStockVideoApi.rejected, (state, action) => {
        state.stockVideoLoading = false;
      });

    // Handle updating a video's status
    builder
      .addCase(updateVideoStatusApi.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateVideoStatusApi.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(updateVideoStatusApi.rejected, (state, action) => {
        state.updateLoading = false;
      });
    builder
      .addCase(getVideoContentApi.pending, (state) => {
        state.contentLoading = true;
      })
      .addCase(getVideoContentApi.fulfilled, (state, action) => {
        state.contentLoading = false;
        state.videoContent = action.payload;
      })
      .addCase(getVideoContentApi.rejected, (state, action) => {
        state.contentLoading = false;
      });
    builder
      .addCase(updateVideoContentApi.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateVideoContentApi.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(updateVideoContentApi.rejected, (state, action) => {
        state.contentLoading = false;
      });
    builder
      .addCase(getPublicAssetApi.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPublicAssetApi.fulfilled, (state, action) => {
        state.loading = false;
        state.publishAssets = action.payload;
      })
      .addCase(getPublicAssetApi.rejected, (state, action) => {
        state.loading = false;
      });
    builder
      .addCase(createVideoApi.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(createVideoApi.fulfilled, (state, action) => {
        state.updateLoading = false;
      })
      .addCase(createVideoApi.rejected, (state, action) => {
        state.updateLoading = false;
      });
    //getVideoColumnField
    builder
      .addCase(getVideoColumnField.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVideoColumnField.fulfilled, (state, action) => {
        state.loading = false;
        state.videoModuleFieldValues = action.payload;
      })
      .addCase(getVideoColumnField.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const getVideoModule = (state) => state?.videoModule;

export const { clearError, resetVideoModuleData, resetVideoContent } =
  videoModuleSlice.actions;

export const videoModuleFieldValues = (state) =>
  state.videoModule.videoModuleFieldValues;

export default videoModuleSlice.reducer;
