import React, { useState } from "react";
import { t } from "i18next";
import { styled } from "@mui/system";
import { ClickAwayListener } from "@mui/material";
import { buttonVariants, MainButtonWithChildren } from "./MainButton";
import ArrowUpIcon from "components/Icons/ArrowIcons/ArrowUpIcon";
import ArrowDownIcon from "components/Icons/ArrowIcons/ArrowDownIcon";
import UploadAssetIcon from "components/Icons/UploadIcon/UploadAssetIcon";

export const StyledContributePopupSaveButton = styled("div")(({ theme }) => ({
  " .contributeSaveButtonOptions": {
    position: "absolute",
    bottom: "62px",
    width: "100%",
    maxWidth: "262px",
    padding: "16px",
    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.07)",
    background: theme.palette.whiteColor.main,
    borderRadius: "8px",
    ".contributeSaveOption": {
      fontFamily: "'Poppins'",
      fontWeight: "500",
      fontSize: "14px",
      color: theme.palette.blackColor.main,
      alignItems: "center",
      display: "flex",
      gap: "8px",
      svg: {
        width: "16px",
        height: "16px",
      },
      p: {
        margin: "0",
      },
      "&:not(:last-child)": {
        marginBottom: "16px",
      },
      "&:hover": {
        color: theme.palette.primaryColor.main,
        cursor: "pointer",
        svg: {
          width: "16px",
          height: "16px",
          path: {
            stroke: theme.palette.primaryColor.main,
          },
        },
      },
    },
    "+ .createProjectBtn": {
      "button.contributeSaveButtonArrow": {
        backgroundColor: theme.palette.darkBlueColor.main,
      },
    },
  },
  ".createProjectBtn": {
    display: "flex",
    alignItem: "center",
    borderRadius: "8px",
    overflow: "hidden",

    button: {
      borderRadius: "0",
      height: "53px",
      border: "0",
      cursor: "pointer",
      svg: {
        width: "16px",
        height: "16px",
        path: {
          stroke: theme.palette.whiteColor.main,
        },
      },
      p: {
        margin: "0",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
      "&:not(.contributeSaveButtonArrow)": {
        width: "197px",
        maxWidth: "unset",
        justifyContent: "flex-start",
        gap: "8px",
      },
      "&.contributeSaveButtonArrow": {
        backgroundColor: "#0056B8",
        width: "63px",
        borderLeft: "1px solid rgba(0, 0, 0, 0.24)",
        "> div": {
          justifyContent: "center",
        },
        "&:is(:hover, :active)": {
          backgroundColor: theme.palette.darkBlueColor.main,
        },
      },
      "&:disabled": {
        backgroundColor: theme.palette.gray_300.main,

        svg: {
          path: {
            stroke: theme.palette.gray_500.main,
          },
        },
        "&:hover": {
          backgroundColor: theme.palette.gray_300.main,
        },
      },
    },
    "&:is(:hover)": {
      button: {
        // backgroundColor: "#0A4992",
      },
    },
  },
  "&.singleButton ": {
    width: "100%",
    maxWidth: "262px",
    ".createProjectBtn": {
      width: "100%",
      button: {
        width: "100%",
        justifyContent: "center",
        // maxWidth: "262px",
      },
    },
  },
}));

const ContributePopupSaveButton = ({
  disableSave,
  buttonTitle,
  setButtonTitle,
  buttonTitleOptions,
  handleCreateCollection,
  contributionType,
  contributionTypeObject,
  handleUploadAsset,
  openScheduleAsset,
  setOpenScheduleAsset,
  ...props
}) => {
  const [openOptions, setOpenOptions] = useState();

  const handleSaveButtonClick = (e) => {
    e.preventDefault();
    if (contributionType === contributionTypeObject.asset) {
      handleUploadAsset(e);
    } else {
      if (buttonTitle?.id === "default") {
        setOpenOptions(true);
      } else if (buttonTitle?.id === "schedule") {
        if (openScheduleAsset) {
          handleCreateCollection(e);
        } else {
          setOpenScheduleAsset(true);
        }
      } else {
        handleCreateCollection(e);
      }
    }
  };

  return (
    <StyledContributePopupSaveButton
      className={`${
        disableSave ? "disableSave" : ""
      } ${contributionType}Button ${
        contributionType === contributionTypeObject.asset || openScheduleAsset
          ? "singleButton"
          : ""
      }`}
    >
      {openOptions && (
        <ClickAwayListener
          onClickAway={() => {
            setOpenOptions(false);
          }}
        >
          <div className="contributeSaveButtonOptions">
            {buttonTitleOptions?.map(
              (data, index) =>
                data.id !== "default" && (
                  <div
                    className="contributeSaveOption"
                    onClick={() => {
                      setButtonTitle(data);
                      setOpenOptions(false);
                    }}
                    key={index}
                  >
                    {data.body()}
                  </div>
                ),
            )}
          </div>
        </ClickAwayListener>
      )}
      <div className="createProjectBtn">
        <MainButtonWithChildren
          disabled={disableSave}
          variant={buttonVariants.blue}
          onClick={handleSaveButtonClick}
          {...props}
        >
          {contributionType === contributionTypeObject.asset ? (
            <>
              <UploadAssetIcon /> <p>{t("uploadAssets")}</p>
            </>
          ) : (
            buttonTitle?.body()
          )}
        </MainButtonWithChildren>
        {contributionType === contributionTypeObject.collection &&
          !openScheduleAsset && (
            <button
              onClick={(e) => {
                e.preventDefault();
                setOpenOptions((prev) => !prev);
              }}
              className={"contributeSaveButtonArrow"}
              disabled={disableSave}
            >
              {openOptions ? <ArrowUpIcon /> : <ArrowDownIcon />}
            </button>
          )}
      </div>
    </StyledContributePopupSaveButton>
  );
};

export default ContributePopupSaveButton;
