import PageTitle from "../../../Components/PageTitle/PageTitle";
import React, { useState } from "react";
import { useFormik } from "formik";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { validationRegex } from "utils/validation/validationUtils";
import CustomButton from "components/Button/CustomButton";
import { encryptAES } from "utils/encrypt";

import ChangePasswordSchema from "../features/changePasswordSchema";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";
import PasswordLayout from "./ChangePasswordStyle";
// import UserLayout from "Layout/UserLayout/UserLayout";
import changePwdImage from "../images/pwdImage.png";
import RightSideArrowIcon from "components/Icons/RightSideArrow/RightSideArrow";
import { useTranslation } from "react-i18next";
import PasswordCheckPopup from "components/PasswordCheckPopup/passwordCheckPopup";
const ChangePassword = () => {
  const { t } = useTranslation();
  const [showExistingPassword, setShowExistingPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [resetLoading, setResetLoading] = useState(false);
  const formik = useFormik({
    validationSchema: ChangePasswordSchema,
    initialValues: {
      existingPassword: "",
      newPassword: "",
      reTypePassword: "",
    },
    onSubmit: async (values, { resetForm }) => {
      const encryptedOldPassword = encryptAES(values.existingPassword);
      const encryptedPassword = encryptAES(values.newPassword);
      const body = {
        old_password: encryptedOldPassword,
        password: encryptedPassword,
      };
      setResetLoading(true);
      performApiRequest(() => axiosConfig.post(apiRoutes.changePassword, body))
        .then((response) => {
          if (response.error) {
            setErrorMessage(response?.payload?.data?.message);
          } else {
            resetForm();
            setErrorMessage("");
          }
        })
        .catch((error) => {
          setErrorMessage(error?.data?.messages);
          console.error(error);
        })
        .finally(() => setResetLoading(false));
    },
  });

  return (
    <PasswordLayout>
      <Box className="container">
        <PageTitle title={t("passwordSettings")} />
        <Box className="changePassWordPage">
          <div className="loginMain">
            <Typography color="secondary" className="errorValidation center">
              {(Object.keys(formik?.errors)[0] in formik.touched &&
                Object.values(formik?.errors)[0]) ||
                (errorMessage && errorMessage)}
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <div className="passwordDiv">
                <label>{t("existingPassword")}</label>
                <TextField
                  className="fields"
                  name="existingPassword"
                  fullWidth
                  placeholder={t("existingPassword")}
                  margin="normal"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setShowExistingPassword(!showExistingPassword)
                          }
                          size="large"
                        >
                          {showExistingPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={showExistingPassword ? "text" : "password"}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  value={formik.values.existingPassword}
                  onChange={(e) => {
                    if (e.target.value.length <= 20) {
                      formik.handleChange(e);
                    }
                    setErrorMessage("");
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                />
              </div>

              <div className="passwordDiv">
                <label>{t("newPassword")}</label>
                <TextField
                  className="fields"
                  name="newPassword"
                  fullWidth
                  placeholder={t("newPassword")}
                  margin="normal"
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowNewPassword(!showNewPassword)}
                          size="large"
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  type={showNewPassword ? "text" : "password"}
                  onCopy={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                  value={formik.values.newPassword}
                  onChange={(e) => {
                    if (
                      validationRegex.matchOthersExceptSpace.test(
                        e.target.value,
                      ) &&
                      e.target.value.length <= 20
                    ) {
                      formik.handleChange(e);
                    }
                    setErrorMessage("");
                  }}
                  onFocus={() => setShowPasswordStrength(true)}
                  onBlur={(e) => {
                    setShowPasswordStrength(false);

                    formik.handleBlur(e);
                  }}
                />
                {showPasswordStrength && (
                  <PasswordCheckPopup password={formik.values.newPassword} />
                )}
              </div>
              <div className="passwordDiv">
                <label>{t("reTypePassword")}</label>
                <TextField
                  className="fields"
                  name="reTypePassword"
                  fullWidth
                  margin="normal"
                  placeholder={t("confirmPassword")}
                  size="small"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setshowConfirmPassword(!showConfirmPassword)
                          }
                          size="large"
                        >
                          {showConfirmPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  value={formik.values.reTypePassword}
                  onChange={(e) => {
                    if (
                      validationRegex.matchOthersExceptSpace.test(
                        e.target.value,
                      ) &&
                      e.target.value.length <= 20
                    ) {
                      formik.handleChange(e);
                    }
                    setErrorMessage("");
                  }}
                  onBlur={(e) => {
                    formik.handleBlur(e);
                  }}
                  type={showConfirmPassword ? "text" : "password"}
                />
              </div>

              <Box className="buttons updateButton">
                {/* <CustomButton onClick={() => formik.resetForm()}>
                  Cancel
                </CustomButton> */}
                {resetLoading ? (
                  <CustomButton className="loaderBtn">
                    <CircularProgress size={26} className="loginLoader" />
                  </CustomButton>
                ) : (
                  <CustomButton variant="contained" type>
                    <span>{t("resetPassword")}</span> <RightSideArrowIcon />
                  </CustomButton>
                )}
              </Box>
            </form>
          </div>
          <Box className="imageBlock">
            <Box className="sideImage">
              <img src={changePwdImage} alt="Change password graphic." />
            </Box>
          </Box>
        </Box>
      </Box>{" "}
    </PasswordLayout>
  );
};

export default ChangePassword;
