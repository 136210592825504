import React from 'react'

const SimilarCollection = ({ className }) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
            <path d="M12.5 14H7.3C5.61984 14 4.77977 14 4.13803 13.673C3.57354 13.3854 3.1146 12.9265 2.82698 12.362C2.5 11.7203 2.5 10.8801 2.5 9.2V4M6.63333 12H12.3667C13.1134 12 13.4868 12 13.772 11.8547C14.0229 11.7269 14.2269 11.5229 14.3547 11.272C14.5 10.9868 14.5 10.6134 14.5 9.86667V4.13333C14.5 3.3866 14.5 3.01323 14.3547 2.72801C14.2269 2.47713 14.0229 2.27315 13.772 2.14533C13.4868 2 13.1134 2 12.3667 2H6.63333C5.8866 2 5.51323 2 5.22801 2.14533C4.97713 2.27315 4.77315 2.47713 4.64533 2.72801C4.5 3.01323 4.5 3.38659 4.5 4.13333V9.86667C4.5 10.6134 4.5 10.9868 4.64533 11.272C4.77315 11.5229 4.97713 11.7269 5.22801 11.8547C5.51323 12 5.8866 12 6.63333 12Z" stroke="#212A36" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.719 9.05861L9.81294 8.12241C9.37575 7.67057 9.15713 7.44469 8.9063 7.36211C8.68566 7.28949 8.44852 7.29215 8.22945 7.36975C7.98035 7.45799 7.76649 7.68874 7.33877 8.15025L5.16797 10.3845M10.719 9.05861L10.9044 8.86702C11.3422 8.41468 11.561 8.18851 11.8122 8.10594C12.033 8.03332 12.2703 8.03621 12.4895 8.11409C12.7387 8.20261 12.9526 8.43398 13.3802 8.89668L13.8346 9.37325M10.719 9.05861L12.8977 11.3333M12.2051 5.13198C12.2051 5.75718 11.7188 6.26397 11.1188 6.26397C10.5188 6.26397 10.0325 5.75718 10.0325 5.13198C10.0325 4.50681 10.5188 4 11.1188 4C11.7188 4 12.2051 4.50681 12.2051 5.13198Z" stroke="#212A36" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>


    )
}

export default SimilarCollection