import React from 'react'
import ArrowStyles from './ArrowStyles'

const ArrowDownIcon = ({ className, ...props }) => {
    return (
        <ArrowStyles className={className ? className : ""}>
            <svg {...props} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 14 14" fill="none">
                <path d="M3.5 5.25L7 8.75L10.5 5.25" stroke="#676D75" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </ArrowStyles>
    )
}

export default ArrowDownIcon