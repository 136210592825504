import React, { useState } from "react";
import ContributePopTextfieldComp from "./ContributePopTextfieldComp";
import { validationRegex } from "utils/validation/validationUtils";
import { t } from "i18next";
import { styled } from "@mui/system";
import TextTag, { textVariants } from "components/Typography/TextTag";

const StyledDiv = styled("div")(({ theme }) => ({
  position: "relative",
  span: {
    color: theme.palette.error.main,
    position: "absolute",
    left: "0px",
    top: "37px",
  },
}));

const TextNumberField = ({ onChange, type, text, ...props }) => {
  const [value, setValue] = useState("");
  const [numberError, setNumberError] = useState(false);
  return (
    <StyledDiv>
      <ContributePopTextfieldComp
        onChange={(e) => {
          const num = e.target.value;
          if (validationRegex.numbersSpaces.test(num) || num === "") {
            onChange(e);
            setValue(num);
            setNumberError(false);
          } else {
            setNumberError(true);
          }
        }}
        value={value}
        {...props}
      />
      <TextTag variant={textVariants._13px.regular}>
        {numberError && t("pleaseEnterNumber")}
      </TextTag>
    </StyledDiv>
  );
};

export default TextNumberField;
