import { createSlice } from "@reduxjs/toolkit";
import {
  deleteBanner,
  feGetBannerApi,
  getbannerActions,
  getBannerTitleAction,
  updateBanner,
  updateBannerTitleAction,
} from "./bannerAction";

const initialState = {
  bannerData: [],
  bannerLoading: false,
  deleteLoading: false,
  updateLoading: false,
  bannerDataFe: [],
  getBannerTitleLoading:false,
  updateBannerTitleLoading:false,
  bannerTitle:{}
};

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getbannerActions.pending, (state) => {
        state.bannerLoading = true;
      })
      .addCase(getbannerActions.fulfilled, (state, action) => {
        state.bannerLoading = false;
        state.bannerData = action.payload;
      })
      .addCase(getbannerActions.rejected, (state) => {
        state.bannerLoading = false;
      })
      .addCase(deleteBanner.pending, (state) => {
        state.deleteLoading = true;
      })
      .addCase(deleteBanner.fulfilled, (state) => {
        state.deleteLoading = false;
      })
      .addCase(deleteBanner.rejected, (state) => {
        state.deleteLoading = false;
      })
      .addCase(updateBanner.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(updateBanner.fulfilled, (state) => {
        state.updateLoading = false;
      })
      .addCase(updateBanner.rejected, (state) => {
        state.updateLoading = false;
      });
    //feGetBannerApi
    builder
      .addCase(feGetBannerApi.pending, (state) => {
        state.updateLoading = true;
      })
      .addCase(feGetBannerApi.fulfilled, (state, action) => {
        state.updateLoading = false;
        state.bannerDataFe = action.payload;
      })
      .addCase(feGetBannerApi.rejected, (state) => {
        state.updateLoading = false;
      });

      builder.addCase(getBannerTitleAction.pending, (state) => {
        state.getBannerTitleLoading=true;
       })
       .addCase(getBannerTitleAction.fulfilled, (state, action) => {
         state.getBannerTitleLoading=false;
         state.bannerTitle = action.payload;
       })
       .addCase(getBannerTitleAction.rejected, (state) => {
         state.getBannerTitleLoading=false;
       });
 
       builder.addCase(updateBannerTitleAction.pending, (state) => {
         state.updateBannerTitleLoading=true;
        })
        .addCase(updateBannerTitleAction.fulfilled, (state) => {
          state.updateBannerTitleLoading=false;
        })
        .addCase(updateBannerTitleAction.rejected, (state) => {
          state.updateBannerTitleLoading=false;
        });
  },
});

export const getBanners = (state) => state.banner;
export const getFeBanner = (state) => state.banner.bannerDataFe;
export const bannerLoading = (state) => state.banner.updateLoading;
export const getBannerTitleFromSelector=(state)=>state.banner.bannerTitle;

export default bannerSlice.reducer;
