import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
  Breadcrumbs,
  Grid,
  Link,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import {
  addCustomTagData,
  deleteCustomTagData,
  getCustomTagDetail,
  getCustomTagsDataFields,
  updateCustomTagData,
} from "../features/customTagAction";
import {
  customTagsDataFieldValueArray,
  tagdetails,
} from "../features/customTagSlice";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import CustomButton from "components/Button/CustomButton";
import ToastHelper from "helper/toastHelper";
import CustomTagCsvPopUp from "./Components/CsvPopup/CustomTagCsvPopUp";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Components/CustomComponentfilter/CustomComponentFilter";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import CommonGrid from "components/Grid/CommonGrid";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import { AgCellEditor } from "utils/datatable/AgCellEditor";
import { FieldManagementStyle } from "pages/Admin-Pages/fields-management/fieldManagementStyle";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import CommonDeletePopup from "pages/Components/CommonDeletePopup/CommonDeletePopup";
import DialogCommon from "components/DialogComponent/DialogCommon";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";

const ScopeOfWork = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const data = useSelector(tagdetails);
  const gridRef = useRef();
  const fieldValueData = useSelector(customTagsDataFieldValueArray); //filter

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    hiddenAgGridColumn("custom_tag_id"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "name",
      headerClass: "leftAlign",
      headerName: data?.custom_tag_data?.name || "Name",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.applied_filters_data?.dataState?.custom_tag_data?.name}
            <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
      cellEditor: AgCellEditor,
      cellEditorParams: {
        checkAlphaNumericValidation: false,
        editFunction: editEntry,
      },
      cellClass: "editableCell",
      editable: true,
    },
    {
      field: "createdAt",
      headerName: t("date"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "createdAt",
          dispatchName: "createdAt",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"createdAt"}
                sortBy={"createdAt"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ data }) =>
        ToolTipWrapperComponent(dayjs(data?.createdAt).format("DD-MM-YYYY")),
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <TableDeleteIcon
            onClick={() => {
              setSelectedRows([]);
              setRowsToBeDeleted([props?.data?._id]);
              setOpenDelete(true);
            }}
          />
        </Grid>
      ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [settingType, setSettingType] = useState();
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [rowData, setRowData] = useState();
  const [selectedRows, setSelectedRows] = useState();
  const [entry, setEntry] = useState("");
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [alphaNumErrorAdd, setAlphaNumErrorAdd] = useState("");
  const [csvOpen, setCsvOpen] = useState(false);
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [dataState, setDataState] = useState({
    id: id || "",
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter
  const [openSuccess, setOpenSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    resetErrorStates();
    // setIsLoading(true);
    dispatch(getCustomTagDetail(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState: {
          ...dataState,
          custom_tag_data: res?.payload?.custom_tag_data,
        },
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dataState, dispatch]);

  useEffect(() => {
    setPreviousColumnFilterData(data?.applied_filters);

    const commonDataObj = {};

    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = data?.[commonKey]),
    );

    setRowData(
      data?.data?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        name: tableDataObj?.name,
        createdAt: tableDataObj?.createdAt,
        action: "",
        custom_tag_id: tableDataObj?.custom_tag_id,
        other_table_data: commonDataObj,
      })),
    );
  }, [data]);

  const resetErrorStates = () => {
    setAlphaNumErrorAdd("");
  };

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  const addNewEntry = () => {
    const trimmedAsset = !!entry && entry.trim();
    if (!!trimmedAsset && entry.trim() !== "") {
      const body = {
        name: trimmedAsset,
        custom_tag_id: id,
      };
      dispatch(addCustomTagData(body)).then(() => {
        setDataState({
          ...dataState,
          search: "",
          limit: 20,
          page: 1,
          sortOrder: "desc",
          sortBy: "createdAt",
          column_name: "",
          column_values: "",
          filtersQueryString: [],
        });
      });

      setEntry("");
      resetErrorStates();
    } else {
      ToastHelper("error", validationMessages.pleaseEnterCustomTag);
    }
  };

  const settingEntry = (e) => {
    // const value = e.target.value;
    // const alphaNumericRegex = validationRegex.alphanumeric;
    if (!validationRegex?.checkForDoubleSpaces.test(e.target.value)) {
      setAlphaNumErrorAdd(validationMessages.noConsecutiveDoubleSpaces);
    } else if (e.target.value.length > 50) {
      setAlphaNumErrorAdd(validationMessages.tooLong);
    } else {
      resetErrorStates();
      setEntry(e.target.value);
    }
    // else if (e.target.value.length <= 50 && alphaNumericRegex.test(value)) {
    //   resetErrorStates();
    //   setEntry(e.target.value);
    // }
    // else if (!alphaNumericRegex.test(value)) {
    //   setAlphaNumErrorAdd(validationMessages.alphanumericOnly);
    // }
  };

  function editEntry(data) {
    const body = {
      custom_tag_id: id,
      name: data?.updatedValue || data?.name,
    };
    if (body?.name === "") {
      ToastHelper("error", "Tag name is required!");
      resetErrorStates();
      return false;
    }

    let modifiedDataState = dataState;

    if (data?.dataObj?.other_table_data) {
      const other_data = data?.dataObj?.other_table_data;
      modifiedDataState = modifyDataState(other_data);
    }
    modifiedDataState.id = id;
    const dataBody = {
      body,
      dataState: modifiedDataState,
      id: data?.dataObj?._id || data?.id,
    };
    dispatch(updateCustomTagData(dataBody)).then((res) => {
      if (res?.error) {
        dispatch(getCustomTagDetail(modifiedDataState));
      }
    });
    resetErrorStates();
  }

  const handleDelete = () => {
    const body = {
      ids: rowsToBeDeleted,
    };
    const dataBody = { body, dataState };

    const shouldPageChange = deletePageChangeChecker(
      data?.currentPage,
      data?.totalDocuments,
      rowsToBeDeleted?.length,
      data?.limit,
    );
    dataBody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(deleteCustomTagData(dataBody)).then(() => {
      gridRef.current.api.deselectAll();
      if (shouldPageChange) setDataState(dataBody.dataState);
    });
    setOpenDelete(false);
    setSelectedRows([]);
  };

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getCustomTagsDataFields,
      id,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  return (
    <FieldManagementStyle>
      <PageTitle
        className="innerCustomTagsTitle"
        title={data?.custom_tag_data?.name}
      />
      <Box role="presentation" className="breadcrumbs">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            {t("taxonomy")}
          </Link>
          <Link
            component="button"
            underline="hover"
            color="inherit"
            onClick={() => {
              navigate("/admin/aui/custom-tags");
            }}
          >
            {t("customTags")}
          </Link>
          <Typography color="text.primary">
            {data?.custom_tag_data?.name}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Box container spacing={4} className="userReq-table">
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType customTag_">
                <Box className="leftSideInputs">
                  <Box className="csvBtn">
                    <CustomButton onClick={() => setCsvOpen(true)}>
                      {t("uploaddownloadCsv")}
                    </CustomButton>
                  </Box>
                  <Box className="addUserPagesBtn">
                    <Grid container spacing={4} className={"addNew"}>
                      <Box className={`addNewDiv`}>
                        <TextField
                          placeholder={`${t("enter")} ${
                            data?.custom_tag_data?.name
                          }`}
                          size="small"
                          className={"addNewText"}
                          variant="outlined"
                          onChange={settingEntry}
                          onKeyDown={(e) => {
                            if (e.code === "Enter" || e.code === "NumpadEnter")
                              addNewEntry();
                          }}
                          value={entry}
                        />
                        <span
                          style={{ display: "flex" }}
                          className="errorClass"
                        >
                          {alphaNumErrorAdd}
                        </span>
                      </Box>
                      <Box className={`addNewDiv1 mr-1`}>
                        <CustomButton
                          onClick={() => addNewEntry()}
                          variant="outlined"
                        >
                          {t("add")}
                        </CustomButton>
                      </Box>
                      <PaginationLimitDropDown
                        dataState={dataState}
                        setDataState={setDataState}
                        setSelectedRows={setSelectedRows}
                      />
                    </Grid>
                  </Box>
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={data?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
            />
          </Box>
        </Box>
      </Box>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("customTagSowMultipleDeleteConfirmationText")}
        singleDeleteText={t("customTagSowDeleteConfirmationText")}
      />
      {csvOpen && (
        <CustomTagCsvPopUp
          open={csvOpen}
          setOpen={() => setCsvOpen(false)}
          id={id}
          setOpenSuccess={setOpenSuccess}
        />
      )}
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
      <DialogCommon
        open={openSuccess}
        handleClose={() => setOpenSuccess()}
        title={t("uploadStarted")}
        contentText={t("csvUploadcustomMsg")}
      ></DialogCommon>
    </FieldManagementStyle>
  );
};

export default ScopeOfWork;
