import React from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";

export default function SearchByFilters() {
  const { t } = useTranslation();
  return (
    <>
      <PageTitle title={t("searchByFilters")} />
    </>
  );
}
