import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";
export const TertiaryGroupSchema = Yup.object().shape({
  business: Yup.string()
    .trim()
    .required(validationMessages.pleaseSelectBusinessUnit),
  regionId: Yup.string().trim().required(validationMessages.pleaseSelectRegion),
  newDepartment: Yup.string()
    .trim()
    .max(50, validationMessages.tooLong)
    .matches(validationRegex.alphanumeric, validationMessages.alphanumericOnly)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseEnterTertiaryGroup),
});
