import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Grid, Box } from "@mui/material";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Components/CustomComponentfilter/CustomComponentFilter";
import { routeConfigs } from "utils/routeConfig";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
} from "utils/datatable/agTableOptions";
import CommonGrid from "components/Grid/CommonGrid";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import TableEditIcon from "components/Grid/TableComponents/TableIcons/TableEditIcon";
import TableViewIcon from "components/Grid/TableComponents/TableIcons/TableViewIcon";
import {
  getGeneralFooterPageFieldsApi,
  getGeneralFooterPagesApi,
} from "./features/footerGeneralPagesAction";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import {
  footerGeneralPagesData,
  footerGeneralPagesFieldsData,
} from "./features/footerGeneralPagesSlice";
import { FieldManagementStyle } from "../../fields-management/fieldManagementStyle";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";

const FooterGeneralPages = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pagesData = useSelector(footerGeneralPagesData);
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "title",
      headerName: t("title"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "title",
          dispatchName: "title",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"title"}
                sortBy={"title"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "createdAt",
      headerName: t("date"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "createdAt",
          dispatchName: "createdAt",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"createdAt"}
                sortBy={"createdAt"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ data }) =>
        ToolTipWrapperComponent(dayjs(data?.createdAt).format("DD-MM-YYYY")),
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (params) => <th>{params?.displayName}</th>,
      cellRenderer: (params) => (
        <Grid container className="actionIcons">
          <TableViewIcon
            onClick={() => {
              window.open(
                `${window.location.origin}${routeConfigs.customFooterGeneralCmsPage}/${params?.data?.slug}`,
              );
            }}
          />
          <TableEditIcon
            onClick={() => {
              navigate(
                `${routeConfigs.adminPagesFooterGeneralPagesEdit}?id=${params?.data?._id}`,
              );
            }}
          />
        </Grid>
      ),
    },
  ];

  const [rowData, setRowData] = useState();
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [settingType, setSettingType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const fieldValueData = useSelector(footerGeneralPagesFieldsData); //filter
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    dispatch(getGeneralFooterPagesApi(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });

    //eslint-disable-next-line
  }, [dataState, dispatch]);

  useEffect(() => {
    setPreviousColumnFilterData(pagesData?.applied_filters);

    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = pagesData?.[commonKey]),
    );

    setRowData(
      pagesData?.data?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        slug: tableDataObj?.slug,
        title: tableDataObj?.title,
        user_data: tableDataObj?.user_data,
        createdAt: tableDataObj?.createdAt,
        other_table_data: commonDataObj,
      })),
    );
  }, [pagesData]);

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getGeneralFooterPageFieldsApi,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("policyPages")} />
      <Box className="tabsLine" />
      <Box container spacing={4} className={"userReq-table footerGeneralPages"}>
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <PaginationLimitDropDown
                dataState={dataState}
                setDataState={setDataState}
                setSelectedRows={null}
              />
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={pagesData?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
            />
          </Box>
        </Box>
      </Box>
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
};

export default FooterGeneralPages;
