import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";

export const videoModuleSectiontitleSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseAddSectionTitle),
  description: Yup.string()
    .trim()
    .required(validationMessages.pleaseEnterDescription)
    .max(250, validationMessages.tooLong)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    ),
  button_name: Yup.string()
    .trim()
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseEnterButtonName),
});
