import React from 'react'

const ThreeDots = ({ className }) => {
    return (

        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="4" viewBox="0 0 12 4" fill="none">
            <path d="M9.9987 1.9987H10.0054M5.9987 1.9987H6.00536M1.9987 1.9987H2.00536M6.66536 1.9987C6.66536 2.3669 6.3669 2.66536 5.9987 2.66536C5.6305 2.66536 5.33203 2.3669 5.33203 1.9987C5.33203 1.6305 5.6305 1.33203 5.9987 1.33203C6.3669 1.33203 6.66536 1.6305 6.66536 1.9987ZM10.6654 1.9987C10.6654 2.3669 10.3669 2.66536 9.9987 2.66536C9.6305 2.66536 9.33203 2.3669 9.33203 1.9987C9.33203 1.6305 9.6305 1.33203 9.9987 1.33203C10.3669 1.33203 10.6654 1.6305 10.6654 1.9987ZM2.66536 1.9987C2.66536 2.3669 2.36688 2.66536 1.9987 2.66536C1.63051 2.66536 1.33203 2.3669 1.33203 1.9987C1.33203 1.6305 1.63051 1.33203 1.9987 1.33203C2.36688 1.33203 2.66536 1.6305 2.66536 1.9987Z" stroke="#212A36" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>


    )
}

export default ThreeDots