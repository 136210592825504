import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import { t } from "i18next";
import _ from "lodash";
import { ClockIcon } from "@mui/x-date-pickers";
import ContributePopupHeader from "./ContributePopupHeader/ContributePopupHeader";
import ContributePopupFooter from "./ContributePopupFooter/ContributePopupFooter";
import ContributePopupBody from "./ContributePopupBody/ContributePopupBody";
import { createContributeCollectionApi } from "./features/contributePopupAction";
import { fieldTypeMappedObject } from "pages/Admin-Pages/fields-management/Components/AddNewFieldPopup";
import { requiredCollectionTypeFields } from "pages/Admin-Pages/fields-management/features/fieldsManagementSlice";
import TickIcon from "components/Icons/TickIcon/TickIcon";
import FileIcon from "components/Icons/FileIcon/FileIcon";
import { routeConfigs } from "utils/routeConfig";
import HexTick from "components/Icons/TickIcon/HexTick";
import { ContributePopupStyles } from "./contributePopupStyles";
import { contributePopupLoading } from "./features/contributePopupSlice";

export const toCamelCase = (str) =>
  str
    ?.toLowerCase()
    .replace(/[^a-z0-9]+(.)/g, (_, letter) => letter.toUpperCase());

const contributionTypeObject = {
  collection: "collection",
  asset: "asset",
};

const buttonTitleOptions = [
  {
    name: "selectAction",
    id: "default",
    state: "default",
    body: () => (
      <>
        <HexTick /> <p>{t("selectAnAction")}</p>
      </>
    ),
  },
  {
    name: "createCollection",
    id: "complete",
    state: "complete",
    body: () => (
      <>
        <TickIcon /> <p>{t("createCollection")}</p>
      </>
    ),
  },
  {
    name: "saveForDraft",
    id: "draft",
    state: "draft",
    body: () => (
      <>
        <FileIcon /> <p>{t("saveForDraft")}</p>
      </>
    ),
  },
  {
    name: "scheduleCollectionPublish",
    id: "schedule",
    state: "complete",
    body: () => (
      <>
        <ClockIcon /> <p>{t("scheduleCollectionPublish")}</p>
      </>
    ),
  },
];

const ContributePopup = (props) => {
  const { handlePopupClose } = props;
  const dispatch = useDispatch();
  const allCollectionFields = useSelector(requiredCollectionTypeFields);
  const contributeLoading = useSelector(contributePopupLoading);
  const navigate = useNavigate();

  //set the save button disable untill required fields are filled
  const [collectionType, setCollectionType] = useState();
  const [scheduleDateTime, setScheduleDateTime] = useState("");
  const [buttonTitle, setButtonTitle] = useState(buttonTitleOptions?.[0]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [openScheduleAsset, setOpenScheduleAsset] = useState(false);
  const [contributionType, setContributionType] = useState(
    contributionTypeObject.collection,
  );

  const createValidationSchema = (fields) => {
    const shape = {};
    fields?.forEach(({ _id, field_type }) => {
      const camelCaseName = toCamelCase(_id);
      shape[camelCaseName] =
        field_type.name === fieldTypeMappedObject?.checkbox
          ? Yup.array().required(`${_id} is required`)
          : Yup.string().trim().required(`${_id} is required`);
    });
    return Yup.object(shape);
  };

  const validationSchema = createValidationSchema(allCollectionFields);

  const initialValues = allCollectionFields?.reduce(
    (acc, { _id, field_type }) => {
      const camelCaseName = toCamelCase(_id);
      acc[camelCaseName] =
        field_type.name === fieldTypeMappedObject?.toggle
          ? "active"
          : field_type.name === fieldTypeMappedObject?.checkbox
          ? []
          : "";
      return acc;
    },
    {},
  );

  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
  });

  useEffect(() => {
    formik?.resetForm();
    formik.setValues(initialValues);
    setSelectedCategory("");
    //eslint-disable-next-line
  }, [contributionType]);

  const handleCreateCollection = (e) => {
    e.preventDefault();

    const allValues = formik?.values;
    const fieldValues = [];

    for (const property in allValues) {
      const obj = { field_id: property };

      if (typeof allValues[property] === "object") {
        const val = allValues[property]?.join();
        obj.value = val;
      } else {
        obj.value = allValues[property];
      }

      fieldValues.push(obj);
    }

    const body = {
      name: "Hether place redevelopment",
      description: "For now random static description.",
      state: buttonTitle?.state,
      collection_id: collectionType?._id,
      fields: fieldValues,
    };

    if (openScheduleAsset) {
      body.schedule_time = scheduleDateTime;
    }

    dispatch(createContributeCollectionApi(body)).then((res) => {
      if (res?.error) {
        return;
      }
      const id = res?.payload?.createAssetCollection?._id;
      if (buttonTitle?.id === "complete") {
        navigate(`${routeConfigs.contributePage}?id=${id}`);
      } else {
        handleCloseContributePopup(e);
      }
      handleCloseContributePopup(e);
    });
  };

  const handleCloseContributePopup = (e) => {
    e.preventDefault();
    formik?.resetForm();
    formik.setValues(initialValues);
    handlePopupClose(e);
  };

  const handleUploadAsset = (e) => {
    // console.log(e, "upload assets");
  };

  const checkDisabled = () => {
    //this function decides if the save button should be enabled or disabled
    //return true to disable the save button

    if (contributeLoading) {
      return true;
    }
    if (contributionType === contributionTypeObject.asset) {
      return selectedCategory && selectedCategory !== "" ? false : true;
    } else if (contributionType === contributionTypeObject.collection) {
      if (openScheduleAsset && (scheduleDateTime === "" || !scheduleDateTime)) {
        return true;
      }
      const allValues = formik?.values;

      if (_.isEmpty(allValues)) {
        return true;
      }

      let allValuesFilled = true;
      for (const property in allValues) {
        if (
          !allValues[property] ||
          allValues[property] === "" ||
          allValues[property].length === 0 ||
          (typeof allValues[property] === "string" &&
            allValues[property]?.trim() === "")
        ) {
          allValuesFilled = false;
        }
      }
      return !allValuesFilled;
    } else {
      return true;
    }
  };

  return (
    <ContributePopupStyles>
      <div className="contributePopupMain">
        <ContributePopupHeader
          handlePopupClose={handleCloseContributePopup}
          popupTitle={
            openScheduleAsset ? t("scheduleProjectPublish") : t("contribute")
          }
          showBackButton={openScheduleAsset}
          setShowBackButton={setOpenScheduleAsset}
        />
        <form>
          <ContributePopupBody
            collectionType={collectionType}
            setCollectionType={setCollectionType}
            allCollectionTypeFields={requiredCollectionTypeFields}
            allCollectionFields={allCollectionFields}
            formik={formik}
            setContributionType={setContributionType}
            contributionTypeObject={contributionTypeObject}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            openScheduleAsset={openScheduleAsset}
            setOpenScheduleAsset={setOpenScheduleAsset}
            setScheduleDateTime={setScheduleDateTime}
          />
          <ContributePopupFooter
            handlePopupClose={handleCloseContributePopup}
            handleCreateCollection={handleCreateCollection}
            formik={formik}
            buttonTitle={buttonTitle}
            setButtonTitle={setButtonTitle}
            buttonTitleOptions={buttonTitleOptions}
            contributionType={contributionType}
            contributionTypeObject={contributionTypeObject}
            handleUploadAsset={handleUploadAsset}
            checkDisabled={checkDisabled}
            openScheduleAsset={openScheduleAsset}
            setOpenScheduleAsset={setOpenScheduleAsset}
          />
        </form>
      </div>
    </ContributePopupStyles>
  );
};

export default ContributePopup;
