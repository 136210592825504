import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import axiosConfig from "utils/axiosConfig";
import { apiRoutes } from "utils/apiRoutes";

// Add a new featured collection
export const addFeaturedCollectionApi = createAsyncThunk(
  "featuredCollection/addFeaturedCollectionApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.addFeaturedCollection, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Get all featured collections
export const getFeaturedCollectionsApi = createAsyncThunk(
  "featuredCollection/getFeaturedCollectionsApi",
  async ({ sortBy, sortOrder, limit, page, filtersQueryString }) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.getFeaturedCollections, {
          params: {
            sortBy,
            sortOrder,
            limit,
            page,
            filters: filtersQueryString,
          },
        }),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Get featured collection title
export const getFeaturedCollectionTitleApi = createAsyncThunk(
  "featuredCollection/getFeaturedCollectionTitleApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.getFeaturedCollectionTitle),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Update featured collection status
export const updateFeatureCollectionApi = createAsyncThunk(
  "featuredCollection/updateFeatureCollectionApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.updateFeatureCollection, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Update feature collection index
export const updateFeatureCollectionIndexApi = createAsyncThunk(
  "featuredCollection/updateFeatureCollectionIndexApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.updateFeatureCollectionIndex, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Update feature collection title
export const updateFeatureCollectionTitleApi = createAsyncThunk(
  "featuredCollection/updateFeatureCollectionTitleApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.updateFeatureCollectionTitle, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Delete feature collection
export const deleteFeatureCollectionApi = createAsyncThunk(
  "featuredCollection/deleteFeatureCollectionApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(`${apiRoutes.deleteFeatureCollection}`, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Get featured collection for frontend
export const getFeaturedCollectionFEApi = createAsyncThunk(
  "featuredCollection/getFeaturedCollectionFEApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.getFeaturedCollectionFE),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
