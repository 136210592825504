import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Field, Formik } from "formik";
import {
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { EditSubCategory } from "pages/Admin-Pages/asset-administration/AssetSubCategory/Components/EditSubCategory";
import { StyledButton } from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import Sorting from "utils/sorting";
import { TertiaryGroupSchema } from "utils/validation/validation-schemas/tertiaryGroupSchema";
import { getSecondaryByPrimaryId } from "../features/departmentAction";

const EditTertiaryGroupPopup = (props) => {
  const { t } = useTranslation();
  const {
    editOpen,
    handleEditPopClose,
    businessData,
    regions,
    currentRowData,
    editDepartment,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (editOpen) {
      dispatch(getSecondaryByPrimaryId(currentRowData?.regionId));
    }
  }, [editOpen, dispatch, currentRowData?.regionId]);

  const initialValues = {
    regionId: currentRowData?.regionId || " ",
    departmentId: currentRowData?._id,
    newDepartment: currentRowData?.name,
    business: currentRowData?.business || " ",
    enabled: currentRowData?.enabled,
  };

  return (
    <DialogCommon
      fullwidth={true}
      open={editOpen}
      handleClose={handleEditPopClose}
      title={t("editTertiaryOrganization")}
      maxWidth={"md"}
    >
      <DialogContent>
        <EditSubCategory>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={TertiaryGroupSchema}
            onSubmit={(values) => editDepartment(values)}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="request-common addNewDivSelect">
                  <Box className="fields">
                    <label className="label">{t("primaryOrganization")}</label>
                    <FormControl
                      variant="outlined"
                      className={"formControl"}
                      size="small"
                    >
                      <Field
                        as={Select}
                        className="permission-role-select"
                        name="regionId"
                        value={values.regionId}
                        onChange={async (e) => {
                          const selectedRegionId =
                            await e?.target?.value?.trim();
                          setFieldValue("business", " ");

                          handleChange(e);

                          dispatch(getSecondaryByPrimaryId(selectedRegionId));
                        }}
                        onBlur={handleBlur}
                      >
                        <MenuItem value=" ">{t("selectPrimary")}</MenuItem>
                        {values?.regionId &&
                          Sorting(regions, "name").map(
                            (val, index) =>
                              (val?._id === values.regionId ||
                                val?.enabled) && (
                                <MenuItem key={index + 1} value={val?._id}>
                                  {val?.name}
                                </MenuItem>
                              ),
                          )}
                      </Field>
                    </FormControl>
                    <span className="errorClass">
                      {errors?.regionId &&
                        touched?.regionId &&
                        errors?.regionId}
                    </span>
                  </Box>
                  <Box className="fields">
                    <label className="label">
                      {t("secondaryOrganization")}
                    </label>
                    <FormControl
                      variant="outlined"
                      className={"formControl"}
                      size="small"
                    >
                      <Field
                        as={Select}
                        className="permission-role-select"
                        name="business"
                        value={values?.business}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <MenuItem value=" ">{t("selectOrganization")}</MenuItem>
                        {businessData &&
                          Sorting(businessData, "name").map(
                            (val, index) =>
                              (val?._id === values.business ||
                                val?.enabled) && (
                                <MenuItem key={index + 1} value={val?._id}>
                                  {val?.name}
                                </MenuItem>
                              ),
                          )}
                      </Field>
                    </FormControl>
                    <span className="errorClass">
                      {values?.regionId && businessData?.length === 0
                        ? t("noOptionsAvailable")
                        : errors.business &&
                          touched.business &&
                          errors.business}
                    </span>
                  </Box>
                  <Box className="fields">
                    <label className="label">{t("tertiaryOrganization")}</label>
                    <TextField
                      placeholder={t("enterTertiaryOrganization")}
                      size="small"
                      variant="outlined"
                      type="text"
                      name="newDepartment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newDepartment}
                    />
                    <span className="errorClass">
                      {errors.newDepartment &&
                        touched.newDepartment &&
                        errors.newDepartment}
                    </span>
                  </Box>
                </div>
                <DialogActions className="popup-button-outer">
                  <StyledButton variant={"contained"} type="submit">
                    {t("update")}
                  </StyledButton>
                  <StyledButton
                    variant={"contained"}
                    onClick={handleEditPopClose}
                  >
                    {t("cancel")}
                  </StyledButton>
                </DialogActions>
              </form>
            )}
          </Formik>
        </EditSubCategory>
      </DialogContent>
    </DialogCommon>
  );
};

export default EditTertiaryGroupPopup;
