import { styled } from "@mui/system";

const StockVideoStyles = styled("div")(({ theme }) => ({
  " .stocksVideoBlock": {
    ".videoBlock": {
      position: "relative",
      overflow: "hidden",
      video: {
        width: "100%",
        height: "41.667vw",
        objectFit: "cover",
        filter: "brightness(0.5)",
      },
      ".stocksVideosText": {
        position: "absolute",
        top: "50%",
        textAlign: "center",
        left: "50%",
        transform: "translate(-50%,-50%)",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "24px",
        width: "100%",
        maxWidth: "max-content",

        h1: {
          color: theme.palette.whiteColor.main,
          margin: "0",
        },
        span: {
          color: theme.palette.gray_200.main,
          maxWidth: "40.375rem",
        },
      },
    },
  },
}));

export default StockVideoStyles;
