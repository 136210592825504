import { styled } from "@mui/system";

export const TabsStyle = styled("div")(({ theme }) => ({
  ".tabsMain": {
    float: "left",
    width: "100%",
    marginBottom: "-1px",
    ".MuiTabs-flexContainer": {
      borderBottom: "1px solid #dadada",
      button: {
        marginBottom: "-1px",
        backgroundColor: "rgb(247, 247, 247)",
        borderBottom: "1px solid rgb(218, 218, 218)",
        border: "1px solid rgb(218, 218, 218)",
        borderTopRightRadius: "0px",
        borderTopLeftRadius: "0px",
        "&:last-child": {
          borderTopRightRadius: "5px",
        },
        "&:first-of-type": {
          borderTopLeftRadius: "5px",
        },
        "&.Mui-selected": {
          border: "1px solid #dadada",
          borderTopRightRadius: "5px",
          borderTopLeftRadius: "5px",
          borderBottom: "0",
          background: "#fff",
          color: "#031d41",
          "&:after": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "2px",
            background: "#031d41",
            top: "0",
          },
        },
        "&:not(:last-child)": {
          borderRight: "0",
        },
      },
    },
    "+ div": {
      float: "left",
      width: "100%",
      borderTop: "1px solid #dadada",
    },
    "&.subTabs": {
      margin: "15px 0 10px",
      position: "relative",
      zIndex: "1",
    },
  },
}));
