import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const marketSegmentsColIndexSortedApi = createAsyncThunk(
  "marketSegments/marketSegmentsColIndexSortedApi",
  async () => {
    try {
      return await performApiRequest(
        () => axiosConfig.get(apiRoutes.getMarketSegmentsColIndexSorted),
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const addMarketSegmentLinkApi = createAsyncThunk(
  "marketSegments/addMarketSegmentLink",
  async (data) => {
    try {
      return await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.adminAddMarketSegmentLink}/${data?.id}`,
            data?.body,
          ),
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateMarketSegmentLinkData
export const updateMarketSegmentLinkDataApi = createAsyncThunk(
  "marketSegments/updateMarketSegmentLinkDataApi",
  async (body) => {
    try {
      return await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.adminUpdateMarketSegmentLinkData}/${body?.id}`,
            body,
          ),
        null,
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateMarketSegmentLinkTarget
export const updateMarketSegmentLinkTargetApi = createAsyncThunk(
  "marketSegments/updateMarketSegmentLinkTargetApi",
  async (body) => {
    try {
      return await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.adminUpdateMarketSegmentLinkTarget}/${body?.id}`,
            body,
          ),
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminDeleteMarketSegmentLink
export const deleteMarketSegmentLinkApi = createAsyncThunk(
  "marketSegments/deleteMarketSegmentLinkApi",
  async (data) => {
    try {
      return await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.adminDeleteMarketSegmentLink}/${data?.colId}`,
            data?.body,
          ),
        false,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateMarketSegmentIndex
export const updateMarketSegmentIndexApi = createAsyncThunk(
  "marketSegments/updateMarketSegmentIndexApi",
  async (data) => {
    try {
      return await performApiRequest(
        () =>
          axiosConfig.put(apiRoutes.adminUpdateMarketSegmentIndex, data?.body),
        null,
        data?.showToast,
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getQuickLinkTitleAction = createAsyncThunk(
  "marketSegments/getQuickLinkTitleApi",
  async () => {
    try {
      return await performApiRequest(() =>axiosConfig.get(apiRoutes.getQuickLinkTitle));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateQuickLinkTitleAction= createAsyncThunk(
  "marketSegments/updateQuickLinkTitleApi",
  async (body) => {
    try {
      return await performApiRequest(
        () => axiosConfig.put(apiRoutes.updateQuickLinkTitle, body)
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
