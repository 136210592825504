import { createSlice } from "@reduxjs/toolkit";
import {
  createSingleContributeAlbum,
  deleteSingleContributeAlbum,
  getAllContributeAlbums,
  getContributionById,
  getCustomTagDatas,
  uploadAssetsForContribution,
  updateKeywordsContribute,
  updateContributeDetailsApi,
  updateContributeLocationApi,
} from "./contributionAction";

const initialState = {
  contributerLoading: false,
  allAlbumsLoading: false,
  createAlbumLoading: false,
  deleteAlbumLoading: false,
  contributerData: [],
  createAlbumData: [],
  deleteAlbumData: [],
  allAlbumsData: [],
  customTagData: [],
  customTagLoading: false,
  uploadAssetsLoading:false,
  uploadAssetsData:[],
  keyWordUpdateLoading: false,
};
const contributionSlice = createSlice({
  name: "contributionSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getContributionById.pending, (state) => {
        state.contributerLoading = true;
      })
      .addCase(getContributionById.fulfilled, (state, action) => {
        state.contributerLoading = false;
        state.contributerData = action.payload;
      });
    builder
      .addCase(getAllContributeAlbums.pending, (state) => {
        state.allAlbumsLoading = true;
      })
      .addCase(getAllContributeAlbums.fulfilled, (state, action) => {
        state.allAlbumsLoading = false;
        state.allAlbumsData = action.payload;
      });
    builder
      .addCase(createSingleContributeAlbum.pending, (state) => {
        state.createAlbumLoading = true;
      })
      .addCase(createSingleContributeAlbum.fulfilled, (state, action) => {
        state.createAlbumLoading = false;
        state.createAlbumData = action.payload;
      });
    builder
      .addCase(deleteSingleContributeAlbum.pending, (state) => {
        state.deleteAlbumLoading = true;
      })
      .addCase(deleteSingleContributeAlbum.fulfilled, (state, action) => {
        state.deleteAlbumLoading = false;
        state.deleteAlbumData = action.payload;
      });
    builder
      .addCase(getCustomTagDatas.pending, (state) => {
        state.customTagLoading = true;
      })
      .addCase(getCustomTagDatas.fulfilled, (state, action) => {
        state.customTagLoading = false;
        state.customTagData = action.payload;
      })
      .addCase(getCustomTagDatas.rejected, (state, action) => {
        state.customTagLoading = false;
      });
      builder
      .addCase(uploadAssetsForContribution.pending, (state) => {
        state.uploadAssetsLoading = true;
      })
      .addCase(uploadAssetsForContribution.fulfilled, (state, action) => {
        state.uploadAssetsLoading = false;
        state.uploadAssetsData = action.payload;
      })
      .addCase(uploadAssetsForContribution.rejected, (state, action) => {
        state.uploadAssetsLoading = false;
      });
    
    builder
      .addCase(updateKeywordsContribute.pending, (state) => {
        state.keyWordUpdateLoading = true;
      })
      .addCase(updateKeywordsContribute.fulfilled, (state, action) => {
        state.keyWordUpdateLoading = false;
      })
      .addCase(updateKeywordsContribute.rejected, (state, action) => {
        state.keyWordUpdateLoading = false;
      });
    //updateContributeDetailsApi
    builder
      .addCase(updateContributeDetailsApi.pending, (state) => {
        state.customTagLoading = true;
      })
      .addCase(updateContributeDetailsApi.fulfilled, (state, action) => {
        state.customTagLoading = false;
        state.customTagData = action.payload;
      })
      .addCase(updateContributeDetailsApi.rejected, (state, action) => {
        state.customTagLoading = false;
      });
    //updateContributeLocationApi
    builder
      .addCase(updateContributeLocationApi.pending, (state) => {
        state.customTagLoading = true;
      })
      .addCase(updateContributeLocationApi.fulfilled, (state, action) => {
        state.customTagLoading = false;
        state.customTagData = action.payload;
      })
      .addCase(updateContributeLocationApi.rejected, (state, action) => {
        state.customTagLoading = false;
      });
  },
});

export const contributerDataState = (state) => {
  return state?.contributionSlice;
};
export default contributionSlice.reducer;
