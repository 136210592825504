import { createSlice } from "@reduxjs/toolkit";
import {
  assetSubCategoryEditApi,
  assetSubCategoryEnableDisableApi,
  assetsSubCategorydeleteApi,
  countActiveSubCategories,
  feGetAllSubCategoriesApi,
  getAssetSubCategoryApi,
  getSubCategoryFields,
  getsubCategoryBasedOnCategory,
  subAssetCategoryCreateApi,
  subCatShowHomePageApi,
} from "./assetSubCategoryAction";

const initialState = {
  subAssetCategory: [],
  subAssetCategoryCount: "",
  feSubCategoryData: [],
  loadingState: false,
  categoriesBySubCategories: [],
};

const assetSubCategorySlice = createSlice({
  name: "subAssetCategory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssetSubCategoryApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAssetSubCategoryApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.subAssetCategory = action.payload;
      });

    builder
      .addCase(assetSubCategoryEnableDisableApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assetSubCategoryEnableDisableApi.fulfilled, (state, action) => {
        state.status = "idle";
      });

    builder
      .addCase(assetsSubCategorydeleteApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assetsSubCategorydeleteApi.fulfilled, (state, action) => {
        state.status = "idle";
      });

    builder
      .addCase(subAssetCategoryCreateApi.pending, (state) => {
        state.loadingState = true;
      })
      .addCase(subAssetCategoryCreateApi.fulfilled, (state, action) => {
        state.loadingState = false;
      })
      .addCase(subAssetCategoryCreateApi.rejected, (state, action) => {
        state.loadingState = false;
      });

    builder
      .addCase(assetSubCategoryEditApi.pending, (state) => {
        state.loadingState = true;
      })
      .addCase(assetSubCategoryEditApi.fulfilled, (state) => {
        state.loadingState = false;
      })
      .addCase(assetSubCategoryEditApi.rejected, (state) => {
        state.loadingState = false;
      });

    builder
      .addCase(countActiveSubCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(countActiveSubCategories.fulfilled, (state, action) => {
        state.status = "idle";
        state.subAssetCategoryCount = action.payload;
      });
    //subCatShowHomePageApi
    builder
      .addCase(subCatShowHomePageApi.pending, (state) => {
        state.loadingState = true;
      })
      .addCase(subCatShowHomePageApi.fulfilled, (state) => {
        state.loadingState = false;
      })
      .addCase(subCatShowHomePageApi.rejected, (state) => {
        state.loadingState = false;
      });

    //filter
    builder
      .addCase(getSubCategoryFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSubCategoryFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });

    //feGetAllSubCategoriesApi
    builder
      .addCase(feGetAllSubCategoriesApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(feGetAllSubCategoriesApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.feSubCategoryData = action.payload;
      });

    //getsubCategoryBasedOnCategory
    builder
      .addCase(getsubCategoryBasedOnCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getsubCategoryBasedOnCategory.fulfilled, (state, action) => {
        state.status = "idle";
        state.categoriesBySubCategories = action.payload;
      });
  },
});

export const subAssetCategoryState = (state) =>
  state?.subAssetCategory?.subAssetCategory;

export const subAssetCategoryCount = (state) =>
  state?.subAssetCategory?.subAssetCategoryCount;

export const subCategoryFieldValueArray = (state) => {
  return state?.subAssetCategory?.fieldValues?.asset_subcategories;
};

export const feAllSubCategories = (state) =>
  state?.subAssetCategory?.feSubCategoryData;

export const assetSubCategoryLoading = (state) =>
  state?.subAssetCategory?.loadingState;

export const categoriesBySubCategories = (state) =>
  state?.subAssetCategory?.categoriesBySubCategories;

export default assetSubCategorySlice.reducer;
