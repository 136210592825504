import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Breadcrumbs, Grid, Typography, Box, Link } from "@mui/material";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import { routeConfigs } from "utils/routeConfig";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import CommonGrid from "components/Grid/CommonGrid";
import {
  allDeletedUsers,
  deletedUsersFields,
} from "./features/recoverUserSlice";
import {
  getDeletedUserApi,
  getDeletedUserFieldsApi,
  permanentDeleteUsersApi,
  recoverUserApi,
} from "./features/recoverUserAction";
import {
  commonRowDataKeys,
  hiddenAgGridColumn,
} from "utils/datatable/agTableOptions";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Components/CustomComponentfilter/CustomComponentFilter";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import TableRestoreIcon from "components/Grid/TableComponents/TableIcons/TableRestoreIcon";
import { checkForClientAndSuperAdmin } from "utils/permissions/checkPermission";
import CommonDeletePopup from "../../Components/CommonDeletePopup/CommonDeletePopup";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";

const UserRecover = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const gridRef = useRef();

  const deletedUsersAll = useSelector(allDeletedUsers);
  const fieldValueData = useSelector(deletedUsersFields);

  const checkDataSuperAdmin = (props) =>
    //check if data is superAdmin
    props?.data?.userRoles?.[0]?.role !== "super admin" &&
    props?.data?.userRoles?.value?.role !== "super admin";

  const initialDataState = {
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  };

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: (props) => checkDataSuperAdmin(props),
      headerCheckboxSelection: (props) => checkDataSuperAdmin(props),
      suppressFiltersToolPanel: true,
      showDisabledCheckboxes: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "first_name",
      headerName: t("firstName"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "first_name",
          dispatchName: "first_name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"first_name"}
                sortBy={"first_name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "last_name",
      headerName: t("lastName"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "last_name",
          dispatchName: "last_name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"last_name"}
                sortBy={"last_name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "email",
      headerName: t("email"),
      headerClass: "leftAlign",
      minWidth: 350,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "email",
          dispatchName: "email",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"email"}
                sortBy={"email"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "userRoles",
      headerName: t("userRole"),
      headerClass: "leftAlign",

      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "userRoles.role",
          dispatchName: "userRoles.role",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"userRoles.role"}
                sortBy={"userRoles.role"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: (params) => {
        return (
          <span
            className={`text_capitalize ${
              params?.value?.[0]?.enabled || params?.value?.enabled
                ? ""
                : "greyDisabled"
            }`}
          >
            {ToolTipWrapperComponent(params?.value?.[0]?.role)}
          </span>
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => {
        return (
          checkDataSuperAdmin(props) && (
            <Grid container className="actionIcons">
              <TableRestoreIcon
                onClick={() => {
                  setOpenRecover(true);
                  setRowsToBeDeleted([props?.data?._id]);
                }}
              />
              <TableDeleteIcon
                onClick={() => {
                  setOpenDelete(true);
                  setRowsToBeDeleted([props?.data?._id]);
                }}
              />
            </Grid>
          )
        );
      },
    },
  ];

  const [rowData, setRowData] = useState();
  const [dataState, setDataState] = useState(initialDataState); //filter changes
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [isLoading, setIsLoading] = useState(false);
  //required for future use if need to make draggable columns
  //eslint-disable-next-line
  const [settingType, setSettingType] = useState();
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [selectedRows, setSelectedRows] = useState();
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openRecover, setOpenRecover] = useState(false);
  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    if (!checkForClientAndSuperAdmin()) {
      navigate(routeConfigs.error);
      return;
    }
    dispatch(getDeletedUserApi(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  useEffect(() => {
    setPreviousColumnFilterData(deletedUsersAll?.applied_filters);

    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = deletedUsersAll?.[commonKey]),
    );

    setRowData(
      deletedUsersAll?.userDataArray?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        first_name: tableDataObj?.first_name,
        last_name: tableDataObj?.last_name,
        email: tableDataObj?.email,
        userRoles: tableDataObj?.userRoles,
        other_table_data: commonDataObj,
      })),
    );
  }, [deletedUsersAll]);

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getDeletedUserFieldsApi,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  function handleDelete() {
    const body = {
      ids: rowsToBeDeleted,
    };
    const databody = { body, dataState };
    const shouldPageChange = deletePageChangeChecker(
      deletedUsersAll?.currentPage,
      deletedUsersAll?.totalDocuments,
      rowsToBeDeleted?.length,
      deletedUsersAll?.limit,
    );

    databody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(permanentDeleteUsersApi(databody)).then(() => {
      gridRef.current.api.deselectAll();
      if (shouldPageChange) setDataState(databody.dataState);
    });

    setSelectedRows(false);
    setOpenDelete(false);
  }

  function handleRecover() {
    const body = {
      ids: rowsToBeDeleted,
    };
    const databody = { body, dataState };
    const shouldPageChange = deletePageChangeChecker(
      deletedUsersAll?.currentPage,
      deletedUsersAll?.totalDocuments,
      rowsToBeDeleted?.length,
      deletedUsersAll?.limit,
    );

    databody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(recoverUserApi(databody)).then(() => {
      gridRef.current.api.deselectAll();
      if (shouldPageChange) setDataState(databody.dataState);
    });

    setSelectedRows(false);
    setOpenRecover(false);
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
    isRowSelectable: (props) => checkDataSuperAdmin(props),
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("recoveredUsers")} />
      <Box role="presentation" className="breadcrumbs">
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit">
            {t("accounts")}
          </Link>
          <Link
            component="button"
            underline="hover"
            color="inherit"
            onClick={() => {
              navigate(routeConfigs.adminUserAll);
            }}
          >
            {t("users")}
          </Link>
          <Typography color="text.primary">{t("recoveredUsers")}</Typography>
        </Breadcrumbs>
      </Box>
      <Box container spacing={4} className={"userReq-table"}>
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="addUserPagesBtn allUserPageBlock table-upper  customeBar_ assestType customTag_">
                <Box className="leftSideInputs">
                  <PaginationLimitDropDown
                    dataState={dataState}
                    setDataState={setDataState}
                    setSelectedRows={null} //for now
                  />
                </Box>
              </Box>
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={deletedUsersAll?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
              setOpenRecover={setOpenRecover}
            />
          </Box>
        </Box>
      </Box>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t(
          "areYouSureYouWantToPermanentlyDeleteTheSelectedUsers",
        )}
        singleDeleteText={t("areYouSureYouWantToPermanentlyDeleteThisUser")}
      />
      <CommonDeletePopup
        openDelete={openRecover}
        setOpenDelete={setOpenRecover}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleRecover}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("areYouSureYouWantToRecoverTheSelectedUsers")}
        singleDeleteText={t("areYouSureYouWantToRecoverThisUser")}
      />
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
};

export default UserRecover;
