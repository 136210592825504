import { styled } from "@mui/system";

const CategoryQuickLinksStyles = styled("div")(({ theme }) => ({
  ".categorySelectMain": {
    display: "flex",
    alignItems: "center",
    gap: "15px",
    margin: "25px 0",
    ".selectValues": {
      display: "flex",
      alignItems: "center",
      gap: "15px",
    },
    ".MuiFormControl-root.formControl": {
      width: "100%",
      maxWidth: "max-content",
      minWidth: "200px",
    },
    ".nodataClass": {
      textAlign: "center",
    },
  },
  ".titleButton": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ".nodataClass": {
    textAlign: "center",
    padding: "100px",
  },
}));

export default CategoryQuickLinksStyles;
