import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";
const allowedFormats = ["image/jpeg", "image/jpg", "image/bmp", "image/png"];

export const contactUsSchema = Yup.object().shape({
  files: Yup.mixed()
    // .test("fileSize", validationMessages.pleaseUploadImageUptoOneMB, (file) => {
    //   if (file) {
    //     return file.size <= 1100000;
    //   } else {
    //     return true;
    //   }
    // })
    // .test(
    //   "fileFormat",
    //   validationMessages.pleaseUploadImageInValidFormat,
    //   (file) => {
    //     if (!file) return true; // Allow empty file
    //     return allowedFormats.includes(file.type);
    //   },
    // )
    .required(validationMessages?.pleaseSelectFile),
  title: Yup.string()
    .trim()
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseAddTitle),
  btnText: Yup.string()
    .trim()
    .max(15, validationMessages.tooLong)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseEnterButtonName),
  description: Yup.string()
    .trim()
    .max(250, validationMessages.tooLong)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    ),
});
