import { styled } from "@mui/system";

export const ProfileSettingStyle = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  // height: "calc(100vh - 70px)",
  ".container": {
    width: "100%",
    maxWidth: "1440px",
    margin: "auto",
    padding: "0 15px",
    ".profileSetting ": {
      ".tabPages ": {
        width: "100%",
        " .profileSettingBlock": {
          padding: "0 15px",
          display: "grid",
          gridTemplateColumns: "repeat(6, 1fr)",
          ".profilePicture ": {
            paddingTop: "0px",
            position: "relative",
            gridColumn: "span 6",
            display: "flex",
            alignItems: "center",
            gap: "50px",

            "label.custom-file-upload": {
              // display: "block",
              width: "100%",
              maxWidth: "262px",
              height: "262px",
              borderRadius: "50%",
              background: theme.palette.white.main,
              margin: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              position: "relative",

              ".img-wrap": {
                width: "100%",
                height: "100%",
                borderRadius: "50%",
                overflow: "hidden",
                objectPosition: "top",
                img: {
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  overflow: "hidden",
                  objectPosition: "top",
                  padding: "8px",
                  boxShadow: "0 0 0 #000, 0 0 15px #0003",
                  objectFit: "cover",
                },
              },
              "span.errorClass": {
                position: "absolute",
                bottom: "-30px",
              },
            },

            ".settingProfile": {
              width: "100%",
              maxWidth: "100%",
              h4: {
                margin: "0 0 28px",
                fontSize: "26px",
                fontWeight: "400",
                color: theme.palette.primary.main,
              },
              ".profileSettingButotns": {
                display: "flex",
                alignItems: "center",
                ".buttonDes ": {
                  maxWidth: "230px",
                  border: "1px solid black",
                  borderRadius: "8px",
                  position: "relative",
                  height: "55px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                  'input[type="file"]': {
                    appearance: "none",
                    height: "100%",
                    width: "100%",
                    textIndent: "500px",
                  },
                  span: {
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%,-50%)",
                    fontSize: "20px",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "400",

                    height: "40px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "15px",
                    pointerEvents: "none",
                    cursor: "pointer",
                  },
                  button: {
                    padding: "0",
                    border: "0px solid #e5e5e5",
                    borderRadius: "50%",
                    background: "transparent",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    gap: "15px",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",

                    span: {
                      fontSize: "20px",
                    },
                    label: {
                      display: "block",
                      width: "100%",
                      height: "100%",
                    },
                  },
                },
                ".deletBtnPic ": {
                  marginLeft: "15px",
                },
              },
            },
          },
        },
        ".profileInformaiton": {
          gridColumn: "span 6",
          marginTop: "30px",
          " .request-common": {
            width: "calc(100% / 2 - 20px)",
            float: "left",
            display: "grid",
            margin: "0 10px 25px",
            position: "relative",
            "&.secondaryCustom": {
              " span.errorClass": {
                top: "75px",
              },
            },
            ":nth-of-type(-n+2)": {
              width: "calc(100% / 2 - 20px)",
            },

            ".addNewDivSelect": {
              width: "100%",
              maxWidth: "100%",
              ".formControl ": {
                width: "100%",
              },
            },
            ".label": {
              marginBottom: "8px",
            },
            "span.errorClass": {
              position: "absolute",
              bottom: "-21px",
            },
          },
        },
      },
    },
  },
}));

export default ProfileSettingStyle;
