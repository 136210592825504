import React from "react";
import { styled } from "@mui/system";
import MinusIcon from "components/Icons/MinusIcon/MinusIcon";
import PlusIcon from "components/Icons/PlusIcon/PlusIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";

const StyledLi = styled("li")(({ theme }) => ({
  border: `1px solid ${theme.palette.gray_300.main}`,
  padding: "7px 16px",
  borderRadius: "30px",
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",
  cursor: "pointer",
  color: theme.palette.gray_300.main,
  ".iconBlock": {
    width: "16px",
    height: "16px",
    border: `1px solid ${theme.palette.primaryColor.main}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "4px",
    svg: {
      width: "10px",
      height: "10px",
      path: {
        stroke: theme.palette.primaryColor.main,
      },
    },
  },
  span: {
    color: theme.palette.blackColor.main,
  },
  "&.selectedTag": {
    borderColor: theme.palette.primaryColor.main,
    color: theme.palette.blackColor.main,
    ".iconBlock": {
      backgroundColor: theme.palette.primaryColor.main,
      svg: {
        path: {
          stroke: theme.palette.whiteColor.main,
        },
      },
    },
  },
  "&:hover": {
    ".iconBlock": {
      backgroundColor: theme.palette.primaryColor.main,
      svg: {
        path: {
          stroke: theme.palette.whiteColor.main,
        },
      },
    },
  },
  "&.disabled": {
    pointerEvents: "none",
    cursor: "auto",
    borderColor: theme.palette.gray_250.main,
    backgroundColor: theme.palette.gray_250.main,
    span: {
      color: theme.palette.gray_500.main,
    },
    ".iconBlock": {
      borderColor: theme.palette.gray_300.main,
      backgroundColor: theme.palette.gray_300.main,
      svg: {
        path: {
          stroke: theme.palette.gray_300.main,
        },
        fill: theme.palette.gray_300.main,
      },
    },
  },
}));

const PlusMinusPill = ({ isSelected, onClick, name, disabled }) => {
  return (
    <StyledLi
      className={`${isSelected ? "selectedTag" : ""} ${disabled ? "disabled" : ""
        }`}
      onClick={onClick}
    >
      {isSelected ? (
        <div className="iconBlock">
          <MinusIcon />
        </div>
      ) : (
        <div className="iconBlock">
          <PlusIcon />
        </div>
      )}
      <TextTag variant={textVariants._12px.medium}>{name}</TextTag>
    </StyledLi>
  );
};

export default PlusMinusPill;
