import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import {
  DialogActions,
  DialogContent,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Box,
  Slider,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { UserDetailDialogDesign } from "pages/Admin-Pages/user-administration/styles/roleStyles";
import CustomButton from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import FieldValuesTable from "./FieldValuesTable";
import { allCustomtagNames } from "pages/Admin-Pages/aui-form-fields/CustomTags/features/customTagSlice";
import { getCustomTags } from "pages/Admin-Pages/aui-form-fields/CustomTags/features/customTagAction";
import Sorting from "utils/sorting";
import {
  adminCreateFieldApi,
  getFieldTypeApi,
  updateFieldApi,
} from "../features/fieldsManagementAction";
import { fieldManagementState } from "../features/fieldsManagementSlice";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";

export const fieldTypeMappedObject = {
  checkbox: "checkbox",
  dropDown: "drop-down",
  toggle: "toggle",
  textBox: "text box",
  customTag: "custom tag",
  date: "date",
  number: "number",
  address: "address",
};

const AddNewFieldPopup = (props) => {
  const { t } = useTranslation();
  const {
    openAddNewFieldPopup, //open or close popup
    setOpenAddNewFieldPopup, //set wether open or close popup
    collectionId, // collection id
    openNewFieldPopupFor, // why you want to open the popup ADD or EDIT
    currentRowData, // data of current table row when edit is clicked
    openPopupForObj, // this is just static object containg add or edit value
    dataState,
    setDataState,
  } = props;

  const initialMainFields = {
    client_id:
      openNewFieldPopupFor === openPopupForObj.edit
        ? currentRowData?.client_id
        : false,
    main_title:
      openNewFieldPopupFor === openPopupForObj.edit
        ? currentRowData?.main_title
        : false,
    main_description:
      openNewFieldPopupFor === openPopupForObj.edit
        ? currentRowData?.main_description
        : false,
  };

  const dispatch = useDispatch();
  const customTagData = useSelector(allCustomtagNames);
  const fieldsAllDataByCollectionId = useSelector(fieldManagementState);
  const [initialFieldArrayValues, setInitialFieldArrayValues] = useState([]);
  const [tableError, setTableError] = useState({
    addError: "",
    editError: "",
  });
  const [limitCheck, setLimitCheck] = useState(false);
  const [mainFields, setMainFields] = useState(initialMainFields);

  useEffect(() => {
    setMainFields(initialMainFields);
    //eslint-disable-next-line
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: currentRowData?.name || "", //name of the field
      description: currentRowData?.description || "", //description about the field
      fieldDataType: currentRowData?.custom_tag_id
        ? "CUSTOMTAG"
        : currentRowData?.field_type_id
        ? "NEWFIELD"
        : " ", // data type of the field CUSTOMTAG or NEWFIELD
      collection_id: collectionId, //collection id in which field is being created
      field_type_id: currentRowData?.field_type_id || " ", // id of data type of field
      field_type_key: currentRowData?.field_type || " ", //name of data type of field
      custom_tag_id: currentRowData?.custom_tag_id || " ", //custom tag id
      limit:
        currentRowData?.field_default_value?.[0]?.limit ||
        currentRowData?.field_default_value?.limit ||
        (openNewFieldPopupFor === openPopupForObj.edit ? null : 70), //text limit of the field
      values: initialFieldArrayValues || [], //checkbox or dropdown values
    },

    onSubmit: (values) => {
      const body = {};

      /*
       *all the code below till dispatch is to filter out those values from the form
       *which are null or empty
       *the other part of code is to make sure only the necessary keys are in the body
       *objects
       */

      for (const [key, value] of Object.entries(values)) {
        if (Array?.isArray(value)) {
          if (value?.length !== 0) {
            body[key] = value;
          }
        } else if (typeof value === "string") {
          if (value?.trim()?.length !== 0) {
            body[key] = value;
          }
        } else if (typeof value === "number" && value !== 0) {
          body[key] = value;
        }
      }

      const data = { body, collectionId };
      let bodyObjKeysArr = [];
      if (values.fieldDataType === "CUSTOMTAG") {
        bodyObjKeysArr = ["collection_id", "custom_tag_id"];
      } else if (values.fieldDataType === "NEWFIELD") {
        bodyObjKeysArr = [
          "collection_id",
          "name",
          "field_type_id",
          "description",
        ];
        if (
          values.field_type_key === fieldTypeMappedObject.checkbox ||
          values.field_type_key === fieldTypeMappedObject.dropDown
        ) {
          bodyObjKeysArr = [...bodyObjKeysArr, "values"];
        } else if (
          values.field_type_key === fieldTypeMappedObject.date ||
          values.field_type_key === fieldTypeMappedObject.textBox
        ) {
          bodyObjKeysArr = [...bodyObjKeysArr, "limit"];
        }
      }

      keepProps(body, bodyObjKeysArr);

      if (limitCheck) {
        delete data?.body["limit"];
      } else {
        data.body["limit"] = formik?.values?.limit ? formik?.values?.limit : 10;
      }

      data.body.main_title = mainFields.main_title;

      data.body.main_description = mainFields.main_description;

      data.body.client_id = mainFields.client_id;

      if (openNewFieldPopupFor === openPopupForObj.add) {
        data.dataState = {
          ...dataState,
          page: 1,
          sortOrder: "asc",
          sortBy: "order_index",
        };
        dispatch(adminCreateFieldApi(data)).then((resp) => {
          if (resp?.error) {
            return;
          }
          setDataState(data.dataState);
          handleClose();
        });
      } else if (openNewFieldPopupFor === openPopupForObj.edit) {
        data.fieldId = currentRowData?._id;
        data.currentCollectionId = collectionId;
        data.dataState = dataState;
        dispatch(updateFieldApi(data)).then((resp) => {
          if (resp?.error) {
            return;
          }
          handleClose();
        });
      }
    },
    validationSchema: Yup.object().shape({
      fieldDataType: Yup.string()
        .trim()
        .required(t("pleaseSelectFieldDataType")),
      custom_tag_id: Yup.string().when("fieldDataType", {
        is: "CUSTOMTAG",
        then: () => Yup.string().trim().required(t("pleaseSelectCustomTag")),
      }),
      name: Yup.string().when("fieldDataType", {
        is: "NEWFIELD",
        then: () =>
          Yup.string()
            .trim()
            .required(t("pleaseEnterFieldName"))
            .max(50, validationMessages.tooLong)
            .matches(
              validationRegex?.checkForDoubleSpaces,
              validationMessages?.noConsecutiveDoubleSpaces,
            ),
      }),
      description: Yup.string().when("fieldDataType", {
        is: "NEWFIELD",
        then: () => Yup.string().trim().max(301, validationMessages.tooLong),
      }),
      field_type_id: Yup.string().when("fieldDataType", {
        is: "NEWFIELD",
        then: () => Yup.string().trim().required(t("pleaseSelectFieldType")),
      }),
      values: Yup.array().when("field_type_key", {
        /**
         * For values checking if field type is either checkbox or dropdown
         * and fieldDataType is NEWFIELD
         * then checking values is and array and has minimum 1 value
         */
        is: (fieldType) =>
          fieldType === fieldTypeMappedObject.checkbox ||
          fieldType === fieldTypeMappedObject.dropDown,
        then: () =>
          Yup.array().when("fieldDataType", {
            is: (fieldType) => fieldType === "NEWFIELD",
            then: () =>
              Yup.array()
                .required(t("pleaseEnterFieldValues"))
                .min(1, t("pleaseEnterFieldValues")),
          }),
      }),
    }),
  });

  useEffect(() => {
    if (openAddNewFieldPopup) {
      // getting the list of custom tags and field types when popup opens
      dispatch(getCustomTags());
      dispatch(getFieldTypeApi());
    }
  }, [dispatch, openAddNewFieldPopup]);

  useEffect(() => {
    setLimitCheck(false);
    if (
      (currentRowData?.field_default_value?.[0]?.limit === null ||
        currentRowData?.field_default_value?.limit === null) &&
      openNewFieldPopupFor === openPopupForObj.edit
    ) {
      setLimitCheck(true);
    }
    // below we are setting initial values of array
    // with the label of the field values object
    if (currentRowData?.field_default_value?.value?.length > 0) {
      const fieldArray = currentRowData?.field_default_value?.value?.map(
        (fieldObj) => fieldObj?.label || fieldObj?.option,
      );
      setInitialFieldArrayValues(fieldArray);
    } else if (currentRowData?.field_default_value?.[0]?.value?.length > 0) {
      const fieldArray = currentRowData?.field_default_value?.[0]?.value?.map(
        (fieldObj) => fieldObj?.label || fieldObj?.option,
      );
      setInitialFieldArrayValues(fieldArray);
    } else {
      setInitialFieldArrayValues([]);
    }
    //eslint-disable-next-line
  }, [currentRowData]);

  function keepProps(obj, keep) {
    // here obj is object with key value pairs
    // here keep is an array of keys
    // that we want to kkep in the object
    for (const prop in obj) {
      if (keep.indexOf(prop) === -1) {
        delete obj[prop];
      }
    }
  }

  function handleClose() {
    setOpenAddNewFieldPopup(false);
    formik.resetForm();
    setMainFields(initialMainFields);
  }

  return (
    <DialogCommon
      open={openAddNewFieldPopup}
      handleClose={handleClose}
      title={
        openNewFieldPopupFor === openPopupForObj.add
          ? t("addNewField")
          : t("upDateField")
      }
      fullwidth={true}
      maxWidth="md"
      extraPaperClasses={
        formik.values.fieldDataType?.trim().length === 0
          ? "singleFileInput"
          : "multiFileInput"
      }
    >
      <UserDetailDialogDesign>
        <Box className="popup-main AddUserDetails">
          <DialogContent>
            <Box className="requesOuter">
              <form
                onSubmit={(e) => {
                  setTableError({ ...tableError, addError: "" });
                  if (
                    e.type !== "keydown" ||
                    (e.target && e.target.type === "submit")
                  ) {
                    formik.handleSubmit(e);
                  }
                }}
              >
                <Box className="formSection">
                  {/* select field data type wether new field or custom tag */}
                  <Box className="request-common">
                    <label className="label">{t("fieldDataType")}</label>
                    <Box className="addNewDivSelect">
                      <FormControl
                        variant="outlined"
                        className={"formControl"}
                        size="small"
                      >
                        <Select
                          className="permission-role-select"
                          onBlur={formik.handleBlur}
                          value={formik.values?.fieldDataType}
                          name={"fieldDataType"}
                          defaultValue={" "}
                          disabled={
                            openNewFieldPopupFor === openPopupForObj.edit
                          }
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setTouched({}, false);

                            formik.setFieldValue("custom_tag_id", " ");
                            formik.setFieldValue("field_type_id", " ");
                            formik.setFieldValue("field_type_key", "");

                            formik.setFieldValue("description", "");
                            formik.setFieldValue("values", []);
                            formik.setFieldValue("limit", 70);
                            formik.setFieldValue("name", "");
                            setMainFields(initialMainFields);
                          }}
                        >
                          <MenuItem value={" "}>
                            {t("selectFieldDataType")}
                          </MenuItem>
                          <MenuItem value={"NEWFIELD"}>
                            {t("newField")}
                          </MenuItem>
                          <MenuItem value={"CUSTOMTAG"}>
                            {t("customTag")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <span className="errorClass">
                      {formik.errors.fieldDataType &&
                        formik.touched.fieldDataType &&
                        formik.errors.fieldDataType}
                    </span>
                  </Box>
                  {/* only show custom tags when custom tag is selected from field data type */}
                  {formik.values?.fieldDataType === "CUSTOMTAG" && (
                    <Box className="request-common">
                      <label className="label">{t("customTagList")}</label>
                      <Box className="addNewDivSelect">
                        <FormControl
                          variant="outlined"
                          className={"formControl"}
                          size="small"
                        >
                          <Select
                            className="permission-role-select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values?.custom_tag_id}
                            name={"custom_tag_id"}
                            defaultValue={" "}
                          >
                            <MenuItem value={" "}>
                              {t("selectCustomTag")}
                            </MenuItem>

                            {Sorting(customTagData?.custom_tags, "name")?.map(
                              (customTagObj) =>
                                customTagObj?.visibility_enabled ? (
                                  <MenuItem value={customTagObj?._id}>
                                    {customTagObj?.name}
                                  </MenuItem>
                                ) : (
                                  // showing selected disabled custom tag
                                  formik.values?.custom_tag_id ===
                                    customTagObj?._id && (
                                    <MenuItem
                                      value={formik.values?.custom_tag_id}
                                    >
                                      {customTagObj?.name}
                                    </MenuItem>
                                  )
                                ),
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                      <span className="errorClass">
                        {formik.errors.custom_tag_id &&
                          formik.touched.custom_tag_id &&
                          formik.errors.custom_tag_id}
                      </span>
                    </Box>
                  )}
                  {/* only show when new field is selected */}
                  {formik.values?.fieldDataType === "NEWFIELD" && (
                    <>
                      {/* select field type like Address Checkbox Date etc */}
                      <Box className="request-common">
                        <label className="label">{t("fieldType")}</label>
                        <Box className="addNewDivSelect">
                          <FormControl
                            variant="outlined"
                            className={"formControl"}
                            size="small"
                          >
                            <Select
                              className="permission-role-select"
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setTouched({}, false);

                                formik.setFieldValue("description", "");
                                formik.setFieldValue("values", []);
                                formik.setFieldValue("limit", 70);
                                formik.setFieldValue("name", "");
                                setMainFields(initialMainFields);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values?.field_type_id}
                              name={"field_type_id"}
                              defaultValue={" "}
                              disabled={
                                openNewFieldPopupFor === openPopupForObj.edit
                              }
                            >
                              <MenuItem
                                value={" "}
                                onClick={() => {
                                  formik.setFieldValue("field_type_key", "");
                                }}
                              >
                                {t("selectFieldType")}
                              </MenuItem>
                              {Sorting(
                                fieldsAllDataByCollectionId?.fieldsTypes
                                  ?.field_type,
                                "name",
                              )?.map(
                                (fieldTypeObj) =>
                                  fieldTypeObj?.name !==
                                    fieldTypeMappedObject?.customTag && (
                                    <MenuItem
                                      value={fieldTypeObj?._id}
                                      onClick={() => {
                                        formik.setFieldValue(
                                          "field_type_key",
                                          fieldTypeObj?.name,
                                        );
                                      }}
                                    >
                                      {t(fieldTypeObj?.name)}
                                    </MenuItem>
                                  ),
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                        <span className="errorClass">
                          {formik.errors.field_type_id &&
                            formik.touched.field_type_id &&
                            formik.errors.field_type_id}
                        </span>
                      </Box>
                      {/* Enter field name */}
                      {formik.values.field_type_key?.trim() !== "" && (
                        <Box className="request-common">
                          <label className="label">{t("fieldName")}</label>
                          <TextField
                            placeholder={t("enterFieldName")}
                            size="small"
                            variant="outlined"
                            type="text"
                            name="name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          <span className="errorClass">
                            {formik.errors.name &&
                              formik.touched.name &&
                              formik.errors.name}
                          </span>
                        </Box>
                      )}
                      {/* if TextBox then set character limit */}
                      {formik.values.field_type_key ===
                        fieldTypeMappedObject?.textBox && (
                        <Box className="request-common fieldSliderCheckbox">
                          <label className="label">
                            {t("setCharacterLimit")}
                          </label>
                          <Slider
                            aria-label="Temperature"
                            defaultValue={formik.values.limit}
                            value={formik.values.limit}
                            valueLabelDisplay="auto"
                            step={20}
                            marks
                            min={10}
                            max={500}
                            name="limit"
                            onChange={formik.handleChange}
                            disabled={limitCheck}
                            className="fieldSlider"
                          />
                          <Box className="checkboxWrapper">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key="limit_chekbox"
                                  value={limitCheck}
                                  checked={limitCheck}
                                  onChange={(e) =>
                                    setLimitCheck(e.target.checked)
                                  }
                                />
                              }
                              label={t("limitless")}
                            />
                          </Box>
                        </Box>
                      )}
                      {/* description (tooltip data) of the field */}
                      {formik.values.field_type_key?.trim() !== "" && (
                        <Box className="request-common fieldDescription">
                          <label className="label">{t("description")}</label>
                          <TextField
                            placeholder={t("informationRegardingTheField")}
                            rows={3}
                            size="small"
                            variant="outlined"
                            type="text"
                            multiline={true}
                            name="description"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.description}
                          />
                          <span className="errorClass">
                            {formik.errors.description &&
                              formik.touched.description &&
                              formik.errors.description}
                          </span>
                        </Box>
                      )}
                      {/*  set as client id  */}
                      {(formik.values.field_type_key ===
                        fieldTypeMappedObject?.textBox ||
                        formik.values.field_type_key ===
                          fieldTypeMappedObject?.number) && (
                        <Box className="request-common">
                          <Box className="checkboxWrapper">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key="client_id"
                                  value={mainFields.client_id}
                                  checked={mainFields.client_id}
                                  onChange={(e) =>
                                    setMainFields({
                                      main_description: false,
                                      main_title: false,
                                      client_id: e.target.checked,
                                    })
                                  }
                                />
                              }
                              label={t("setAsClientId")}
                            />
                          </Box>
                        </Box>
                      )}
                      {/*  set as main description */}
                      {formik.values.field_type_key ===
                        fieldTypeMappedObject?.textBox && (
                        <Box className="request-common">
                          <Box className="checkboxWrapper">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key="main_description"
                                  value={mainFields.main_description}
                                  checked={mainFields.main_description}
                                  onChange={(e) =>
                                    setMainFields({
                                      main_description: e.target.checked,
                                      main_title: false,
                                      client_id: false,
                                    })
                                  }
                                />
                              }
                              label={t("setMainDescription")}
                            />
                          </Box>
                        </Box>
                      )}
                      {/*  set as main title */}
                      {formik.values.field_type_key ===
                        fieldTypeMappedObject?.textBox && (
                        <Box className="request-common">
                          <Box className="checkboxWrapper">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  key="main_title"
                                  value={mainFields.main_title}
                                  checked={mainFields.main_title}
                                  onChange={(e) =>
                                    setMainFields({
                                      main_description: false,
                                      main_title: e.target.checked,
                                      client_id: false,
                                    })
                                  }
                                />
                              }
                              label={t("setAsMainTitle")}
                            />
                          </Box>
                        </Box>
                      )}
                      {/* if dropdown or checkbox then show table for field's data */}
                      {(formik.values.field_type_key ===
                        fieldTypeMappedObject?.dropDown ||
                        formik.values.field_type_key ===
                          fieldTypeMappedObject?.checkbox) && (
                        <Box className="request-common fieldValueTable">
                          <FieldValuesTable
                            fieldValueArray={formik.values?.values}
                            setFieldValue={formik.setFieldValue}
                            fieldTypeKey={formik.values.field_type_key}
                            formik={formik}
                            tableError={tableError}
                            setTableError={setTableError}
                          />
                          <span className="errorClass">
                            {formik.errors.values &&
                              formik.touched.values &&
                              formik.errors.values}
                          </span>
                        </Box>
                      )}
                    </>
                  )}
                </Box>
                <DialogActions className="popup-button-outer">
                  <CustomButton variant={"contained"} type="submit">
                    {openNewFieldPopupFor === openPopupForObj.add
                      ? t("save")
                      : t("update")}
                  </CustomButton>
                  <CustomButton variant={"contained"} onClick={handleClose}>
                    {t("cancel")}
                  </CustomButton>
                </DialogActions>
              </form>
            </Box>
          </DialogContent>
        </Box>
      </UserDetailDialogDesign>
    </DialogCommon>
  );
};

export default AddNewFieldPopup;
