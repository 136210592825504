import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const createContributeCollectionApi = createAsyncThunk(
  "contributePopup/createContributeCollectionApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.createContributeCollection, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
