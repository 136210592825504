import { createSlice } from "@reduxjs/toolkit";
import {
  addFeaturedCollectionApi,
  deleteFeatureCollectionApi,
  getFeaturedCollectionFEApi,
  getFeaturedCollectionTitleApi,
  getFeaturedCollectionsApi,
  updateFeatureCollectionApi,
  updateFeatureCollectionIndexApi,
  updateFeatureCollectionTitleApi,
} from "./featuredAction";

const initialState = {
  featuredCollections: [],
  featuredCollectionsFrontend: [],
  collectionTitle: {},
  status: "idle",
  error: null,
};

const featuredCollectionsSlice = createSlice({
  name: "featuredCollectionsSlice",
  initialState,
  reducers: {
    resetError(state) {
      state.error = null; // Reset error state
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFeaturedCollectionsApi.pending, (state) => {
        state.status = "loading"; // Set loading status
      })
      .addCase(getFeaturedCollectionsApi.fulfilled, (state, action) => {
        state.status = "idle"; // Reset status
        state.featuredCollections = action.payload; // Update featured collections
      })
      .addCase(getFeaturedCollectionsApi.rejected, (state, action) => {
        state.status = "idle"; // Reset status
        state.error = action.error.message; // Capture error message
      });

    builder
      .addCase(addFeaturedCollectionApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addFeaturedCollectionApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(addFeaturedCollectionApi.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });

    builder
      .addCase(deleteFeatureCollectionApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteFeatureCollectionApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(deleteFeatureCollectionApi.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });

    builder
      .addCase(updateFeatureCollectionApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateFeatureCollectionApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(updateFeatureCollectionApi.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });

    builder
      .addCase(updateFeatureCollectionIndexApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateFeatureCollectionIndexApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(updateFeatureCollectionIndexApi.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });

    builder
      .addCase(updateFeatureCollectionTitleApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateFeatureCollectionTitleApi.fulfilled, (state) => {
        state.status = "idle";
      })
      .addCase(updateFeatureCollectionTitleApi.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });

    builder
      .addCase(getFeaturedCollectionTitleApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFeaturedCollectionTitleApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.collectionTitle = action.payload;
      })
      .addCase(getFeaturedCollectionTitleApi.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });
    builder
      .addCase(getFeaturedCollectionFEApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFeaturedCollectionFEApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.featuredCollectionsFrontend = action.payload; // Update collection title
      })
      .addCase(getFeaturedCollectionFEApi.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer
export const { resetError } = featuredCollectionsSlice?.actions;
export const selectFeaturedCollections = (state) =>
  state?.featuredCollections?.featuredCollections;
export const selectCollectionTitle = (state) =>
  state?.featuredCollections?.collectionTitle;
export const selectFeaturedCollectionsFrontend = (state) =>
  state?.featuredCollections?.featuredCollectionsFrontend;

export default featuredCollectionsSlice.reducer;
