import React, { useEffect, useRef, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box } from "@mui/system";
import { getUserData } from "pages/Admin-Pages/user-administration/features/userAction";
import Sidebar from "Layout/AdminLayout/Sidebar/Sidebar";
import HeaderNew from "Layout/AdminLayout/Header/HeaderNew";
import withAuth from "../../Auth/withAuth";
import {
  checkForAdminPermission,
  checkPermissionFunction,
} from "utils/permissions/checkPermission";
import { routeConfigs } from "utils/routeConfig";
import { permissionIdObject } from "utils/permissions/permissionUtils";
import Footer from "Layout/AdminLayout/Footer/Footer";
import ForceResetPasswordDialog from "pages/User-Pages/UserPages/home/HomeComponents/ForceResetPasswordDialog";
import HeaderMenu from "./Header/components/HeaderMenu/HeaderMenu";
import { LayoutStyles } from "./LayoutStyles";
import ContributePopup from "components/ContributePopup/ConttributePopup";

const Layout = (props) => {
  const { children } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [showAdmin, setShowAdmin] = useState("load");
  const [showRoute, setShowRoute] = useState("load");

  const [openContributePopup, setOpenContributePopup] = useState(false);

  useEffect(() => {
    //for disabling scroll when popup is open
    document.body.style.overflow = openContributePopup ? "hidden" : "unset";
    return () => (document.body.style.overflow = "unset");
  }, [openContributePopup]);

  useEffect(() => {
    dispatch(getUserData()).then(() => {
      if (checkForAdminPermission()) {
        //checking if user has admin permission
        setShowAdmin(true);
      } else {
        setShowAdmin(false);
      }

      //we are first fetching the PID of the view permission
      //using current pathname and saving pid in routePid
      let routePid = undefined;
      for (const permissionObj in permissionIdObject) {
        if (
          permissionIdObject[permissionObj]?.permissionRoutes !== undefined &&
          permissionIdObject[permissionObj]?.permissionRoutes.some(
            (routeName) => routeName === location.pathname,
          )
        ) {
          routePid = permissionIdObject[permissionObj]?.pid;
        }
      }

      //if we find pid and user doesnot have that pid
      //only then we are not showing that route to the user
      if (routePid !== undefined) {
        if (checkPermissionFunction(routePid)) {
          setShowRoute(true);
        } else {
          setShowRoute(false);
        }
      }
    });
  }, [dispatch, location.pathname]);

  const createRef = useRef(null);

  function handleClick(event) {
    if (createRef.current && !createRef.current.contains(event?.target)) {
      setisOpenMenu(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  return (
    <LayoutStyles>
      <Box className={`main`}>
        <div className={`innMainBlock  ${isOpenMenu ? "headerMenuOpen" : ""}`}>
          <HeaderNew
            isOpenMenu={isOpenMenu}
            setisOpenMenu={setisOpenMenu}
            setOpenContributePopup={setOpenContributePopup}
            openContributePopup={openContributePopup}
          />
          {children}
          {window.location.pathname?.includes("admin") ? (
            showAdmin !== "load" && (
              <Sidebar location={location} {...{ open, setOpen }}>
                <Box className="root">
                  <Box className="dashboardData">
                    {showAdmin && showRoute !== false ? (
                      <Outlet props={props} />
                    ) : (
                      <Navigate to={routeConfigs.error} replace={true} />
                    )}
                  </Box>
                </Box>
              </Sidebar>
            )
          ) : (
            <Footer />
          )}
        </div>
        <Box className="headerMenuBlock" ref={createRef}>
          <HeaderMenu isOpenMenu={isOpenMenu} setisOpenMenu={setisOpenMenu} />
        </Box>
        <ForceResetPasswordDialog />
      </Box>
      {openContributePopup && (
        <ContributePopup
          handlePopupClose={() => setOpenContributePopup(false)}
        />
      )}
    </LayoutStyles>
  );
};

export default withAuth(Layout);
