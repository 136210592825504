import React, { useState } from "react";
import {
  DialogActions,
  DialogContent,
  FormControl,
  Select,
  TextField,
  MenuItem,
  Box,
} from "@mui/material";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getBusinessRegion } from "pages/Admin-Pages/user-administration/features/userAction";
import DialogCommon from "components/DialogComponent/DialogCommon";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import { allUserAddUserApi } from "../../features/allUserAction";
import { UserDetailDialogDesign } from "pages/Admin-Pages/user-administration/styles/roleStyles";
import { UserSchema } from "utils/validation/validation-schemas/userSchema";
import Sorting from "utils/sorting";
import { getDepartemtsByBusinessUnitApi } from "pages/Admin-Pages/tertiaryGroup/features/departmentAction";
import { validationMessages } from "utils/validation/validationUtils";

const AddUserPopup = ({
  open,
  setOpen,
  regionData,
  userRoleData,
  dataState,
  setDataState,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [businessData, setBusinessData] = useState("");
  const state = useSelector((state) => state);
  const [tertiaryGroupData, setTertiaryGroupData] = useState();

  const handleRegionChange = (region) => {
    dispatch(getBusinessRegion(region)).then((response) => {
      setBusinessData(
        response?.payload?.business.filter((dataObj) => dataObj?.enabled),
      );
    });
  };

  const handleSecondaryGroupChange = (secondaryGroupId) => {
    setTertiaryGroupData();
    const data = {
      id: secondaryGroupId,
    };
    dispatch(getDepartemtsByBusinessUnitApi(data)).then((resp) => {
      if (!resp?.error) {
        setTertiaryGroupData(
          resp?.payload?.business_units?.[0]?.departments.filter(
            (dataObj) => dataObj?.enabled,
          ),
        );
      }
    });
  };

  return (
    <DialogCommon
      fullwidth={true}
      open={open}
      handleClose={() => setOpen()}
      title={t("addUserDetails")}
      maxWidth={"md"}
    >
      <UserDetailDialogDesign>
        <div className="popup-main AddUserDetails">
          <DialogContent>
            <div className="requesOuter">
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  email: "",
                  region: " ",
                  business_unit: " ",
                  department: " ",
                  role: " ",
                }}
                validationSchema={UserSchema}
                onSubmit={(values) => {
                  const modifiedValues = {};
                  for (const objVal in values) {
                    modifiedValues[objVal] =
                      values[objVal]?.trim() !== "" &&
                      values[objVal]?.trim() !== undefined
                        ? values[objVal]
                        : null;
                  }
                  dispatch(allUserAddUserApi(modifiedValues))
                    .then((res) => {
                      if (!res?.error) {
                        setOpen();
                      }
                      setDataState({
                        ...dataState,
                        search: "",
                        limit: 20,
                        page: 1,
                        sortOrder: "desc",
                        sortBy: "createdAt",
                        column_name: "",
                        column_values: "",
                      });
                    })
                    .catch((res) => {
                      console.error("an error occured");
                    });
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="request-common">
                      <label className="label">{t("firstName")}</label>
                      <TextField
                        placeholder={t("enterFirstName")}
                        size="small"
                        variant="outlined"
                        type="text"
                        name="first_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.first_name}
                      />
                      <span className="errorClass">
                        {errors.first_name &&
                          touched.first_name &&
                          errors.first_name}
                      </span>
                    </div>
                    <div className="request-common">
                      <label className="label">{t("lastName")}</label>
                      <TextField
                        placeholder={t("enterLastName")}
                        size="small"
                        variant="outlined"
                        type="text"
                        name="last_name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.last_name}
                      />
                      <span className="errorClass">
                        {errors.last_name &&
                          touched.last_name &&
                          errors.last_name}
                      </span>
                    </div>
                    <div className="request-common">
                      <label className="label">{t("userRole")}</label>
                      <Box className="addNewDivSelect">
                        <FormControl
                          variant="outlined"
                          className={"formControl"}
                          size="small"
                        >
                          <Select
                            className="permission-role-select text_capitalize"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.role}
                            name={"role"}
                            defaultValue={" "}
                          >
                            <MenuItem value={" "}>
                              {t("selectUserRole")}
                            </MenuItem>
                            {Array.isArray(userRoleData) &&
                              Sorting(userRoleData, "role")?.map(
                                (val, index) => {
                                  return (
                                    val?.enabled && (
                                      <MenuItem
                                        key={index + 1}
                                        value={val?._id}
                                        className="text_capitalize"
                                      >
                                        {val?.role}
                                      </MenuItem>
                                    )
                                  );
                                },
                              )}
                          </Select>
                        </FormControl>
                      </Box>
                      <span className="errorClass">
                        {errors.role && touched.role && errors.role}
                      </span>
                    </div>
                    <div className="request-common">
                      <label className="label">{t("email")}</label>
                      <TextField
                        placeholder={t("enterEmail")}
                        size="small"
                        variant="outlined"
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <span className="errorClass">
                        {errors.email && touched.email && errors.email}
                      </span>
                    </div>
                    <div className="request-common">
                      <label className="label">
                        {t("primaryOrganization")}
                      </label>
                      <Box className="addNewDivSelect">
                        <FormControl
                          variant="outlined"
                          className={"formControl"}
                          size="small"
                        >
                          <Select
                            name="region"
                            className="permission-role-select"
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue("business_unit", " ");
                              setFieldValue("department", " ");
                              handleRegionChange(e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={values?.region}
                            defaultValue={" "}
                          >
                            <MenuItem value={" "}>
                              {t("selectOrganization")}
                            </MenuItem>
                            {Sorting(regionData, "name")?.map((val, index) => {
                              return (
                                val?.enabled && (
                                  <MenuItem key={index + 1} value={val?._id}>
                                    {val?.name}
                                  </MenuItem>
                                )
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Box>
                    </div>
                    <div className="request-common secondaryOrganiztion">
                      <label className="label">
                        {t("secondaryOrganization")}
                      </label>
                      <Box className="addNewDivSelect">
                        <FormControl
                          variant="outlined"
                          className={"formControl"}
                          size="small"
                        >
                          <Select
                            className="permission-role-select"
                            onChange={(e) => {
                              setFieldValue("department", " ");
                              handleChange(e);
                              handleSecondaryGroupChange(e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={values?.business_unit}
                            name={"business_unit"}
                            disabled={
                              values?.region.trim() === "" ||
                              businessData?.length === 0
                            }
                            defaultValue={" "}
                          >
                            <MenuItem value={" "}>
                              {t("selectOrganization")}
                            </MenuItem>
                            {Array.isArray(businessData) &&
                              Sorting(businessData, "name")?.map(
                                (val, index) => {
                                  return (
                                    val?.enabled && (
                                      <MenuItem
                                        key={index + 1}
                                        value={val?._id}
                                      >
                                        {val?.name}
                                      </MenuItem>
                                    )
                                  );
                                },
                              )}
                          </Select>
                        </FormControl>
                      </Box>
                      <span className="errorClass">
                        {businessData && businessData?.length === 0
                          ? validationMessages.noSecondaryGroupAssociated
                          : ""}
                      </span>
                    </div>
                    <div className="request-common">
                      <label className="label">
                        {t("tertiaryOrganization")}
                      </label>
                      <Box className="addNewDivSelect">
                        <FormControl
                          variant="outlined"
                          className={"formControl"}
                          size="small"
                        >
                          <Select
                            className="permission-role-select"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.department}
                            name={"department"}
                            defaultValue={" "}
                            disabled={
                              values?.business_unit?.trim() === "" ||
                              tertiaryGroupData?.length === 0 ||
                              !tertiaryGroupData
                            }
                          >
                            <MenuItem value={" "}>
                              {t("selectOrganization")}
                            </MenuItem>
                            {Sorting(tertiaryGroupData, "name")?.map(
                              (val, index) => {
                                return (
                                  val?.enabled && (
                                    <MenuItem key={index + 1} value={val?._id}>
                                      {val?.name}
                                    </MenuItem>
                                  )
                                );
                              },
                            )}
                          </Select>
                        </FormControl>
                      </Box>
                      <span className="errorClass">
                        {values?.business_unit?.trim() !== "" &&
                        tertiaryGroupData &&
                        tertiaryGroupData?.length === 0
                          ? validationMessages.noTertiaryGroupAssociated
                          : ""}
                      </span>
                    </div>
                    <DialogActions
                      disabled={state?.allUser?.addUserLoader}
                      className="popup-button-outer"
                    >
                      <StyledButton variant={"contained"} type="submit">
                        {t("save")}
                      </StyledButton>
                      <CustomButton
                        variant={"contained"}
                        onClick={() => setOpen()}
                      >
                        {t("cancel")}
                      </CustomButton>
                    </DialogActions>
                  </form>
                )}
              </Formik>
            </div>
          </DialogContent>
        </div>
      </UserDetailDialogDesign>
    </DialogCommon>
  );
};

export default AddUserPopup;
