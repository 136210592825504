import React from "react";
import { styled } from "@mui/system";
import ContributeCollectionField from "./ContributeCollectionField/ContributeCollectionField";

export const ContributePopupStyles = styled("div")(({ theme }) => ({}));

const CollectionFieldsData = ({ allCollectionFields, ...props }) => {
  return (
    <ContributePopupStyles  className={"popupField"} >
      {allCollectionFields?.map((fieldData) => {
        return (
          fieldData?.required_enabled && (
            <ContributeCollectionField fieldData={fieldData} {...props} />
          )
        );
      })}
    </ContributePopupStyles>
  );
};

export default CollectionFieldsData;
