//getFieldsDataByCollectionId
const { createSlice } = require("@reduxjs/toolkit");
const {
  getFieldsDataByCollectionId,
  getFieldTypeApi,
  updateFieldPermission,
  adminCreateFieldApi,
  adminDeleteFieldsApi,
  adminUpdateFieldRowsIndexApi,
  getContributeRequiredFieldsApi,
} = require("./fieldsManagementAction");

const initialState = {
  fieldsByCollection: [],
  fieldsTypes: [],
  updateLoader: false,
  fieldsByCollectionUser: [],
  fieldsByCollectionLoading: false,
};

const fieldsManagementSlice = createSlice({
  name: "fieldManagement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFieldsDataByCollectionId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFieldsDataByCollectionId.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldsByCollection = action.payload;
      })
      .addCase(getFieldsDataByCollectionId.rejected, (state) => {
        state.status = "idle";
      });
    //user
    builder
      .addCase(getContributeRequiredFieldsApi.pending, (state) => {
        state.fieldsByCollectionLoading = true;
      })
      .addCase(getContributeRequiredFieldsApi.fulfilled, (state, action) => {
        state.fieldsByCollectionLoading = false;
        state.fieldsByCollectionUser = action.payload;
      })
      .addCase(getContributeRequiredFieldsApi.rejected, (state) => {
        state.fieldsByCollectionLoading = false;
      });

    builder
      .addCase(getFieldTypeApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFieldTypeApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldsTypes = action.payload;
      })
      .addCase(getFieldTypeApi.rejected, (state) => {
        state.status = "idle";
      });
    builder
      .addCase(updateFieldPermission.pending, (state) => {
        state.updateLoader = true;
      })
      .addCase(updateFieldPermission.fulfilled, (state, action) => {
        state.updateLoader = false;
      })
      .addCase(updateFieldPermission.rejected, (state) => {
        state.updateLoader = false;
      });
    builder
      .addCase(adminCreateFieldApi.pending, (state) => {
        state.updateLoader = true;
      })
      .addCase(adminCreateFieldApi.fulfilled, (state, action) => {
        state.updateLoader = false;
      })
      .addCase(adminCreateFieldApi.rejected, (state) => {
        state.updateLoader = false;
      });
    //adminDeleteFieldsApi
    builder
      .addCase(adminDeleteFieldsApi.pending, (state) => {
        state.updateLoader = true;
      })
      .addCase(adminDeleteFieldsApi.fulfilled, (state, action) => {
        state.updateLoader = false;
      })
      .addCase(adminDeleteFieldsApi.rejected, (state) => {
        state.updateLoader = false;
      });
    //adminUpdateFieldRowsIndexApi
    builder
      .addCase(adminUpdateFieldRowsIndexApi.pending, (state) => {
        state.updateLoader = true;
      })
      .addCase(adminUpdateFieldRowsIndexApi.fulfilled, (state, action) => {
        state.updateLoader = false;
      })
      .addCase(adminUpdateFieldRowsIndexApi.rejected, (state) => {
        state.updateLoader = false;
      });
  },
});

export const fieldManagementState = (state) => state?.fieldManagementStore;

export const requiredCollectionTypeFields = (state) =>
  state?.fieldManagementStore?.fieldsByCollectionUser?.fields;

export const fieldsByCollectionLoading = (state) =>
  state?.fieldManagementStore?.fieldsByCollectionLoading;

export default fieldsManagementSlice.reducer;
