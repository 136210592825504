import * as Yup from "yup";
import { validationMessages, validationRegex } from "../validationUtils";

const allowedFormats = [
  "video/mp4",
  "video/ogg",
  "video/webm",
  "video/mov",
  //   "video/avi",
];

export const videoAddModuleSchema = Yup.object().shape({
  asset_id: Yup.string().when("type", {
    is: "published",
    then: () =>
      Yup.string().trim().required(validationMessages.pleaseSelectFile),
  }),

  type: Yup.string().trim().required(validationMessages.pleaseSelectOption),

  file: Yup.string().when("type", {
    is: "new",
    then: () =>
      Yup.mixed()
        .required(validationMessages.pleaseUploadFile)
        .test(
          "fileFormat",
          "Please upload a valid video format (mp4, ogg, webm, mov)",
          (file) => file && allowedFormats.includes(file.type),
        ),
  }),

  name: Yup.string()
    .trim()
    .max(50, validationMessages.tooLong)
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .required(validationMessages.pleaseEnterName),
});
