import { useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import { StyledDivViewIcon } from "components/Grid/TableComponents/tableComponentStyle";
import { ViewColumnSvgIcon } from "components/Grid/TableComponents/tableComponentIcons";
import ViewColumnPopup from "pages/Admin-Pages/fields-management/Components/ViewColumnPopup";
import i18n from "Assets/i18n/config";

const t = i18n.t;

export const hiddenAgGridColumn = (entityName) => ({
  //column defination for hidden ag grid column
  hide: true,
  suppressFiltersToolPanel: true,
  headerName: entityName,
  field: entityName,
  initialHide: true,
});

export const isFirstColumn = (params) => {
  const displayedColumns = params.api.getAllDisplayedColumns();
  return displayedColumns[0] === params.column;
};

export const defaultColDef = {
  sortable: false,
  resizable: false,
  flex: 1,
  lockPinned: true,
  cellDataType: false,
  minWidth: 175,
};

export const commonTooltipHeaderParams = {
  tooltip_data: {
    module_name: "",
    tooltip_setting: null,
  },
  dataState: {},
};

export const agGridDefaultConfig = {
  headerHeight: 33,
  suppressRowClickSelection: true,
  domLayout: "autoHeight",
  suppressDragLeaveHidesColumns: true,
  overlayNoRowsTemplate: `<div class="agGridNoRows">${" "}</div>`,
  suppressMovableColumns: true, //stop user from dragging columns
  stopEditingWhenCellsLoseFocus: true, //Set this to true to stop cell editing when grid loses focus.
  // enableCellTextSelection: true, //this will let users copy from ag grid
};

export const fitCellContentFieldsIdsArr = [
  "email",
  "first_name",
  "last_name",
  "role",
  "userRoles",
  "name",
  "address",
  "asset_type_data_name",
  "asset_type_data",
  "category_data",
];

export const commonRowDataKeys = [
  "applied_filters",
  "currentPage",
  "limit",
  "sortBy",
  "sortOrder",
  "totalDocuments",
  "totalPages",
  "search",
];

export function modifyDataState(apiStateData) {
  //in ag grid we are putting data from api using commonRowDataKeys into the rowData
  //in this funciton we are modifying that data to send in get api
  return {
    filtersQueryString: apiStateData?.applied_filters,
    page: apiStateData?.currentPage,
    ...apiStateData,
  };
}

export const AgViewColumn = (props) => {
  const { colDefs, setColDefs, gridRef } = props;
  const [openViewPopup, setOpenViewPopup] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Tooltip placement="bottom" title={t("viewColumn")}>
        <StyledDivViewIcon>
          <IconButton
            className="viewColumnButtonInner"
            onClick={(e) => setOpenViewPopup(e.currentTarget)}
          >
            <ViewColumnSvgIcon />
          </IconButton>
        </StyledDivViewIcon>
      </Tooltip>
      <ViewColumnPopup
        openViewPopup={openViewPopup}
        setOpenViewPopup={setOpenViewPopup}
        colDefs={colDefs}
        setColDefs={setColDefs}
        gridRef={gridRef}
        {...props}
      />
    </>
  );
};
