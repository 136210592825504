import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { t } from "i18next";
import SingleCheckbox from "components/Checkbox/SingleCheckbox";
import ArrowLeftIcon from "components/Icons/ArrowIcons/ArrowLeftIcon";
import TooltipWrapper from "components/TooltipComponent/TooltipWrapper";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { chunkArray } from "helper/arrayHelpers";
import FePaginationComp from "components/Pagination/FePaginationComp";
import { getAllCustomTagsDataKeywords } from "pages/Admin-Pages/aui-form-fields/CustomTags/features/customTagAction";
import FilterIcon from "components/Icons/FilterIcon/FilterIcon";

const HeaderCollectionRight = ({
  keyData,
  setKeywordState,
  keywordState,
  showAll,
  setShowAll,
  selectedTagArr,
  setSelectedTagArr,
  setRightState,
  initialRightState,
  initialKeywordState,
  commonTagsArr,
  collectionTypeId,
  seeAllLoading,
  setSeeAllLoading,
  handleCrossIconPillClick,
}) => {
  const dispatch = useDispatch();
  const customTagId = keyData?.custom_tag?._id;
  const customTagDataCount = keyData?.custom_tag?.custom_tag_data_count;
  const customTagArr = keyData?.custom_tag_data;
  const chunkedArr = chunkArray(customTagArr, 8);

  const allDataCount = useMemo(() => {
    if (showAll && keyData?.custom_tag_data_get_count) {
      const entries = keyData?.custom_tag_data_get_count;
      return Math.ceil(entries / keywordState.limit);
    } else {
      return 0;
    }
  }, [keyData?.custom_tag_data_get_count, keywordState.limit, showAll]);

  const showAllCond =
    +keywordState?.limit === 8 &&
    +customTagDataCount > 8 &&
    !showAll &&
    customTagArr?.length >= 8;

  const showClearFilterButtonCond =
    !showAll && commonTagsArr?.find((x) => x?.custom_tag_id === customTagId);

  const handleSeeAllClick = () => {
    //when see all is clicked
    setSeeAllLoading(true);
    setShowAll(true);
    setRightState(initialRightState);

    setKeywordState((prev) => {
      return {
        ...prev,
        custom_tag_id: keyData?.custom_tag?._id,
        limit: 32,
        page: 1,
      };
    });
  };

  const handleSeeLessClick = () => {
    //when see less or back button is clicked
    setSeeAllLoading(true);
    setShowAll(false);
    setKeywordState((prev) => ({
      ...initialKeywordState,
      alfabetSearch: prev.alfabetSearch,
    }));
  };

  const handleCheckboxClick = (e, obj) => {
    //when tag checkbox is clicked
    const isChecked = e.target.checked;
    const objIndex = selectedTagArr?.findIndex((x) => x?._id === obj?._id);

    if (isChecked) {
      if (objIndex === -1) {
        setSelectedTagArr((prev) => [...prev, obj]);
      }
    } else if (objIndex > -1) {
      setSelectedTagArr((prev) =>
        prev.filter((_, index) => index !== objIndex),
      );
    }
  };

  const handlePageChange = (_, page) => {
    //when page is changed
    setKeywordState((prev) => {
      return {
        ...prev,
        page,
      };
    });
  };

  const handleHeadingChange = (e) => {
    //when clicked on heading checkbox
    if (e.target.checked) {
      const data = {
        collectionTypeId,
        search: keywordState?.search,
        page: keywordState?.page,
        custom_tag_id: customTagId,
        limit: customTagDataCount,
      };
      dispatch(getAllCustomTagsDataKeywords(data)).then((res) => {
        if (res?.error) return;
        const arr = res?.payload?.collection_type?.[0]?.custom_tag_data;

        const combinedArray = [...arr, ...selectedTagArr];

        const uniqueIds = new Set();
        const result = combinedArray.filter((item) => {
          if (!uniqueIds.has(item._id)) {
            uniqueIds.add(item._id);
            return true;
          }
          return false;
        });

        setSelectedTagArr(result);
      });
    } else {
      setSelectedTagArr((prev) =>
        prev.filter((data) => data.custom_tag_id !== customTagId),
      );
    }
  };

  return (
    !seeAllLoading && (
      <div className={`curatedSections ${showAll ? "showAll" : ""}`}>
        <div className="curatedSectionHeading">
          <label
            className={
              showClearFilterButtonCond ? "showClearFilterButtonCond" : ""
            }
          >
            <SingleCheckbox
              onChange={handleHeadingChange}
              checked={
                commonTagsArr?.find((x) => x?.custom_tag_id === customTagId)
                  ?.commonNo === customTagDataCount
              }
            />
            <TooltipWrapper tooltipText={keyData?.custom_tag?.name}>
              <div className="collectionCustomTag">
                <TextTag
                  className={"customTagName"}
                  variant={textVariants._14px.semiBold}
                >
                  {keyData?.custom_tag?.name}
                </TextTag>
                <TextTag
                  className={"assetNumber"}
                  variant={textVariants._14px.semiBold}
                >
                  {`(${customTagDataCount})`}
                </TextTag>
              </div>
            </TooltipWrapper>
          </label>
          {showClearFilterButtonCond && (
            <div
              className="clearSelectedTags"
              onClick={() =>
                handleCrossIconPillClick({ custom_tag_id: customTagId })
              }
            >
              <FilterIcon className={"clearfilterIcon"} />
            </div>
          )}
          {showAll && (
            <button onClick={handleSeeLessClick} className="backToFilters">
              <ArrowLeftIcon />
              <TextTag variant={textVariants._14px.medium}>
                {t("backToAllFilters")}
              </TextTag>
            </button>
          )}
        </div>
        {chunkedArr?.map((cData, index) => {
          return (
            <div className="curatedSectionDatas" key={index}>
              {cData?.map((linkd, linkIndex) => (
                <div key={linkIndex} className="curatedHeadLink">
                  <label>
                    <SingleCheckbox
                      onChange={(e) => handleCheckboxClick(e, linkd)}
                      checked={
                        selectedTagArr?.some((x) => x?._id === linkd?._id) ||
                        false
                      }
                    />
                    <TooltipWrapper tooltipText={linkd?.name}>
                      <div className="collectionCustomTag">
                        <TextTag
                          className={"customTagName"}
                          variant={textVariants._14px.medium}
                        >
                          {linkd?.name}
                        </TextTag>
                        <TextTag
                          className={"assetNumber"}
                          variant={textVariants._14px.semiBold}
                        >
                          {`(${linkd?.asset_count ? linkd?.asset_count : 0})`}
                        </TextTag>
                      </div>
                    </TooltipWrapper>
                  </label>
                </div>
              ))}
            </div>
          );
        })}
        {showAllCond && (
          <button onClick={handleSeeAllClick} className="seeAllButton">
            {t("seeAll")}
          </button>
        )}
        {showAll && (
          <FePaginationComp
            count={allDataCount}
            onChange={handlePageChange}
            page={keywordState?.page}
          />
        )}
      </div>
    )
  );
};

export default HeaderCollectionRight;
