import { Box } from "@mui/system";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import CustomButton from "components/Button/CustomButton";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import ChangeThumbnailTitlePopup from "./Components/ChangeThumbnailTitlePopup";
import { useSelector } from "react-redux";
import {
  selectQuickThumbnailData,
  selectThumbnailLoading,
} from "./features/quickThumbSlice";
import { useDispatch } from "react-redux";
import { getQuickThumbnailApi } from "./features/quickThumbAction";

const QuickThumbs = () => {
  const { t } = useTranslation();
  const data = useSelector(selectQuickThumbnailData);
  const dispatch=useDispatch();

useEffect(()=>{
dispatch(getQuickThumbnailApi())


},[])
  const [openChangeTitle, setOpenChangeTitle] = useState(false);

  return (
    <FieldManagementStyle>
      <Box className={"titleButton"}>
        <PageTitle title={data?.title || t("noTitleAvailable")} />
        <Box className={"addNewDiv1"}>
          <CustomButton
            onClick={() => setOpenChangeTitle(true)}
            variant="outlined"
          >
            {t("changeSectionDetails")}
          </CustomButton>
        </Box>
      </Box>
      {openChangeTitle && (
        <ChangeThumbnailTitlePopup
          openChangeTitle={openChangeTitle}
          setOpenChangeTitle={setOpenChangeTitle}
          data={data}
        />
      )}
    </FieldManagementStyle>
  );
};

export default QuickThumbs;
