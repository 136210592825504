import { styled } from "@mui/system";
import { t } from "i18next";
import React from "react";

const StyledTextArea = styled("textarea")(({ theme }) => ({
  height: "166px",
  borderColor: "#CCCED1",
  borderRadius: "8px",
  padding: "11px 16px",
  width: "100%",
  maxWidth: "349px",
  outline: "unset",
  fontSize: "12px",
  fontWeight: "500",
  color: theme.palette.blackColor.main,
  fontFamily: "poppins",
  "&::placeholder": {
    fontSize: "12px",
    fontWeight: "500",
    color: theme.palette.gray_400.main,
    fontFamily: "poppins",
  },
}));
const ContributePopTextAreaComp = ({ children, ...props }) => {
  return (
    <StyledTextArea {...props} placeholder={t("enter")}>
      {children}
    </StyledTextArea>
  );
};

export default ContributePopTextAreaComp;
