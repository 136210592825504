import React, { useState } from "react";
import { Edit, Menu } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import AddEditSegmentLinkPopup from "../../../AddEditSegmentLinkPopup.jsx/AddEditSegmentLinkPopup";

const MarketLinkItem = (props) => {
  const { link, provided, handleRemoveChild, id, isDragging, columnData } =
    props;

  const [openEditPopup, setOpenEditPopup] = useState(false);
  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      className={`linkMain ${
        isDragging ? "linkDraggingOn" : "linkDraggingOff"
      }`}
    >
      <Box className="dragIconText">
        <Menu />
        <span>{link?.name}</span>
      </Box>
      <IconButton onClick={() => setOpenEditPopup(true)}>
        <Edit />
      </IconButton>
      {openEditPopup && (
        <AddEditSegmentLinkPopup
          openAddPopup={openEditPopup}
          setOpenAddPopup={setOpenEditPopup}
          id={id}
          link={link}
          openFor={"EDIT"}
          handleRemoveChild={handleRemoveChild}
          columnData={columnData}
        />
      )}
    </div>
  );
};

export default MarketLinkItem;
