import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TextfieldWithIcon from "components/Textfield/TextfieldwithIcon";
import {
  getContributionById,
  getCustomTagDatas,
  updateKeywordsContribute,
} from "../../features/contributionAction";
import { contributerDataState } from "../../features/contributionSlice";
import FilterIcon from "components/Icons/FilterIcon/FilterIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import i18n from "Assets/i18n/config";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import DetailFormLeftHeader from "../DetailForm/components/DetailFormLeftHeader";
import ShowcaseCommonFooter from "../DetailForm/components/ShowcaseCommonFooter";
import PlusMinusPill from "components/Pills/PlusMinusPill";
import CrossIconPill from "components/Pills/CrossIconPill";
import { activeCompObj } from "../../Contribute";
import KeyWordStyle from "./KeyWordStyle";

const t = i18n.t;

export const EditKeyword = ({
  mountKeyWordSection,
  keyWordArr,
  setkeyWordArr,
  unMountActiveComponent,
  activeComponent,
}) => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleCrossIcon = (id) => {
    const data = keyWordArr?.filter((val) => val?._id !== id);
    setkeyWordArr(data);
  };

  const itemsToShow = showAll ? keyWordArr : keyWordArr?.slice(0, 20);

  const shouldShowButton = keyWordArr?.length > 20;

  return (
    <KeyWordStyle
      className={
        activeComponent && activeComponent !== activeCompObj.keywords
          ? "addOpacity"
          : ""
      }
    >
      <div className="keyWordLeftSec">
        <DetailFormLeftHeader
          title={t("keywords")}
          onEditClick={mountKeyWordSection}
          activeComponent={activeComponent === activeCompObj.keywords}
          onCloseClick={unMountActiveComponent}
        />
        <div className="keyWordWordsClass">
          <ul>
            {itemsToShow?.length > 0 ? (
              itemsToShow?.map((val, index) => {
                return (
                  <CrossIconPill
                    name={val?.name}
                    isActive={activeComponent === activeCompObj.keywords}
                    onCrossClick={() => handleCrossIcon(val?._id)}
                  />
                );
              })
            ) : (
              <TextTag
                variant={textVariants._16px.regular}
                className="noKeyWords"
              >
                {t("noKeywordsFound")}
              </TextTag>
            )}
            {shouldShowButton && (
              <TextTag
                variant={textVariants._14px.medium}
                onClick={toggleShowAll}
                className="readAllBtn"
              >
                {showAll ? t("hide") : t("View all")}
              </TextTag>
            )}
          </ul>
        </div>
      </div>
    </KeyWordStyle>
  );
};

export const Keywords = ({
  unMountActiveComponent,
  keyWordArr,
  setkeyWordArr,
  id,
}) => {
  const dispatch = useDispatch();

  const [expandedItems, setExpandedItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    dispatch(getCustomTagDatas());
  }, [dispatch]);

  const { customTagData, customTagLoading } = useSelector(contributerDataState);

  const keyWordIds = keyWordArr?.reduce((accumulator, current) => {
    accumulator.push(current?._id);
    return accumulator;
  }, []);

  const handleExpandAll = () => {
    if (expandedItems.length === customTagData?.custom_tag_all?.length) {
      setExpandedItems([]);
    } else {
      setExpandedItems(customTagData?.custom_tag_all?.map((item) => item._id));
    }
  };

  const handleIndividualExpand = (panel) => {
    setExpandedItems((prevExpandedItems) =>
      prevExpandedItems.includes(panel)
        ? prevExpandedItems.filter((item) => item !== panel)
        : [...prevExpandedItems, panel],
    );
  };

  const toogleKeywords = (keyword) => {
    setkeyWordArr((prev) =>
      prev.some((item) => item._id === keyword._id)
        ? prev.filter((item) => item._id !== keyword._id)
        : [...prev, keyword],
    );
  };

  const filteredData =
    customTagData && Array.isArray(customTagData.custom_tag_all)
      ? customTagData.custom_tag_all.map((item) => {
          const hasMatchingChild =
            Array.isArray(item.custom_tags_data) &&
            item.custom_tags_data.some((child) =>
              child.name.toLowerCase().includes(searchQuery.toLowerCase()),
            );

          return {
            ...item,
            isVisible: !searchQuery || hasMatchingChild,
            autoExpand: hasMatchingChild,
          };
        })
      : [];

  useEffect(() => {
    if (searchQuery && filteredData.length > 0) {
      const matchingIds = filteredData
        .filter((item) => item.autoExpand)
        .map((item) => item._id);

      setExpandedItems(matchingIds);
    } else {
      setExpandedItems([]);
    }
    //eslint-disable-next-line
  }, [searchQuery]);

  const handleSavekeyword = () => {
    const formData = {
      keyword: keyWordArr?.map((data) => data?._id),
      id,
    };
    dispatch(updateKeywordsContribute(formData)).then((res) => {
      if (res?.error) {
        return;
      }
      dispatch(getContributionById(id));
      unMountActiveComponent();
    });
  };

  return (
    <KeyWordStyle>
      <div>
        <div className="titleAndButton">
          <HeadingTag variant={headingVariants.h3}>{t("keywords")}</HeadingTag>
          <MainButton
            title={
              expandedItems?.length === customTagData?.custom_tag_all?.length
                ? t("collapseAll")
                : t("expandAll")
            }
            variant={buttonVariants.grey}
            onClick={handleExpandAll}
          />
        </div>
        <TextfieldWithIcon
          placeholder={t("enterKeywords")}
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <div className="keyWordsAccording">
          {filteredData?.map((item) => (
            <Accordion
              key={item._id}
              expanded={expandedItems?.includes(item._id)}
              onChange={() => handleIndividualExpand(item._id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${item.name}-content`}
                id={`${item.name}-header`}
              >
                <TextTag variant={textVariants._16px.semiBold}>
                  {item.name}{" "}
                  <span className="keywordNumbers">
                    ({item.custom_tags_data?.length})
                  </span>
                </TextTag>
                <FilterIcon />
              </AccordionSummary>
              <AccordionDetails>
                <ul>
                  {item.custom_tags_data?.length > 0 ? (
                    item.custom_tags_data?.map((child) => (
                      <PlusMinusPill
                        key={child?._id}
                        isSelected={keyWordIds?.includes(child?._id)}
                        onClick={() => {
                          toogleKeywords(child);
                        }}
                        name={child.name}
                      />
                    ))
                  ) : (
                    <TextTag
                      variant={textVariants._16px.regular}
                      className="noKeyWordFound"
                    >
                      {t("noKeywordsFound")}
                    </TextTag>
                  )}
                </ul>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
        <ShowcaseCommonFooter
          onCancelClick={unMountActiveComponent}
          onSaveClick={handleSavekeyword}
        />
      </div>
    </KeyWordStyle>
  );
};
