import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import { DialogActions, IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";

import AddNewLinkPopup from "./AddNewLinkPopup/AddNewLinkPopup";
import LinksDragDrop from "./LinksDragDrop/LinksDragDrop";
import DialogCommon from "components/DialogComponent/DialogCommon";
import CustomButton from "components/Button/CustomButton";
import {
  getCuratedGalleries,
  removeCuratedGalleryApi,
  updateGalleryNameApi,
} from "../../features/categoryQuickLinkAction";

const GalleryColumn = (props) => {
  const { listData, title, index, id, categoryId } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openAddPopup, setOpenAddPopup] = useState(false);
  const [editColTitle, setEditColTitle] = useState(false);
  const [colTitle, setColTitle] = useState(title);
  const [alphaNumErrorEdit, setAlphaNumErrorEdit] = useState("");
  const [open, setOpen] = useState(false);

  const handleRemove = () => {
    dispatch(removeCuratedGalleryApi(id)).then((res) => {
      if (res?.error) return;
      setOpen(false);
      dispatch(getCuratedGalleries(categoryId));
    });
  };

  function handleAddPageToColumn() {
    setOpenAddPopup(true);
  }

  function handleColumnTitleChange(e) {
    const value = e.target.value;
    if (!validationRegex?.checkForDoubleSpaces.test(value)) {
      setAlphaNumErrorEdit(validationMessages.noConsecutiveDoubleSpaces);
    } else if (value.length <= 50) {
      if (value.trim() === "") {
        setAlphaNumErrorEdit(validationMessages.pleaseEnterName);
      } else {
        setAlphaNumErrorEdit("");
      }
      setColTitle(value);
    } else if (value.length > 30) {
      setAlphaNumErrorEdit(validationMessages.tooLong);
    }
  }

  function updateColumnTitle() {
    const body = {
      name: colTitle,
    };
    const data = { body, id };
    if (alphaNumErrorEdit === "") {
      dispatch(updateGalleryNameApi(data)).then((res) => {
        dispatch(getCuratedGalleries(categoryId));
        if (res?.error) {
          setColTitle(title);
        }
      });
    } else {
      setColTitle(title);
    }
    setAlphaNumErrorEdit("");
    setEditColTitle(false);
  }

  return (
    <>
      <Draggable draggableId={id} index={index}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={`dragColumnMain ${
              snapshot.isDragging ? "columnDraggingOn" : "columnDraggingOff"
            }`}
          >
            <div className="hedingCardDrag">
              <div isDragging={snapshot.isDragging} className="columnHeader">
                <div
                  isDragging={snapshot.isDragging}
                  {...provided.dragHandleProps}
                  className="columnTitle"
                >
                  {editColTitle ? (
                    <div>
                      <input
                        placeholder={"Enter Column Name"}
                        size="small"
                        variant="outlined"
                        type="text"
                        name="title"
                        value={colTitle}
                        onChange={handleColumnTitleChange}
                        onBlur={updateColumnTitle}
                        autoFocus={true}
                        onKeyDown={(e) => {
                          if (e.code === "NumpadEnter" || e.code === "Enter")
                            updateColumnTitle();
                        }}
                      />
                    </div>
                  ) : (
                    <h2
                      onDoubleClick={() => {
                        setEditColTitle(true);
                      }}
                    >
                      {title}
                    </h2>
                  )}
                </div>
                <div className="addPageToColumn">
                  <IconButton onClick={handleAddPageToColumn}>
                    <Add />
                  </IconButton>
                </div>
                <div className="addPageToColumn">
                  <IconButton onClick={() => setOpen(true)}>
                    <Remove />
                  </IconButton>
                </div>
              </div>
              {editColTitle && (
                <span className="errorClass">{alphaNumErrorEdit}</span>
              )}
            </div>
            <LinksDragDrop
              listId={id}
              listType="List"
              listData={listData}
              internalScroll={props.isScrollable}
              isCombineEnabled={Boolean(props.isCombineEnabled)}
              {...props}
            />
            {openAddPopup && (
              <AddNewLinkPopup
                openAddPopup={openAddPopup}
                setOpenAddPopup={setOpenAddPopup}
                id={id}
                categoryId={categoryId}
              />
            )}
          </div>
        )}
      </Draggable>
      <DialogCommon
        open={open}
        handleClose={() => setOpen(false)}
        contentText={t("deleteColumnConfirm")}
        title={" "}
        fullwidth={true}
      >
        <DialogActions className={"popup-button-outer"}>
          <CustomButton variant="contained" onClick={handleRemove}>
            {t("yes")}
          </CustomButton>
          <CustomButton variant="contained" onClick={() => setOpen(false)}>
            {t("no")}
          </CustomButton>
        </DialogActions>
      </DialogCommon>
    </>
  );
};

export default GalleryColumn;
