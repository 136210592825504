import { styled } from "@mui/system";

const DetailStyle = styled("div")(({ theme }) => ({
  paddingTop: "0px",
  marginBottom: "32px",
  "&.addOpacity": {
    opacity: "0.24",
    pointerEvents: "none",
  },

  // ".asstesDetailsBlock": {
  //   maxWidth: "528px",
  // },
  // ".asstesDetailsBlock": {
  " .nameValue": {
    marginTop: "16px",
    ".showCaseDetailSection": {
      display: "flex",
      gap: "4px",
      alignItems: "baseline",
      flexDirection: "column",
      marginBottom: "16px",
      span: {
        fontFamily: "poppins",
        // textTransform: "capitalize",
        "&.nameClass": {
          width: "100%",
          color: theme.palette.gray_400.main,
          wordBreak: "auto-phrase",
          wordWrap: "break-word",
          lineHeight: 1.2,
          flexShrink: 0,
          fontSize: "12px",
          fontWeight: "400",
        },
        "&.valueClass": {
          color: theme.palette.blackColor.main,
        },
      },
      ".detailWithCopy": {
        display: "flex",
        gap: "16px",
        button: {
          opacity: "0",
          visibility: "hidden",
        },
        "&:hover": {
          button: {
            opacity: "1",
            visibility: "visible",
          },
        },
      },
    },
    "span.readAllBtn": {
      cursor: "pointer",
      color: theme.palette.primaryColor.main,
    },
  },
  ".fieldsEditMain": {
    ".detailEditTopSection": {
      display: "flex",
      alignItems: "center",
      gap: "16px",
      marginBottom: "32px",
      h3: {
        margin: "0",
      },
      button: {
        "&.openFieldsList": {
          backgroundColor: theme.palette.primaryColor.main,
          svg: {
            path: {
              stroke: theme.palette.whiteColor.main,
            },
          },
        },
      },
      ".assetsFieldItems": {
        cursor: "pointer",
        ".dropDownList": {
          maxHeight: "270px",
          overflow: "auto",
          marginTop: "60px",
          maxWidth: "100%",
          "::-webkit-scrollbar-thumb": {
            background: theme.palette.gray_250.main,
          },
          "::-webkit-scrollbar": {
            width: "4px",
          },
          ".fieldListSearch": {
            position: "fixed",
            top: "16px",
            backgroundColor: theme.palette.whiteColor.main,
            marginBottom: "16px",
            padding: "0 16px",
            left: "0",
            width: "100%",
          },
          ".uploadSection-menuItems": {
            color: theme.palette.blackColor.main,
            "&:not(last-child)": {
              marginBottom: "16px",
            },
          },
        },
      },
    },
    ".editFieldsData": {
      marginBottom: "16px",
      position: "relative",
      width: "100%",
      textarea: {
        maxWidth: "100%",
        ":not(:empty)": {
          borderColor: theme.palette.blackColor.main,
        },
      },
      ".contributePopCheckboxes": {
        display: "flex",
        gap: "25px",
        flexWrap: "wrap",
      },

      ".fieldEditTooltip": {
        right: "25px",
        background: "#fff",
        boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.07)",
        zIndex: "1",
      },
    },
    ".showCaseDetailsFooterButtons": {
      position: "fixed",
      bottom: "0",
      width: "100%",
      left: "0",
      display: "flex",
      alignItems: "center",
      padding: "8px 64px",
      justifyContent: "end",
      gap: "8px",
      boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.07)",
      backgroundColor: theme.palette.whiteColor.main,
      zIndex: "99",
      borderTop: `1px solid ${theme.palette.gray_250.main}`,
      button: {
        height: "32px",
        padding: "0 16px",
        "&.saveShowcaseDetails": {
          width: "100%",
          maxWidth: "200px",
          svg: {
            marginRight: "8px",
            path: {
              stroke: theme.palette.whiteColor.main,
            },
          },
        },
      },
    },
  },
  ".valueClass": {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    color: theme.palette.blackColor.main,
  },
  // },
}));

export default DetailStyle;
