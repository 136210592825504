import { routeConfigs } from "utils/routeConfig";
import i18n from "Assets/i18n/config";

const t = i18n.t;

export const usersPageTabs = [
  {
    label: t("allUsers"),
    value: routeConfigs.adminUserAll,
  },
  {
    label: t("pendingUsers"),
    value: routeConfigs.adminUserRequests,
  },
];

export const groupsTabs = [
  {
    label: t("primary"),
    value: routeConfigs.adminRegions,
  },
  {
    label: t("secondary"),
    value: routeConfigs.adminBusinessunits,
  },
  {
    label: t("tertiary"),
    value: routeConfigs.adminDepartments,
  },
];

export const taxonomyTabs = [
  {
    label: t("creator"),
    value: routeConfigs.adminAuiIptcCorefields,
  },
  {
    label: t("customTags"),
    value: routeConfigs.adminAuiCustomTags,
  },
  {
    label: t("lookupList"),
    value: routeConfigs.adminAuiLookuplist,
  },
];

export const categoriesTabs = [
  {
    label: t("category"),
    value: routeConfigs.adminAssetCategory,
  },
  {
    label: t("subCategory"),
    value: routeConfigs.adminAssetSubcategory,
  },
];

export const assetCollection = [
  {
    label: t("types"),
    value: routeConfigs.adminAssetsCollections,
  },
  {
    label: t("fields"),
    value: routeConfigs.adminFieldsManagement,
  },
];
