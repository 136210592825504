import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

//get Stock video
export const getStockVideoApi = createAsyncThunk(
  "videoModule/getStockVideoApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.getStockVideo),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//getAllVideo
export const getVideoModuleData = createAsyncThunk(
  "videoModule/getVideoModuleData",

  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.getAllVideos}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.getAllVideos}?data_limit=all&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }

      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Create a new video
export const createVideoApi = createAsyncThunk(
  "videoModule/createVideoApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.createVideo, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Get video by ID
export const getVideoByIdApi = createAsyncThunk(
  "videoModule/getVideoByIdApi",
  async (videoId) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.getVideoById.replace("{id}", videoId)),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Update video status
export const updateVideoStatusApi = createAsyncThunk(
  "videoModule/updateVideoStatusApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(`${apiRoutes.updateVideoStatus}/${body?.id}`, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Delete a video
export const deleteVideoApi = createAsyncThunk(
  "videoModule/deleteVideoApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.deleteVideo, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Get video content
export const getVideoContentApi = createAsyncThunk(
  "videoModule/getVideoContentApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getVideoContent}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Update video content
export const updateVideoContentApi = createAsyncThunk(
  "videoModule/updateVideoContentApi",
  async ({ id, body }) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(`${apiRoutes.updateVideoContent}/${id}`, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// Get public asset
export const getPublicAssetApi = createAsyncThunk(
  "videoModule/getPublicAssetApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.getPublicAsset),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//getVideoColumnField
//filter API's
export const getVideoColumnField = createAsyncThunk(
  "videoModule/getVideoColumnField",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.getVideoColumnField}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.getVideoColumnField}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
