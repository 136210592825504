import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import _ from "lodash";
import ContributePopSectionHeader from "../../ContributePopSectionHeader/ContributePopSectionHeader";
import { fieldTypeMappedObject } from "pages/Admin-Pages/fields-management/Components/AddNewFieldPopup";
import { StyledContributePopupBodySection } from "../../contributePopupBodyCompStyles";
import { getCustomTagDetailUser } from "pages/Admin-Pages/aui-form-fields/CustomTags/features/customTagAction";
import SearchSelectComp from "components/Select/SearchSelectComp";
import ContributePopTextfieldComp from "components/Textfield/ContributePopTextfieldComp";
import ContributePopTextAreaComp from "components/Textfield/ContributePopTextAreaComp";
import ToggleSwitch from "components/Switch/ToggleSwitch";
import DateComponent from "components/DateComponent/DateComponent";
import { toCamelCase } from "components/ContributePopup/ConttributePopup";
import CheckboxWithLabel from "components/Checkbox/CheckboxWithLabel";
import { SaveMapLocation } from "components/MapLocationComp";
import TextNumberField from "components/Textfield/TextNumberField";

const ContributeCollectionField = ({ fieldData, formik }) => {
  const dispatch = useDispatch();
  const [customTagData, setCustomTagData] = useState(false);
  const camelCaseName = toCamelCase(fieldData?._id);

  useEffect(() => {
    if (fieldData?.field_type?.name === fieldTypeMappedObject?.customTag) {
      dispatch(getCustomTagDetailUser({ id: fieldData?.custom_tag_id })).then(
        (res) => {
          if (res?.error) {
            return;
          }
          const data = res?.payload;
          if (data?.custom_tag_data) {
            setCustomTagData(data?.data);
          } else {
            setCustomTagData(false);
          }
        },
      );
    }
  }, [fieldData, dispatch]);

  const onValueAddChange = (value) => {
    //add or replace the values
    formik?.values && formik?.setFieldValue(camelCaseName, value);
  };

  return fieldData?.field_type?.name === fieldTypeMappedObject?.customTag &&
    (!customTagData || customTagData?.length <= 0) ? null : (
    <StyledContributePopupBodySection>
      <ContributePopSectionHeader
        sectionHeading={fieldData?.name}
        isRequired={true}
        tooltipHeading={fieldData?.name}
        tooltipContent={fieldData?.description}
      />

      {fieldData?.field_type?.name === fieldTypeMappedObject?.customTag && (
        <SearchSelectComp
          selectData={customTagData}
          selectName={"name"}
          title={fieldData?.name}
          parentClass={"popupField"}
          onSelectChange={(i) => {
            const value = i?._id;
            onValueAddChange(value);
          }}
        />
      )}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.textBox &&
        (fieldData?.field_default_value?.[0]?.limit ? (
          <ContributePopTextfieldComp
            maxLength={fieldData?.field_default_value?.[0]?.limit}
            onChange={(e) => {
              const value = e?.target?.value;
              onValueAddChange(value);
            }}
          />
        ) : (
          <ContributePopTextAreaComp
            onChange={(e) => {
              const value = e?.target?.value;
              onValueAddChange(value);
            }}
          />
        ))}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.dropDown &&
        fieldData?.field_default_value?.[0]?.value?.length > 0 && (
          <SearchSelectComp
            selectData={fieldData?.field_default_value?.[0]?.value}
            selectName={"option"}
            parentClass={"popupField"}
            title={fieldData?.name}
            onSelectChange={(i) => {
              const value = i?.value;
              onValueAddChange(value);
            }}
          />
        )}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.checkbox &&
        fieldData?.field_default_value?.[0]?.value?.length > 0 && (
          <div className="contributePopCheckboxes">
            {_.map(fieldData?.field_default_value?.[0]?.value, (r) => {
              return (
                <CheckboxWithLabel
                  key={r?.value}
                  labelName={r?.label}
                  name={camelCaseName}
                  value={r?.value}
                  onChange={(e) => {
                    formik.handleChange(e);
                  }}
                  onBlur={formik.handleBlur}
                />
              );
            })}
          </div>
        )}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.toggle &&
        fieldData?.field_default_value?.[0]?.value?.length > 0 && (
          <ToggleSwitch
            name={camelCaseName}
            checked={formik.values?.[camelCaseName] === "active" ? true : false}
            onChange={(e) => {
              const value = e?.target?.checked ? "active" : "inactive";
              onValueAddChange(value);
            }}
          />
        )}
      {fieldData?.field_type?.name === fieldTypeMappedObject?.date && (
        <DateComponent
          date={null}
          onDateChange={(e) => {
            const date = e?.$d ? dayjs(e?.$d).format("DD/MM/YYYY") : "";
            const value = date;
            onValueAddChange(value);
          }}
        />
      )}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.address && (
        <div className="popupAddressField">
          <SaveMapLocation
            getSelectedLocation={
              formik.values?.[camelCaseName]
                ? JSON.parse(formik.values?.[camelCaseName])
                : {}
            }
            setSelectedLocation={() => {}}
            unMountActiveComponent={() => {}}
            showTextFieldOnly={true}
            onChange={(e) => {
              if (e && !_.isEmpty(e)) {
                onValueAddChange(JSON.stringify(e));
              } else {
                onValueAddChange(null);
              }
            }}
          />
        </div>
      )}

      {fieldData?.field_type?.name === fieldTypeMappedObject?.number && (
        <TextNumberField
          onChange={(e) => {
            onValueAddChange(e.target.value);
          }}
        />
      )}
    </StyledContributePopupBodySection>
  );
};

export default ContributeCollectionField;
