import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import CustomButton from "components/Button/CustomButton";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";
import ContactUsChangeDetailPopUp from "./Components/ContactUsChangeDetailPopUp/ContactUsChangeDetailPopUp";

import { useSelector } from "react-redux";
import { getContactUsTitleSelector } from "./features/contactUsSlice";
import { getContactUsTitleAction } from "./features/contactUsAction";

const ContactUs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const contactUsTitle = useSelector(getContactUsTitleSelector);
  useEffect(() => {
    dispatch(getContactUsTitleAction());
  }, [dispatch]);
  const [open, setOpen] = useState(false);

  return (
    <FieldManagementStyle>
      <Box className="titleButton">
        <PageTitle title={contactUsTitle?.title} />
        <Box className={"addNewDiv1"}>
          <CustomButton
            onClick={() => {
              setOpen(true);
            }}
            variant="outlined"
          >
            {t("changeSectionTitle")}
          </CustomButton>
        </Box>
        <ContactUsChangeDetailPopUp
          open={open}
          handleClose={() => setOpen(false)}
        />
      </Box>
      {/* <img src={contactUsTitle?.file} alt={contactUsTitle?.title} /> */}
    </FieldManagementStyle>
  );
};

export default ContactUs;
