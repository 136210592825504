import { styled } from "@mui/system";

export const HeaderNewStyles = styled("div")(({ theme }) => ({
  position: "sticky",
  top: "0px",
  zIndex: "9",

  "&.homepageHeader": {
    position: "fixed",
    top: "0px",
    height: "80px",
    zIndex: "4",
    width: "100%",
    // transition: "top 0.5s linear",
    background: theme.palette.whiteColor.main,
    "&.headerSticky": {
      top: "-80px",
      ".headerMain": {
        height: "56px",
        "a.headerLogo": {
          lineHeight: "0",
          svg: {
            width: "67px",
          },
        },
        ".headerTypeMenuMain": {
          // padding: "85px 0 0",
        },
      },
    },
    "&.upScrolling": {
      top: '0',
      width: '100%',
      height: '50px',

      ".headerMain": {
        span: {
          color: theme.palette.gray_600.main,
          cursor: "pointer",
        },
        ".headerTypeMenuMain": {
          padding: "60px 0 0",
          top: "-4px",
        },
      },
    },
  },


  ".headerMain": {
    height: "80px",
    display: "flex",
    gap: "2.5rem",
    alignItems: "center",
    padding: "1.25rem 2.5rem",
    backgroundColor: theme.palette.white.main,
    borderBottom: "1px solid rgba(233, 233, 233, 1)",
    "@media screen and (width < 992px)": {
      gap: "0.5rem",
    },
    "> span": {
      color: theme.palette.blackColor.main,
      cursor: "pointer",
    },
    // ".headerTextItem": {
    //   display: "flex",
    //   alignItems: "center",
    //   gap: "0.375rem",
    //   cursor: "pointer",
    //   "&:is(:hover, .active)": {
    //     span: {
    //       color: "rgba(0, 86, 184, 1)",
    //     },
    //     svg: {
    //       path: {
    //         stroke: theme.palette.primaryColor.main,
    //       },
    //     },
    //   },
    // },
    " .headerRight": {
      display: "flex",
      alignItems: "center",
      margin: "0 0 0 auto",
      gap: "24px",
      ".contributeHeader": {
        span: {
          "&:hover": {
            color: theme.palette.primaryColor.main,
          },
        },
      },
      " .headerUserAvatar": {
        paddingLeft: "1.5rem",
        borderLeft: "1px solid rgba(233, 233, 233, 1)",
        cursor: "pointer",
      },
      ".notificationButton": {
        ".headerMenuButton": {
          "svg.headerIcon": {
            "&:hover": {
              fill: theme.palette.primaryColor.main,
            },
          },
        },
      },
    },
    ".headerMiddle": {
      display: "flex",
      gap: "1.5rem",
      ".headerCollections": {
        padding: "0 1.5rem",
        borderRight: "1px solid rgba(233, 233, 233, 1)",
        borderLeft: "1px solid rgba(233, 233, 233, 1)",
      },
      ".headerTypes": {
        display: "flex",
        gap: "1.5rem",
        "@media screen and (width < 992px)": {
          gap: "10px",
        },
      },
    },
  },
}));

export default HeaderNewStyles;
