import React, { useState } from "react";
import { t } from "i18next";
import _ from "lodash";
import { BiPencil } from "react-icons/bi";
import { RxCross2 } from "react-icons/rx";
import ButtonWithFrontIcon from "components/Button/ButtonWithfrontIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import HeartIcon from "components/Icons/HeartIcon/HeartIcon";
import DetailFormLeftHeader from "../DetailForm/components/DetailFormLeftHeader";
import { activeCompObj } from "../../Contribute";
import DescriptionStyle from "./DescriptionStyle";
import SimilarCollection from "components/Icons/SimilarCollection/SimilarCollection";

const Description = ({
  contributerData,
  uploadAssetsHandler,
  unMountActiveComponent,
  activeComponent,
  mountDetailSection,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const descObj = contributerData?.[0]?.fieldsData.filter(
    (a) => a.main_description && a.visible,
  )?.[0];

  const clientIdObj = contributerData?.[0]?.fieldsData.filter(
    (a) => a.client_id && a.visible,
  )?.[0];

  const desc = descObj?.field_value?.value;
  const client_id = clientIdObj?.field_value?.value;

  const handleEditDetails = (name) => {
    mountDetailSection();
  };

  return (
    <DescriptionStyle>
      <div>
        {/* <div className={`headingBlock ${headerFix ? "headingSticky" : ""} ${upScrolling ? "upScrolling" : ""}`}>
          <div className="backButtonCard">
            <ButtonWithFrontIcon
              icon={ArrowLeftIcon}
              className="backButtonClass"
              variant="contained"
              onClick={() => navigate("/")}
            >
              <TextTag variant={textVariants._12px.medium}>{t("back")}</TextTag>
            </ButtonWithFrontIcon>

          </div>
          <div
            className={`titleBlock ${activeComponent && activeComponent !== activeCompObj.detailForm
              ? "addOpacity"
              : ""
              }`}
          >
            {mainTitleObj?.field_value?.value && (
              <DetailFormLeftHeader
                title={mainTitleObj?.field_value?.value}
                activeComponent={activeComponent === activeCompObj.detailForm}
                onCloseClick={unMountActiveComponent}
                mainTitle={true}
              />
            )}
          </div>

        </div> */}
        <div className="detailsInfoBlock">
          <div className="assetsButtonsBlock">
            <ButtonWithFrontIcon icon={SimilarCollection} disabled>
              <TextTag variant={textVariants._14px.semiBold}>
                {t("similarCollection")}
              </TextTag>
            </ButtonWithFrontIcon>

            <ButtonWithFrontIcon icon={HeartIcon} onClick={() => {}} disabled />
            {activeComponent === activeCompObj.detailForm ? (
              <ButtonWithFrontIcon
                icon={RxCross2}
                className="closeEditBtn"
                variant="contained"
                onClick={unMountActiveComponent}
              >
                {/* {t("close")} */}
              </ButtonWithFrontIcon>
            ) : (
              <ButtonWithFrontIcon
                icon={BiPencil}
                className={`closeEditBtn`}
                variant="contained"
                onClick={() => {
                  handleEditDetails();
                }}
              ></ButtonWithFrontIcon>
            )}
          </div>
        </div>
        <DetailFormLeftHeader title={t("details")} />
        <div
          className={`descriptionBlock ${
            activeComponent && activeComponent !== activeCompObj.detailForm
              ? "addOpacity"
              : ""
          }`}
        >
          {clientIdObj && !_.isEmpty(clientIdObj) && (
            <div className="showCaseDetailSection">
              <span className="nameClass">{clientIdObj?.name}</span>
              {client_id && (
                <div className="descSection">
                  <TextTag variant={textVariants._16px.regular}>
                    {client_id}
                  </TextTag>
                </div>
              )}
            </div>
          )}
          {descObj && !_.isEmpty(descObj) && (
            <div className="showCaseDetailSection">
              <span className="nameClass">{descObj?.name}</span>
              {desc && (
                <div className="descSection">
                  <TextTag variant={textVariants._16px.regular}>
                    {isExpanded ? (
                      desc.split("\n").map((line, index) => (
                        <span key={index} className="line">
                          {line}
                        </span>
                      ))
                    ) : (
                      <span className="line">
                        {desc.length > 434
                          ? desc
                              ?.slice(0, 434)
                              ?.split("\n")
                              ?.map((line, index) => (
                                <span key={index} className="line">
                                  {line}
                                </span>
                              ))
                          : desc.split("\n").map((line, index) => (
                              <span key={index} className="line">
                                {line}
                              </span>
                            ))}
                      </span>
                    )}
                  </TextTag>
                  {desc.length > 434 && !isExpanded && (
                    <TextTag
                      variant={textVariants._14px.medium}
                      onClick={toggleExpand}
                      className="readAllBtn"
                    >
                      {t("Read all")}
                    </TextTag>
                  )}
                  {isExpanded && (
                    <TextTag
                      variant={textVariants._14px.medium}
                      onClick={toggleExpand}
                      className="readAllBtn"
                    >
                      {t("hide")}
                    </TextTag>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </DescriptionStyle>
  );
};

export default Description;
