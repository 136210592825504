import MuiDrawer from "@mui/material/Drawer";
import {
  List,
  Divider,
  CssBaseline,
  Box,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { SLIDER_LISTING } from "./components/SidebarConstant";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import { checkPermissionFunction } from "utils/permissions/checkPermission";
import FooterLanguageChange from "Layout/AdminLayout/Footer/components/FooterLanguageChange";
import { SidebarStyles } from "./styles";

const drawerWidth = 350;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  zIndex: "0",
  paddingTop: "0px",
  // marginTop: "120px",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  zIndex: "0",
  paddingTop: "0px",
  // marginTop: "120px",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 24px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer({ children, location, open, setOpen }) {
  const { t } = useTranslation();
  return (
    <SidebarStyles>
      <Box
        sx={{ display: "flex" }}
        className={`${open ? "sidebarOpened " : "sideBarClosed"} `}
      >
        <CssBaseline />
        <Divider />

        <Drawer className="sideBarDashBoard" variant="permanent" open={open}>
          <Tooltip placement="right" title={open ? t("collapse") : t("expand")}>
            <IconButton
              className="hoverOpenClose"
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? (
                <ArrowBackIosNew fontSize="small" />
              ) : (
                <ArrowForwardIos fontSize="small" />
              )}
            </IconButton>
          </Tooltip>

          <List className="singleLink" style={{ padding: "15px 0 0" }}>
            {SLIDER_LISTING()?.map((link, index) => {
              const pathsplit = location.pathname.split("/");
              const linksplit = link?.link?.split("/");
              //below checking if viewPid key is defined
              //if it is defined then checking if that permission is available to the user
              const seeLink =
                link?.viewPid !== undefined &&
                !checkPermissionFunction(link?.viewPid)
                  ? false
                  : true;
              return (
                seeLink &&
                link?.link && (
                  <SidebarLink
                    key={index}
                    location={location}
                    isSidebarOpened={open}
                    isCollapseOpen={pathsplit[2] === linksplit[2]}
                    {...link}
                  />
                )
              );
            })}
          </List>
          <div className="sidebarLanguage">
            <FooterLanguageChange className="adminLanguageChange" />
          </div>
        </Drawer>

        <Box component="main" sx={{ p: 3 }} className="mainContent">
          {children}
        </Box>
      </Box>
    </SidebarStyles>
  );
}
