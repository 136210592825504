import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const getQuickThumbnailApi = createAsyncThunk(
  "thumbnailModule/getQuickThumbnailApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.getQuickThumbnail),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateQuickThumbnailApi = createAsyncThunk(
  "thumbnailModule/updateQuickThumbnailApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.updateQuickThumbnail, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
