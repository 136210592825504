import React from "react";

const ArrowRightIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 24 25"
      fill="none"
      {...props}
    >
      <path
        d="M9 6.5L15 12.5L9 18.5"
        stroke="#212A36"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ArrowRightIcon;
