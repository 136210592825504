import React from "react";
import { styled } from "@mui/system";
import { t } from "i18next";

export const StyledContributePopTextfield = styled("input")(({ theme }) => ({
  height: "40px",
  width: "100%",
  borderRadius: "8px",
  boxShadow: "unset",
  border: `1px solid ${theme.palette.gray_400.main}`,
  padding: "11px 16px",
  fontFamily: "poppins",
  fontSize: "12px",
  fontWeight: "500",
  outline: "unset",
  color: theme.palette.blackColor.main,
  "::placeholder": {
    color: theme.palette.gray_400.main,
  },
}));

const ContributePopTextfieldComp = ({ compRef, type, text, ...props }) => {
  return (
    <StyledContributePopTextfield
      type="text"
      placeholder={t("enter")}
      ref={compRef}
      {...props}
    />
  );
};

export default ContributePopTextfieldComp;
