import { createSlice } from "@reduxjs/toolkit";
import {
  getQuickThumbnailApi,
  updateQuickThumbnailApi,
} from "./quickThumbAction";

const initialState = {
  quickThumbnail: {},
  loading: false,
  error: null,
};

const thumbnailSlice = createSlice({
  name: "thumbnailModule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQuickThumbnailApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getQuickThumbnailApi.fulfilled, (state, action) => {
        state.loading = false;
        state.quickThumbnail = action.payload.data;
        state.error = null;
      })
      .addCase(getQuickThumbnailApi.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload?.error || "Failed to fetch quick thumbnail";
      })
      .addCase(updateQuickThumbnailApi.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateQuickThumbnailApi.fulfilled, (state, action) => {
        state.loading = false;
        state.quickThumbnail = action.payload;
        state.error = null;
      })
      .addCase(updateQuickThumbnailApi.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload?.error || "Failed to update quick thumbnail";
      });
  },
});

// Selectors

export const selectQuickThumbnailData = (state) => state?.thumbnailModule?.quickThumbnail;

export default thumbnailSlice.reducer;
