import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import _ from "lodash";
import {
  Box,
  DialogActions,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import { allActiveAssetCategoryByType } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategorySlice";
import {
  createCuratedGalleryApi,
  getCuratedGalleries,
} from "./features/categoryQuickLinkAction";
import { curatedGalleriesData } from "./features/categoryQuickLinkSlice";
import CuratedGalleryDragDrop from "./CuratedGalleryDragDrop/CuratedGalleryDragDrop";
import CustomButton from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import { getActiveAssetType } from "pages/Admin-Pages/asset-administration/AssetType/features/typeAction";
import { allActiveAssets } from "pages/Admin-Pages/asset-administration/AssetType/features/typeSlice";
import { getActiveAssetCategoryByType } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategoryAction";
import AddPageToFooterStyles from "pages/Admin-Pages/pages-cms/footerMenu/components/AddPageToFooter/AddPageToFooterStyles";
import CategoryQuickLinksStyles from "./CategoryQuickLinksStyles";

export default function CategoryQuickLinks() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const allTypes = useSelector(allActiveAssets)?.data;
  const allCategoryByType = useSelector(allActiveAssetCategoryByType).data;
  const curatedGalleryData = useSelector(curatedGalleriesData);

  const [typeId, setTypeId] = useState(" ");
  const [categoryId, setCategoryId] = useState(" ");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    dispatch(getActiveAssetType());
  }, [dispatch]);

  const handleTypeChange = (e) => {
    const type_id = e.target.value;
    setTypeId(type_id);
    setCategoryId(" ");
    if (type_id?.trim() === "" || !type_id) {
      //when no type is selected
      return;
    }
    dispatch(getActiveAssetCategoryByType(type_id));
  };

  const handlCategoryChange = (e) => {
    const category_id = e.target.value;
    setCategoryId(category_id);
    if (category_id?.trim() === "" || !category_id) {
      //when no category is selected
      return;
    }
    dispatch(getCuratedGalleries(category_id));
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(t("nameRequired"))
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      ),
  });

  const submitHandler = async (values) => {
    const body = {
      name: values?.name,
      category_id: categoryId,
    };

    dispatch(createCuratedGalleryApi(body)).then((res) => {
      if (res.error) {
        return false;
      }
      setOpen(false);
      dispatch(getCuratedGalleries(categoryId));
    });
  };

  return (
    <CategoryQuickLinksStyles>
      <Box className="curatedGalleryMain">
        <Box className="titleButton">
          <PageTitle title={t("categoryQuickLinks")} />
          <Box className={"addNewDiv1"}>
            {categoryId !== " " && curatedGalleryData?.length < 4 && (
              <CustomButton onClick={() => setOpen(true)}>
                {t("addColumn")}
              </CustomButton>
            )}
          </Box>
        </Box>

        <Box className="categorySelectMain">
          <div className="selectValues">
            <label htmlFor="categorySelect">
              {`${t("select")} ${t("fileType")}`}
            </label>
            <FormControl
              variant="outlined"
              className={"formControl selectCategory"}
              size="small"
            >
              <Select
                className="permission-role-select"
                name="typeSelect"
                value={typeId}
                onChange={handleTypeChange}
                id="typeSelect"
              >
                <MenuItem value={" "}>{t("select")}</MenuItem>
                {allTypes?.map((data, index) => (
                  <MenuItem value={data?._id} key={index}>
                    {data?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {typeId && typeId?.trim() !== "" && !_.isEmpty(allCategoryByType) && (
            <div className="selectValues">
              <label htmlFor="categorySelect">{t("selectCategory")}</label>
              <FormControl
                variant="outlined"
                className={"formControl selectCategory"}
                size="small"
              >
                <Select
                  className="permission-role-select"
                  name="categorySelect"
                  value={categoryId}
                  onChange={handlCategoryChange}
                  id="categorySelect"
                >
                  <MenuItem value={" "}>{t("select")}</MenuItem>
                  {allCategoryByType?.map((data, index) => (
                    <MenuItem value={data?._id} key={index}>
                      {data?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
        </Box>

        {(!categoryId || categoryId?.trim() !== "") &&
          curatedGalleryData?.length > 0 && (
            <CuratedGalleryDragDrop
              curatedGalleryData={curatedGalleryData}
              categoryId={categoryId}
            />
            // : (<div className="nodataClass">{t("noDataFound")}</div>)
          )}
      </Box>

      <DialogCommon
        open={open}
        handleClose={() => setOpen(!open)}
        title={t("addColumn")}
        fullwidth={true}
        maxWidth={"md"}
        extraPaperClasses={`singleFileInput`}
      >
        <AddPageToFooterStyles>
          <div className="addPagePopupMain">
            <Formik
              initialValues={{ name: "" }}
              validationSchema={validationSchema}
              onSubmit={submitHandler}
            >
              {({ values, handleBlur, handleChange, errors, touched }) => (
                <Form>
                  <Box className="formSection">
                    <Box className="dropDownSection">
                      <span>{t("enterName", { name: t("name") })}</span>
                      <TextField
                        placeholder={t("enterName", { name: t("name") })}
                        size="small"
                        variant="outlined"
                        name={"name"}
                        id={"name"}
                        value={values?.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="errorClass">
                        {errors.name && touched.name && errors.name}
                      </span>
                    </Box>
                    <DialogActions className="popup-button-outer">
                      <CustomButton variant={"contained"} type="submit">
                        {t("save")}
                      </CustomButton>
                      <CustomButton
                        variant={"contained"}
                        onClick={() => setOpen(!open)}
                      >
                        {t("cancel")}
                      </CustomButton>
                    </DialogActions>
                  </Box>
                </Form>
              )}
            </Formik>
          </div>
        </AddPageToFooterStyles>
      </DialogCommon>
    </CategoryQuickLinksStyles>
  );
}
