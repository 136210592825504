import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";
import performApiRequest from "helper/apiRequestHelper";

export const getAssetSubCategoryApi = createAsyncThunk(
  "assetSubCategory/getAssetSubCategoryApi",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.adminGetSubAssetCategory}?limit=5000`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetSubAssetCategory}?sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllAssetSubCategories = createAsyncThunk(
  "assetSubCategory/getAllAssetSubCategories",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.adminGetSubAssetCategory}?limit=5000`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//feGetsubCategoryBasedOnCategory
export const getsubCategoryBasedOnCategory = createAsyncThunk(
  "assetSubCategory/getsubCategoryBasedOnCategory",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.feGetsubCategoryBasedOnCategory}/${id}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const assetSubCategoryEnableDisableApi = createAsyncThunk(
  "assetSubCategory/assetSubCategoryEnableDisableApi",
  async (body) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminAssetSubCategoryStatusChange}/${body?.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminSubCatShowHomePage
export const subCatShowHomePageApi = createAsyncThunk(
  "assetSubCategory/subCatShowHomePageApi",
  async (body) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminSubCatShowHomePage}/${body?.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const countActiveSubCategories = createAsyncThunk(
  "assetSubCategory/countActiveSubCategories",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.adminCountActiveSubCategory}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const assetsSubCategorydeleteApi = createAsyncThunk(
  "assetSubCategory/assetsSubCategorydeleteApi",
  async (userIds, { dispatch }) => {
    try {
      await performApiRequest(
        () =>
          axiosConfig.put(apiRoutes.adminAssetSubCategoryDelete, userIds?.body),
        userIds?.body?.ids?.length > 1 ? "Users Deleted!" : "User Deleted!",
      );

      return dispatch(getAssetSubCategoryApi(userIds?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const subAssetCategoryCreateApi = createAsyncThunk(
  "assetSubCategory/subAssetCategoryCreateApi",
  async (formData) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminAssetSubCategoryAdd, formData),
      );
      // return dispatch(getAssetSubCategoryApi());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const assetSubCategoryEditApi = createAsyncThunk(
  "assetSubCategory/assetSubCategoryEditApi",
  async (body) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminAssetSubCategoryUpdate}/${body?.id}`,
          body?.data,
        ),
      );
      //  return dispatch(getAssetCategoryApi());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminGetSubCategoryFields
export const getSubCategoryFields = createAsyncThunk(
  "user/getSubCategoryFields",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetSubCategoryFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetSubCategoryFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//export for frontent feGetAllSubCategories

export const feGetAllSubCategoriesApi = createAsyncThunk(
  "assetSubCategory/feGetAllSubCategoriesApi",
  async (signal) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.feGetAllSubCategories}?limit=5000`, {
          signal,
        }),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
