import React from "react";

const DoubleRightArrowIcon = (props) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 11.8346L7.33333 8.5013L4 5.16797M8.66667 11.8346L12 8.5013L8.66667 5.16797"
        stroke="#353D48"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default DoubleRightArrowIcon;
