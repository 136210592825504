import { createSlice } from "@reduxjs/toolkit";
import {
  addMarketSegmentLinkApi,
  deleteMarketSegmentLinkApi,
  getQuickLinkTitleAction,
  marketSegmentsColIndexSortedApi,
  updateMarketSegmentIndexApi,
  updateMarketSegmentLinkDataApi,
  updateMarketSegmentLinkTargetApi,
  updateQuickLinkTitleAction,
} from "./marketSegmentsAction";

const initialState = {
  allSortedMarketData: {},
  marketSegmentLoading: false,
  quickLinkTitle:{},
  getQuickLinkTitleLoading:false,
  updateQuickLinkTitleLoading:false
};

const marketSegmentSlice = createSlice({
  name: "marketSegmentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(marketSegmentsColIndexSortedApi.pending, (state) => {
        state.marketSegmentLoading = true;
      })
      .addCase(marketSegmentsColIndexSortedApi.fulfilled, (state, action) => {
        state.allSortedMarketData = action.payload;
        state.marketSegmentLoading = false;
      })
      .addCase(marketSegmentsColIndexSortedApi.rejected, (state, action) => {
        state.marketSegmentLoading = false;
      });

    //addMarketSegmentLinkApi
    builder
      .addCase(addMarketSegmentLinkApi.pending, (state) => {
        state.marketSegmentLoading = true;
      })
      .addCase(addMarketSegmentLinkApi.fulfilled, (state, action) => {
        state.marketSegmentLoading = false;
      })
      .addCase(addMarketSegmentLinkApi.rejected, (state, action) => {
        state.marketSegmentLoading = false;
      });
    ///updateMarketSegmentLinkDataApi

    builder
      .addCase(updateMarketSegmentLinkDataApi.pending, (state) => {
        state.marketSegmentLoading = true;
      })
      .addCase(updateMarketSegmentLinkDataApi.fulfilled, (state, action) => {
        state.marketSegmentLoading = false;
      })
      .addCase(updateMarketSegmentLinkDataApi.rejected, (state, action) => {
        state.marketSegmentLoading = false;
      });

    //adminUpdateMarketSegmentLinkTarget
    builder
      .addCase(updateMarketSegmentLinkTargetApi.pending, (state) => {
        state.marketSegmentLoading = true;
      })
      .addCase(updateMarketSegmentLinkTargetApi.fulfilled, (state, action) => {
        state.marketSegmentLoading = false;
      })
      .addCase(updateMarketSegmentLinkTargetApi.rejected, (state, action) => {
        state.marketSegmentLoading = false;
      });

    //deleteMarketSegmentLinkApi
    builder
      .addCase(deleteMarketSegmentLinkApi.pending, (state) => {
        state.marketSegmentLoading = true;
      })
      .addCase(deleteMarketSegmentLinkApi.fulfilled, (state, action) => {
        state.marketSegmentLoading = false;
      })
      .addCase(deleteMarketSegmentLinkApi.rejected, (state, action) => {
        state.marketSegmentLoading = false;
      });

    //updateMarketSegmentIndexApi
    builder
      .addCase(updateMarketSegmentIndexApi.pending, (state) => {
        state.marketSegmentLoading = true;
      })
      .addCase(updateMarketSegmentIndexApi.fulfilled, (state, action) => {
        state.marketSegmentLoading = false;
      })
      .addCase(updateMarketSegmentIndexApi.rejected, (state, action) => {
        state.marketSegmentLoading = false;
      });

      builder.addCase(getQuickLinkTitleAction.pending, (state) => {
       state.getQuickLinkTitleLoading=true;
      })
      .addCase(getQuickLinkTitleAction.fulfilled, (state, action) => {
        state.getQuickLinkTitleLoading=false;
        state.quickLinkTitle = action.payload;
      })
      .addCase(getQuickLinkTitleAction.rejected, (state) => {
        state.getQuickLinkTitleLoading=false;
      });

      builder.addCase(updateQuickLinkTitleAction.pending, (state) => {
        state.updateQuickLinkTitleLoading=true;
       })
       .addCase(updateQuickLinkTitleAction.fulfilled, (state) => {
         state.updateQuickLinkTitleLoading=false;
       })
       .addCase(updateQuickLinkTitleAction.rejected, (state) => {
         state.updateQuickLinkTitleLoading=false;
       });
     
  },
});
export const getQuickLinkFromSelector=(state)=>state.marketSegmentSlice.quickLinkTitle;

export const allSortedMarketSegmentData = (state) =>
  state.marketSegmentSlice.allSortedMarketData;

export const marketSegmentLoading = (state) =>
  state.marketSegmentSlice.marketSegmentLoading;

export default marketSegmentSlice.reducer;
