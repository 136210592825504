import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Grid, Box } from "@mui/material";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import CustomButton from "components/Button/CustomButton";
import {
  iptcCreatorsFieldValueArray,
  iptcCreatorsState,
} from "./features/iptcCreatorSlice";
import {
  getAllIptcCreators,
  getIptcCreatorsFields,
  iptcCreatorsDeleteApi,
} from "./features/iptcCreatorActions";
import AddEditIptcCreatorsPopup from "./components/AddEditIptcCreatorsPopup";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Components/CustomComponentfilter/CustomComponentFilter";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import TabsComponent from "pages/Components/Tabs/Tabs";
import { taxonomyTabs } from "pages/Components/Tabs/TabsConstant";
import CommonGrid from "components/Grid/CommonGrid";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
} from "utils/datatable/agTableOptions";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import TableEditIcon from "components/Grid/TableComponents/TableIcons/TableEditIcon";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import i18n from "Assets/i18n/config";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import CommonDeletePopup from "pages/Components/CommonDeletePopup/CommonDeletePopup";
import { FieldManagementStyle } from "pages/Admin-Pages/fields-management/fieldManagementStyle";

export default function IptcCoreFields() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { language } = i18n;
  const iptcCreatorsData = useSelector(iptcCreatorsState);
  const iptcCreatorsAllData = iptcCreatorsData?.iptcCreators;
  const fieldValueData = useSelector(iptcCreatorsFieldValueArray); //filter
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "name",
      headerName: t("creator"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "email",
      headerName: t("email"),
      headerClass: "leftAlign",
      minWidth: 360,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "email",
          dispatchName: "email",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"email"}
                sortBy={"email"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "address",
      headerName: t("address"),
      headerClass: "leftAlign",
      minWidth: 480,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "address",
          dispatchName: "address",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"address"}
                sortBy={"address"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "country",
      headerName: t("country"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "country",
          dispatchName: "country",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"country"}
                sortBy={"country"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "state",
      headerName: t("state"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "state",
          dispatchName: "state",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"state"}
                sortBy={"state"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "city",
      headerName: t("city"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "city",
          dispatchName: "city",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"city"}
                sortBy={"city"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "job_title",
      headerName: t("jobTitle"),
      headerClass: "leftAlign",
      minWidth: language === "en" ? 150 : 205,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "job_title",
          dispatchName: "job_title",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"job_title"}
                sortBy={"job_title"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "post_code",
      headerName: t("postalCode"),
      headerClass: "leftAlign",
      minWidth: language === "es" || language === "it" ? 200 : 150,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "post_code",
          dispatchName: "post_code",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"post_code"}
                sortBy={"post_code"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "phone",
      headerName: t("phone"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "phone",
          dispatchName: "phone",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"phone"}
                sortBy={"phone"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "website",
      headerName: t("website"),
      headerClass: "leftAlign",
      minWidth: 200,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "website",
          dispatchName: "website",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"website"}
                sortBy={"website"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "createdAt",
      headerName: t("date"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "createdAt",
          dispatchName: "createdAt",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"createdAt"}
                sortBy={"createdAt"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: ({ data }) =>
        ToolTipWrapperComponent(dayjs(data?.createdAt).format("DD-MM-YYYY")),
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <TableEditIcon
            onClick={() => {
              setEditTrue(true);
              setOpen(true);
              setCurrentRowData(props?.data);
              gridRef.current.api.deselectAll();
            }}
          />
          <TableDeleteIcon
            onClick={() => {
              setOpenDelete(true);
              setRowsToBeDeleted([props?.data?._id]);
            }}
          />
        </Grid>
      ),
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [rowData, setRowData] = useState();
  const [settingType, setSettingType] = useState();
  const [open, setOpen] = useState(false);
  const [editTrue, setEditTrue] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [currentRowData, setCurrentRowData] = useState();
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter

  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllIptcCreators(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  useEffect(() => {
    setPreviousColumnFilterData(iptcCreatorsAllData?.applied_filters);
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) =>
        (commonDataObj[commonKey] = iptcCreatorsAllData?.[commonKey]),
    );

    setRowData(
      iptcCreatorsAllData?.data?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        name: tableDataObj?.name,
        email: tableDataObj?.email,
        address: tableDataObj?.address,
        country: tableDataObj?.country,
        state: tableDataObj?.state,
        city: tableDataObj?.city,
        job_title: tableDataObj?.job_title,
        post_code: tableDataObj?.post_code,
        phone: tableDataObj?.phone,
        website: tableDataObj?.website,
        createdAt: tableDataObj?.createdAt,
        other_table_data: commonDataObj,
      })),
    );
  }, [iptcCreatorsAllData]);

  function handleDelete() {
    const body = {
      ids: rowsToBeDeleted,
    };

    const databody = { body, dataState };

    const shouldPageChange = deletePageChangeChecker(
      iptcCreatorsAllData?.currentPage,
      iptcCreatorsAllData?.totalDocuments,
      rowsToBeDeleted?.length,
      iptcCreatorsAllData?.limit,
    );

    databody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(iptcCreatorsDeleteApi(databody)).then(() => {
      if (shouldPageChange) setDataState(databody.dataState);
      gridRef.current.api.deselectAll();
    });

    setOpenDelete(false);
  }

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getIptcCreatorsFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("taxonomy")} />
      <TabsComponent tabsDataArray={taxonomyTabs} />
      <Box container spacing={4} className={"userReq-table"}>
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType ">
                <Box className="leftSideInputs">
                  <Grid container spacing={4} className={"addNew"}>
                    <Box className={`add_button`}>
                      <CustomButton
                        onClick={() => {
                          setCurrentRowData(null);
                          setEditTrue(false);
                          setOpen(true);
                        }}
                        variant="outlined"
                      >
                        {t("addCreator")}
                      </CustomButton>
                    </Box>
                  </Grid>
                  <PaginationLimitDropDown
                    dataState={dataState}
                    setDataState={setDataState}
                    setSelectedRows={null}
                  />
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={iptcCreatorsAllData?.totalPages}
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
            />
          </Box>
        </Box>
      </Box>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("creatorMultipleDeleteConfirmationText")}
        singleDeleteText={t("creatorDeleteConfirmationText")}
      />
      <AddEditIptcCreatorsPopup
        open={open}
        setOpen={setOpen}
        editTrue={editTrue}
        currentRowData={currentRowData}
        dataState={dataState}
        setDataState={setDataState}
      />
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
}
