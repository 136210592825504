import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { Box, DialogActions, IconButton, TextField } from "@mui/material";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { CsvPopupStyle } from "pages/Admin-Pages/user-administration/Components/CsvPopup/CsvPopupStyle";
import Cropper from "react-easy-crop";
import { Close } from "@mui/icons-material";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import { useTranslation } from "react-i18next";
import ImageDirections from "components/ImageCropModal/ImageDirections";
import getCroppedImg from "pages/Admin-Pages/asset-administration/AssetCategory/Components/AddEditPopUp/getCroppedImg";
import { contactUsSchema } from "utils/validation/validation-schemas/contactUsSchema";
import {
  getContactUsTitleAction,
  updateContactUsTitleAction,
} from "../../features/contactUsAction";
import { getContactUsTitleSelector } from "../../features/contactUsSlice";

const ContactUsChangeDetailPopUp = ({ open, handleClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { file, title, description, btnText, _id } = useSelector(
    getContactUsTitleSelector,
  );
  const initialValues = {
    files: file ? file : "",
    title: title ? title : "",
    description: description ? description : "",
    btnText: btnText ? btnText : "",
  };

  const [urlImage, setUrlImage] = useState(true);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [fileName, setFileName] = useState(t("noFileChosen"));

  useEffect(() => {
    if (open && file) {
      setImageUrl(file); // Set the initial image URL
      setFileName(file.split("/").pop()); // Extract the file name
      setUrlImage(true); // Ensure the image is marked for display
      // Set initial file value
    } else {
      setImageUrl(""); // Clear image on open if no initial file
      setFileName(""); // Clear file name
      setUrlImage(false);
    }
  }, [open, file]);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const handleImageUpload = (e, setFieldValue) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setFieldValue("files", selectedImage);
      setFileName(selectedImage.name);
      setImageUrl(URL.createObjectURL(selectedImage));
      setUrlImage(true);
    } else {
      setFileName(t("noFileChosen"));
    }
  };

  const handleCloseImage = (setFieldValue) => {
    setFieldValue("files", null);
    setImageUrl("");
    setFileName("");
    setUrlImage(false);
  };
  async function handleSubmit(values) {
    const formData = new FormData();
    formData.append("title", values?.title);
    formData.append("btnText", values?.btnText);
    formData.append("description", values?.description);
    formData.append("contactId", _id);

    //cropping the image once right before submitting
    if (values?.files !== null) {
      try {
        const croppedImage = await getCroppedImg(
          values.files,
          croppedAreaPixels,
          values.files.name,
        );
        const file = new File([croppedImage], values.files.name, {
          type: croppedImage.type,
        });
        formData.append("file", file);
      } catch (error) {
        console.error("Error cropping image", error.message);
      }
    }

    dispatch(updateContactUsTitleAction(formData)).then((res) => {
      if (res?.error) {
        return;
      }
      handleClose();
      dispatch(getContactUsTitleAction());
    });
  }

  return (
    <DialogCommon
      fullwidth={true}
      open={open}
      handleClose={handleClose}
      title={t("changeSectionTitle")}
      maxWidth={"md"}
    >
      <CsvPopupStyle>
        <Box className="Csv_main addEditCategory">
          <Box className="upload_Csv commanCsvBlock">
            <Formik
              initialValues={initialValues}
              enableReinitialize={true}
              validationSchema={contactUsSchema}
              onSubmit={handleSubmit}
            >
              {({ handleBlur, handleChange, values, setFieldValue }) => {
                return (
                  <Form>
                    <Box className="request-common">
                      <Box className="categoryImageInput">
                        <p>{t("contactImage")}</p>
                        <Box className="chooseFile">
                          <Field
                            variant="outlined"
                            id="files"
                            name="files"
                            onChange={(e) =>
                              handleImageUpload(e, setFieldValue)
                            }
                            onBlur={handleBlur}
                            accept="image/jpeg, image/jpg, image/png"
                            title={"Category Image"}
                            type="file"
                            value={""}
                          />
                          <label>{fileName}</label>
                          {urlImage ? (
                            <span>{t("changeFile")}</span>
                          ) : (
                            <span>{t("chooseFile")}</span>
                          )}
                        </Box>
                        <Box className="image_class">
                          {imageUrl ? (
                            <Box className="uploadCropImage">
                              <IconButton
                                onClick={() => handleCloseImage(setFieldValue)}
                              >
                                <Close />
                              </IconButton>
                              {values?.files instanceof File ? (
                                <Cropper
                                  image={imageUrl}
                                  crop={crop}
                                  zoom={zoom}
                                  aspect={12 / 5}
                                  onCropChange={onCropChange}
                                  onCropComplete={onCropComplete}
                                  onZoomChange={onZoomChange}
                                />
                              ) : (
                                <img
                                  src={imageUrl}
                                  alt="contact us"
                                  title={title}
                                />
                              )}
                            </Box>
                          ) : null}
                        </Box>
                        <ErrorMessage
                          component="span"
                          name="files"
                          className="errorClass"
                        />
                        <ImageDirections ratio={"12:5"} />
                      </Box>
                      <Box className="categoryImageName">
                        <p>{t("contactTitle")}</p>
                        <TextField
                          placeholder={t("enterCategory")}
                          size="small"
                          variant="outlined"
                          type="text"
                          name="title"
                          rows={3}
                          multiline={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.title}
                        />
                        <ErrorMessage
                          component="span"
                          name="title"
                          className="errorClass"
                        />
                      </Box>

                      <Box className="categoryImageName catDescription">
                        <p className="label">{t("description")}</p>
                        <TextField
                          placeholder={t("description")}
                          rows={3}
                          size="small"
                          variant="outlined"
                          type="text"
                          multiline={true}
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.description}
                        />
                        <ErrorMessage
                          component="span"
                          name="description"
                          className="errorClass"
                        />
                      </Box>

                      <Box className="categoryImageName">
                        <p>{t("enterButtonName")}</p>
                        <TextField
                          placeholder={t("enterButtonName")}
                          size="small"
                          variant="outlined"
                          type="text"
                          name="btnText"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values?.btnText}
                        />
                        <ErrorMessage
                          component="span"
                          name="btnText"
                          className="errorClass"
                        />
                      </Box>
                    </Box>
                    <DialogActions className="popup-button-outer">
                      <StyledButton
                        variant={"contained"}
                        type="submit"
                        //    disabled={isLoading}
                      >
                        {values?.files !== null ? t("cropAndSave") : t("save")}
                      </StyledButton>
                      <CustomButton
                        variant={"contained"}
                        onClick={handleClose}
                        //   disabled={isLoading}
                      >
                        {t("cancel")}
                      </CustomButton>
                    </DialogActions>
                  </Form>
                );
              }}
            </Formik>
          </Box>
        </Box>
      </CsvPopupStyle>
    </DialogCommon>
  );
};

export default ContactUsChangeDetailPopUp;
