import React from "react";

const MenuIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <path
        d="M13.3359 12.5L2.66927 12.5M13.3359 8.5L2.66927 8.5M13.3359 4.5L2.66927 4.5"
        stroke="#676D75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default MenuIcon;
