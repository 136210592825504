import React, { useEffect, useState } from "react";
import { Field, FormikProvider, useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  DialogActions,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import DialogCommon from "components/DialogComponent/DialogCommon";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import {
  createVideoApi,
  getPublicAssetApi,
  getVideoModuleData,
} from "../../features/videoModuleAction";
import { getVideoModule } from "../../features/videoModuleSlice";
import { CsvPopupStyle } from "pages/Admin-Pages/user-administration/Components/CsvPopup/CsvPopupStyle";
import Sorting from "utils/sorting";
import { Close } from "@mui/icons-material";
import { videoAddModuleSchema } from "utils/validation/validation-schemas/videoAddModuleSchema";

const AddVideoPopUp = ({ setAddOpen, addOpen, dataState }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { updateLoading, publishAssets } = useSelector(getVideoModule);

  const initialValues = {
    asset_id: " ",
    name: "",
    file: null,
    type: " ",
  };

  const [fileName, setFileName] = useState(t("noFileChosen"));
  const [fileUrl, setFileUrl] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [fileInputRef, setFileInputRef] = useState(null);

  const handleFileChange = (e) => {
    setFileInputRef(e.currentTarget);
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name);
      setIsVideo(file.type.startsWith("video"));

      const fileURL = URL.createObjectURL(file);
      setFileUrl(fileURL);
      formik.setFieldValue("file", file);
    }
  };

  const formik = useFormik({
    validationSchema: videoAddModuleSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    dispatch(getPublicAssetApi());
  }, [dispatch]);

  async function handleSubmit(values) {
    const formData = new FormData();
    formData.append("name", values?.name);
    formData.append("type", values?.type);

    if (values?.type === "new" && values?.file) {
      formData.append("file", values?.file);
    } else if (values?.type === "published" && values?.asset_id) {
      formData.append("asset_id", values?.asset_id);
    }
    dispatch(createVideoApi(formData)).then((res) => {
      if (res?.error) {
        return;
      }
      dispatch(getVideoModuleData(dataState));
      handleClose();
    });
  }

  const handleClose = () => {
    setAddOpen(false);
    formik?.resetForm();
    setFileUrl(null);
    setFileName(t("noFileChosen"));
  };

  return (
    <DialogCommon
      fullwidth={true}
      open={addOpen}
      handleClose={handleClose}
      title={t("addNewVideo")}
      maxWidth={"md"}
    >
      <CsvPopupStyle>
        <Box className="Csv_main addEditCategory">
          <Box className="upload_Csv commanCsvBlock">
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Box className="request-common">
                  <Box className="categoryImageName">
                    <p>{t("selectAnOption")}</p>
                    <Box className="addNewDivSelect">
                      <FormControl
                        variant="outlined"
                        className={"formControl"}
                        size="small"
                      >
                        <Select
                          className="permission-role-select"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values?.type}
                          name={"type"}
                          defaultValue={" "}
                        >
                          <MenuItem value={" "}>{t("select")}</MenuItem>
                          <MenuItem value={"new"}>
                            {t("uploadANewVideo")}
                          </MenuItem>
                          <MenuItem value={"published"}>
                            {t("selectPublishedVideo")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <span className="errorClass">
                        {formik.errors.type &&
                          formik.touched.type &&
                          formik.errors.type}
                      </span>
                    </Box>
                  </Box>
                  {formik?.values?.type !== " " && (
                    <>
                      {formik?.values?.type === "new" ? (
                        <Box className="categoryImageInput">
                          <p>{t("chooseVideo")}</p>
                          <Box className="chooseFile">
                            <Field
                              variant="outlined"
                              id="file"
                              name="file"
                              onChange={handleFileChange}
                              title={"Choose Video"}
                              type="file"
                              accept="video/*"
                              value={undefined}
                            />
                            <label>{fileName}</label>
                            {fileUrl && fileUrl ? (
                              <span>{t("changeFile")}</span>
                            ) : (
                              <span>{t("chooseFile")}</span>
                            )}
                          </Box>

                          <Box className="image_class">
                            {fileUrl && isVideo ? (
                              <>
                                <IconButton
                                  onClick={() => {
                                    formik.setFieldValue("file", null);
                                    setFileUrl(null);
                                    setFileName(t("noFileChosen"));
                                    setIsVideo(false);
                                    fileInputRef.value = null;
                                  }}
                                >
                                  <Close />
                                </IconButton>
                                <video controls width="300" src={fileUrl} />
                              </>
                            ) : (
                              fileUrl && (
                                <img src={fileUrl} alt="Preview" width="300" />
                              )
                            )}
                          </Box>

                          <span className="errorClass">
                            {formik.touched.file &&
                              formik.errors.file &&
                              formik.errors.file}
                          </span>
                        </Box>
                      ) : (
                        <Box className="categoryImageName">
                          <p>{t("selectVideo")}</p>
                          <Box className="addNewDivSelect">
                            <FormControl
                              variant="outlined"
                              className={"formControl"}
                              size="small"
                            >
                              <Select
                                className="permission-role-select"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values?.asset_id}
                                name={"asset_id"}
                                defaultValue={" "}
                              >
                                <MenuItem value={" "}>
                                  {t("selectVideo")}
                                </MenuItem>
                                {Array.isArray(publishAssets?.video_asset) &&
                                  Sorting(
                                    publishAssets?.video_asset,
                                    "name",
                                  )?.map((val, index) => {
                                    return (
                                      <MenuItem
                                        key={index + 1}
                                        value={val?._id}
                                      >
                                        {val?.name}
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </FormControl>
                            <span className="errorClass">
                              {formik.errors.asset_id &&
                                formik.touched.asset_id &&
                                formik.errors.asset_id}
                            </span>
                          </Box>
                        </Box>
                      )}

                      <Box className="categoryImageName">
                        <p>{t("videoName")}</p>
                        <TextField
                          placeholder={t("enterVideoName")}
                          size="small"
                          variant="outlined"
                          type="text"
                          name="name"
                          onChange={(e) => {
                            if (e.target.value?.length <= 50) {
                              formik.setFieldValue("name", e.target.value);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        <span className="errorClass">
                          {formik.errors.name &&
                            formik.touched.name &&
                            formik.errors.name}
                        </span>
                      </Box>
                    </>
                  )}
                </Box>
                <DialogActions className="popup-button-outer">
                  <StyledButton
                    variant={"contained"}
                    type="submit"
                    disabled={updateLoading}
                  >
                    {t("save")}
                  </StyledButton>
                  <CustomButton
                    variant={"contained"}
                    onClick={handleClose}
                    disabled={updateLoading}
                  >
                    {t("cancel")}
                  </CustomButton>
                </DialogActions>
              </form>
            </FormikProvider>
          </Box>
        </Box>
      </CsvPopupStyle>
    </DialogCommon>
  );
};

export default AddVideoPopUp;
