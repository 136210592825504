import { styled } from "@mui/system";

const UploadAssetsBlock = styled("div")(({ theme, disabled = true }) => ({
  ".uploadAssetsBlock": {
    // gridTemplateColumns: "repeat(2,1fr)",
    gap: "32px",
    maxHeight: "1478px",
    overflow: "auto",
    marginBottom: "32px",
    "@media only screen and (width >= 1920px)": {
      // maxHeight: "1630px",
      maxHeight: "max-content",
      gap: "32px",
    },
    "@media screen and (width < 992px)": {
      maxHeight: "unset",
    },
    // minHeight: '1270px',
    "::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.whiteColor.main,
    },

    // ".blankUploadSec": {
    //   cursor: "pointer",
    //   "&:first-child": {
    //     gridColumn: "span 2",
    //     ".afterUploadImages": {
    //       ".upladImg": {
    //         img: {
    //           width: "100%",
    //           height: "390px",
    //           objectFit: "cover",
    //           "@media only screen and (width >= 1920px)": {
    //             height: "477px",
    //           },
    //         },
    //       },
    //     },

    //     ".uploadImageBlank": {
    //       minHeight: "347px",
    //       flexDirection: "column",
    //       gap: "16px",
    //       "@media only screen and (width >= 1920px)": {
    //         height: "477px",
    //       },
    //       span: {
    //         display: "block",
    //         color: theme.palette.blackColor.main,
    //       },
    //     },
    //   },
    //   ".uploadImageBlank": {
    //     background: theme.palette.gray_200.main,
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     borderRadius: "8px",
    //     minHeight: "177px",
    //     border: `1px solid ${theme.palette.gray_250.main}`,
    //     "@media only screen and (width >= 1920px)": {
    //       height: "246px",
    //     },
    //   },
    //   ".afterUploadImages": {
    //     position: "relative",
    //     overflow: "hidden",
    //     ".upladImg": {
    //       img: {
    //         width: "100%",
    //         height: "177px",
    //         borderRadius: "8px",
    //         cursor: "pointer",
    //         objectFit: "cover",
    //         objectPosition: "top",
    //         "@media only screen and (width >= 1920px)": {
    //           height: "246px",
    //         },

    //       },
    //     },
    //     ".topBarIcons": {
    //       position: "absolute",
    //       top: "-40px",
    //       display: "flex",
    //       gap: "8px",
    //       left: "0px",
    //       width: "100%",
    //       padding: "0 16px",
    //       transition: "all 0.2s linear",
    //       button: {
    //         backgroundColor: theme.palette.whiteColor.main,
    //         padding: "0",
    //         minWidth: "32px",
    //         border: `1px solid ${theme.palette.gray_250.main}`,
    //         borderRadius: "16px",
    //         svg: {
    //           path: {
    //             stroke: theme.palette.blackColor.main,
    //           },
    //         },
    //         "&:hover": {
    //           backgroundColor: theme.palette.primaryColor.main,
    //           borderColor: theme.palette.primaryColor.main,
    //           svg: {
    //             path: {
    //               stroke: theme.palette.whiteColor.main,
    //             },
    //           },
    //         },
    //         "&.fullScreen": {
    //           margin: "0 0 0 auto",
    //         },
    //       },
    //     },
    //     // ".uploadImageDetailBar": {
    //     //   position: "absolute",
    //     //   bottom: "-50px",
    //     //   width: "100%",
    //     //   padding: "0 16px",
    //     //   transition: "all 0.2s linear",
    //     //   span: {
    //     //     display: "block",
    //     //     color: theme.palette.whiteColor.main,
    //     //     "&.imageId": {
    //     //       opacity: "0.4",
    //     //     },
    //     //   },
    //     // },
    //     "&:hover": {
    //       ".topBarIcons": {
    //         top: "16px",
    //       },
    //       ".uploadImageDetailBar": {
    //         bottom: "24px",
    //       },
    //       ".upladImg": {
    //         cursor: "pointer",
    //         "&:after": {
    //           content: "''",
    //           position: "absolute",
    //           width: "100%",
    //           height: "100%",
    //           top: "0",
    //           left: "0",
    //           background:
    //             "linear-gradient(180deg, rgba(33, 42, 54, 0.00) 0%, #101419 100%)",
    //           WebkitTransition: "all 0.2s linear",
    //           transition: "all 0.2s linear",
    //           borderRadius: "10px",
    //           zIndex: "0",
    //         },
    //       },
    //     },
    //   },

    //   "&:not(first-child)": {
    //     ".uploadImageBlank": {
    //       span: {
    //         display: "none",
    //       },
    //     },
    //   },
    // },
  },

  ".blankUploadSec": {
    cursor: "pointer",
    ul: {
      margin: "0",
      gap: "8px!important",
      overflow: "hidden",
      height: "auto!important",
      // minHeight: "130px",
      // maxHeight: "174px",
      li: {
        height: "100%!important",
        borderRadius: "8px",
        overflow: "hidden",
        img: {
          aspectRatio: "16 / 9",
          objectFit: "fill",
        },
      },
      "&:not(:last-child)": {
        marginBottom: "8px",
      },
      "&.showcaseImages1.notFeaturedImages": {
        maxWidth: "50%",
        // height: "12.083vw",
      },
      "&.showcaseImages2": {
        // height: "12.083vw",
        "@media screen and (width > 1920px)": {
          // height: "6.083vw",
        },
        "@media screen and (width < 992px)": {
          ".afterUploadImages": {
            li: {
              img: {
                aspectRatio: '1/1',
                objectFit: "cover",
              },
            },
          },
        },
      },
      "&.showcaseImages4": {
        height: "10.417vw",
        li: {
          minHeight: "150px",
          maxHeight: "150px",
          // aspectRatio: "16 / 16",
        },
        "span.imageName": { wordBreak: "break-word" },
        ".afterUploadImages": {
          // aspectRatio: "16/16",
          ".uploadImageDetailBar": {
            ".uploadImageName": {
              span: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              },
            },
          },
        },
        "@media screen and (width > 1920px)": {
          height: "8.417vw",
        },
      },
      "&.showcaseImages3": {
        height: "10.556vw",

        ".afterUploadImages": {
          minHeight: '202px',
          maxHeight: '202px',
          // aspectRatio: "18/14",
          ".uploadImageDetailBar": {
            ".uploadImageName": {
              span: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              },
            },
          },
        },
        "@media screen and (width > 1920px)": {
          // height: "6.083vw",
        },
      },
      ".afterUploadImages": {
        "&:after": {
          content: "''",
          position: "absolute",
          width: "100%",
          height: "100%",
          top: "0",
          left: "0",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.4682247899159664) 0%, rgba(0, 0, 0, 0) 49%)",
          zIndex: "1",
          WebkitTransition: "all 0.2s linear",
          transition: "all 0.2s linear",
          borderRadius: "10px",
        },
        "&:hover": {
          "&:after": {
            opacity: "1",
            zIndex: "0",
            background:
              "linear-gradient(0deg, rgb(16 20 25 / 80%) 0%, rgb(0 0 0 / 9%) 100%)",
          },
        },
      },
    },
    "&:first-child": {
      gridColumn: "span 2",
      // height: "24.097vw",
      height: "auto",
      overflow: "hidden",
      borderRadius: "8px",

      "@media only screen and (width >= 1920px)": {
        // maxHeight: "477px",
        maxHeight: "unset",
        height: "auto",
      },
      ".afterUploadImages": {
        ".upladImg": {
          img: {
            width: "100%",
            // height: "347px",
            objectFit: "cover",
            borderRadius: 8,
          },
        },
      },

      ".uploadImageBlank": {
        flexDirection: "column",
        // height: "100%",
        height: "24.097vw",
        gap: "16px",
        // minHeight: "463px",
        maxHeight: "450px",
        span: {
          display: "block",
          color: theme.palette.blackColor.main,
        },
      },
    },

    ".uploadImageBlank": {
      background: theme.palette.gray_200.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      minHeight: "177px",
      height: "12.292vw",
    },
    ".afterUploadImages": {
      position: "relative",
      overflow: "hidden",
      height: "100%",
      ".upladImg": {
        height: "100%",
        img: {
          width: "100%",
          // height: "177px",
          borderRadius: "8px",
          cursor: "pointer",
          objectFit: "cover",
          objectPosition: "top",
          height: "100%",
        },
      },
      ".topBarIcons": {
        position: "absolute",
        top: "-40px",
        display: "flex",
        gap: "8px",
        left: "0px",
        width: "100%",
        padding: "0 16px",
        transition: "all 0.2s linear",
        zIndex: "1",
        "@media screen and (width < 1280px)": {
          padding: "0 8px",
        },
        button: {
          "&.fullScreen": {
            margin: "0 0 0 auto",
          },
        },
      },
      button: {
        backgroundColor: theme.palette.whiteColor.main,
        padding: "0",
        minWidth: "32px",
        border: `1px solid ${theme.palette.gray_250.main}`,
        borderRadius: "16px",
        svg: {
          path: {
            stroke: theme.palette.blackColor.main,
          },
        },
        "&:hover": {
          backgroundColor: theme.palette.primaryColor.main,
          borderColor: theme.palette.primaryColor.main,
          svg: {
            path: {
              stroke: theme.palette.whiteColor.main,
            },
          },
        },
        "&.fullScreen": {
          margin: "0 0 0 auto",
        },
      },
      ".uploadImageDetailBar": {
        position: "absolute",
        bottom: "-100%",
        width: "100%",
        padding: "0 16px",
        transition: "all 0.2s linear",
        display: "flex",
        alignItems: "end",
        flexWrap: "wrap",
        zIndex: "1",
        "@media screen and (width < 1280px)": {
          padding: "0 8px",
          gap: "10px",
        },
        span: {
          // display: "block",
          color: theme.palette.whiteColor.main,
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitLineClamp: "2",
          WebkitBoxOrient: "vertical",
          "&.imageId": {
            opacity: "0.4",
          },
        },
        ".uploadImageName": {
          flex: "1",
          paddingRight: "16px",
          span: {

            wordBreak: 'break-all',
          },
          "@media screen and (width < 1366px)": {
            span: {
              fontSize: "11px",
              lineHeight: "normal",
              wordBreak: 'break-all',
            },
          },
        },
        ".infoIcongBlock": {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          // margin: "0 0 0 auto",
        },
      },
      "&:hover": {
        ".topBarIcons": {
          top: "16px",
        },
        ".uploadImageDetailBar": {
          bottom: "16px",
        },
        ".upladImg": {
          cursor: "pointer",
          "&:after": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            background:
              "linear-gradient(180deg, rgba(33, 42, 54, 0.00) 0%, #101419 100%)",
            WebkitTransition: "all 0.2s linear",
            transition: "all 0.2s linear",
            borderRadius: "10px",
            zIndex: "0",
          },
        },
      },
      "&:has(.threeDotOpen )": {
        ".topBarIcons": {
          top: "16px",
        },
        ".uploadImageDetailBar": {
          bottom: "16px",
        },
        button: {
          backgroundColor: theme.palette.primaryColor.main,
          borderColor: theme.palette.primaryColor.main,
          svg: {
            path: {
              stroke: theme.palette.whiteColor.main,
            },
          },
        },
      },
    },

    "&:not(:first-child)": {
      // height: "12.292vw",
      // maxHeight: "246px",
      overflow: "hidden",
      borderRadius: "8px",
      ".uploadImageBlank": {
        maxHeight: "246px",
        span: {
          display: "none",
        },
      },
    },
    "&:not(:last-child)": {
      marginBottom: "8px",
    },
  },

  ".seeAllButon": {
    gridColumn: "span 2",
    button: {
      width: "100%",
      maxWidth: "100%",

      "&:hover": {
        backgroundColor: theme.palette.primaryColor.main,
        color: theme.palette.whiteColor.main,
        borderColor: theme.palette.primaryColor.main,
        svg: {
          path: {
            stroke: theme.palette.whiteColor.main,
          },
        },
      },
    },
  },
}));

export default UploadAssetsBlock;
