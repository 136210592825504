import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import ButtonWithFrontIcon from "components/Button/ButtonWithfrontIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { isHeaderFixed, isScrollingUp } from "Layout/features/layoutSlice";
import DetailFormLeftHeader from "./DetailForm/components/DetailFormLeftHeader";
import ArrowLeftIcon from "components/Icons/ArrowIcons/ArrowLeftIcon";

const ShowcaseHeading = ({
  activeComponent,
  activeCompObj,
  mainTitleObj,
  unMountActiveComponent,
  sticky,
}) => {
  const navigate = useNavigate();
  const headerFix = useSelector(isHeaderFixed);
  const upScrolling = useSelector(isScrollingUp);
  return (
    <div
      className={`headingBlock ${headerFix && sticky ? "headingSticky" : ""} ${
        sticky && upScrolling ? "upScrolling" : ""
      } ${sticky ? "fixedTitle" : ""}`}
    >
      <div className="backButtonCard">
        <ButtonWithFrontIcon
          icon={ArrowLeftIcon}
          className="backButtonClass"
          variant="contained"
          onClick={() => navigate("/")}
        >
          <TextTag variant={textVariants._12px.medium}>{t("back")}</TextTag>
        </ButtonWithFrontIcon>
      </div>
      <div
        className={`titleBlock ${
          activeComponent && activeComponent !== activeCompObj.detailForm
            ? "addOpacity"
            : ""
        }`}
      >
        {mainTitleObj?.field_value?.value && (
          <DetailFormLeftHeader
            title={mainTitleObj?.field_value?.value}
            activeComponent={activeComponent === activeCompObj.detailForm}
            onCloseClick={unMountActiveComponent}
            mainTitle={true}
          />
        )}
      </div>
    </div>
  );
};

export default ShowcaseHeading;
