import { styled } from "@mui/system";
import { t } from "i18next";
import React from "react";

const ImageDirectionsStyles = styled("div")(({ theme }) => ({
  ".imageDirections": {
    ul: {
      margin: "0 0 20px 0",
      paddingLeft: "16px",
    },
  },
}));

const ImageDirections = ({ size, ratio = "19:12" }) => {
  return (
    <ImageDirectionsStyles>
      <div className="imageDirections">
        <ul>
          {size === !null && (
            <li>
              {t("maximumFileSize")}: <span>1MB</span>
            </li>
          )}
          <li>
            {t("idealAspectRatio")}: <span>{ratio}</span>
          </li>
          <li>
            {t("acceptableFileTypes")}: <span>.jpg, .jpeg, .png</span>
          </li>
        </ul>
      </div>
    </ImageDirectionsStyles>
  );
};

export default ImageDirections;
