import React, { useEffect, useState } from "react";
import {
  Box,
  DialogActions,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Field, FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "react-easy-crop";
import { useTranslation } from "react-i18next";
import { Close } from "@mui/icons-material";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import {
  assetSubCategoryEditApi,
  getAssetSubCategoryApi,
  subAssetCategoryCreateApi,
} from "../features/assetSubCategoryAction";
import { SubCategorySchema } from "utils/validation/validation-schemas/subCategorySchema";
import Sorting from "utils/sorting";
import getCroppedImg from "../../AssetCategory/Components/AddEditPopUp/getCroppedImg";
import { assetCategoryState } from "../../AssetCategory/features/assetCategorySlice";
import { getAssetCategoryApi } from "../../AssetCategory/features/assetCategoryAction";
import ImageDirections from "components/ImageCropModal/ImageDirections";
import { CsvPopupStyle } from "pages/Admin-Pages/user-administration/Components/CsvPopup/CsvPopupStyle";
import { assetSubCategoryLoading } from "../features/assetSubCategorySlice";

const AddEditPopupSubCategory = (props) => {
  const {
    open,
    setOpen,
    id,
    editTrue,
    name,
    categoryId,
    setDataState,
    dataState,
    url,
    urlName,
  } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const assetCategoryData = useSelector(assetCategoryState);
  const isLoading = useSelector(assetSubCategoryLoading);

  const initialValues = {
    category_id: editTrue === "Edit" ? (categoryId ? categoryId : " ") : " ",
    name: editTrue === "Edit" ? name : "",
    files: null,
  };

  const formik = useFormik({
    validationSchema: SubCategorySchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  const [urlImage, setUrlImage] = useState(true);
  const [fileInputRef, setFileInputRef] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  const [fileName, setFileName] = useState(t("noFileChosen"));

  useEffect(() => {
    if (formik.values.files) {
      setImageUrl(URL.createObjectURL(formik.values.files));
    } else {
      setImageUrl("");
    }
  }, [formik.values.files]);

  useEffect(() => {
    dispatch(getAssetCategoryApi());
  }, [dispatch]);

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const handleImageUpload = (e, setFieldValue) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setFieldValue("files", selectedImage);
      setFileName(selectedImage?.name);
    } else {
      setFileName(t("noFileChosen"));
    }
  };

  async function handleSubmit(values) {
    // Proceed with form submission logic (saving data to the table)
    const formData = new FormData();
    formData.append("name", values?.name);
    formData.append("category_id", values.category_id);

    //cropping the image once right before submitting
    if (values?.files !== null) {
      try {
        const croppedImage = await getCroppedImg(
          values.files,
          croppedAreaPixels,
          values.files.name,
        );
        const file = new File([croppedImage], values.files.name, {
          type: croppedImage.type,
        });
        formData.append("file", file);
      } catch (error) {
        console.error("Error cropping image", error.message);
      }
    }

    let modifiedValues;
    if (editTrue === "Edit") {
      // Handle edit submission
      if (values?.files === null) {
        if (urlName !== "") {
          if (urlImage) {
            formData.append("old_image", true);
          }
          formData.delete("file");
        }
      }
      modifiedValues = { data: formData, id, ...values };

      // Dispatch the API call to update the category with cropped image
      dispatch(assetSubCategoryEditApi(modifiedValues))
        .then((res) => {
          if (!res?.error) {
            dispatch(getAssetSubCategoryApi(dataState));
            setOpen();
          }
        })
        .catch((res) => {
          console.error("An error occurred", res);
        });
    } else {
      // Handle create submission
      // Dispatch the API call to create a new category with cropped image
      dispatch(subAssetCategoryCreateApi(formData))
        .then((res) => {
          if (!res?.error) {
            setDataState({
              ...dataState,
              search: "",
              limit: 20,
              page: 1,
              sortOrder: "desc",
              sortBy: "createdAt",
              column_name: "",
              column_values: "",
              filtersQueryString: [],
            });
            setOpen();
          }
        })
        .catch((res) => {
          console.error("An error occurred");
        });
    }
  }

  return (
    <DialogCommon
      fullwidth={true}
      open={open}
      handleClose={() => setOpen()}
      title={editTrue === "Edit" ? t("editSubCategories") : t("addSubCategory")}
      maxWidth={"md"}
      extraPaperClasses="subCategoryPopup"
    >
      <CsvPopupStyle>
        <Box className="Csv_main addEditCategory">
          <Box className="upload_Csv commanCsvBlock">
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Box className="request-common">
                  <Box className="categoryImageInput">
                    <p>{t("subCategoryImage")}</p>
                    <Box className="chooseFile">
                      <Field
                        variant="outlined"
                        id="files"
                        name="files"
                        onChange={(e) => {
                          handleImageUpload(e, formik.setFieldValue);
                          setFileInputRef(e.currentTarget);
                        }}
                        accept="image/jpeg, image/jpg, image/png"
                        title={"Category Image"}
                        type="file"
                        value={undefined}
                      />
                      <label>{fileName}</label>
                      {urlName && urlImage ? (
                        <span>{t("changeFile")}</span>
                      ) : (
                        <span>{t("chooseFile")}</span>
                      )}
                    </Box>
                    <Box className="image_class">
                      {formik.values?.files ? (
                        <Box className="uploadCropImage">
                          <IconButton
                            onClick={() => {
                              formik.setFieldValue("files", null);
                              fileInputRef.value = null;
                              setUrlImage(false);
                              setFileName(t("noFileChosen"));
                            }}
                          >
                            <Close />
                          </IconButton>
                          {formik.values.files && (
                            <Cropper
                              image={imageUrl}
                              crop={crop}
                              zoom={zoom}
                              aspect={19 / 12}
                              onCropChange={(crop) =>
                                onCropChange(crop, formik.setFieldValue)
                              }
                              onCropComplete={onCropComplete}
                              onZoomChange={onZoomChange}
                            />
                          )}
                        </Box>
                      ) : editTrue === "Edit" && url ? (
                        <>
                          {urlImage && (
                            <>
                              <IconButton
                                onClick={() => {
                                  formik.setFieldValue("files", null);
                                  setUrlImage(false);
                                  setFileName(t("noFileChosen"));
                                }}
                              >
                                <Close />
                              </IconButton>
                              <img
                                src={url}
                                alt="asset_sub_category"
                                title={urlName}
                              />
                            </>
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                    <span className="errorClass">
                      {formik.errors.files && formik.errors.files}
                    </span>
                    <ImageDirections />
                  </Box>
                  <Box className="categoryImageName">
                    <p> {t("category")}</p>
                    <FormControl
                      variant="outlined"
                      className={"formControl"}
                      size="small"
                    >
                      <Select
                        className="permission-role-select"
                        name="category_id"
                        value={formik?.values?.category_id || " "}
                        onChange={formik.handleChange}
                        defaultValue={" "}
                      >
                        <MenuItem value={" "}>{t("selectCategory")}</MenuItem>
                        {Sorting(assetCategoryData?.asset_type, "name")?.map(
                          (val, index) => {
                            return editTrue === "Edit"
                              ? (val?._id === formik.values?.category_id ||
                                  val?.enabled) && (
                                  <MenuItem key={index + 1} value={val?._id}>
                                    {val?.name}
                                  </MenuItem>
                                )
                              : val?.enabled && (
                                  <MenuItem key={index + 1} value={val?._id}>
                                    {val?.name}
                                  </MenuItem>
                                );
                          },
                        )}
                      </Select>
                    </FormControl>
                    <span className="errorClass">
                      {formik.errors.category_id &&
                        formik.touched.category_id &&
                        formik.errors.category_id}
                    </span>
                  </Box>
                  <Box className="categoryImageName">
                    <p>{t("subCategory")}</p>
                    <TextField
                      placeholder={t("enterSubCategory")}
                      size="small"
                      variant="outlined"
                      type="text"
                      name="name"
                      onChange={(e) => {
                        if (e.target.value?.length <= 50) {
                          formik.setFieldValue("name", e.target.value);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    <span className="errorClass">
                      {formik.errors.name &&
                        formik.touched.name &&
                        formik.errors.name}
                    </span>
                  </Box>
                </Box>
                <DialogActions className="popup-button-outer">
                  <StyledButton
                    variant={"contained"}
                    type="submit"
                    disabled={isLoading}
                  >
                    {formik.values?.files !== null
                      ? t("cropAndSave")
                      : t("save")}
                  </StyledButton>
                  <CustomButton
                    variant={"contained"}
                    onClick={() => setOpen()}
                    disabled={isLoading}
                  >
                    {t("cancel")}
                  </CustomButton>
                </DialogActions>
              </form>
            </FormikProvider>
          </Box>
        </Box>
      </CsvPopupStyle>
    </DialogCommon>
  );
};

export default AddEditPopupSubCategory;
