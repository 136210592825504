import { createSlice } from "@reduxjs/toolkit";
import {
  addCuratedGalleryLinkApi,
  createCuratedGalleryApi,
  curatedGalleryUpdateIndexesApi,
  curatedGalleryUpdateLinkTargetApi,
  deleteCuratedGalleryApi,
  getCuratedGalleries,
  getCuratedGallery,
  getCuratedGalleryLinks,
  removeCuratedGalleryApi,
  updateCuratedLinkApi,
} from "./categoryQuickLinkAction";

const initialState = {
  curatedGalleryData: [],
  loader: false,
  curatedGalleryHeaderData: [],
  curatedGalleryHeaderLoading: false,
  curatedGalleryLinksData: [],
};

const categoryQuickLinkSlice = createSlice({
  name: "categoryQuickLinkSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //createCategoryApi
    builder
      .addCase(createCuratedGalleryApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(createCuratedGalleryApi.rejected, (state) => {
        state.loader = false;
      })
      .addCase(createCuratedGalleryApi.fulfilled, (state) => {
        state.loader = false;
      });
    //getCuratedGalleries
    builder
      .addCase(getCuratedGalleries.pending, (state) => {
        state.loader = true;
      })
      .addCase(getCuratedGalleries.rejected, (state) => {
        state.loader = false;
      })
      .addCase(getCuratedGalleries.fulfilled, (state, action) => {
        state.loader = false;
        state.curatedGalleryData = action.payload;
      });
    //addCuratedGalleryLinkApi
    builder
      .addCase(addCuratedGalleryLinkApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(addCuratedGalleryLinkApi.rejected, (state) => {
        state.loader = false;
      })
      .addCase(addCuratedGalleryLinkApi.fulfilled, (state) => {
        state.loader = false;
      });
    //curatedGalleryUpdateIndexesApi
    builder
      .addCase(curatedGalleryUpdateIndexesApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(curatedGalleryUpdateIndexesApi.rejected, (state) => {
        state.loader = false;
      })
      .addCase(curatedGalleryUpdateIndexesApi.fulfilled, (state) => {
        state.loader = false;
      });
    //update footer sub menu data
    builder
      .addCase(updateCuratedLinkApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(updateCuratedLinkApi.fulfilled, (state) => {
        state.loader = false;
      })
      .addCase(updateCuratedLinkApi.rejected, (state) => {
        state.loader = false;
      });
    //curatedGalleryUpdateLinkTargetApi
    builder
      .addCase(curatedGalleryUpdateLinkTargetApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(curatedGalleryUpdateLinkTargetApi.fulfilled, (state) => {
        state.loader = false;
      })
      .addCase(curatedGalleryUpdateLinkTargetApi.rejected, (state) => {
        state.loader = false;
      });
    builder
      .addCase(deleteCuratedGalleryApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(deleteCuratedGalleryApi.fulfilled, (state) => {
        state.loader = false;
      })
      .addCase(deleteCuratedGalleryApi.rejected, (state) => {
        state.loader = false;
      });
    //getCuratedGallery
    builder
      .addCase(getCuratedGallery.pending, (state) => {
        state.curatedGalleryHeaderLoading = true;
      })
      .addCase(getCuratedGallery.rejected, (state) => {
        state.curatedGalleryHeaderLoading = false;
      })
      .addCase(getCuratedGallery.fulfilled, (state, action) => {
        state.curatedGalleryHeaderLoading = false;
        state.curatedGalleryHeaderData = action.payload;
      });
    builder
      .addCase(removeCuratedGalleryApi.pending, (state) => {
        state.loader = true;
      })
      .addCase(removeCuratedGalleryApi.rejected, (state) => {
        state.loader = false;
      })
      .addCase(removeCuratedGalleryApi.fulfilled, (state, action) => {
        state.loader = false;
      });
    //getCuratedGalleryLinks
    builder
      .addCase(getCuratedGalleryLinks.pending, (state) => {
        state.loader = true;
      })
      .addCase(getCuratedGalleryLinks.rejected, (state) => {
        state.loader = false;
      })
      .addCase(getCuratedGalleryLinks.fulfilled, (state, action) => {
        state.loader = false;
        state.curatedGalleryLinksData = action.payload;
      });
  },
});

export const curatedGalleriesData = (state) =>
  state?.categoryQuickLinkSlice?.curatedGalleryData?.curatedGallery;

export const curatedGalleryLoading = (state) =>
  state?.categoryQuickLinkSlice?.loader;

export const curatedGalleryHeaderData = (state) =>
  state?.categoryQuickLinkSlice?.curatedGalleryHeaderData;

export const curatedGalleryHeaderLoading = (state) =>
  state?.categoryQuickLinkSlice?.curatedGalleryHeaderLoading;

export const curatedGalleryLinksData = (state) =>
  state?.categoryQuickLinkSlice?.curatedGalleryLinksData;

export default categoryQuickLinkSlice.reducer;
