import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";
import performApiRequest from "helper/apiRequestHelper";

export const getAssetCategoryApi = createAsyncThunk(
  "assetCategory/getAssetCategory",

  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.adminGetAssetCategory}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetAssetCategory}?data_limit=all&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }

      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllAssetCategoryApi = createAsyncThunk(
  "assetCategory/getAllAssetCategoryApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(
          `${apiRoutes.adminGetAssetCategory}?limit=99999&data_limit=all`,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//getActiveAssetCategoryByType
export const getActiveAssetCategoryByType = createAsyncThunk(
  "assetCategory/getActiveAssetCategoryByType",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getActiveAssetCategoryByType}/${id}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const countActiveCategoryApi = createAsyncThunk(
  "assetCategory/countActiveCategoryApi",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.adminCountActiveCategory),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const assetCategoryEnableDisableApi = createAsyncThunk(
  "assetCategory/assetCategoryEnableDisableApi",
  async (data) => {
    try {
      await performApiRequest(
        () =>
          axiosConfig.put(
            `${apiRoutes.adminAssetCategoryStatusChange}/${data?.body?.id}`,
            data?.body,
          ),
        "User updated successfully",
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminCategoryShowHomePage
export const categoryShowHomePageApi = createAsyncThunk(
  "assetCategory/categoryShowHomePageApi",
  async (body) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminCategoryShowHomePage}/${body?.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const assetsCategorydeleteApi = createAsyncThunk(
  "assetCategory/assetsCategorydeleteApi",
  async (userIds, { dispatch }) => {
    try {
      await performApiRequest(
        () =>
          axiosConfig.put(apiRoutes.adminAssetCategoryDelete, userIds?.body),
        userIds?.body?.ids?.length > 1 ? "Users Deleted!" : "User Deleted!",
      );

      return dispatch(getAssetCategoryApi(userIds?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const createCategoryApi = createAsyncThunk(
  "assetCategory/createCategoryApi",
  async (formData, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminAssetCategoryAdd, formData),
      );
      return dispatch(getAssetCategoryApi());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const assetCategoryEditApi = createAsyncThunk(
  "assetCategory/assetCategoryEditApi",
  async (body, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminAssetCategoryUpdate}/${body?.modifiedValues?.id}`,
          body?.modifiedValues?.data,
        ),
      );
      return dispatch(getAssetCategoryApi(body?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminGetAssetCategroyFields
export const getAssetCategoryFields = createAsyncThunk(
  "user/getAssetCategoryFields",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetAssetCategroyFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetAssetCategroyFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
