import React from "react";
import _ from "lodash";
import { fieldTypeMappedObject } from "pages/Admin-Pages/fields-management/Components/AddNewFieldPopup";
import ButtonWithFrontIcon from "components/Button/ButtonWithfrontIcon";
import { copyToClipboard } from "utils/copyToClipboard";
import CopyIcon from "components/Icons/CopyIcon/CopyIcon";

const ShowDetails = ({ collectiondata }) => {
  const value = collectiondata?.value;
  return (
    <div className="showCaseDetailSection">
      <span className="nameClass">{collectiondata?.name}</span>
      <div className="detailWithCopy">
        <span className="valueClass" id={`1${collectiondata?.name}`}>
          {collectiondata?.type === fieldTypeMappedObject?.textBox
            ? value.split("\n").map((line, index) => (
                <span key={index} className="line">
                  {line}
                </span>
              ))
            : value}
        </span>
        {!_.isEmpty(value) && (
          <ButtonWithFrontIcon
            icon={CopyIcon}
            className="backButtonClass copyDetail"
            onClick={() => {
              copyToClipboard(`1${collectiondata?.name}`);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ShowDetails;
