import React, { useRef, useState } from "react";
import { styled } from "@mui/system";
import TextTag, { textVariants } from "components/Typography/TextTag";

const TooltipWrapperStyles = styled("div")(({ theme, isEllipses }) => ({
  position: "relative",
  display: "block",
  width: "95%",
  ".tooltipPopup": {
    display: "none",
  },
  "&:hover": {
    ".tooltipPopup": {
      display: isEllipses ? "flex" : "none",
      position: "absolute",
      backgroundColor: "rgba(33, 42, 54, 0.80)",
      bottom: "27px",
      padding: "4px 8px",
      width: "max-content",
      borderRadius: "4px",
      zIndex: "9",
      span: {
        color: theme.palette.white.main,
      },
    },
  },

  "&.gridTooltip": {
    "&:hover": {
      ".tooltipPopup": {},
    },
  },

  "&:has(.customTagTextTooltip)": {
    width: "80%",
  },
}));

const TooltipWrapper = ({
  tooltipText,
  children,
  delay = 300,
  style,
  className,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const timeoutRef = useRef();
  const [isEllipses, setIsEllipses] = useState(false);
  const handleMouseEnter = (event) => {
    const isOverFlow =
      event.target.offsetHeight < event.target.scrollHeight ||
      event.target.offsetWidth < event.target.scrollWidth;
    setIsEllipses(isOverFlow);
    timeoutRef.current = setTimeout(() => {
      setShowTooltip(true);
    }, delay);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
    setIsEllipses(false);
    clearTimeout(timeoutRef.current);
  };

  return (
    <TooltipWrapperStyles
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      aria-label={tooltipText}
      style={style}
      isEllipses={isEllipses}
      className={className}
    >
      {showTooltip && (
        <div className="tooltipPopup">
          <TextTag variant={textVariants._12px.medium}>{tooltipText}</TextTag>
        </div>
      )}
      {children}
    </TooltipWrapperStyles>
  );
};

export default TooltipWrapper;
