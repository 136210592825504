import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { DialogActions } from "@mui/material";
import { downloadCSV } from "utils/arrayToCsv";
import { getAllCustomTagDetail } from "../../../features/customTagAction";
import CustomButton from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";

const DialogForDownload = ({
  openDelete,
  setOpenDelete,
  rowsToBeDeleted,
  handleDelete,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [data, setData] = useState([]);

  useEffect(() => {
    if (rowsToBeDeleted.length > 0) {
      dispatch(getAllCustomTagDetail(rowsToBeDeleted)).then((res) => {
        setData(res?.payload?.data);
      });
    }
  }, [rowsToBeDeleted, dispatch]);

  const filename = `${data[0]?.custom_tags[0]?.name}-scope-of-work-values`;
  const arr = [];

  data?.map((val) => {
    arr.push({
      "Name ": val?.name,
      "Created Date": dayjs(val?.createdAt).format("DD-MM-YYYY"),
    });
    return false;
  });

  const handleDownload = () => {
    downloadCSV(arr, filename);
    handleDelete();
  };

  return (
    <DialogCommon
      open={openDelete}
      contentText={
        data?.length > 0 ? t("confirmDownloadText") : t("deleteCustomTag")
      }
      handleClose={() => {
        setOpenDelete(!openDelete);
      }}
      title={data?.length > 0 ? t("confirmDownload") : " "}
      fullwidth={true}
    >
      <DialogActions className={"popup-button-outer"}>
        {data?.length > 0 ? (
          <>
            <CustomButton variant="contained" onClick={() => handleDownload()}>
              {t("yesDelete")}
            </CustomButton>
            <CustomButton variant="contained" onClick={() => handleDelete()}>
              {t("deleteWithDownload")}
            </CustomButton>
          </>
        ) : (
          <>
            <CustomButton variant="contained" onClick={() => handleDelete()}>
              {t("yes")}
            </CustomButton>
            <CustomButton
              variant="contained"
              onClick={() => setOpenDelete(!openDelete)}
            >
              {t("no")}
            </CustomButton>
          </>
        )}
      </DialogActions>
    </DialogCommon>
  );
};

export default DialogForDownload;
