import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
import TextTag, { textVariants } from "components/Typography/TextTag";

const AlphabetStyles = styled("div")(({ theme }) => ({
  margin: "32px 0 24px",
  display: "flex",
  gap: "16px",
  ".alphabetLetters": {
    cursor: "pointer",
    "&:is(:hover, .activeLetter)": {
      color: theme.palette.primaryColor.main,
    },
  },
}));

const Alphabet = ({ value, onChange }) => {
  const alphabet = Array.from(Array(26)).map((_, i) =>
    String.fromCharCode(i + 65),
  );

  const [activeLetter, setActiveLetter] = useState(value);

  useEffect(() => {
    setActiveLetter(value);
  }, [value]);

  useEffect(() => {
    if (onChange) onChange(activeLetter);
    //eslint-disable-next-line
  }, [activeLetter]);

  const handleClick = (letter) => {
    setActiveLetter((prev) => (letter === prev && letter !== "" ? "" : letter));
  };

  return (
    <AlphabetStyles>
      {alphabet.map((letter) => (
        <TextTag
          variant={textVariants._12px.medium}
          key={letter}
          className={`alphabetLetters ${activeLetter === letter ? "activeLetter" : ""
            }`}
          onClick={() => handleClick(letter)}
        >
          {letter}
        </TextTag>
      ))}
    </AlphabetStyles>
  );
};

export default Alphabet;
