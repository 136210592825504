import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { t } from "i18next";
import ArrowRightIcon from "components/Icons/ArrowIcons/ArrowRightIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { getActiveAssetCategoryByType } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategoryAction";
import {
  allActiveAssetCategoryByType,
  assetCategoryLoadingState,
} from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategorySlice";
import { getCuratedGallery } from "pages/Admin-Pages/cms-changes/CategoryQuickLinks/features/categoryQuickLinkAction";
import { curatedGalleryHeaderData } from "pages/Admin-Pages/cms-changes/CategoryQuickLinks/features/categoryQuickLinkSlice";
import HeaderCuratedGallery from "./HeaderCuratedGallery";
import { headerMenuObj } from "Layout/AdminLayout/Header/HeaderNew";
import ArrowUpIcon from "components/Icons/ArrowIcons/ArrowUpIcon";
import ArrowDownIcon from "components/Icons/ArrowIcons/ArrowDownIcon";
import HeaderHoverMenuStyles from "../HeaderHoverMenuStyles";
import { Loader } from "components/Loader/Loader";
import { deepCopy } from "helper/deepCopyFunction";
import { useNavigate } from "react-router-dom";
import { routeConfigs } from "utils/routeConfig";

const MoreType = ({
  typeData,
  setHeaderTypeData1,
  setActiveTypeIndex,
  isIndexActive,
  index,
  ...props
}) => {
  const [isMoreTypeActive, setIsMoreTypeActive] = useState(false);

  return (
    <HeaderHoverMenuStyles
      className={`headerItmeAndMenu ${
        isMoreTypeActive || isIndexActive ? "active" : "inactive"
      }`}
      onMouseEnter={() => {
        setIsMoreTypeActive(true);
        setHeaderTypeData1(typeData);
        setActiveTypeIndex(index);
      }}
      onMouseLeave={() => setIsMoreTypeActive(false)}
      {...props}
    >
      <div className="headerTextItem">
        <TextTag variant={textVariants._14px.medium}>{typeData?.name}</TextTag>
        {isMoreTypeActive || isIndexActive ? (
          <ArrowUpIcon />
        ) : (
          <ArrowDownIcon />
        )}
      </div>
    </HeaderHoverMenuStyles>
  );
};

const HeaderTypeMenu = (props) => {
  const {
    headerTypeData,
    menuType,
    allTypes,
    setActiveTypeIndex,
    activeTypeIndex,
    handleMouseLeave,
  } = props;
  const dispatch = useDispatch();
  const timeoutRef = useRef();
  const navigate = useNavigate();

  const allCategoryByType = useSelector(allActiveAssetCategoryByType).data;
  const catLoading = useSelector(assetCategoryLoadingState);
  const curatedGalleryData = useSelector(
    curatedGalleryHeaderData,
  )?.curatedGallery;

  console.log(
    allCategoryByType?.length === 1 && curatedGalleryData?.length === 0,
  );
  const smallHeaderMenu =
    allCategoryByType?.length === 1 && curatedGalleryData?.length === 0;

  const galleryData = deepCopy(curatedGalleryData);

  const sortedGalleryData = useMemo(() => {
    if (!galleryData || galleryData?.length <= 0) {
      return [];
    }
    return galleryData?.sort((a, b) => a?.display_index - b?.display_index);
  }, [galleryData]);

  const [activeCatIndex, setActiveCatIndex] = useState(0);
  const [headerTypeData1, setHeaderTypeData1] = useState(headerTypeData);
  const [oneTypeShowing, setOneTypeShowing] = useState(false);

  useEffect(() => {
    if (headerTypeData1)
      dispatch(getActiveAssetCategoryByType(headerTypeData1?._id));
  }, [dispatch, headerTypeData1]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (allCategoryByType?.length > 0) {
      timeoutRef.current = setTimeout(() => {
        dispatch(getCuratedGallery(allCategoryByType[activeCatIndex]?._id));
      }, 300);
    }

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [activeCatIndex, allCategoryByType, dispatch]);

  return (
    <div
      className={`headerTypeMenuMain ${
        smallHeaderMenu ? "smallHeaderMenu" : ""
      }`}
    >
      {menuType === headerMenuObj.more && (
        <div className="moreTypes">
          {allTypes?.length > 0 &&
            allTypes?.map((typeData, index) => {
              return (
                <MoreType
                  typeData={typeData}
                  key={index}
                  setHeaderTypeData1={setHeaderTypeData1}
                  isIndexActive={index === activeTypeIndex}
                  setActiveTypeIndex={setActiveTypeIndex}
                  index={index}
                />
              );
            })}
        </div>
      )}
      {catLoading ? (
        <div className="headerTypeMenu">
          <Loader />
        </div>
      ) : allCategoryByType?.length > 0 ? (
        <div className="headerTypeMenu">
          <div className="menuLeftSection megaMenusCard">
            {allCategoryByType?.map((catData, index) => {
              return (
                <div
                  className={`leftListHeading ${
                    activeCatIndex === index ? "active" : ""
                  }`}
                  onClick={() => setActiveCatIndex(index)}
                  onMouseEnter={() => setActiveCatIndex(index)}
                  key={index}
                >
                  <TextTag variant={textVariants._14px.medium}>
                    {catData?.name}
                  </TextTag>
                  <ArrowRightIcon />
                </div>
              );
            })}
          </div>
          <div className="menuMiddleSection megaMenusCard">
            <div className="menuMiddleTop">
              <TextTag variant={textVariants._18px.medium}>
                {allCategoryByType[activeCatIndex]?.name}
              </TextTag>
            </div>
            <div className="menuMiddleCenter">
              <TextTag variant={textVariants._14px.regular}>
                {allCategoryByType[activeCatIndex]?.description}
              </TextTag>
            </div>
            <div
              className="menuMiddleBottom"
              onClick={() => navigate(routeConfigs.searchPage)}
            >
              <ArrowRightIcon />
              <TextTag variant={textVariants._14px.medium}>
                {t("seeAllName", {
                  name: allCategoryByType[activeCatIndex]?.name,
                })}
              </TextTag>
            </div>
          </div>
          <div className="headerRightSection megaMenusCard">
            <div className="headerCollecRightMain">
              <div className="headerCollecRightAlphabet">
                <div className="curatedGallerySections">
                  {sortedGalleryData?.length > 0 ? (
                    sortedGalleryData?.map((curatedData, index) => {
                      return (
                        <HeaderCuratedGallery
                          curatedData={curatedData}
                          key={index}
                          oneTypeShowing={oneTypeShowing}
                          setOneTypeShowing={setOneTypeShowing}
                        />
                      );
                    })
                  ) : (
                    <div className="noDataFound">
                      <TextTag variant={textVariants._12px.medium}>
                        {t("")}
                      </TextTag>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="headerTypeMenu">
          <div className="noDataFound">
            <TextTag variant={textVariants._12px.medium}>{t("")}</TextTag>
          </div>
        </div>
      )}
      <div
        className="hoverMenuClose"
        onMouseEnter={handleMouseLeave}
        onMouseMove={handleMouseLeave}
      />
    </div>
  );
};

export default HeaderTypeMenu;
