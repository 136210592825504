import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import TextTag, { textVariants } from "components/Typography/TextTag";

const PlainLinkStyles = styled(Link)(({ theme }) => ({
  color: theme.palette.blackColor.main,
  textDecoration: "none",
  "&:hover": {
    color: theme.palette.primaryColor.main,
    textDecoration: "underline",
  },
}));

const PlainLink = (props) => {
  const { children } = props;
  return (
    <PlainLinkStyles {...props}>
      <TextTag variant={textVariants._14px.medium}>{children}</TextTag>
    </PlainLinkStyles>
  );
};

export default PlainLink;
