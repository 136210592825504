import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { t } from "i18next";
import DateComponent from "components/DateComponent/DateComponent";
import TimeFieldComp from "components/Textfield/TimeFieldComp";
import ContributePopSectionHeader from "../ContributePopSectionHeader/ContributePopSectionHeader";
import { StyledContributePopupBodySection } from "../contributePopupBodyCompStyles";

const ScheduleAssetBody = ({ setScheduleDateTime }) => {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");

  useEffect(() => {
    if (date && date !== "" && time && time !== "") {
      const dateTimeStr = `${date} ${time}`;
      const dateTime = dayjs(dateTimeStr, "MM/DD/YYYY hh:mm A");
      const jsDate = dateTime.toDate();
      setScheduleDateTime(jsDate);
      return;
    }
    setScheduleDateTime("");
  }, [date, time, setScheduleDateTime]);

  return (
    <div>
      <StyledContributePopupBodySection>
        <ContributePopSectionHeader sectionHeading={t("date")} />
        <DateComponent
          date={null}
          disablePast={true}
          onDateChange={(e) => {
            const date = e?.$d ? dayjs(e?.$d).format("DD/MM/YYYY") : "";
            setDate(date);
          }}
        />
      </StyledContributePopupBodySection>
      <StyledContributePopupBodySection>
        <ContributePopSectionHeader sectionHeading={t("time")} />
        <TimeFieldComp onChange={(e) => setTime(e)} />
      </StyledContributePopupBodySection>
    </div>
  );
};

export default ScheduleAssetBody;
