import React, { useEffect } from "react";
import { Box } from "@mui/system";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import StockVideoStyles from "./StockVideoStyles";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getStockVideoApi } from "pages/Admin-Pages/video-module/features/videoModuleAction";
import { getVideoModule } from "pages/Admin-Pages/video-module/features/videoModuleSlice";
import ReactPlayer from "react-player";
import stocksvideo from "./stocksVideos.mp4";

const StockVideo = () => {
  const dispatch = useDispatch();
  const { stockVideos } = useSelector(getVideoModule);

  useEffect(() => {
    dispatch(getStockVideoApi());
  }, [dispatch]);

  let urlFile =
    stockVideos?.videos?.file_preview !== ""
      ? stockVideos?.videos?.file_preview
      : stockVideos?.videos?.file_slug;

  const videoUrl = stockVideos?.videos?.file_slug
    ? `${stockVideos?.video_base_url}/${stockVideos?.video_container_name}/${urlFile}`
    : stocksvideo;

  return (
    <StockVideoStyles>
      <Box className="stocksVideoBlock">
        {!stockVideos ? (
          <Box className="loading">Loading...</Box>
        ) : (
          <Box className="videoBlock">
            <ReactPlayer
              url={videoUrl}
              playing={true}
              loop={true}
              muted={true}
              controls={false}
              width="100%"
              height={"41.667vw"}
              config={{
                file: {
                  attributes: {
                    preload: "auto",
                  },
                },
              }}
              onError={(e) => console.error("Error loading video:", e)}
            />
            <Box className="stocksVideosText">
              <HeadingTag variant={headingVariants.h1}>
                {stockVideos?.videos?.video_content_data?.name ||
                  t("stockVideoSectionHeading")}
              </HeadingTag>
              <TextTag variant={textVariants._16px.regular}>
                {stockVideos?.videos?.video_content_data?.description ||
                  t("stockVideoSectionDesc")}
              </TextTag>
              <MainButton
                title={
                  stockVideos?.videos?.video_content_data?.button_name ||
                  "View All Collection"
                }
                variant={buttonVariants.transparent}
              />
            </Box>
          </Box>
        )}
      </Box>
    </StockVideoStyles>
  );
};

export default StockVideo;
