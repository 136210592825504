import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Grid, Box } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import PageTitle from "../../Components/PageTitle/PageTitle";
import withAuth from "../../../Auth/withAuth";
import { getFiveRequestApi } from "./Features/dashboardAction";
import { fiveRequests } from "./Features/dashboardSlice";
import { routeConfigs } from "utils/routeConfig";
import { permissionIdObject } from "utils/permissions/permissionUtils";
import { checkPermissionFunction } from "utils/permissions/checkPermission";
import CommonGrid from "components/Grid/CommonGrid";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";
import { useTranslation } from "react-i18next";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";

const Dashboard = () => {
  const dispatch = useDispatch();
  const requestData = useSelector(fiveRequests);
  const { t } = useTranslation();

  const gridRef = useRef();
  const [rowData, setRowData] = useState(false);

  const initialColDefs = [
    {
      field: "first_name",
      headerName: t("firstName"),
      headerClass: "leftAlign",
      flex: 2,
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "last_name",
      headerName: t("lastName"),
      headerClass: "leftAlign",
      flex: 2,
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "email",
      headerName: t("email"),
      headerClass: "leftAlign",
      flex: 3,
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "status",
      headerName: t("status"),
      headerClass: "fieldCenter",
      headerComponent: (params) => <span>{params?.displayName}</span>,
      cellRenderer: () => (
        <span className="center">
          <Box className="chip">{t("pending")}</Box>
        </span>
      ),
      cellClass: "actionColumn",
    },
  ];

  useEffect(() => {
    dispatch(getFiveRequestApi());
  }, [dispatch]);

  useEffect(() => {
    setRowData(
      requestData?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        first_name: tableDataObj?.first_name,
        last_name: tableDataObj?.last_name,
        email: tableDataObj?.email,
      })),
    );
  }, [requestData]);

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: initialColDefs,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("dashboard")} />
      {checkPermissionFunction(permissionIdObject?.usersManagement?.pid) && (
        <>
          <Grid item xs={12}>
            <h2 className="subTitle_">{t("pendingRequests")}</h2>
            <Box className="fieldTableMain">
              <CommonGrid commonGridObj={commonGridObj} isLoading={false} />
            </Box>
          </Grid>
          <Link className="requestLink" to={routeConfigs.adminUserRequests}>
            <span>{t("allUserRequests")}</span>
            <div className="arrowIcon">
              <ChevronRight />
            </div>
          </Link>
        </>
      )}
    </FieldManagementStyle>
  );
};
export default withAuth(Dashboard);
