import React from 'react'

const Copy2Icon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path d="M10.0013 2V4.26667C10.0013 4.64003 10.0013 4.82672 10.074 4.96933C10.1379 5.09477 10.2398 5.19675 10.3653 5.26067C10.5079 5.33333 10.6946 5.33333 11.068 5.33333H13.3346M6.66797 5.33333H4.0013C3.26492 5.33333 2.66797 5.93029 2.66797 6.66667V12.6667C2.66797 13.4031 3.26492 14 4.0013 14H8.0013C8.7377 14 9.33464 13.4031 9.33464 12.6667V10.6667M10.668 2H8.8013C8.05457 2 7.68117 2 7.39597 2.14533C7.1451 2.27315 6.9411 2.47713 6.8133 2.72801C6.66797 3.01323 6.66797 3.3866 6.66797 4.13333V8.53333C6.66797 9.28007 6.66797 9.65347 6.8133 9.93867C6.9411 10.1895 7.1451 10.3935 7.39597 10.5213C7.68117 10.6667 8.05457 10.6667 8.8013 10.6667H11.2013C11.948 10.6667 12.3214 10.6667 12.6066 10.5213C12.8575 10.3935 13.0615 10.1895 13.1893 9.93867C13.3346 9.65347 13.3346 9.28007 13.3346 8.53333V4.66667L10.668 2Z" stroke="#212A36" stroke-width="1.5" stroke-linejoin="round"/>
    </svg>
  )
}

export default Copy2Icon