import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import axiosConfig from "../../../../../../utils/axiosConfig";
import { apiRoutes } from "utils/apiRoutes";

export const getLookupTableTabs = createAsyncThunk(
  "lookupTable/getLookupTable",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.adminGetLookupTable}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetLookupTable}?data_limit=all&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
// adminGetLookupDataById

export const getLookupDataById = createAsyncThunk(
  "collection/getLookupDataById",
  async (paramData) => {
    try {
      let apiUrl;
      if (paramData?.collectionId && (!paramData?.limit || !paramData?.page)) {
        apiUrl = `${apiRoutes.adminGetLookupDataById}/${paramData?.collectionId}?sortOrder=asc&sortBy=order_index&limit=999999`;
      } else {
        const {
          sortBy,
          sortOrder,
          limit,
          page,
          filtersQueryString,
          collectionId,
        } = paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetLookupDataById}/${collectionId}?sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getLookupListDataById = createAsyncThunk(
  "lookupTable/getLookupListDataById",
  async (id, { rejectWithValue }) => {
    try {
      const apiUrl = `${apiRoutes.GET_LOOKUP_LIST_DATA}/${id}`;
      const response = await performApiRequest(() => axiosConfig.get(apiUrl));

      if (response.success) {
        return response.data;
      } else {
        return rejectWithValue(
          response.error || "Failed to fetch lookup list data",
        );
      }
    } catch (error) {
      return rejectWithValue(error.response?.data || "An error occurred");
    }
  },
);

export const addLookupList = createAsyncThunk(
  "lookupTable/addLookupList",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes.adminAddLookupEntry, body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteLookupList = createAsyncThunk(
  "lookupTable/deleteLookupList",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminDeleteLookupList, data?.body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateLookupEntry
export const updateLookupEntryApi = createAsyncThunk(
  "lookupTable/updateLookupEntryApi",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminUpdateLookupEntry, data?.body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminUpdateLookupStatus
export const updateLookupStatus = createAsyncThunk(
  "lookupTable/updateLookupStatus",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(`${apiRoutes.adminUpdateLookupStatus}`, data?.body),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getLookupTableStatusCount = createAsyncThunk(
  "lookupTable/getLookupTableStatusCount",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes.adminLookupTableStatusCount),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

// /adminGetLookupField
export const getLookupField = createAsyncThunk(
  "user/getLookupField",
  async (data) => {
    try {
      const { column, filters, id } = data;
      let apiUrl = `${apiRoutes.adminGetLookupField}/${id}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetLookupField}/${id}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
