import { styled } from "@mui/system";

const DescriptionStyle = styled("div")(({ theme }) => ({
  ".addOpacity": {
    opacity: "0.24",
    pointerEvents: "none",
  },
  ".backButtonCard": {
    display: "flex",
    gap: "8px",
    ".backButtonClass": {
      position: "sticky",
      top: "100px",
      left: 0,
    },
  },
  ".detailsInfoBlock": {
    marginTop: "0px",
    marginBottom: "32px",
    h2: {
      // margin: "0 0 16px",
      margin: 0,
      color: theme.palette.blackColor.main,
    },
    ".assetsButtonsBlock": {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      flexWrap: "wrap",
      button: {
        borderColor: theme.palette.gray_300.main,
        svg: {
          path: {
            stroke: theme.palette.blackColor.main,
          },
        },
        "&.closeEditBtn": {
          span: {
            margin: "0",
          },
        },
        "&:disabled": {
          svg: {
            path: {
              stroke: theme.palette.gray_500.main,
            },
          },
        },
      },
    },
  },
  ".descriptionBlock": {
    // maxWidth: "528px",
    marginTop: "16px",
    "@media only screen and (width >= 1920px)": {
      maxWidth: "100%",
    },
    ".descSection": {
      span: {
        display: "block",
        marginBottom: "16px",
        color: theme.palette.blackColor.main,
        "&.readAllBtn": {
          cursor: "pointer",
          color: theme.palette.primaryColor.main,
        },
      },
    },
    span: {
      fontFamily: "poppins",
      // textTransform: "capitalize",
      "&.nameClass": {
        width: "100%",
        color: theme.palette.gray_400.main,
        wordBreak: "auto-phrase",
        wordWrap: "break-word",
        lineHeight: 1.2,
        flexShrink: 0,
        fontSize: "12px",
        fontWeight: "400",
      },
    },
    ".detailWithCopy": {
      display: "flex",
      gap: "16px",
      span: {
        "&.valueClass": {
          color: theme.palette.blackColor.main,
        },
      },
      button: {
        opacity: "0",
        visibility: "hidden",
      },
      "&:hover": {
        button: {
          opacity: "1",
          visibility: "visible",
        },
      },
    },
  },
}));

export default DescriptionStyle;
