import React, { useEffect } from "react";
import { FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Box, DialogActions, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton, { StyledButton } from "components/Button/CustomButton";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { object, string } from "yup";
import { CsvPopupStyle } from "pages/Admin-Pages/user-administration/Components/CsvPopup/CsvPopupStyle";

import {
  getQuickThumbnailApi,
  updateQuickThumbnailApi,
} from "../features/quickThumbAction";
import { selectQuickThumbnailData } from "../features/quickThumbSlice";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import { t } from "i18next";

const validationSchema = object({
  title: string()
    .trim()
    .required(t("pleaseEnterThumbTitle"))
    .matches(
      validationRegex?.checkForDoubleSpaces,
      validationMessages?.noConsecutiveDoubleSpaces,
    )
    .max(50, validationMessages.tooLong),
});
const ChangeThumbnailTitlePopup = (props) => {
  const dispatch = useDispatch();
  const quickThumbnail = useSelector(selectQuickThumbnailData);
  const { openChangeTitle, setOpenChangeTitle, data } = props;
  const { t } = useTranslation();

  console.log("quickThumbnail>>>>>>>>>>>>>>", quickThumbnail);

  const initialValues = {
    title: quickThumbnail?.title || "",
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    dispatch(getQuickThumbnailApi());
  }, [dispatch]);

  async function handleSubmit({ title }) {
    dispatch(
      updateQuickThumbnailApi({ quickThumbnailId: data._id, title }),
    ).then(() => {
      setOpenChangeTitle(false);
      dispatch(getQuickThumbnailApi());
    });
  }
  console.log(formik.errors);
  return (
    <DialogCommon
      fullwidth={true}
      open={openChangeTitle}
      handleClose={() => setOpenChangeTitle(false)}
      title={t("changeSectionDetails")}
      maxWidth={"md"}
    >
      <CsvPopupStyle>
        <Box className="Csv_main addEditCategory">
          <Box className="upload_Csv commanCsvBlock">
            <FormikProvider value={formik}>
              <form onSubmit={formik.handleSubmit}>
                <Box className="request-common">
                  <Box className="categoryImageName">
                    <p>{t("sectionTitle")}</p>
                    <TextField
                      placeholder={t("enterSectionTitle")}
                      size="small"
                      variant="outlined"
                      type="text"
                      name="title"
                      onChange={(e) => {
                        // if (e.target.value?.length <= 50) {
                        formik.setFieldValue("title", e.target.value);
                        // }
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.title}
                    />
                    <span className="errorClass">
                      {formik.errors.title &&
                        formik.touched.title &&
                        formik.errors.title}
                    </span>
                  </Box>
                </Box>
                <DialogActions className="popup-button-outer">
                  <StyledButton
                    variant={"contained"}
                    type="submit"
                    //   disabled={updateLoading}
                  >
                    {t("save")}
                  </StyledButton>
                  <CustomButton
                    variant={"contained"}
                    onClick={() => setOpenChangeTitle(false)}
                    //  disabled={updateLoading}
                  >
                    {t("cancel")}
                  </CustomButton>
                </DialogActions>
              </form>
            </FormikProvider>
          </Box>
        </Box>
      </CsvPopupStyle>
    </DialogCommon>
  );
};

export default ChangeThumbnailTitlePopup;
