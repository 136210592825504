import { Grid, TextField, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import {
  regionsFieldValueArray,
  regionsWithFilterData,
  selectAllRegion,
  selectAllRegionAll,
} from "./features/groupSlice";
import {
  addRegion,
  getActiveRegions,
  getAllRegions,
  getRegionFields,
  getRegions,
  updateRegion,
  updateRegionStatus,
} from "./features/groupAction";
import CustomButton from "../../../components/Button/CustomButton";
import ToastHelper from "../../../helper/toastHelper";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Components/CustomComponentfilter/CustomComponentFilter";
import ToggleSwitch from "pages/Components/ToggleSwitch/ToggleSwitch";
import StatusAndReassignPopup from "./components/statusAndReassignPopup";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import TabsComponent from "pages/Components/Tabs/Tabs";
import { groupsTabs } from "pages/Components/Tabs/TabsConstant";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import { AgCellEditor } from "utils/datatable/AgCellEditor";
import CommonGrid from "components/Grid/CommonGrid";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import { useTranslation } from "react-i18next";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";

export default function Groups() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const regionData = useSelector(selectAllRegion);
  const regionData1 = useSelector(regionsWithFilterData);
  const fieldValueData = useSelector(regionsFieldValueArray); //filter
  const allMainTableData = useSelector(selectAllRegionAll); //all regions data for reassign region
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    hiddenAgGridColumn("business_count"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "name",
      headerName: t("primary"),
      headerClass: "leftAlign",
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },

      cellEditor: AgCellEditor,
      cellEditorParams: {
        checkAlphaNumericValidation: true,
        editFunction: editRegion,
      },
      cellClass: "editableCell",
      editable: true,
    },
    {
      field: "enabled",
      headerName: t("status"),
      headerClass: "fieldCenter",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "enabled",
          dispatchName: "enabled",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"enabled"}
                sortBy={"enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn",
      cellRenderer: (props) => {
        return (
          <Grid className="toggleBlockCo">
            <ToggleSwitch
              checked={props?.value}
              value={props?.value}
              defaultChecked={props?.value}
              onChange={(e) => {
                gridRef.current.api.deselectAll();
                setOpenedFor("REASSIGN");
                setCurrentRowData(props?.data);
                handleSwitchChange(
                  props?.data?._id, //id
                  props?.value, //value
                  props?.data?.business_count, // count
                  false, //no reassing happening
                  // true, //open reassign popup
                  false, //open reassign popup
                  props,
                );
              }}
            />
          </Grid>
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <TableDeleteIcon
            onClick={() => {
              setOpenedFor("DELETE");
              dispatch(getActiveRegions()).then((response) => {
                if (
                  response?.payload?.total_regions <= 1 &&
                  props?.data?.enabled
                ) {
                  //if only one record is enabled and the record we are deleting is also enabled
                  //means we are deleting last enabled record then show toast
                  ToastHelper(
                    "error",
                    validationMessages.onePrimaryGroupShouldBeEnabled,
                  );
                } else {
                  if (props?.data?.business_count > 0) {
                    setOpenDelete(true);
                    // setOpen(true);
                  } else {
                    setOpenDelete(true);
                  }
                }
              });
              setCurrentRowData(props.data);
              setRowsToBeDeleted([props?.data?._id]);
              resetErrorStates();
            }}
          />
        </Grid>
      ),
    },
  ];

  const [regionName, setRegionName] = useState("");
  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState("-1");
  const [currentRowData, setCurrentRowData] = useState(null);
  const [showReassign, setShowReassign] = useState(false);
  const [alphaNumErrorAdd, setAlphaNumErrorAdd] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [selectedRows, setSelectedRows] = useState();
  const [openedFor, setOpenedFor] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [rowData, setRowData] = useState(regionData);
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [settingType, setSettingType] = useState("");
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter

  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = regionData1?.[commonKey]),
    );
    setRowData(
      regionData1?.regions?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        name: tableDataObj?.name,
        enabled: tableDataObj?.enabled,
        action: "",
        business_count: tableDataObj?.business_count,
        other_table_data: commonDataObj,
      })),
    );
  }, [regionData1]);

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getRegions(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  useEffect(() => {
    dispatch(getAllRegions());
  }, [dispatch, open, openDelete]);

  useEffect(() => {
    setPreviousColumnFilterData(regionData1?.applied_filters);
  }, [regionData1]);

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  const resetErrorStates = () => {
    setAlphaNumErrorAdd("");
  };

  const settingNewDepartment = (e) => {
    const value = e.target.value;
    const alphaNumericRegex = validationRegex.alphanumeric;
    if (!validationRegex?.checkForDoubleSpaces.test(e.target.value)) {
      setAlphaNumErrorAdd(validationMessages.noConsecutiveDoubleSpaces);
    } else if (value.length <= 50 && alphaNumericRegex.test(value)) {
      resetErrorStates();
      setRegionName(value);
    } else if (!alphaNumericRegex.test(value)) {
      setAlphaNumErrorAdd(validationMessages.alphanumericOnly);
    }
  };

  function editRegion(data) {
    const { dataObj, updatedValue } = data;
    const body = {
      RegionId: data?.RegionId || dataObj?._id,
      newRegion: data?.newRegion || updatedValue,
      enabled: data?.enabled || dataObj?.enabled,
    };

    let modifiedDataState = dataState;

    if (data?.dataObj?.other_table_data) {
      const other_data = data?.dataObj?.other_table_data;
      modifiedDataState = modifyDataState(other_data);
    }

    const dataBody = { body, dataState: modifiedDataState };
    dispatch(updateRegion(dataBody)).then((res) => {
      dispatch(getRegions(modifiedDataState));
      if (res?.error) {
        return;
      }
    });
  }

  function addNewRegion() {
    const trimmedAsset = !!regionName && regionName.trim();
    if (!!trimmedAsset && regionName.trim() !== "") {
      const body = { name: trimmedAsset };
      dispatch(addRegion(body)).then((res) => {
        if (res?.error) {
          return;
        }
        setDataState({
          ...dataState,
          search: "",
          limit: 20,
          page: 1,
          sortOrder: "desc",
          sortBy: "createdAt",
          column_name: "",
          column_values: "",
          filtersQueryString: [],
        });
        setRegionName("");
        resetErrorStates();
      });
      return;
    }
    ToastHelper("error", validationMessages.pleaseEnterRegion);
  }

  const handleSwitchChange = useCallback(
    async (
      entryId, //id
      switchValue, //value true or false
      count, //number of child entries, enter 0 if you do not want to open reassign popup
      newRegionsId, //new entry for reassign, only enter when required
      shouldPopupOpen = false,
      props, //props from grid, will use datastate if not available
    ) => {
      gridRef.current.api.forEachNode((rowNode) => {
        if (rowNode?.data?._id !== entryId) {
          return false;
        }
        resetErrorStates();
        setSelectedRows([]);
        setRowsToBeDeleted([]);
        if (switchValue && count > 0 && shouldPopupOpen) {
          //when deactivating if there are child elements open reassign popup
          //also only open the popup if there is more than one active
          dispatch(getActiveRegions())
            .then((response) => {
              if (response?.payload?.total_regions > 1) {
                setOpen(true);
              } else {
                ToastHelper(
                  "error",
                  validationMessages.onePrimaryGroupShouldBeEnabled,
                );
              }
            })
            .catch((e) => console.error(e));
          return;
        }

        //updating the ui
        //by toggling switchvalue and setting count
        rowNode.setDataValue("enabled", !switchValue);
        rowNode.setDataValue("business_count", count);

        let modifiedDataState = dataState;

        if (props?.data?.other_table_data) {
          const other_data = props?.data?.other_table_data;
          modifiedDataState = modifyDataState(other_data);
        }

        const dataBody = {
          body: {
            RegionId: entryId,
            enabled: !switchValue,
          },
          dataState: modifiedDataState,
        };

        if (newRegionsId) {
          //this means we are also reassigning new business unit while updating the status
          dataBody.body.newRegionsId = newRegionsId;
        }

        //running the update status api
        dispatch(updateRegionStatus(dataBody)).then((resp) => {
          if (resp?.error) {
            //reverting if error occurs
            dispatch(getRegions(modifiedDataState));
            return "error";
          } else if (newRegionsId) {
            dispatch(getRegions(modifiedDataState));
          }
        });
      });
    },
    //eslint-disable-next-line
    [],
  );

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getRegionFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  const extraBulkDeleteProps = {
    //props required for extra bulk delete functionality
    //if allowBulkDeleteConditions is false then no need to send other props at all
    allowBulkDeleteConditions: true,
    //if true bulk delete will not check for extra conditions
    childCountExtension: "business_count",
    setOpenedFor,
    getActiveEntries: getActiveRegions,
    activeTableEntriesExtension: "total_regions",
    lastRecordValidationMessage:
      validationMessages.onePrimaryGroupShouldBeEnabled,
    getAllEntries: getAllRegions,
    allEntriesExtension: "regions",
    //setOpen,
    setOpen: setOpenDelete,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("organization")} />
      <TabsComponent tabsDataArray={groupsTabs} />
      <Box container spacing={4} className={"userReq-table"}>
        <Grid id container spacing={4}>
          <Grid item xs={12}>
            <Box className="fieldTableMain">
              <Box className="fieldTableHeader">
                <Box className="table-upper  ">
                  <Box className="leftSideInputs">
                    <Grid container spacing={4} className={`addNew`}>
                      <Box className={"addNewDiv"}>
                        <TextField
                          placeholder={t("createPrimary")}
                          size="small"
                          className={"addNewText"}
                          variant="outlined"
                          onChange={settingNewDepartment}
                          onKeyDown={(e) => {
                            if (e.code === "Enter" || e.code === "NumpadEnter")
                              addNewRegion();
                          }}
                          value={regionName}
                        />
                      </Box>
                      <Box className={"addNewDiv1"}>
                        <CustomButton
                          onClick={() => addNewRegion()}
                          variant="outlined"
                        >
                          {t("addPrimary")}
                        </CustomButton>
                      </Box>
                      <span className="errorClass">{alphaNumErrorAdd}</span>
                    </Grid>
                    <PaginationLimitDropDown
                      dataState={dataState}
                      setDataState={setDataState}
                      setSelectedRows={setSelectedRows}
                    />
                  </Box>
                </Box>
                <AgViewColumn
                  colDefs={colDefs}
                  setColDefs={setColDefs}
                  gridRef={gridRef}
                  dispatch={dispatch}
                  settingType={settingType}
                  dataState={dataState}
                />
              </Box>
              <CommonGrid
                commonGridObj={commonGridObj}
                selectedRows={selectedRows}
                setOpenDelete={setOpenDelete}
                setRowsToBeDeleted={setRowsToBeDeleted}
                setSelectedRows={setSelectedRows}
                isLoading={isLoading}
                totalPages={regionData1?.totalPages}
                handlePageChange={handlePageChange}
                currentPage={dataState?.page}
                {...extraBulkDeleteProps}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <StatusAndReassignPopup
        open={open}
        showReassign={showReassign}
        setShowReassign={setShowReassign}
        resetErrorStates={resetErrorStates}
        allMainTableData={allMainTableData}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        currentRowData={currentRowData}
        setOpen={setOpen}
        dataState={dataState}
        setDataState={setDataState}
        rowsToBeDeleted={rowsToBeDeleted}
        regionData1={regionData1}
        openedFor={openedFor}
        setOpenDelete={setOpenDelete}
        openDelete={openDelete}
        setSelectedRows={setSelectedRows}
        handleSwitchChange={handleSwitchChange}
        gridRef={gridRef}
      />
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
}
