import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";
import performApiRequest from "helper/apiRequestHelper";

export const getContributionById = createAsyncThunk(
  "contribution/getContributionById",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getContributeById}/${id}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllContributeAlbums = createAsyncThunk(
  "contribution/getAllContributionsAlbums",
  async () => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(apiRoutes?.getAllContributeAlbums),
      );
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);

export const createSingleContributeAlbum = createAsyncThunk(
  "contribution/createSingleContributeAlbum",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.post(apiRoutes?.createSingleContributeAlbum, body),
      );
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);

export const deleteSingleContributeAlbum = createAsyncThunk(
  "contribution/deleteSingleContributeAlbum",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(`${apiRoutes.deleteSingleContributeAlbum}`, body),
      );
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);

export const getCustomTagDatas = createAsyncThunk(
  "contribution/getCustomTagDatas",
  async (id) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.get(`${apiRoutes.getCustomTagData}`),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const uploadAssetsForContribution = createAsyncThunk(
  "contribution/uploadAssetsForContribution",
  async (data, { dispatch }) => {
    const { id, formData } = await data;
    try {
      // const config = {
      //   onUploadProgress: (progressEvent) =>
      //     console.log(progressEvent.loaded),
      // };
 
     await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.uploadAssetsForContribution}/${id}`,
          formData,
          // config,
        ),
      );
      const vid= new URLSearchParams(window.location.search).get("id");
    return dispatch(getContributionById(vid));

    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateKeywordsContribute = createAsyncThunk(
  "contribution/updateKeywordsContribute",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.updateKeywordsContributeApi}/${body?.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);
export const updateContributeDetailsApi = createAsyncThunk(
  "contribution/updateContributeDetailsApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.updateContributeDetails}/${body?.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);

//updateContributeLocation
export const updateContributeLocationApi = createAsyncThunk(
  "contribution/updateContributeLocationApi",
  async (body) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.updateContributeLocation}/${body?.id}`,
          body,
        ),
      );
    } catch (error) {
      return { success: false, error: error?.response?.data };
    }
  },
);
