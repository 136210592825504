import React from "react";
import { styled } from "@mui/system";
import TextTag, { textVariants } from "components/Typography/TextTag";

const buttonSize = { mini: "mini" };

const ButtonOutlineIconStyles = styled("button")(({ theme, size }) => {
  return {
    cursor: "pointer",
    width: "auto",
    maxWidth: "200px",
    height: `${size === buttonSize?.mini ? "32" : "40"}px`,
    padding: "16px 24px 16px 24px",
    gap: "8px",
    border: `1px solid ${theme.palette.gray_300.main}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    backdropFilter: "blur(4px)",
    background: "transparent",
    span: {
      color: theme.palette.blackColor.main,
      "&.iconC": {
        display: "flex",
        svg: {
          height: "16px",
          width: "16px",
          path: {
            stroke: theme.palette.blackColor.main,
          },
        },
      },
    },
    "&:hover": { background: theme.palette.gray_200.main },
    "&:disabled": {
      background: theme.palette.gray_300.main,
      cursor: "auto",
      pointerEvents: "none",
      span: {
        color: theme.palette.gray_500.main,
        "&.iconC": {
          svg: {
            path: {
              stroke: theme.palette.gray_500.main,
            },
          },
        },
      },
    },
  };
});

const ButtonOutlineIcon = ({ title, size, iconComp, ...props }) => {
  return (
    <ButtonOutlineIconStyles size={size} {...props}>
      <span className="iconC">{iconComp}</span>
      <TextTag variant={textVariants._12px.medium}>{title}</TextTag>
    </ButtonOutlineIconStyles>
  );
};

export default ButtonOutlineIcon;
