import { createSlice } from "@reduxjs/toolkit";
import {  getContactUsTitleAction, updateContactUsTitleAction } from "./contactUsAction";

const initialState = {
  loadingState: false,
  contactUsTitleData:{},
  contactUsTitleLoading:false,
  updateContactUsTitleLoading:false
};

const contactUsSlice = createSlice({
  name: "contactUsSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
      builder.addCase(getContactUsTitleAction.pending, (state) => {
        state.contactUsTitleLoading=true;
       })
       .addCase(getContactUsTitleAction.fulfilled, (state, action) => {
         state.contactUsTitleLoading=false;
         state.contactUsTitleData = action.payload.data;
       })
       .addCase(getContactUsTitleAction.rejected, (state) => {
         state.contactUsTitleLoading=false;
       });
 
       builder.addCase(updateContactUsTitleAction.pending, (state) => {
         state.updateContactUsTitleLoading=true;
        })
        .addCase(updateContactUsTitleAction.fulfilled, (state) => {
          state.updateContactUsTitleLoading=false;
        })
        .addCase(updateContactUsTitleAction.rejected, (state) => {
          state.updateContactUsTitleLoading=false;
        });
  },
});
export const getContactUsTitleSelector = (state) => state.contactUsAdmin.contactUsTitleData;
export default contactUsSlice.reducer;
