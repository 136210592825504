import React, { useEffect, useRef, useState } from "react";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Box,
  DialogContent,
  TextField,
  DialogActions,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import PageTitle from "pages/Components/PageTitle/PageTitle";
import { getBanners, getBannerTitleFromSelector } from "./features/bannerSlice";
import {
  getbannerActions,
  deleteBanner,
  updateBanner,
  updateBannerTitleAction,
  getBannerTitleAction,
} from "./features/bannerAction";
import CommonGrid from "components/Grid/CommonGrid";
import { hiddenAgGridColumn } from "utils/datatable/agTableOptions";
import { CommonEditTooltip } from "components/TooltipComponent/CommonEditTooltip";
import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import TableViewIcon from "components/Grid/TableComponents/TableIcons/TableViewIcon";
import CommonDeletePopup from "../../Components/CommonDeletePopup/CommonDeletePopup";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { UserDetailDialogDesign } from "../user-administration/styles/roleStyles";
import { ErrorMessage, Formik, Form } from "formik";

import { t } from "i18next";
import CustomButton from "components/Button/CustomButton";
import * as Yup from "yup";
const Banners = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { bannerData } = useSelector(getBanners); // Access data from the store
  const [popUpName, setPopUpName] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);
  const { data: bannerNameData } = useSelector(getBannerTitleFromSelector);
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "bannerImages",
      headerName: t("banner"),
      headerClass: "fieldCenter",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellClass: "category_center",
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellRenderer: (params) => {
        const url = `${params?.data?.partial_image_url}/${params?.value}`;
        return (
          <span key={params?.value} className="center thumbImage dblock">
            <img
              src={!params?.value ? "./logos/assetCategoryDefault.png" : url}
              alt="categoryImage"
            />
          </span>
        );
      },
    },
    {
      field: "user",
      headerName: t("author"),
      headerClass: "leftAlign",
      cellClass: "category_cell",
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
    },
    {
      field: "createdAt",
      headerName: t("date"),
      headerClass: "leftAlign",
      cellClass: "category_cell",
      cellRenderer: ({ data }) =>
        ToolTipWrapperComponent(dayjs(data?.createdAt).format("DD-MM-YYYY")),
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (params) => {
        const url = `${params?.data?.partial_image_url}/${params?.data?.bannerImages}`;
        return (
          <Grid container className="actionIcons">
            <TableViewIcon
              onClick={() => {
                window.open(url, "_blank");
              }}
            />
            <TableDeleteIcon
              onClick={() => {
                setOpenDelete(true);
                setRowsToBeDeleted([params?.data?._id]);
              }}
            />
          </Grid>
        );
      },
    },
  ];

  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [dataState, setDataState] = useState();

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getbannerActions()).then((res) => {
      if (!res?.error) setIsLoading(false);
    });
    dispatch(getBannerTitleAction());
  }, [dispatch, dataState]);

  useEffect(() => {
    const partial_image_url = `${bannerData?.image_base_url}/${bannerData?.image_container_name}`;
    if (bannerData?.banner?.banner_images_data) {
      setRowData(
        bannerData?.banner?.banner_images_data?.map((tableDataObj) => ({
          _id: tableDataObj?._id,
          bannerImages: tableDataObj?.bannerImages?.file_name,
          partial_image_url,
          user: tableDataObj?.user?.email,
          createdAt: tableDataObj?.createdAt,
        })),
      );
    } else {
      setRowData([]);
    }
  }, [bannerData]);

  function handleDelete() {
    const body = {
      ids: rowsToBeDeleted,
    };

    dispatch(deleteBanner(body)).then(() => {
      setOpenDelete(false);
    });
  }

  const handleChecked = (val) => {
    const body = {
      id:
        bannerData?.banner?.banner_data?.banner_id ||
        bannerData?.banner?.banner_data?._id,
      is_manually: val.target.value === "true",
    };
    dispatch(updateBanner(body)).then((res) => {});
  };

  const checkBoxData = [
    {
      label: t("bannerLabelOne"),
      // info: "If you select this option then the banners added to the list will be shown randomaly on the homepage as hero banner",
      value: true,
      checked: bannerData?.banner?.banner_data?.is_manually || "",
      displayName: "manual_select",
    },
    {
      label: t("bannerLabelTwo"),
      // info: "If you select this option then the system will randomaly select any asset to be displayed as hero banner on the homepage",
      value: false,
      checked: !bannerData?.banner?.banner_data?.is_manually || "",
      displayName: "random_select",
    },
  ];

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: initialColDefs,
  };

  return (
    <FieldManagementStyle>
      <Box className="titleButton">
        <PageTitle title={bannerNameData?.title || t("banners")} />

        <Box className={"addNewDiv1"}>
          <CustomButton
            onClick={() => {
              setPopUpName(t("changeSectionTitle"));
              setOpenPopUp(true);
            }}
            variant="outlined"
          >
            {t("changeSectionTitle")}
          </CustomButton>
        </Box>
      </Box>
      <span>{bannerNameData?.description || t("bannerTitleText")}</span>
      <Box className={`checkBoxesBlockArea`}>
        {checkBoxData?.map((val, ind) => {
          const tooltip_data = {
            module_name: bannerData?.module_type,
            tooltip_setting: {
              details: bannerData?.toolTipSetting?.details || [],
            },
          };
          const displayName = val?.displayName;
          return (
            <Box key={val?.ind} className="bannerCheckBox">
              <Box className="tooltipWrapper">
                <CommonEditTooltip
                  tooltip_data={tooltip_data}
                  displayName={displayName}
                  field_name={displayName}
                  dataState={dataState}
                  setDataState={setDataState}
                />
              </Box>
              <Box className="checkboxWrapper">
                <FormControlLabel
                  control={
                    <Checkbox
                      key={ind}
                      value={val?.value}
                      checked={val?.checked}
                      onChange={handleChecked}
                    />
                  }
                  label={val?.label}
                />
              </Box>
            </Box>
          );
        })}
      </Box>
      <Grid container spacing={4} className={"userReq-table "}>
        <Grid item xs={12} className="bannerTable">
          <Box className="fieldTableMain">
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              rowHeight={100}
            />
          </Box>
        </Grid>
      </Grid>
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        multiDeleteText={t("bannerMultipleDeleteConfirmationText")}
        singleDeleteText={t("bannerDeleteConfirmationText")}
      />
      <ChangeSelectionTitle
        popUpName={popUpName}
        closePopUp={() => setOpenPopUp(false)}
        open={openPopUp}
        data={bannerNameData}
        dispatch={dispatch}
      />
    </FieldManagementStyle>
  );
};

export default Banners;

const ChangeSelectionTitle = ({
  data,
  popUpName,
  closePopUp,
  open,
  dispatch,
}) => {
  const validationSchema = Yup.object({
    title: Yup.string()
      .trim()
      .required(t("pleaseEnterPageTitle"))
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      ).max(50, validationMessages.tooLong),
      description: Yup.string()
      .trim()
      .required(t("pleaseEnterDescription"))
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      ).max(400, validationMessages.tooLong),
  });
  const submitHandler = async (values) => {
    const body = {
      bannerId: data?._id,
      title: values?.title,
      description: values?.description,
    };

    dispatch(updateBannerTitleAction(body)).then(() =>
      dispatch(getBannerTitleAction()),
    );
    return closePopUp();
  };

  return (
    <DialogCommon
      title={popUpName}
      handleClose={closePopUp}
      open={open}
      extraPaperClasses={`singleFileInput`}
      maxWidth={"md"}
    >
      <UserDetailDialogDesign>
        <Box className="popup-main AddUserDetails addEditCollectionType">
          <DialogContent>
            <Box className="requesOuter">
              <Formik
                initialValues={{
                  title: data?.title,
                  description: data?.description,
                }}
                validationSchema={validationSchema}
                onSubmit={submitHandler}
              >
                {({ values, handleBlur, handleChange }) => (
                  <Form>
                    <Box className="formSection">
                      <Box className="request-common">
                        <label htmlFor="title" className="label">
                          {t("enterName", { name: t("title") })}
                        </label>
                        <TextField
                          placeholder={t("enterName", { name: t("title") })}
                          size="small"
                          className={"addNewText"}
                          variant="outlined"
                          name={"title"}
                          id={"title"}
                          value={values?.title}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <ErrorMessage
                          name="title"
                          component={"span"}
                          className="errorClass"
                        />
                      </Box>
                      <Box className="request-common">
                        <label htmlFor={"description"} className="label">
                          {t("description")}
                        </label>
                        <TextField
                          placeholder={t("description")}
                          rows={3}
                          size="small"
                          variant="outlined"
                          type="text"
                          multiline={true}
                          name="description"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.description ?? ""}
                        />
                        <ErrorMessage
                          name="description"
                          component={"span"}
                          className="errorClass"
                        />
                      </Box>
                      <DialogActions className="popup-button-outer">
                        <CustomButton variant={"contained"} type="submit">
                          {t("save")}
                        </CustomButton>
                        <CustomButton
                          variant={"contained"}
                          onClick={closePopUp}
                        >
                          {t("cancel")}
                        </CustomButton>
                      </DialogActions>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </DialogContent>
        </Box>
      </UserDetailDialogDesign>
    </DialogCommon>
  );
};
