import React from "react";
import { t } from "i18next";
import { styled } from "@mui/system";
import ContributePopupSaveButton from "components/Button/ContributePopupSaveButton";
import MainButton, { buttonVariants } from "components/Button/MainButton";

export const PopupFooterStyles = styled("div")(({ theme }) => ({
  padding: "24px 24px 0",
  borderTop: `1px solid ${theme.palette.gray_250.main}`,
  display: "flex",
  gap: "8px",
  position: "relative",
  "button.popupCancelButton": {
    maxWidth: "98px",
  },
}));

const ContributePopupFooter = ({
  formik,
  handlePopupClose,
  checkDisabled,
  ...props
}) => {
  return (
    <PopupFooterStyles>
      <MainButton
        title={t("cancel")}
        variant={buttonVariants.grey}
        onClick={(e) => {
          e.preventDefault();
          handlePopupClose(e);
        }}
        className={"popupCancelButton"}
      />
      <ContributePopupSaveButton disableSave={checkDisabled()} {...props} />
    </PopupFooterStyles>
  );
};

export default ContributePopupFooter;
