import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "utils/queryString";
import { TabsStyle } from "./TabsStyle";

const TabsComponent = (props) => {
  const {
    tabsDataArray,
    // setDataState,
    //  initialDataState,
    className,
  } = props;

  const tabId1 = useQuery().get("id");
  let tabId;
  try {
    tabId = atob(tabId1);
  } catch (e) {}

  const navigate = useNavigate();
  function handleChange(event, value) {
    // if (setDataState && initialDataState) {
    //   //go to first page and reset all filters etc when the state is changed
    //   setDataState(initialDataState);
    // }
    if (value?.includes("?id=")) {
      const valArr = value.split("?id=");
      navigate({ pathname: valArr[0], search: `?id=${btoa(valArr[1])}` });
    } else {
      navigate(value);
    }
  }

  return (
    <TabsStyle>
      <Box className={`${className} tabsMain`}>
        <Tabs
          value={
            tabsDataArray?.[0]?._id
              ? `${window.location.pathname}?id=${tabId}`
              : window.location.pathname
          }
          onChange={handleChange}
          aria-label="page tabs"
          className="tabsOuter"
          variant="scrollable"
          scrollButtons="auto"
        >
          {tabsDataArray?.map((tabsDataObj, index) => (
            <Tab
              className="tabInner"
              disableRipple
              label={tabsDataObj.label}
              key={index}
              value={
                tabsDataObj?._id
                  ? `${tabsDataObj.value}?id=${tabsDataObj?._id}`
                  : tabsDataObj.value
              }
            />
          ))}
        </Tabs>
      </Box>
    </TabsStyle>
  );
};

export default TabsComponent;
