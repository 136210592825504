import { styled } from "@mui/system";
import MainButton, {
  buttonVariants,
  MainButtonWithChildren,
} from "components/Button/MainButton";
import TickIcon from "components/Icons/TickIcon/TickIcon";
import { t } from "i18next";
import React from "react";

const ShowcaseCommonFooterStyles = styled("div")(({ theme }) => ({
  position: "fixed",
  bottom: "0",
  width: "100%",
  left: "0",
  display: "flex",
  alignItems: "center",
  padding: "8px 64px",
  justifyContent: "end",
  gap: "8px",
  boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.07)",
  backgroundColor: theme.palette.whiteColor.main,
  zIndex: "99",
  borderTop: `1px solid ${theme.palette.gray_250.main}`,
  button: {
    height: "32px",
    padding: "0 16px",
    "&.saveShowcaseDetails": {
      width: "100%",
      maxWidth: "200px",
      svg: {
        marginRight: "8px",
        path: {
          stroke: theme.palette.whiteColor.main,
        },
      },
    },
  },
}));

const ShowcaseCommonFooter = ({
  cancelTitle,
  saveTitle,
  onCancelClick,
  onSaveClick,
  saveButtonType,
  disableSave,
}) => {
  return (
    <ShowcaseCommonFooterStyles>
      <MainButton
        title={cancelTitle ? cancelTitle : t("cancel")}
        variant={buttonVariants.grey}
        onClick={onCancelClick}
      />
      <MainButtonWithChildren
        variant={buttonVariants.blue}
        onClick={onSaveClick}
        className={"saveShowcaseDetails"}
        disabled={disableSave}
        type={saveButtonType ? saveButtonType : "button"}
      >
        <TickIcon />
        <span>{saveTitle ? saveTitle : t("save")}</span>
      </MainButtonWithChildren>
    </ShowcaseCommonFooterStyles>
  );
};

export default ShowcaseCommonFooter;
