import React from "react";

const HeartIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7 2.99996C5.8004 1.60195 3.79585 1.1699 2.29282 2.45006C0.789786 3.73022 0.57818 5.87057 1.75852 7.38464C2.73989 8.64344 5.70987 11.2984 6.68327 12.1578C6.79213 12.2539 6.8466 12.302 6.91013 12.3208C6.96553 12.3373 7.0262 12.3373 7.08167 12.3208C7.1452 12.302 7.1996 12.2539 7.30853 12.1578C8.28193 11.2984 11.2519 8.64344 12.2333 7.38464C13.4136 5.87057 13.2278 3.71676 11.6989 2.45006C10.1701 1.18337 8.1996 1.60195 7 2.99996Z" stroke="#212A36" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    );
};

export default HeartIcon;
