import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Box, Grid } from "@mui/material";
import PageTitle from "../../../Components/PageTitle/PageTitle";
import CustomButton from "components/Button/CustomButton";
import {
  assetCategoryEditApi,
  assetCategoryEnableDisableApi,
  categoryShowHomePageApi,
  countActiveCategoryApi,
  getAllAssetCategoryApi,
  getAssetCategoryApi,
  getAssetCategoryFields,
} from "./features/assetCategoryAction";
import {
  allAssetCategory,
  assetCategoryFieldValueArray,
  assetCategoryState,
} from "./features/assetCategorySlice";
import { categoriesTabs } from "pages/Components/Tabs/TabsConstant";
import CommonGrid from "components/Grid/CommonGrid";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { agSetColumnIndexes } from "pages/Components/ColumnDrag/ColumnDrag";
import { AgCellEditor } from "utils/datatable/AgCellEditor";
import TableEditIcon from "components/Grid/TableComponents/TableIcons/TableEditIcon";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import CustomComponentFilter, {
  getFieldsDataHelper,
  ShowHeaderIcon,
} from "pages/Components/CustomComponentfilter/CustomComponentFilter";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import TabsComponent from "pages/Components/Tabs/Tabs";
import { validationMessages } from "utils/validation/validationUtils";
import ToggleSwitch from "pages/Components/ToggleSwitch/ToggleSwitch";
import AddEditPopup from "./Components/AddEditPopUp/AddEditPopup";
import ReassignSubCategory from "./Components/ReassignSubCategory/ReassignSubCategory";
import ToastHelper from "helper/toastHelper";
import { getAllAssetType } from "../AssetType/features/typeAction";
import { allAssetTypes } from "../AssetType/features/typeSlice";
import ToolTipWrapperComponent from "helper/TooltipTableWrapper";
import { FieldManagementStyle } from "pages/Admin-Pages/fields-management/fieldManagementStyle";

function AssetCategory() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const assetCategoryData = useSelector(assetCategoryState);
  const allAssetCategoryData = useSelector(allAssetCategory);
  const fieldValueData = useSelector(assetCategoryFieldValueArray); //filter
  const allAssetTypesData = useSelector(allAssetTypes);
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    hiddenAgGridColumn("subcategory_count"),
    hiddenAgGridColumn("description"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "category_image",
      minWidth: 180,
      headerName: t("categoryImage"),
      headerClass: "fieldCenter",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellClass: "category_center",
      cellRenderer: (props) => {
        const url = `${props?.data?.partial_image_url}/${props?.value}`;
        return (
          <span key={props?.value} className="center thumbImage dblock">
            <img
              src={props?.value ? url : "./logos/assetCategoryDefault.png"}
              alt="categoryImage"
            />
          </span>
        );
      },
    },
    {
      field: "name",
      headerName: t("category"),
      headerClass: "leftAlign",
      minWidth: 300,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value),
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellEditor: AgCellEditor,
      cellEditorParams: {
        checkAlphaNumericValidation: true,
        editFunction: editCategoryName,
      },
      cellClass: "editableCell category_edit category_cell",
      editable: true,
    },
    {
      field: "asset_type_data",
      headerName: t("type"),
      headerClass: "leftAlign",
      minWidth: 250,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "asset_type_data.name",
          dispatchName: "asset_type_data.name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"asset_type_data.name"}
                sortBy={"asset_type_data.name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      cellRenderer: ({ value }) => ToolTipWrapperComponent(value?.name),
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "category_cell",
    },
    {
      field: "createdAt",
      headerName: t("date"),
      headerClass: "leftAlign",
      cellClass: "category_cell",
      minWidth: 190,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "createdAt",
          dispatchName: "createdAt",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"createdAt"}
                sortBy={"createdAt"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      cellRenderer: ({ data }) =>
        ToolTipWrapperComponent(dayjs(data?.createdAt).format("DD-MM-YYYY")),
      headerComponentParams: {
        applied_filters_data: [],
      },
    },
    {
      field: "enabled",
      headerName: t("status"),
      headerClass: "fieldCenter",
      minWidth: 170,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "enabled",
          dispatchName: "enabled",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"enabled"}
                sortBy={"enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn category_status",
      cellRenderer: (props) => {
        return (
          <Grid className="toggleBlockCo">
            <ToggleSwitch
              checked={props?.value}
              value={props?.value}
              defaultChecked={props?.value}
              onChange={(e) => {
                gridRef.current.api.deselectAll();
                setOpenedFor("REASSIGN");
                setCurrentRowData(props?.data);
                handleSwitchChange(
                  props?.data?._id, //id
                  props?.value, //value
                  props?.data?.subcategory_count,
                  false, //no reassing happening
                  true, //open reassign popup
                  props,
                );
              }}
            />
          </Grid>
        );
      },
    },
    {
      field: "show_on_home_page",
      headerName: t("quickThumbnail"),
      headerClass: "fieldCenter",
      minWidth: 190,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "show_on_home_page",
          dispatchName: "show_on_home_page",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"show_on_home_page"}
                sortBy={"show_on_home_page"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn category_status",
      cellRenderer: (props) => {
        return (
          <Grid className="toggleBlockCo">
            <ToggleSwitch
              checked={props?.value}
              value={props?.value}
              defaultChecked={props?.value}
              onChange={(e) => {
                gridRef.current.api.deselectAll();
                gridRef.current.api.forEachNode((rowNode) => {
                  if (rowNode?.data?._id !== props?.data?._id) {
                    return false;
                  }
                  //updating the ui by toggling switchvalue
                  rowNode.setDataValue("show_on_home_page", !props?.value);

                  const body = {
                    id: props?.data?._id,
                    show_on_home_page: !props?.value,
                  };

                  dispatch(categoryShowHomePageApi(body));
                });
              }}
            />
          </Grid>
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      minWidth: 200,
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <TableEditIcon
            onClick={() => {
              setEditTrue("Edit");
              setAddOpen(true);
              setSelectedRows([]);
              setCurrentRowData(props?.data);
            }}
          />
          <TableDeleteIcon
            onClick={() => {
              // setOpenDelete(true);
              // setRowsToBeDeleted([props?.data?._id]);

              setOpenedFor("DELETE");

              if (props?.total_active <= 1 && props?.data?.enabled) {
                //if only one record is enabled and the record we are deleting is also enabled
                //means we are deleting last enabled record then show toast
                ToastHelper(
                  "error",
                  validationMessages.onePrimaryGroupShouldBeEnabled,
                );
              } else {
                if (props?.data?.subcategory_count > 0) {
                  setOpen(true);
                } else {
                  setOpenDelete(true);
                }
              }

              setCurrentRowData(props.data);
              setRowsToBeDeleted([props?.data?._id]);
              // resetErrorStates();
            }}
          />
        </Grid>
      ),
    },
  ];

  const [colDefs, setColDefs] = useState(initialColDefs);
  const [isLoading, setIsLoading] = useState(false);
  const [settingType, setSettingType] = useState();
  const [rowData, setRowData] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [open, setOpen] = useState(false);
  const [showReassign, setShowReassign] = useState(false);
  const [openedFor, setOpenedFor] = useState("");
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [addOpen, setAddOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState();
  const [editTrue, setEditTrue] = useState("");
  const [selectedRows, setSelectedRows] = useState();
  const [selectValue, setSelectValue] = useState("-1");
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    dispatch(getAllAssetCategoryApi());
  }, [dispatch, open, openDelete]);

  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllAssetType());
  }, [dispatch]);

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getAssetCategoryApi(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dataState, dispatch]);

  useEffect(() => {
    setPreviousColumnFilterData(assetCategoryData?.applied_filters);
    const partial_image_url = `${assetCategoryData?.image_base_url}/${assetCategoryData?.image_container_name}`;
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) =>
        (commonDataObj[commonKey] = assetCategoryData?.[commonKey]),
    );
    setRowData(
      assetCategoryData?.asset_type?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        category_image: tableDataObj?.category_image,
        partial_image_url,
        name: tableDataObj?.name,
        createdAt: tableDataObj?.createdAt,
        enabled: tableDataObj?.enabled,
        action: "",
        other_table_data: commonDataObj,
        subcategory_count: tableDataObj?.subcategory_count,
        total_active: assetCategoryData?.total_active,
        show_on_home_page: tableDataObj?.show_on_home_page,
        asset_type_data: tableDataObj?.asset_type_data?.[0],
        description: tableDataObj?.description,
      })),
    );
  }, [assetCategoryData]);

  const handleSwitchChange = useCallback(
    async (
      entryId, //id
      switchValue, //value true or false
      count, //number of child entries, enter 0 if you do not want to open reassign popup
      newRegionsId, //new entry for reassign, only enter when required
      shouldPopupOpen = false,
      props, //props from grid, will use datastate if not available
    ) => {
      gridRef.current.api.forEachNode((rowNode) => {
        if (rowNode?.data?._id !== entryId) {
          return false;
        }
        //  resetErrorStates();
        setSelectedRows([]);
        setRowsToBeDeleted([]);
        if (switchValue && count > 0 && shouldPopupOpen) {
          //when deactivating if there are child elements open reassign popup
          //also only open the popup if there is more than one active
          dispatch(countActiveCategoryApi()).then((res) => {
            if (res?.error) {
              return;
            }
            if (res?.payload?.total_category > 1) {
              setOpen(true);
            } else {
              ToastHelper(
                "error",
                validationMessages.oneCategoryShouldBeEnabled,
              );
            }
          });
          return;
        }

        //updating the ui
        //by toggling switchvalue and setting count
        rowNode.setDataValue("enabled", !switchValue);
        rowNode.setDataValue("subcategory_count", count);

        let modifiedDataState = dataState;

        if (props?.data?.other_table_data) {
          const other_data = props?.data?.other_table_data;
          modifiedDataState = modifyDataState(other_data);
        }

        const dataBody = {
          body: {
            id: entryId,
            enabled: !switchValue,
          },
          dataState: modifiedDataState,
        };

        if (newRegionsId) {
          //this means we are also reassigning new business unit while updating the status
          dataBody.body.new_category = newRegionsId;
        }

        //running the update status api
        dispatch(assetCategoryEnableDisableApi(dataBody)).then((resp) => {
          if (resp?.error) {
            //reverting if error occurs
            dispatch(getAssetCategoryApi(modifiedDataState));
            return "error";
          } else if (newRegionsId) {
            dispatch(getAssetCategoryApi(modifiedDataState));
          }
        });
      });
    },
    //eslint-disable-next-line
    [],
  );

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  function editCategoryName(data) {
    const formData = new FormData();
    formData.append("old_image", true);
    formData.append("name", data?.updatedValue || data?.name);
    formData.append("asset_type_id", data?.dataObj?.asset_type_data?._id);

    const modifiedValues = {
      data: formData,
      id: data?.dataObj?._id || data?.id,
    };

    const other_data = data?.dataObj?.other_table_data;
    const modifiedDataState = modifyDataState(other_data);

    const dataBody = {
      modifiedValues,
      dataState: modifiedDataState,
    };

    dispatch(assetCategoryEditApi(dataBody))
      .then((res) => {
        dispatch(getAssetCategoryApi(modifiedDataState));
        if (res?.error) {
          return;
        }
      })
      .catch((res) => {
        console.error("an error occured", res);
      });
  }

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getAssetCategoryFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  const extraBulkDeleteProps = {
    //props required for extra bulk delete functionality
    //if allowBulkDeleteConditions is false then no need to send other props at all
    allowBulkDeleteConditions: true,
    //if true bulk delete will not check for extra conditions
    childCountExtension: null, //no child entries
    // setOpenedFor,
    getActiveEntries: countActiveCategoryApi,
    activeTableEntriesExtension: "total_category",
    lastRecordValidationMessage: validationMessages.oneCategoryShouldBeEnabled,
    getAllEntries: getAllAssetCategoryApi,
    allEntriesExtension: "asset_type",
    setOpen: null, //no need to open reassign
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("category")} />
      <TabsComponent tabsDataArray={categoriesTabs} />
      <Box container spacing={4} className={"userReq-table"}>
        <Box item xs={12}>
          <Box className="fieldTableMain category">
            <Box className="fieldTableHeader">
              <Box className="table-upper">
                <Box className={`add_button`}>
                  <CustomButton
                    onClick={() => {
                      setAddOpen(true);
                      setEditTrue("");
                    }}
                  >
                    {t("addCategory")}
                  </CustomButton>
                </Box>
                <PaginationLimitDropDown
                  dataState={dataState}
                  setDataState={setDataState}
                  setSelectedRows={setSelectedRows}
                />
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={isLoading}
              totalPages={assetCategoryData?.totalPages}
              handlePageChange={handlePageChange}
              rowHeight={90}
              currentPage={dataState?.page}
              {...extraBulkDeleteProps}
            />
          </Box>
        </Box>
      </Box>
      {addOpen && (
        <AddEditPopup
          open={addOpen}
          setOpen={() => setAddOpen(false)}
          id={currentRowData?._id ? currentRowData?._id : ""}
          url={
            currentRowData?.category_image &&
            `${assetCategoryData?.image_base_url}/${assetCategoryData?.image_container_name}/${currentRowData?.category_image}`
          }
          urlName={
            currentRowData?.category_image ? currentRowData?.category_image : ""
          }
          editTrue={editTrue}
          name={currentRowData?.name ? currentRowData?.name : ""}
          dataState={dataState}
          setDataState={setDataState}
          allAssetTypesData={allAssetTypesData}
          currentRowData={currentRowData}
        />
      )}
      <ReassignSubCategory
        open={open}
        setOpen={setOpen}
        setOpenDelete={setOpenDelete}
        openDelete={openDelete}
        showReassign={showReassign}
        setShowReassign={setShowReassign}
        // resetErrorStates={resetErrorStates}
        allMainTableData={assetCategoryData}
        selectValue={selectValue}
        setSelectValue={setSelectValue}
        currentRowData={currentRowData}
        dataState={dataState}
        setDataState={setDataState}
        rowsToBeDeleted={rowsToBeDeleted}
        regionData1={allAssetCategoryData}
        openedFor={openedFor}
        setSelectedRows={setSelectedRows}
        handleSwitchChange={handleSwitchChange}
        gridRef={gridRef}
      />
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          fieldValueData={fieldValueData}
        />
      )}
    </FieldManagementStyle>
  );
}

export default AssetCategory;
