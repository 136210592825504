import {
  TextField,
  DialogActions,
  MenuItem,
  Select,
  DialogContent,
} from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "components/Button/CustomButton";
import { ErrorMessage, Formik, Form } from "formik";
import { t } from "i18next";

import {
  updateFeatureCollectionTitleApi,
  getFeaturedCollectionTitleApi,
  addFeaturedCollectionApi,
} from "./features/featuredAction";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import {
  getActiveContributionbyCollectionId,
  Getcollection,
} from "pages/Admin-Pages/collections/features/collectionAction";
import {
  activeContributionFromCollectionType,
  collectiostate,
} from "pages/Admin-Pages/collections/features/collectionSlice";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import * as Yup from "yup";
import DialogCommon from "components/DialogComponent/DialogCommon";
import { UserDetailDialogDesign } from "pages/Admin-Pages/user-administration/styles/roleStyles";

const AddFeaturedForm = ({
  closePopUp,
  setFeaturedCollectionsHandler,
  dispatch,
}) => {
  const allCollections = useSelector(collectiostate);
  useEffect(() => {
    dispatch(Getcollection());
    return () => {
      dispatch(getActiveContributionbyCollectionId({ collectionId: " " }));
    };
  }, [dispatch]);

  const validationSchema = Yup.object().shape({
    assetCollectionId: Yup.string()
      .required(t("pleaseSelectCollectionType"))
      .trim(),
    digitalAssetCollectionId: Yup.string()
      .required(t("pleaseSelectCollection"))
      .trim(),
  });
  const filterdCollections = allCollections?.collections?.reduce(
    (res, curr) => {
      if (curr.isDeleted === false && curr.enabled === true) {
        res.push([curr?._id, curr?.name]);
      }
      return res;
    },
    [],
  );
  const initialValues = {
    assetCollectionId: " ",
    digitalAssetCollectionId: " ",
  };

  const handleSubmit = async (values, { resetForm }) => {
    dispatch(addFeaturedCollectionApi(values)).then(
      setFeaturedCollectionsHandler,
    );

    await resetForm();
    await closePopUp();
    return;
  };
  const gellUserContributions = useSelector(
    activeContributionFromCollectionType,
  );
  const filterdUsersContributions = gellUserContributions?.data?.reduce(
    (res, curr) => {
      if (curr.isDeleted === false && curr.enabled === true) {
        res.push([curr?._id, curr?.unique_id, curr?.name, curr?.client_id]);
      }
      return res;
    },
    [],
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, handleBlur, values, handleChange }) => (
        <Form>
          <Box className="formSection">
            <Box className="request-common">
              <label className="label">{t("selectCollectionType")} :</label>
              <Select
                name="assetCollectionId"
                className=" addNewText"
                onChange={async ({ target }) => {
                  const collectionId = (await target?.value?.trim()) || " ";
                  dispatch(
                    getActiveContributionbyCollectionId({ collectionId }),
                  );
                  await setFieldValue("assetCollectionId", collectionId);
                  setFieldValue("digitalAssetCollectionId", " ");
                  return;
                }}
                onBlur={handleBlur}
                value={values.assetCollectionId || " "}
              >
                <MenuItem value=" ">{t("selectCollectionType")}</MenuItem>
                {Array.isArray(filterdCollections) &&
                  filterdCollections?.map(([id, label]) => (
                    <MenuItem key={id} value={id}>
                      {label || id}
                    </MenuItem>
                  ))}
              </Select>
              <ErrorMessage
                name="assetCollectionId"
                component={"span"}
                className="errorClass"
              />
            </Box>
          </Box>

          <Box className="request-common">
            <label className="label">{t("selectFromTheOptionsBelow")} :</label>

            <Select
              name="digitalAssetCollectionId"
              className="permission-role-select"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values?.digitalAssetCollectionId || " "}
            >
              <MenuItem value=" ">{t("selectCollections")}</MenuItem>
              {values?.assetCollectionId &&
                Array.isArray(filterdUsersContributions) &&
                filterdUsersContributions?.map(
                  ([id, uniqueId, label, clientId]) => (
                    <MenuItem key={id} value={id}>
                      {clientId || uniqueId}
                      {label && ` - ${label}`}
                    </MenuItem>
                  ),
                )}
            </Select>

            <ErrorMessage
              name="digitalAssetCollectionId"
              component={"span"}
              className="errorClass"
            />
          </Box>
          <DialogActions className="popup-button-outer">
            <CustomButton variant={"contained"} type="submit">
              {t("save")}
            </CustomButton>
            <CustomButton variant={"contained"} onClick={closePopUp}>
              {t("cancel")}
            </CustomButton>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};
const ChangeSelectionTitle = ({ data, closePopUp, dispatch }) => {
  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required(t("featuredCollectionNameIsRequired"))
      .matches(
        validationRegex?.checkForDoubleSpaces,
        validationMessages?.noConsecutiveDoubleSpaces,
      ),
  });
  const submitHandler = async (values) => {
    const body = {
      featureCollectionTitleId: data?._id,
      name: values?.name,
    };

    dispatch(updateFeatureCollectionTitleApi(body)).then(() =>
      dispatch(getFeaturedCollectionTitleApi()),
    );
    return closePopUp();
  };
  return (
    <Formik
      initialValues={{ name: data?.name }}
      validationSchema={validationSchema}
      onSubmit={submitHandler}
    >
      {({ values, handleBlur, handleChange }) => (
        <Form>
          <Box className="formSection">
            <Box className="request-common">
              <label htmlFor="name" className="label">
                {t("enterName", { name: t("name") })}
              </label>
              <TextField
                placeholder={t("enterName", { name: t("name") })}
                size="small"
                className={"addNewText"}
                variant="outlined"
                name={"name"}
                id={"name"}
                value={values?.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <ErrorMessage
                name="name"
                component={"span"}
                className="errorClass"
              />
            </Box>

            <DialogActions className="popup-button-outer">
              <CustomButton variant={"contained"} type="submit">
                {t("save")}
              </CustomButton>
              <CustomButton variant={"contained"} onClick={closePopUp}>
                {t("cancel")}
              </CustomButton>
            </DialogActions>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default function FeaturedAssetsPopups({
  popUpMode,
  closeAddNewPopUpHandler,
  addNewPopUp,
  setFeaturedCollectionsHandler,
  dispatch,
  collectionNameData,
}) {
  return (
    <DialogCommon
      title={popUpMode}
      handleClose={closeAddNewPopUpHandler}
      open={addNewPopUp}
      extraPaperClasses={`singleFileInput`}
      maxWidth={"md"}
    >
      <UserDetailDialogDesign>
        <Box className="popup-main AddUserDetails addEditCollectionType">
          <DialogContent>
            <Box className="requesOuter">
              {popUpMode === t("addFeatured") ? (
                <AddFeaturedForm
                  closePopUp={closeAddNewPopUpHandler}
                  setFeaturedCollectionsHandler={setFeaturedCollectionsHandler}
                  dispatch={dispatch}
                />
              ) : (
                " "
              )}
              {popUpMode === t("changeSectionTitle") ? (
                <ChangeSelectionTitle
                  closePopUp={closeAddNewPopUpHandler}
                  data={collectionNameData}
                  dispatch={dispatch}
                />
              ) : (
                " "
              )}
            </Box>
          </DialogContent>
        </Box>
      </UserDetailDialogDesign>
    </DialogCommon>
  );
}
