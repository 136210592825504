import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { ArrowLinkComp } from "components/Links/ArrowLinkComp";
import { marketSegmentsColIndexSortedApi } from "pages/Admin-Pages/cms-changes/MarketSegment/features/marketSegmentsAction";
import { allSortedMarketSegmentData } from "pages/Admin-Pages/cms-changes/MarketSegment/features/marketSegmentSlice";
import MarketSegmentStyles from "./MarketSegmentStyles";
import { deepCopy } from "helper/deepCopyFunction";

const MarketSegment = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(marketSegmentsColIndexSortedApi());
  }, [dispatch]);
  const {market_segment: marketSegmentData1 , quickLink} = useSelector(allSortedMarketSegmentData,)?.data || {};

  const marketSegmentData = deepCopy(marketSegmentData1);

  marketSegmentData?.sort(
    (prev, next) => prev.display_index - next.display_index,
  );

  marketSegmentData?.sort((a, b) => {
    // Check if 'a' and 'b' has an empty branches array
    const aHasLinkData = a?.link_data?.length > 0;
    const bHasLinkData = b?.link_data?.length > 0;

    // If 'a' has branches and 'b' does not, 'a' should come first
    if (aHasLinkData && !bHasLinkData) return -1;

    // If 'b' has branches and 'a' does not, 'b' should come first
    if (!aHasLinkData && bHasLinkData) return 1;

    // If both have the same condition, maintain their original order
    return 0;
  });

 

  const dummyData = {
    sectionHeading1: t("marketSectionHeading1"),
    sectionHeading2: t("marketSectionHeading2"),
    desc: t("marketSectionDesc"),
  };

  return (
    <MarketSegmentStyles>
      <div className="marketSegmentMain">
        <div className="marketRightSection">
          <div className="linksBlock">
            <div className="marketLeftSection">
              <div className="marketHeading">
                <HeadingTag variant={headingVariants.h2}>
                  {quickLink?.title || dummyData?.sectionHeading1}
                </HeadingTag>
               
              </div>
              <div className="marketDescription">
                <TextTag variant={textVariants._16px.regular}>
                  { quickLink?.description || dummyData?.desc}
                </TextTag>
              </div>
            </div>
            {marketSegmentData?.map((colData, index) => (
              <ul key={index}>
                {colData?.link_data?.map(
                  (linkData, linkIndex) =>
                    linkIndex < 5 && (
                      <ArrowLinkComp
                        key={linkIndex}
                        link={linkData?.url}
                        title={linkData?.name}
                        targetBlank={linkData?.target_blank}
                      />
                    ),
                )}
              </ul>
            ))}
          </div>
        </div>
      </div>
    </MarketSegmentStyles>
  );
};

export default MarketSegment;
