import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import ImageSearchIcon from "../Icons/ImageSearchIcon";
import SearchIcon from "../Icons/SearchIcon";
import SearchTypeSelect, {
  searchFlagType,
} from "./Components/SearchTypeSelect/SearchTypeSelect";
import { isHeaderFixed, isScrollingUp } from "Layout/features/layoutSlice";
import HeroSearchStyles from "./HeroSearchStyle";
import { routeConfigs } from "utils/routeConfig";
import ArrowDownIcon from "components/Icons/ArrowIcons/ArrowDownIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";

const HeroSearch = ({ fullSearch }) => {
  const headerFix = useSelector(isHeaderFixed);
  const upScrolling = useSelector(isScrollingUp);
  const navigate = useNavigate();

  const [openSearchType, setOpenSearchType] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchType, setSearchType] = useState({
    name: "Image",
    searchFlag: searchFlagType?.type,
  });

  const handleSearchType = (e) => {
    e.stopPropagation();
    setOpenSearchType(!openSearchType);
  };

  const handleSearchSimilar = () => {};

  return (
    <HeroSearchStyles>
      <div
        className={`heroSearch ${headerFix ? "heroSearchFixed" : ""} ${
          fullSearch ? "fullHeaderSearch" : ""
        }${upScrolling ? "upScrolling" : ""}`}
      >
        <div className="searchTypeSelect">
          <div className="searchTypeMain">
            <div className="searchTypeButton" onClick={handleSearchType}>
              <TextTag variant={textVariants._14px.regular}>
                {searchType?.name}
              </TextTag>
              <ArrowDownIcon />
            </div>
            <SearchTypeSelect
              openSearchType={openSearchType}
              setOpenSearchType={setOpenSearchType}
              searchType={searchType}
              setSearchType={setSearchType}
            />
          </div>
        </div>
        <div className="searchAndSimilar">
          <div className="searchInput">
            <SearchIcon />
            <input
              type="text"
              name="search"
              id="search"
              placeholder={t("search")}
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e?.code === "Enter" || e?.code === "NumpadEnter") {
                  navigate(`${routeConfigs.searchPage}?s=${e.target.value}`);
                }
              }}
            />
          </div>
          <button className="similarSearchButton" onClick={handleSearchSimilar}>
            <ImageSearchIcon />
            <span>{t("searchSimilar")}</span>
          </button>
        </div>
      </div>
    </HeroSearchStyles>
  );
};

export default HeroSearch;
