import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { t } from "i18next";
import {
  getContributionById,
  uploadAssetsForContribution,
} from "./features/contributionAction";
import {
  EditDetailForms,
  SaveDetailForms,
} from "./Components/DetailForm/DetailForm";
import Description from "./Components/Description/Description";
import { EditKeyword, Keywords } from "./Components/Keywords/Keywords";
import UploadSection from "./Components/UploadSection";
import Layout from "Layout/AdminLayout/Layout";
import { contributerDataState } from "./features/contributionSlice";
import { EditMapLocation, SaveMapLocation } from "components/MapLocationComp";
import ContributeStyle from "./ContributeStyle";
import { fieldTypeMappedObject } from "pages/Admin-Pages/fields-management/Components/AddNewFieldPopup";
import {
  fixHeroSearch,
  isHeaderFixed,
  scrollingUp,
} from "Layout/features/layoutSlice";
import ShowcaseHeading from "./Components/ShowcaseHeading";

export const activeCompObj = {
  description: "description",
  detailForm: "detailForm",
  keywords: "keywords",
  location: "location",
};

const Contribute = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const headingRef = useRef();

  const headerFix = useSelector(isHeaderFixed);
  const { contributerData } = useSelector(contributerDataState);

  const [id, setId] = useState(new URLSearchParams(location?.search).get("id"));
  const [activeComponent, setActiveComponent] = useState(null);
  const [keyWordArr, setkeyWordArr] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState(t("all"));
  const [defaultLocation, setDefaultLocation] = useState({});

  const addressMapFieldId =
    contributerData?.digital_collections?.[0]?.allVisiableField
      .filter((a) => a?.field_type?.name === fieldTypeMappedObject.address)
      .sort((a, b) => a?.order_index - b?.order_index)?.[0]?._id;

  const addressMapFieldData =
    addressMapFieldId &&
    contributerData?.digital_collections?.[0]?.fieldsData.find(
      (a) => a?._id === addressMapFieldId,
    );

  // scroll to top of page after a page transition.
  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname]);

  useEffect(() => {
    setId(new URLSearchParams(location?.search).get("id"));
  }, [location.search]);

  useEffect(() => {
    const digitalCollections = contributerData?.digital_collections?.[0];

    // Default location setup
    if (addressMapFieldData) {
      let loc;
      try {
        loc = JSON.parse(addressMapFieldData?.field_value?.value);
      } catch (error) {
        console.error("Failed to parse location:", error);
        loc = {}; // Fallback to initial value in case of error
      }
      setDefaultLocation(loc);
    } else {
      setDefaultLocation({});
    }

    // Set keyword array
    setkeyWordArr(digitalCollections?.keyword_data);
  }, [contributerData, activeComponent, addressMapFieldData]);

  useEffect(() => {
    dispatch(getContributionById(id));
  }, [dispatch, id]);

  useEffect(() => {
    let lastScrollTop = window.scrollY || window.pageYOffset;

    const handleScroll = () => {
      const top = headingRef.current?.getBoundingClientRect().top;

      if (top > 10) {
        dispatch(fixHeroSearch(false));
      } else {
        dispatch(fixHeroSearch(true));
      }

      const st = window.scrollY || window.pageYOffset;

      if (top <= 120) {
        if (st > lastScrollTop) {
          dispatch(scrollingUp(false));
        } else if (st < lastScrollTop) {
          dispatch(scrollingUp(true));
        }
      } else {
        dispatch(scrollingUp(false));
      }

      lastScrollTop = st;
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [dispatch]);

  const unMountActiveComponent = () => {
    setActiveComponent(null);
    dispatch(getContributionById(id));
  };

  const uploadAssetsHandler = async (files) => {
    try {
      const formData = new FormData();
      for await (const file of files) {
        formData.append("files", file);
      }
      formData.append("description", "test data");
      formData.append("type", "collection");
      formData.append("state", "complete");
      selectedAlbum?._id && formData.append("album_id", selectedAlbum?._id);
      dispatch(uploadAssetsForContribution({ id, formData }));
    } catch (e) {
      console.warn(e);
    }
  };

  const mainTitleObj =
    contributerData?.digital_collections?.[0]?.fieldsData.filter(
      (a) => a.main_title && a.visible,
    )?.[0];

  const RightSectionOfContribute = {
    detailForm: (
      <SaveDetailForms
        activeComponent={activeComponent}
        unMountActiveComponent={unMountActiveComponent}
        contributerData={
          contributerData ? contributerData?.digital_collections : []
        }
        id={id}
        addressMapFieldId={addressMapFieldId}
      />
    ),
    keywords: (
      <Keywords
        contributerData={contributerData}
        activeComponent={activeComponent}
        unMountActiveComponent={unMountActiveComponent}
        keyWordArr={keyWordArr}
        setkeyWordArr={setkeyWordArr}
        id={id}
      />
    ),
    location: addressMapFieldId && (
      <SaveMapLocation
        getSelectedLocation={defaultLocation}
        addressMapFieldId={addressMapFieldId}
        onChange={(e) => {
          setDefaultLocation(e);
        }}
        unMountActiveComponent={unMountActiveComponent}
        id={id}
        contributerData={
          contributerData ? contributerData?.digital_collections : []
        }
      />
    ),
  };

  return (
    <Layout>
      <ContributeStyle>
        <div className={`container ${headerFix ? "headingSticky" : ""}`}>
          <ShowcaseHeading
            activeComponent={activeComponent}
            activeCompObj={activeCompObj}
            mainTitleObj={mainTitleObj}
            unMountActiveComponent={unMountActiveComponent}
          />
          <ShowcaseHeading
            activeComponent={activeComponent}
            activeCompObj={activeCompObj}
            mainTitleObj={mainTitleObj}
            unMountActiveComponent={unMountActiveComponent}
            sticky={true}
          />
          <div ref={headingRef} />
          <div className="assetsBlock">
            <div className="assetsDetails">
              <Description
                contributerData={
                  contributerData ? contributerData?.digital_collections : []
                }
                activeComponent={activeComponent}
                unMountActiveComponent={unMountActiveComponent}
                uploadAssetsHandler={uploadAssetsHandler}
                mountDetailSection={() =>
                  setActiveComponent(activeCompObj.detailForm)
                }
              />
              <EditDetailForms
                // show details on left section
                mountDetailSection={() =>
                  setActiveComponent(activeCompObj.detailForm)
                }
                unMountActiveComponent={unMountActiveComponent}
                contributerData={
                  contributerData ? contributerData?.digital_collections : []
                }
                activeComponent={activeComponent}
                addressMapFieldId={addressMapFieldId}
              />
              <EditKeyword
                mountKeyWordSection={() =>
                  setActiveComponent(activeCompObj.keywords)
                }
                unMountActiveComponent={unMountActiveComponent}
                keyWordArr={keyWordArr}
                setkeyWordArr={setkeyWordArr}
                activeComponent={activeComponent}
              />
              {addressMapFieldId && (
                <EditMapLocation
                  mountMapSection={() =>
                    setActiveComponent(activeCompObj.location)
                  }
                  unMountActiveComponent={unMountActiveComponent}
                  activeComponent={activeComponent}
                  selectedLocation={defaultLocation}
                />
              )}
            </div>
            <div className="asstsFormBlock">
              {RightSectionOfContribute[activeComponent] ? (
                RightSectionOfContribute[activeComponent]
              ) : (
                <UploadSection
                  activeComponent={activeComponent}
                  selectedAlbum={selectedAlbum}
                  setSelectedAlbum={setSelectedAlbum}
                  uploadAssetsHandler={uploadAssetsHandler}
                  unMountActiveComponent={unMountActiveComponent}
                />
              )}
            </div>
          </div>
        </div>
      </ContributeStyle>
    </Layout>
  );
};

export default Contribute;
