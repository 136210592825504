import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/system";
import PropTypes from "prop-types";
import TextTag, { textVariants } from "components/Typography/TextTag";

const StyledDiv = styled("div")(({ theme }) => ({
  position: "relative",
  height: "40px",
  borderRadius: "8px",
  boxShadow: "unset",
  border: "1px solid #BDC0C4",
  padding: "11px 16px",
  fontFamily: "poppins",
  fontSize: "12px",
  fontWeight: "500",
  outline: "unset",
  color: "#212A36",
  'input[type="time"].timeField': {
    width: "100%",
    height: "40px",
    borderRadius: "8px",
    border: `1px solid ${theme.palette.gray_300.main}`,
    backdropFilter: "blur(2px)",
    fontSize: "12px",
    fontWeight: "500",
    fontFamily: "poppins",
    padding: "8px 16px",
    outline: "unset",
    "&::-webkit-calendar-picker-indicator": {
      display: "none",
    },
  },
  ".timeInput": {
    display: "flex",
    alignItems: "center",
    position: "relative",
    ".timeB": {
      position: "relative",
      input: {
        maxWidth: "max-content",
        width: "15px",
        border: "0",
        padding: "0",
        textAlign: "center",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "18px",
        color: "#212A36",
        outline: "unset",
      },
      span: {
        fontSize: "15px",
        fontFamily: "Poppins",
        color: "black",
      },

      "&.bottomLine": {
        "&:after, &:before": {
          content: "''",
          position: "absolute",
          width: "7px",
          left: "0",
          height: "1px",
          bottom: "0",
          background: theme.palette.gray_400.main,
        },
        "&:before": {
          right: "0",
          left: "unset",
        },
      },

      "&:nth-child(3)": {
        width: "calc(100% - 86px)",
        input: {
          width: "100%",
          textAlign: "left",
          maxWidth: "100%",
        },
        "&:before": {
          left: "8px",
          right: "unset",
        },
      },
    },
  },
  ".timeBlock": {
    maxWidth: "max-content",
    borderRadius: "30px",
    overflow: "hidden",
    position: "absolute",
    right: "16px",
    top: "8px",
    height: "24px",
    display: "flex",
    alignItems: "center",
    button: {
      padding: "2px 8px",
      height: "24px",
      background: "#E9E9E9",
      border: "0",
      outline: "unset",
      cursor: "pointer",
      "&.active": {
        backgroundColor: "#0056B8",
        color: theme.palette.whiteColor.main,
      },
    },
  },
}));

const TimeFieldComp = ({ disabled, onChange }) => {
  const refData = useRef();
  const hourRef = useRef();

  const [activeButton, setActiveButton] = useState("AM");

  const [timeValues, setTimeValues] = useState({ hours: "", minutes: "" });

  useEffect(() => {
    if (timeValues.hours !== "" && timeValues.minutes !== "") {
      const time = `${timeValues.hours}:${timeValues.minutes} ${activeButton}`;
      onChange(time);
    } else {
      onChange("");
    }
  }, [onChange, activeButton, timeValues]);

  function _handleBlur(action = "hours") {
    return (events) => {
      const timeValue = events.target.value;
      if (timeValue === "") {
        setTimeValues((prv) => ({ ...prv, [action]: "" }));
      } else {
        let CURRENT_TIME = "";
        if (!isNaN(timeValue)) {
          CURRENT_TIME =
            timeValue?.length === 1 ? `0${timeValue}` : timeValues?.[action];
          setTimeValues((prv) => ({ ...prv, [action]: CURRENT_TIME }));
        }
      }
    };
  }

  function _handleTimeValues(action = "hours") {
    return (events) => {
      if (
        events.target.value !== "" &&
        events.target.value.length <= 2 &&
        (action !== "hours" || events.target.value !== "00")
      ) {
        let CURRENT_TIME = "";
        if (!isNaN(events.target.value)) {
          if (action === "hours") {
            CURRENT_TIME =
              +events.target.value <= 12
                ? events.target.value
                : timeValues.hours;
          }
          if (action === "minutes") {
            CURRENT_TIME =
              +events.target.value <= 59
                ? events.target.value
                : timeValues.minutes;
          }
          setTimeValues((prv) => ({ ...prv, [action]: CURRENT_TIME }));
        }
      } else {
        setTimeValues((prv) => ({ ...prv, [action]: "" }));
      }
    };
  }

  function handleMinuteKeydown(e) {
    if (e.code === "Backspace" && e.target.value === "") {
      hourRef.current.focus();
    }
    if (e.code === "ArrowLeft" && e.target.selectionStart === 0) {
      hourRef.current.focus();
    }
  }

  function handleHourKeyDown(e) {
    const value = e.target.value;
    const keyArr = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "0"];
    const isEndOfInput = e.target.selectionStart === value.length;
    const isFullLength = value.length === 2;
    const isSingleDigit = value.length === 1;

    if (e.code === "ArrowRight" && isEndOfInput) {
      refData.current.focus();
    }

    if (
      keyArr.includes(e.key) &&
      ((isFullLength && isEndOfInput) ||
        (isSingleDigit && +value > 1 && e.target.selectionStart === 1) ||
        (isFullLength && +value > 9 && e.target.selectionStart === 2))
    ) {
      refData.current.focus();
    }
  }

  const handleButtonClick = (e, period) => {
    e.preventDefault();
    setActiveButton(period);
  };

  return (
    <StyledDiv className={disabled ? "disabled" : ""}>
      <div className="timeInput">
        <div className={`timeB ${!timeValues?.hours ? "bottomLine" : ""}`}>
          <input
            type="text"
            placeholder=""
            ref={hourRef}
            value={timeValues?.hours}
            onChange={_handleTimeValues("hours")}
            onBlur={_handleBlur("hours")}
            onKeyDown={handleHourKeyDown}
          />
        </div>
        <span>:</span>
        <div className={`timeB ${!timeValues?.minutes ? "bottomLine" : ""}`}>
          <input
            type="text"
            ref={refData}
            id="minutes"
            placeholder=""
            value={timeValues?.minutes}
            onChange={_handleTimeValues("minutes")}
            onBlur={_handleBlur("minutes")}
            onKeyDown={handleMinuteKeydown}
          />
        </div>
      </div>
      <div class="timeBlock">
        <button
          onClick={(e) => handleButtonClick(e, "AM")}
          className={`amButton ${activeButton === "AM" ? "active" : ""}`}
          disabled={disabled}
        >
          <TextTag variant={textVariants._12px.medium}>AM</TextTag>
        </button>
        <button
          onClick={(e) => handleButtonClick(e, "PM")}
          className={`pmButton ${activeButton === "PM" ? "active" : ""}`}
          disabled={disabled}
        >
          <TextTag variant={textVariants._12px.medium}>PM</TextTag>
        </button>
      </div>
    </StyledDiv>
  );
};

TimeFieldComp.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default TimeFieldComp;
