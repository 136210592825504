import React from "react";
import { t } from "i18next";
import { StyledContributePopupBodySection } from "../contributePopupBodyCompStyles";
import SelectComp from "components/Select/SelectComp";
import ContributePopSectionHeader from "../ContributePopSectionHeader/ContributePopSectionHeader";

const WhatAreYouContributing = ({
  setOpenCollectionType,
  activeContributingObj,
  setContributionType,
}) => {
  const whatContributeData = [
    { name: t("digitalAssets"), id: activeContributingObj.asset },
    { name: t("collection"), id: activeContributingObj.collection },
  ];
  return (
    <StyledContributePopupBodySection>
      <ContributePopSectionHeader
        sectionHeading={t("whatAreYouContributing")}
        isRequired={true}
        tooltipHeading={t("whatAreYouContributing")}
        tooltipContent={t("whatContributingStaticToolip")}
      />
      <SelectComp
        selectData={whatContributeData}
        selectName={"name"}
        selectValue={"id"}
        onSelectChange={(i) => {
          if (i?.id === "collection") {
            setOpenCollectionType(activeContributingObj.collection);
            setContributionType(activeContributingObj.collection);
          } else if (i?.id === "asset") {
            setOpenCollectionType(activeContributingObj.asset);
            setContributionType(activeContributingObj.asset);
          } else {
            setOpenCollectionType(false);
          }
        }}
      />
    </StyledContributePopupBodySection>
  );
};

export default WhatAreYouContributing;
