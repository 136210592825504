import { createSlice } from "@reduxjs/toolkit";
import {
  addDepartment,
  deleteDepartment,
  getActiveDeartment,
  getAllDepartments,
  getDepartemtsByBusinessUnitApi,
  getDepartmentFields,
  getDepartments,
  updateDepartment,
  updateDepartmentStatus,
  getSecondaryByPrimaryId, // New action import
} from "./departmentAction";

const initialState = {
  departments: [],
  activeDepartments: "",
  fieldValues: [],
  businessDepartments: [],
  secondaryOptions: [], // New state for secondary options
};

const departmentSlice = createSlice({
  name: "department",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDepartments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDepartments.fulfilled, (state, action) => {
        state.status = "idle";
        state.departments = action.payload;
      });

    //getAllDepartments
    builder
      .addCase(getAllDepartments.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllDepartments.fulfilled, (state) => {
        state.status = "idle";
      });

    builder
      .addCase(addDepartment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addDepartment.fulfilled, (state) => {
        state.status = "idle";
      });

    builder
      .addCase(deleteDepartment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDepartment.fulfilled, (state) => {
        state.status = "idle";
      });

    builder
      .addCase(updateDepartment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDepartment.fulfilled, (state) => {
        state.status = "idle";
      });

    builder
      .addCase(updateDepartmentStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDepartmentStatus.fulfilled, (state) => {
        state.status = "idle";
      });

    builder
      .addCase(getActiveDeartment.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getActiveDeartment.fulfilled, (state) => {
        state.status = "idle";
      });

    //getDepartemtsByBusinessUnitApi
    builder
      .addCase(getDepartemtsByBusinessUnitApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDepartemtsByBusinessUnitApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.businessDepartments = action.payload;
      });

    //filter
    builder
      .addCase(getDepartmentFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDepartmentFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });

    // Handle fetching secondary options based on primary ID
    builder
      .addCase(getSecondaryByPrimaryId.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSecondaryByPrimaryId.fulfilled, (state, action) => {
        state.status = "idle";
        state.secondaryOptions = action.payload;
      })
      .addCase(getSecondaryByPrimaryId.rejected, (state, action) => {
        state.status = "idle";
        state.secondaryOptions = action.payload;
      });
  },
});

// Selectors
export const selectAllDepartment = (state) =>
  state.department.departments.departments;

export const allDeprtMentsWithFilterData = (state) =>
  state.department.departments;

export const departmentfieldValueArray = (state) =>
  state?.department?.fieldValues?.departments;

export const businessDepartmentsData = (state) =>
  state?.department?.businessDepartments;

export const selectSecondaryOptions = (state) =>
  state?.department?.secondaryOptions; // New selector for secondary options

export default departmentSlice.reducer;
