import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Box,
} from "@mui/material";
import PaginationLimitDropDown from "pages/Components/PaginationDropdown/PaginationLimitDropDown";
import CustomButton from "components/Button/CustomButton";
import Sorting from "utils/sorting";
import { selectAllRegion } from "pages/Admin-Pages/groups/features/groupSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getRegions } from "pages/Admin-Pages/groups/features/groupAction";
import { getSecondaryByPrimaryId } from "../features/departmentAction";

const TertiaryGroupHead = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const regions = useSelector(selectAllRegion);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPrimarySelected, setIsPrimarySelected] = useState(false);

  const {
    selectedPrimaryValue,
    setSelectedPrimaryValue,
    selectedValue,
    setSelectedValue,
    businessData,
    setDataState,
    settingNewDepartment,
    addNewDepartment,
    departmentName,
    alphaNumErrorAdd,
    dataState,
    setSelectedRows,
  } = props;

  useEffect(() => {
    dispatch(getRegions());
  }, [dispatch]);

  const getSecondaryGroupHead = (e) => {
    const primaryId = e?.target?.value;
    setSelectedPrimaryValue(primaryId);
    setSelectedValue(" ");
    dispatch(getSecondaryByPrimaryId(primaryId));
    setErrorMessage("");
    setIsPrimarySelected(true);
  };

  useEffect(() => {
    if (isPrimarySelected && businessData?.length === 0) {
      setErrorMessage(t("noOptionsAvailable"));
    } else {
      setErrorMessage("");
    }
  }, [businessData, isPrimarySelected, t]);

  return (
    <Box className="table-upper customeBar_  withOutViewIcon">
      <Box className="leftSideInputs">
        <Box className={`addNewDivSelect`}>
          <FormControl
            variant="outlined"
            className={"formControl"}
            size="small"
          >
            <Select
              className="permission-role-select"
              value={selectedPrimaryValue}
              onChange={getSecondaryGroupHead}
              defaultValue={"-1"}
            >
              <MenuItem value={"-1"} className="select-none">
                {t("selectPrimary")}
              </MenuItem>
              {regions &&
                Array.isArray(regions) &&
                Sorting(regions, "name")?.map((data, i) => {
                  return (
                    data?.enabled && (
                      <MenuItem key={i} value={data?._id}>
                        {data?.name}
                      </MenuItem>
                    )
                  );
                })}
            </Select>
          </FormControl>
        </Box>
        <Box className={`addNewDivSelect`}>
          <FormControl
            variant="outlined"
            className={"formControl"}
            size="small"
            disabled={selectedPrimaryValue === "-1"}
          >
            <Select
              className="permission-role-select"
              value={selectedValue}
              onChange={(e) => setSelectedValue(e.target.value)}
              defaultValue={" "}
            >
              <MenuItem value={" "} className="select-none">
                {t("selectSecondary")}
              </MenuItem>
              {businessData && businessData?.length > 0
                ? Sorting(businessData, "name")?.map((data, i) => {
                    return (
                      data?.enabled && (
                        <MenuItem key={i} value={data?._id}>
                          {data?.name}
                        </MenuItem>
                      )
                    );
                  })
                : null}
            </Select>
          </FormControl>
          {isPrimarySelected && errorMessage && (
            <span className="errorClass">{errorMessage}</span>
          )}
        </Box>
        <Grid container spacing={4} className={`addNew`}>
          <Box className={"addNewDiv"}>
            <TextField
              placeholder={t("createTertiary")}
              size="small"
              className={"addNewText"}
              variant="outlined"
              onChange={settingNewDepartment}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  addNewDepartment();
              }}
              value={departmentName}
            />
          </Box>
          <Box className={"addNewDiv1"}>
            <CustomButton onClick={() => addNewDepartment()} variant="outlined">
              {t("addTertiary")}
            </CustomButton>
          </Box>
          <span className="errorClass">{alphaNumErrorAdd}</span>
        </Grid>
        <Box className="addUserPagesBtn">
          <PaginationLimitDropDown
            dataState={dataState}
            setDataState={setDataState}
            setSelectedRows={setSelectedRows}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TertiaryGroupHead;
