import { styled } from "@mui/system";

const ContributeStyle = styled("div")(({ theme }) => ({

  padding: "48px 64px",
  "@media screen and (width < 992px)": {
    padding: "48px 15px",
  },
  ".container.headingSticky": {
    ".assetsBlock": {
      // marginTop: "121px",
    },
  },

  ".backButtonCard": {
    // display: "flex",
    // gap: "16px",
    // position: "sticky",
    // top: "100px",
    zIndex: "1",
    width: "100%",
    // maxWidth: "calc(1440px - 128px)",
    margin: "auto",
    "@media only screen and (width >= 1920px)": {
      maxWidth: "calc(1920px - 128px)",
    },
  },
  ".headingBlock ": {
    maxWidth: 'calc(1920px - 128px)',
    margin: '0 auto 32px',
    h2: {
      marginBottom: "0",
      marginTop: "24px",
      color: theme.palette.blackColor.main,
    },
    ".titleBlock": {
      "&:empty": {
        marginTop: "24px",
      },
    },
    "&.headingSticky": {
      position: "fixed",
      top: "0",
      background: "#ffffff",
      zIndex: "9",
      width: "100%",
      left: "0",
      display: "flex",
      alignItems: "center",
      flexDirection: "row-reverse",
      padding: "16px 40px",
      borderBottom: "1px solid #f2f2f2",
      maxWidth: "100%",
      ".backButtonCard": {
        margin: "0",
        maxWidth: "fit-content",
      },
      ".titleBlock": {
        flex: "1",

        "> div": {
          marginBottom: "0",
          h2: {
            margin: "0",
            fontSize: "24px",
          },
        },
        "&:empty": {
          marginTop: "24px",
        },
      },
      "&.upScrolling": {
        top: "0",
      },
    },
    "&.fixedTitle": {
      position: 'fixed',
      display: 'none',
      "&.headingSticky": {
        display: "flex",
      }
    }
  },
  ".assetsBlock": {
    display: "flex",
    // gridTemplateColumns: 'repeat(2,1fr)',
    // gap: '144px',
    // maxWidth: "calc(1440px - 128px)",
    maxWidth: "100%",
    margin: "auto",
    justifyContent: "space-between",
    overflow: "auto",
    gap: "15px",
    "@media only screen and (width >= 1920px)": {
      maxWidth: "calc(1920px - 128px)",
    },

    ".assetsDetails": {
      // flex: "0 0 42%",
      // maxWidth: "calc(42% - 23px)",
      flex: "0 0 48%",
      maxWidth: "calc(50% - 32px)",
      "@media screen and (width < 1200px)": {
        minWidth: '450px',
      },
      " > div:not(:last-child)": {
        borderBottom: '1px solid #E9E9E9',
        paddingBottom: '16px',
        marginBottom: '32px',
      },
      "@media only screen and (width >= 1920px)": {
        // maxWidth: "calc(42% - 24px)",
      },
      button: {
        svg: {
          width: "16px",
          height: "16px",
        },
        span: {
          marginLeft: "8px",
          fontSize: "12px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
    },
    ".asstsFormBlock": {
      // flex: "0 0 58%",
      // maxWidth: "calc(58% - 40px)",
      // position: "relative",
      // zIndex: "1",

      flex: "0 0 58%",
      maxWidth: "calc(50% - 32px)",
      position: "relative",
      zIndex: "1",
      " @media only screen and (width >= 1920px)": {
        maxWidth: "calc(50% - 16px)",
      },
      "@media screen and (width < 1200px)": {
        minWidth: '450px',
      },
      ".titleAndButton": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "32px",
        h3: {
          margin: "0",
        },
        button: {
          padding: "8px 12px",
          height: "24px",
          fontSize: "12px!important",
        },
      },
    },
  },
}));

export default ContributeStyle;
