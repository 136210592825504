import { styled } from "@mui/system";

export const LayoutStyles = styled("div")(({ theme }) => ({
  overflow: "auto",
  ".main": {
    ".innMainBlock": {
      // transform: 'translateX(0px)',
      transition: "all 0.2s linear",
      ".adminHeader": {
        position: 'fixed',
        width: '100%',
      },
      "&:has(.adminHeader)": {
        paddingTop: '80px',
      },
      "&.headerMenuOpen": {
        transform: "translateX(-290px)",
        position: "relative",
        pointerEvents: "none",

        "+ .headerMenuBlock": {
          right: "0",
        },
        "&:after": {
          content: '""',
          position: "absolute",
          width: "100%",
          background: "#00000078",
          height: "100%",
          top: "0",
          zIndex: "99",
          cursor: "pointer",
          pointerEvents: "none",
        },
      },
    },
    ".headerMenuBlock": {
      width: "100%",
      minWidth: "289px",
      position: "fixed",
      top: "0",
      zIndex: "9",
      maxWidth: "289px",
      right: "-290px",
      transition: "all 0.2s linear",
      nav: {
        height: "100vh",
      },
    },
    "&.headerMenuOpen ": {
      transform: "translateX(-289px)",
      "&:after": {
        content: '""',
        position: "absolute",
        width: "100%",
        background: "#00000078",
        height: "100%",
        top: "0",
        zIndex: "9",
        cursor: "pointer",
        pointerEvents: "none",
      },
    },
  },
}));
