import React, { useState } from "react";
import { useFormik } from "formik";
import dayjs from "dayjs";
import "dayjs/locale/en-gb";
import { Box } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CustomButton from "components/Button/CustomButton";

const ScheduleForLater = ({ handleClose }) => {
  const Dayjs = dayjs();
  const defaultTime = dayjs().add(15, "minute");
  const [disableTime, setDisableTime] = useState(true);

  const formik = useFormik({
    initialValues: { date: Dayjs, time: defaultTime },
    onSubmit: (values) => {},
  });

  const disable = (date) => {
    const disableBoolean =
      date.format("DD-MM-YYYY") <= Dayjs.format("DD-MM-YYYY");
    if (disableBoolean) {
      setDisableTime(true);
      formik?.setFieldValue("time", defaultTime);
    } else {
      setDisableTime(false);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={"en-gb"}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ mt: 10 }}>
          <label className="label">Select Date And Time</label>

          <DatePicker
            name="date"
            disablePast
            value={formik?.values?.date}
            onChange={(date, error) => {
              if (error.validationError === null) {
                formik?.setFieldValue("date", date);
              } else {
                formik?.setFieldValue("date", Dayjs);
              }
              disable(date);
            }}
          />
          <TimePicker
            name="time"
            clearable
            ampm={false}
            value={formik?.values?.time}
            disablePast={disableTime}
            onChange={(time, error, uu) => {
              error.validationError === null
                ? formik?.setFieldValue("time", time)
                : formik?.setFieldValue("time", defaultTime);
            }}
          />
        </Box>
        <Box sx={{ my: 10, textAlign: "center" }}>
          <CustomButton type="submit">Submit</CustomButton>
          <CustomButton onClick={handleClose}>Cancel</CustomButton>
        </Box>
      </form>
    </LocalizationProvider>
  );
};

export default ScheduleForLater;
