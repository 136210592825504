const GlobalStyle = (theme) => ({
  "@font-face": {
    fontFamily: "Cera Regular",
    src: `url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.eot"),
          url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.eot?#iefix") format("embedded-opentype"),
          url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.woff2") format("woff2"),
          url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.woff") format("woff"),
          url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.ttf") format("truetype"),
          url("https://db.onlinewebfonts.com/t/2d00a81dcb1012bbeabf5b52f87f1c34.svg#Cera Regular") format("svg")`,
  },
  "@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap')": true,

  "::-webkit-scrollbar": { width: "8px", height: "8px" },
  "::-webkit-scrollbar-track": { borderRadius: "10px" },
  "::-webkit-scrollbar-thumb": {
    background: theme.palette.primary.main,
    borderRadius: "10px",
  },

  main: {
    background: theme.palette.white.main,
    maxWidth: "calc(100% - 350px)",
    width: "100%",
    margin: "0 0 0 auto",
    transition: " all 0.2s linear",
    minHeight: "calc(100vh - 80px)",
  },

  "ul.MuiList-padding": { paddingTop: "0", paddingBottom: "0" },

  ".Mui-disabled": { cursor: "not-allowed" },
  ".successToastProgress": {
    background: "#041E42",
    transformOrigin: "right",
  },
  ".headerMenu.profileMenu": {
    " .selected": {
      backgroundColor: "#041E42",
      color: theme.palette.white.main,
    },
  },
  ".text_capitalize": {
    textTransform: "capitalize",
  },
  ".successToastBody svg": {
    fill: "#041E42",
  },
  ".successToastBody": {
    color: "#041E42",
  },
  ".Toastify__progress-bar.Toastify__progress-bar--animated.Toastify__progress-bar-theme--light.loading_hideProgrssBar":
  {
    transformOrigin: "right !important",
  },

  ".dblock": {
    display: "block",
  },

  ".inputWithError": { position: "relative", display: "grid", gap: "7px" },
  ".inputWithError span.errorClass": {
    position: "absolute",
    bottom: "-7px",
    fontSize: "10px",
  },

  ".link .linkIcon svg": { width: "30px", height: "30px" },

  ".d-flex": {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  ".newTableBox .MuiGrid-root": { paddingTop: "0" },

  ".addNewDivSelect  .MuiSelect-select": { textAlign: "left" },

  ".addNewDivSelect": {
    width: "100%",
    maxWidth: "max-content",
    position: "relative",
    marginRight: "15px",
  },
  ".addNewDivSelect > .MuiInputBase-root": {
    height: "40px",
  },

  ".addNewDivSelect .MuiSelect-select": {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  "html body svg.deleteIcon": {
    background: "transparent",
    fill: "#7b7b7f",
    transform: "scale(1.4)",
  },

  ".actionIcons svg.viewEyeIcon": {
    background: "transparent",
    fill: "#707070",
  },
  ".withIcon": {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    justifyContent: "center",
  },

  button: {
    textTransform: "capitalize !important",
    fontSize: "16px!important",
    fontWeight: "400!important",
    height: "40px",
  },
  ".popup-main .MuiDialogContent-root": { padding: "25px 0 0" },

  "option.select-none": {
    display: "none",
  },
  "span.errorClass": { fontSize: "12px", color: "red" },
  ".font-color-black": {
    color: theme.palette.black.main,
  },
  ".datatables-noprint .MuiTypography-root": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
  },
  "button:is(.yes-button)": {
    background: theme.palette.white.main,
    color: "#0056b8",
  },
  '.MuiTooltip-popper[data-popper-placement*="bottom"] .css-ja5taz-MuiTooltip-tooltip':
  {
    transformOrigin: "center top",
    marginTop: "14px",
    fontSize: "10px !important",
    padding: "1px 8px",
    fontWeight: 200,
  },
  ".center": {
    textAlign: "Center",
  },

  ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiMenu-paper.MuiPopover-paper.MuiMenu-paper":
  {
    maxHeight: "30vh",
  },

  ".withIcon svg.MuiSvgIcon-root": { width: "21px", height: "18px" },
  ".multiFileInput": { width: "100%" },
  ".dynamic_class_popup.singleFileInput": { maxWidth: "540px", width: "100%" },
  ".dynamic_class_popup.singleFileInput .request-common": {
    width: "calc(100%)",
  },
  ".dynamic_class_popup.singleFileInput .request-common:has(textarea) span.errorClass": {
    bottom: '-18px',
    top: 'unset',
  },

  ".category_cell": { display: "flex", alignItems: "center" },
  ".manageHierarchy .hierarchyBox span.errorClass": { bottom: "-16px" },
  ".MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper > div":
  {
    width: "auto",
  },
  ".mr-1": { marginRight: "15px" },
  ".thumbImage img ": {
    width: " 100%",
    maxWidth: "56px",
    height: "100px",
    objectFit: "contain",
  },

  "@media screen and (max-width:1366px)": {
    "h1.typo": {
      fontSize: "22px",
    },

    button: {
      fontSize: "14px!important",
    },
    ".thumbImage img ": {
      maxWidth: "50px",
      height: "40px",
    },
  },

  "span.MuiTabs-indicator": { display: "none" },

  ".addViewTable": { paddingTop: "50px" },

  ".tss-qbo1l6-MUIDataTableToolbar-actions": { alignItems: "center" },
  ".MuiCheckbox-root": { color: "#707070!important" },

  "h2.subTitle_": { color: "#4A4A4A" },
  ".fieldMultiSelectHeader svg.MuiSvgIcon-root": { cursor: "pointer" },
  ".checkBoxesBlockArea": { display: "flex" },
  ".MuiPopover-paper": { boxShadow: "unset", border: "1px solid #ebebeb" },

  ".ag-header-cell.ag-column-first.checkboxColumn.ag-focus-managed .ag-labeled":
  {
    margin: "0",
  },
  ".ag-header-cell.ag-column-first.checkboxColumn.ag-focus-managed .ag-header-cell-comp-wrapper":
  {
    display: "none",
  },
  ".tooltip_main_outer": {
    lineHeight: "normal",
    position: "relative",
  },
  ".tooltip_title_wrapper button.MuiButtonBase-root": {
    padding: "0",
    height: "20px",
    background: "transparent",
  },
  ".dynamic_class_popup.subCategoryPopup": {
    maxWidth: "575px",
  },

  "ul.notificationMenuList": {
    overflow: "hidden",
  },
  //mui language style
  ".MuiPopover-paper  ul li.svgOption ": { gap: "11px" },

  ".MuiPopover-paper  ul li.svgOption svg ": {
    width: "28px",
    height: "20px",
  },

  ".addHyperLinkAndpage": {
    maxWidth: "700px",
    width: "100%",
    "&:has(.fr, .it)": {
      ".popupTitle": {
        maxWidth: "650px",
      },
    },
  },

  "html:has(.headerMenuOpen)": {
    overflow: "hidden",
  },

  ".buttonText": {
    display: "flex",
    justifyContent: "center",
    width: "fitContent",
    alignItems: "start",
    flexFlow: "column",
  },
  ".submit_buttons": {
    display: "flex",
  },
  // Location Style Map Start

  ".pac-container": {
    backgroundColor: "#fff",
    position: "absolute !important",
    zIndex: 1000,
    borderRadius: "2px",
    borderTop: "0px solid #d9d9d9",
    fontFamily: "Arial,sans-serif",
    WebkitBoxShadow: "0 2px 6px rgba(0,0,0,.3)",
    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.07)",
    WebkitBoxSizing: "border-box",
    boxSizing: "border-box",
    overflow: "hidden",
    marginTop: "4px",
    border: "0",
    padding: "16px",
  },
  "html body .pac-container.pac-logo .pac-item": {
    border: "0 !important",
    cursor: "pointer",
    marginBottom: "16px",
    padding: "0",
  },
  "html body .pac-container.pac-logo .pac-item span.pac-icon.pac-icon-marker": {
    display: "none",
  },
  "html body .pac-container.pac-logo .pac-item span": {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.14px",
  },
  "html body .pac-container.pac-logo .pac-item span:not(.pac-item-query)": {
    color: "#BDC0C4",
  },
  "html body .pac-container.pac-logo .pac-item  span.pac-item-query span": {
    color: "#676D75",
  },
  "html body .pac-container.pac-logo:after": { display: "none" },
  "html body .pac-container.pac-logo .pac-item:hover span": {
    color: "#0056b8 !important",
  },
  "html body .pac-container.pac-logo .pac-item:hover": { background: "#fff" },
  "html body .pac-container.pac-logo .pac-item:last-child": {
    marginBottom: "0",
  },

  ".displaySectionPopUp": {
    padding: "0 15px",
    " .MuiBox-root": {
      width: "100%",
      maxWidth: "max-content",
      maxHeight: "max-content",
      overflow: "hidden",

      background: "transparent",
      boxShadow: "unset",
      img: {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
        maxHeight: "678px",
        maxWidth: "1174px",
        borderRadius: "16px",
      },
      button: {
        position: "absolute",
        top: "16px",
        right: "16px",
        width: "32px",
        padding: "0",
        minWidth: "32px",
        "&:hover": {
          borderColor: theme.palette.primaryColor.main,
        }
      },
    },
  },
  ".popupGrid": {
    width: "140px",
    padding: "16px",
    border: "0",
    ".backButtonClass": {
      background: 'transparent!important',
      border: '0',
      padding: '0',
      width: '100%',
      maxWidth: '100%',
      justifyContent: 'flex-start',
      "&:hover": {
        background: "transparent",
        color: "#0056B8",
        svg: {
          path: {
            stroke: "#0056B8",
          },
        },
      },

      "&:not(:last-child)": {
        marginBottom: "16px",
      },
    },
  },
  // Location Style Map End
  ".table-upper .leftSideInputs .addNewDivSelect span.errorClass": {
    position: "absolute",
    width: "100%",
    left: "0",
    bottom: "-17px"
  }
});

export default GlobalStyle;
