import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";
import { footerDamMetrics, rightsReservedContent } from "./footerDummy";
import { pagesCmsState } from "pages/Admin-Pages/pages-cms/features/pagesCmsSlice";
import { getHierarchyofPagesCmsFE } from "pages/Admin-Pages/pages-cms/features/pagesCmsAction";
import { routeConfigs } from "utils/routeConfig";
import FooterLanguageChange from "./components/FooterLanguageChange";
import { getGeneralFooterPagesApi } from "pages/Admin-Pages/pages-cms/FooterGeneralPages/features/footerGeneralPagesAction";
import { footerGeneralPagesData } from "pages/Admin-Pages/pages-cms/FooterGeneralPages/features/footerGeneralPagesSlice";
import { deepCopy } from "helper/deepCopyFunction";
import TcdamBlueText from "components/Logos/TcdamBlueText";
import TextTag, { textVariants } from "components/Typography/TextTag";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import { FooterStyle } from "./footerStyles";

const Footer = () => {
  const dispatch = useDispatch();
  const orderedPages = useSelector(pagesCmsState)?.updatedpages;
  const generalFooterPageData = useSelector(footerGeneralPagesData)?.data;
  const { t } = useTranslation();
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    dispatch(getHierarchyofPagesCmsFE());
    dispatch(getGeneralFooterPagesApi());
  }, [dispatch]);

  useEffect(() => {
    const colData = deepCopy(orderedPages);
    if (colData?.length > 0) {
      const columnsMap = colData?.sort(
        (a, b) => a.display_index - b.display_index,
      );
      setColumns(columnsMap);
    }
  }, [orderedPages]);

  return (
    <FooterStyle>
      <Box className="footerMain">
        <Box className="footerUpper">
          <Box className="upperSectionInner">
            <Box className="footerTcdamLogo">
              <TcdamBlueText />
            </Box>
            <FooterLanguageChange className={"footerLogo"} />
          </Box>
          <Box className="footerLinks ">
            {columns?.map((footerSectionData, index) => {
              return (
                <Box className="upperSectionInner" key={index}>
                  <Box className="upperSectionHeading">
                    <TextTag variant={textVariants._16px.semiBold}>
                      {footerSectionData?.name}
                    </TextTag>
                  </Box>
                  <Box className="upperSectionLinks">
                    {footerSectionData?.FooterPagesData?.map(
                      (footerSectionPages, index1) => (
                        <>
                          {footerSectionPages?.type === "href" ? (
                            footerSectionPages?.target_blank === true ? (
                              <a
                                href={
                                  footerSectionPages?.description?.includes(
                                    "http",
                                  )
                                    ? footerSectionPages?.description
                                    : `https://${footerSectionPages?.description}`
                                }
                                rel="noopener noreferrer"
                                target="_blank"
                                key={index1}
                              >
                                <TextTag variant={textVariants._14px.regular}>
                                  {" "}
                                  {footerSectionPages?.title}
                                </TextTag>
                              </a>
                            ) : (
                              <a
                                href={
                                  footerSectionPages?.description?.includes(
                                    "http",
                                  )
                                    ? footerSectionPages?.description
                                    : `https://${footerSectionPages?.description}`
                                }
                                rel="noopener noreferrer"
                                key={index1}
                              >
                                <TextTag variant={textVariants._14px.regular}>
                                  {" "}
                                  {footerSectionPages?.title}
                                </TextTag>
                              </a>
                            )
                          ) : footerSectionPages?.target_blank === true ? (
                            <Link
                              to={`${routeConfigs.customCmsPage}/${footerSectionPages?.slug}`}
                              key={index1}
                              target="_blank"
                            >
                              <TextTag variant={textVariants._14px.regular}>
                                {" "}
                                {footerSectionPages?.title}
                              </TextTag>
                            </Link>
                          ) : (
                            <Link
                              to={`${routeConfigs.customCmsPage}/${footerSectionPages?.slug}`}
                              key={index1}
                            >
                              <TextTag variant={textVariants._14px.regular}>
                                {" "}
                                {footerSectionPages?.title}
                              </TextTag>
                            </Link>
                          )}
                        </>
                      ),
                    )}
                  </Box>
                </Box>
              );
            })}
            <Box className="upperSectionInner">
              <Box className="upperSectionHeading">
                <TextTag variant={textVariants._16px.semiBold}>{`Dam ${t(
                  "metrics",
                )}`}</TextTag>
              </Box>
              <Box className="footerMatrics">
                {footerDamMetrics?.map((metricData, index) => (
                  <Box className="footerMatricsSection" key={index}>
                    <Box className="footerMatricsHeading">
                      <TextTag variant={textVariants._14px.regular}>
                        {metricData?.metric_name}
                      </TextTag>
                    </Box>
                    <Box className="footerMatricsFigure">
                      <HeadingTag variant={headingVariants.h3}>
                        {" "}
                        {metricData?.metric_value?.toLocaleString()}
                      </HeadingTag>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className="bottomfooterLower">
          <Box className="footerLower">
            <Box className="lowerLeftSection">
              <TextTag variant={textVariants._12px.medium}>
                {rightsReservedContent}
              </TextTag>
            </Box>
            <Box className="lowerRightSection">
              {generalFooterPageData?.map((footerData, index) => (
                <Link
                  key={index}
                  to={`${routeConfigs.customFooterGeneralCmsPage}/${footerData.slug}`}
                >
                  <TextTag variant={textVariants._12px.medium}>
                    {footerData?.title}
                  </TextTag>
                </Link>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </FooterStyle>
  );
};

export default Footer;
