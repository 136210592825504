import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { register } from "swiper/element/bundle";
import HomeSliderTop from "../HomeSliderTop";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";
import {
  getFeaturedCollectionFEApi,
  getFeaturedCollectionTitleApi,
} from "pages/Admin-Pages/cms-changes/FeaturedAssets/features/featuredAction";
import {
  selectCollectionTitle,
  selectFeaturedCollectionsFrontend,
} from "pages/Admin-Pages/cms-changes/FeaturedAssets/features/featuredSlice";
import notFoundImage from "../FindLookingForSection/images/imageNotFound.png";
import FeaturedProjectStyles from "./featuredProjectStyles";
import { routeConfigs } from "utils/routeConfig";

register();

const FeaturedProjects = () => {
  const dispatch = useDispatch();
  const { data } = useSelector(selectFeaturedCollectionsFrontend);
  const { data: collectionNameData } = useSelector(selectCollectionTitle);

  useEffect(() => {
    dispatch(getFeaturedCollectionFEApi());
    dispatch(getFeaturedCollectionTitleApi());
  }, [dispatch]);

  const featuredProjectsData = data?.map(
    ({ digitalAssetCollection, featureCollectionImage }) => {
      const { clientId, description, name, uniqueId, _id } =
        digitalAssetCollection;

      return {
        project_image: featureCollectionImage || notFoundImage,
        project_name: name,
        project_description: description,
        project_uid: clientId || uniqueId,
        id: _id,
      };
    },
  );

  const sectionHeading = collectionNameData?.name || collectionNameData?._id;
  const swiperElRef = useRef(null);

  // const featuredProjectsData = [
  //   {
  //     project_image: image2,
  //     project_name: "Lower.com Field",
  //     project_description:
  //       "The 460,000 square foot stadium is home to the Columbus Crew SC. Built on a 12-acre site and anchoring the west end of the Arena District, the stadium occupies 20,000-seats and a natural grass pitch",
  //     project_uid: "#121589735",
  //   },
  //   {
  //     project_image: image3,
  //     project_name: "Momentum BMW",
  //     project_description:
  //       "Sonic Automotive relocated the Momentum BMW dealership to the former location of a Sears department store on Southwest Freeway, following this adaptive-reuse renovation of the facility. The 180,000 square foot renovation of the old department store building was carried out in several phases",
  //     project_uid: "#121589785",
  //   },
  //   {
  //     project_image: image4,
  //     project_name: "Lincoln Center, David Geffen Hall",
  //     project_description:
  //       "Turner is providing construction management services for the renovation and revitalization of David Geffen Hall, a part of New York City's Lincoln Center for Performing Arts campus in Manhattan. The concert hall is home to the New York Philharmonic and hosts a variety of other public and private events",
  //     project_uid: "#129982312",
  //   },
  //   {
  //     project_image: image5,
  //     project_name: "609 H Street - Bond Collective Fitout",
  //     project_description:
  //       "The new Bond Collective coworking space is a luxury shared office workspace that offers traditional offices ranging for 1-10 people, in addition to scalable setups for teams of 10-50+ with custom floor plates, growth schedules and swing spaces",
  //     project_uid: "#121586935",
  //   },
  //   {
  //     project_image: image6,
  //     project_name: "McCarthy Tetrault LLP",
  //     project_description:
  //       "The new office space revolutionized the firm's work culture with its modern layout. The top client-facing floor included 12 client meetings rooms including video-conferencing rooms, presentation rooms, on-line meeting rooms and one divisible training/conference room inclusive of projection capabilities, blackout blinds and an operable wall",
  //     project_uid: "#121510785",
  //   },
  // ];

  const FeaturedProjectSlideComp = (props) => {
    const { imgData } = props;
    return (
      <div
        className={`featured_slide_inner 
      `}
      >
        <img
          className="featuredSlideImage"
          src={imgData?.project_image}
          alt={`swiper pic`}
        />
        <div className="slideHoverTop">
          <TextTag variant={textVariants._13px.regular}>
            {imgData?.project_uid}
          </TextTag>
        </div>
        <div className="hoverSlide">
          <div className="slideHoverBottom">
            <HeadingTag variant={headingVariants.h3}>
              {imgData?.project_name}
            </HeadingTag>
            <TextTag variant={textVariants._13px.regular}>
              {imgData?.project_description}
            </TextTag>
          </div>
        </div>
      </div>
    );
  };

  if (featuredProjectsData?.length === 0) {
    return <></>;
  }

  return (
    <FeaturedProjectStyles>
      <div className="featured_main">
        <HomeSliderTop
          sectionHeading={sectionHeading}
          swiperElRef={swiperElRef}
          slidesPerView="3.139"
        />
        <div className="featured_slider">
          <swiper-container
            ref={swiperElRef}
            slides-per-view="3.139"
            slides-offset-before="0"
            slides-offset-after="64"
            space-between="32"
          >
            {featuredProjectsData?.map((imgData, i) => (
              <swiper-slide key={imgData?.project_image}>
                <Link to={`${routeConfigs.contributePage}?id=${imgData?.id}`}>
                  <FeaturedProjectSlideComp imgData={imgData} />
                </Link>
              </swiper-slide>
            ))}
          </swiper-container>
        </div>
      </div>
    </FeaturedProjectStyles>
  );
};

export default FeaturedProjects;
