import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { register } from "swiper/element/bundle";
import HomeSliderTop from "../HomeSliderTop";
import { feGetAllSubCategoriesApi } from "pages/Admin-Pages/asset-administration/AssetSubCategory/features/assetSubCategoryAction";
import { feAllSubCategories } from "pages/Admin-Pages/asset-administration/AssetSubCategory/features/assetSubCategorySlice";
import notFoundImage from "./images/imageNotFound.png";
import FindLookingForStyles from "./FindLookingForStyles";
import { Link } from "react-router-dom";
import { routeConfigs } from "utils/routeConfig";
import TextTag, { textVariants } from "components/Typography/TextTag";

import { selectQuickThumbnailData } from "pages/Admin-Pages/quick-Thumbs/features/quickThumbSlice";
import { getQuickThumbnailApi } from "pages/Admin-Pages/quick-Thumbs/features/quickThumbAction";

const FindLookingForSection = () => {
  const { t } = useTranslation();
useEffect(() => {
  dispatch(getQuickThumbnailApi());

}, [])

  const swiperElRef = useRef(null);
  const dispatch = useDispatch();
  const allSubCategory = useSelector(feAllSubCategories);
  const quickThumbnailData=useSelector(selectQuickThumbnailData)
  const allSubCatArray = allSubCategory?.category_data;
  const imageBasePath = `${allSubCategory?.image_base_url}/${allSubCategory?.image_container_name}`;
  const SLIDESVIEW = "4.186";

  const swiperSettings = {
    init: "false",
    "slides-per-view": SLIDESVIEW,
    "slides-offset-before": "0",
    "slides-offset-after": "64",
    "space-between": "32",
  };

  useEffect(() => {
   
    const controller = new AbortController();
    dispatch(feGetAllSubCategoriesApi(controller.signal));
    return () => {
      controller.abort();
    };
  }, [dispatch]);

  useEffect(() => {
    register();
    swiperElRef.current.setAttribute("slides-per-view", SLIDESVIEW);
    swiperElRef.current.initialize();
  }, [allSubCategory]);

  const SlideComp = ({ imgData, imageSrcKey, imgSrc, type }) => {
    const [active, setActive] = useState(false);
    return (
      <Link
        to={`${routeConfigs.searchPage}?${type}=${imgData?._id}`}
        className={`findLookingForSlideMain ${active ? "active" : ""}`}
      >
        <div className="findLookingSlide">
          <img
            src={
              imgSrc && imgData?.[imageSrcKey]
                ? `${imgSrc}/${imgData?.[imageSrcKey]}`
                : notFoundImage
            }
            alt={imgData?.name}
            onMouseOver={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
          />
          <TextTag variant={textVariants._16px.semiBold}>
            {imgData?.name}
          </TextTag>
          <span></span>
        </div>
        <div className="hoverSlide">
          <div className="slideHoverTop">
            {/* not needed anymore */}
            {/*<span>
              {"#"}
              {imgData?.search_code}
            </span> */}
          </div>
        </div>
      </Link>
    );
  };

  return (
    <FindLookingForStyles>
      <div className="findLookingForSectionMain">
        <HomeSliderTop
          sectionHeading={quickThumbnailData?.title ? quickThumbnailData?.title: t("findWhatYouAreLookingFor")}
          swiperElRef={swiperElRef}
          slidesPerView={SLIDESVIEW}
        />
        <div className="featured_slider">
          <swiper-container ref={swiperElRef} {...swiperSettings}>
            {/* showing all asset categories */}
            {allSubCatArray?.map(
              (imgData, i) =>
                imgData?.enabled &&
                !imgData?.isDeleted &&
                imgData?.show_on_home_page && (
                  <swiper-slide key={i}>
                    <SlideComp
                      imgData={imgData}
                      imageSrcKey={"category_image"}
                      imgSrc={imageBasePath}
                      type={"cat"}
                    />
                  </swiper-slide>
                ),
            )}
            {/* showing all asset sub-categories */}
            {allSubCatArray?.map(
              (subImgData) =>
                subImgData?.enabled &&
                !subImgData?.isDeleted &&
                subImgData?.sub_category_data?.map(
                  (subCatData, index) =>
                    subCatData?.enabled &&
                    !subCatData?.isDeleted &&
                    subCatData?.show_on_home_page && (
                      <swiper-slide key={index}>
                        <SlideComp
                          imgData={subCatData}
                          imageSrcKey={"sub_category_image"}
                          imgSrc={imageBasePath}
                          type={"subCat"}
                        />
                      </swiper-slide>
                    ),
                ),
            )}
          </swiper-container>
        </div>
      </div>
    </FindLookingForStyles>
  );
};

export default FindLookingForSection;
