import {
  APIProvider,
  Map,
  AdvancedMarker,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { MapHandler } from ".";
import TextTag, { textVariants } from "components/Typography/TextTag";
import DetailFormLeftHeader from "pages/User-Pages/ContributePage/Components/DetailForm/components/DetailFormLeftHeader";
import { activeCompObj } from "pages/User-Pages/ContributePage/Contribute";
import MapLocationStyle from "./MapLocation.style";
import { t } from "i18next";
import _ from "lodash";
import { useQuery } from "utils/queryString";

const MAP_API_KEY = process.env.REACT_APP_MAP_API_KEY;

const EditMapLocation = ({
  selectedLocation,
  mountMapSection,
  unMountActiveComponent,
  activeComponent,
}) => {
  const viewOnly = useQuery().get("type") === "view";
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <APIProvider solutionChannel="GMP_map" apiKey={MAP_API_KEY}>
      <MapLocationStyle
        className={
          activeComponent && activeComponent !== activeCompObj.location
            ? "addOpacity"
            : ""
        }
      >
        <DetailFormLeftHeader
          title={"Locations"}
          onEditClick={mountMapSection}
          activeComponent={activeComponent === activeCompObj.location}
          onCloseClick={unMountActiveComponent}
          doNotHover={viewOnly}
        />
        {selectedLocation && !_.isEmpty(selectedLocation) ? (
          <div className="editMap-body">
            <TextTag variant={textVariants._16px.regular}>
              {selectedLocation?.formatted_address}
            </TextTag>
            <Map
              mapId={"bf51a910020fa25a"}
              className="editMap-map hideTags"
              defaultZoom={3}
              defaultCenter={{ lat: 22.54992, lng: 0 }}
              gestureHandling={"greedy"}
              disableDefaultUI={true}
            >
              <AdvancedMarker ref={markerRef} position={null} />
            </Map>
            <MapHandler place={selectedLocation} marker={marker} />
          </div>
        ) : (
          <TextTag
            variant={textVariants._16px.regular}
            className="noLocationFound"
          >
            {t("noLocationFound")}
          </TextTag>
        )}
      </MapLocationStyle>
    </APIProvider>
  );
};

export default EditMapLocation;
