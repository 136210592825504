import React from "react";

const FileIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M12.6654 6.5V12.3667C12.6654 13.1134 12.6654 13.4868 12.52 13.772C12.3922 14.0229 12.1882 14.2269 11.9374 14.3547C11.6522 14.5 11.2788 14.5 10.532 14.5H5.46536C4.71862 14.5 4.34526 14.5 4.06004 14.3547C3.80916 14.2269 3.60518 14.0229 3.47736 13.772C3.33203 13.4868 3.33203 13.1134 3.33203 12.3667V4.63333C3.33203 3.88659 3.33203 3.51323 3.47736 3.22801C3.60518 2.97713 3.80916 2.77315 4.06004 2.64533C4.34526 2.5 4.71863 2.5 5.46536 2.5H8.66536M12.6654 6.5L8.66536 2.5M12.6654 6.5H9.33203C8.96383 6.5 8.66536 6.20152 8.66536 5.83333V2.5"
        stroke="#676D75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default FileIcon;
