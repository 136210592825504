import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Divider, Box } from "@mui/material";
import { downloadCSV } from "utils/arrayToCsv";
import DialogCommon from "components/DialogComponent/DialogCommon";
import CustomButton from "components/Button/CustomButton";
import { CsvPopupStyle } from "pages/Admin-Pages/user-administration/Components/CsvPopup/CsvPopupStyle";
import { customTagCsvUpload } from "../../../features/customTagAction";
import { useParams } from "react-router-dom";
import { validationMessages } from "utils/validation/validationUtils";
import UploadIcon from "components/Icons/UploadIcon/UploadIcon";
import DownloadIcon from "components/Icons/DownloadIcon/DownloadIcon";
import { useTranslation } from "react-i18next";

const CustomTagCsvPopUp = ({ open, setOpen, setOpenSuccess }) => {
  const { id } = useParams();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const [uploadedFile, setUploadedFile] = useState();
  const [fileMessage, setFileMessage] = useState("");
  const [fileName, setFileName] = useState(t("noFileChosen"));

  function handleDownloadTemplate() {
    const templateObjArr = [
      {
        Name: "Demo",
      },
      {
        Name: "Test",
      },
    ];
    downloadCSV(templateObjArr, "tcdamCustomTagTemplateCsv");
  }

  function handleUploadChange(e) {
    setFileMessage("");
    const file = e.target.files[0];

    //checking if csv file is blank
    const reader = new FileReader();
    reader.onload = () => {
      const lines = reader.result.split("\n");
      let isCSVBlank = true;
      for (const line of lines) {
        if (line.trim().length > 1) {
          isCSVBlank = false;
          break;
        }
      }
      if (isCSVBlank) {
        setFileMessage("The CSV is blank.");
      }
    };
    reader.readAsText(file);

    const maxFileSize = 5 * 1024 * 1024; // 5mB
    if (file?.size > maxFileSize) {
      setFileMessage("The file exceeds the maximum file size of 5MB");
      return;
    }
    setUploadedFile(file);

    if (file) {
      setFileName(file?.name);
    } else {
      setFileName(t("noFileChosen"));
    }
  }

  function handleUploadRecords() {
    if (
      uploadedFile === "" ||
      uploadedFile === undefined ||
      uploadedFile === null
    ) {
      setFileMessage(validationMessages.selectFile);
      return;
    }
    if (fileMessage !== "") {
      return;
    }

    dispatch(customTagCsvUpload({ file: uploadedFile, id: id })).then((res) => {
      if (!res.error) {
        setOpenSuccess(true);
      }
    });
    setOpen(false);
  }

  return (
    <DialogCommon
      fullwidth={true}
      open={open}
      handleClose={() => setOpen()}
      title={t("uploaddownloadCsvTittle")}
      maxWidth={"md"}
    >
      <CsvPopupStyle>
        <Box className="Csv_main">
          <Box className="download_Class commanCsvBlock">
            <DownloadIcon className="downloadIcon" />
            <h3 className="text">{t("downloadCsvFormat")}</h3>
            <p>{t("downloadCsvFormatText")}</p>

            <CustomButton onClick={handleDownloadTemplate}>
              {t("downloadFormat")}
            </CustomButton>
          </Box>
          <Divider orientation="vertical" />
          <Box className="upload_Csv commanCsvBlock">
            <UploadIcon className="uploadIcon" />
            <h3 className="text"> {t("uploadCsv")}</h3>
            <p>{t("selectCsvText")} </p>
            <Box className="chooseFile">
              <input
                type="file"
                id="csvFile"
                name="csvFile"
                accept=".csv, text/csv"
                onChange={handleUploadChange}
              />
              <label>{fileName}</label>
              <span>{t("chooseFile")}</span>
            </Box>
            <span className="errorClass">{fileMessage}</span>
            <CustomButton onClick={handleUploadRecords}>
              {t("upload")}
            </CustomButton>
            <CustomButton onClick={() => setOpen(false)}>
              {t("cancel")}
            </CustomButton>
          </Box>
        </Box>
      </CsvPopupStyle>
    </DialogCommon>
  );
};

export default CustomTagCsvPopUp;
